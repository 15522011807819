import React from 'react';
import { Content } from '../components';
import { useAddAppointment, useAllAppointments, useCancelAppointment, useDeleteAppointment, useUpdateAppointment } from '../hooks/appointments';
import AddEditModal, { AddEditModalState, IaddEditModalStates } from '../pageComponents/Appointments/AddEditModal';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { loginSliceValue } from '../redux/reducers/loginSlice';
import { CustomTableRow, Table, TableBody, TableButtons, TableHead, TableImageRow, TableNoContent, TableRowTitle, TableStatusWithToggle } from '../components/Table';
import { appointmentTableHeadData } from '../hooks/appointments/staticData';
import TableSkeleton from '../Skeletons/TableSkeleton';
import { IAppointment } from '../hooks/appointments/index.type';
import CancelAppointmentModal, { IcancelModalStates } from '../pageComponents/Appointments/CancelAppointmentModal';
import { DeleteAppointmentModal } from '../pageComponents/Appointments';

const Appointments = () => {
  // ====================================== Add edit states start ======================================
  const [selectedClinicId, setSelectedClinicId] = React.useState<string>('');
  const [addEditModal, setAddEditModal] = React.useState<AddEditModalState>({
    status: false,
  });
  const startaddEditModalStatesValue = {
    user: { value: { label: 'Select Patient', value: '' }, error: '' },
    doctor: { value: { label: 'Select Provider', value: '' }, error: '' },
    appointmentDate: { value: new Date().toString(), error: '' },
    appointmentTime: { value: '', error: '' },
  };
  const [addEditModalStates, setAddEditModalStates] = React.useState<IaddEditModalStates>(startaddEditModalStatesValue);
  // ====================================== Add edit states end ======================================

  // ====================================== Cancel Appointment states start ======================================
  const [cancelModal, setCancelModal] = React.useState<AddEditModalState>({
    status: false,
  });
  const startCancelModalStatesValue = {
    reason: { value: '', error: '' },
  };
  const [cancelModalStates, setCancelModalStates] = React.useState<IcancelModalStates>(startCancelModalStatesValue);
  // ====================================== Cancel Appointment states end ======================================

  // ====================================== Delete Appointment states start ======================================
  const [deleteModal, setDeleteModal] = React.useState<AddEditModalState>({
    status: false,
  });
  // ====================================== Delete Appointment states end ======================================

  const { userDetails } = useSelector(loginSliceValue);
  const { appointmentsData, getAllAppointments, appointmentsLoading } = useAllAppointments();
  const { addAppointmentLoading, addAppointment } = useAddAppointment();
  const { updateAppointmentLoading, updateAppointment } = useUpdateAppointment();
  const { cancelAppointmentLoading, cancelAppointment } = useCancelAppointment({ appointmentId: cancelModal.data?._id });
  const { deleteAppointmentLoading, deleteAppointment } = useDeleteAppointment({ appointmentId: deleteModal.data?._id });

  React.useEffect(() => {
    if (userDetails.data)
      if (userDetails.data.user.role === 'clinic') {
        setSelectedClinicId(userDetails.data.user._id);
      } else {
        setSelectedClinicId(userDetails.data.user.clinic._id);
      }
  }, [userDetails]);

  React.useEffect(() => {
    if (addEditModal.data?._id) {
      setAddEditModalStates({
        user: { value: { label: addEditModal.data.user.name, value: addEditModal.data.user._id }, error: '' },
        doctor: { value: { label: addEditModal.data.doctor.name, value: addEditModal.data.doctor._id }, error: '' },
        appointmentDate: { value: new Date(addEditModal.data.appointmentDate).toString(), error: '' },
        appointmentTime: { value: addEditModal.data.appointmentTime, error: '' },
      });
    }
  }, [addEditModal]);

  const getEncryptedName = (name: string) => {
    if (!name) return '';

    let split = name.split(' ');
    let first_name = split[0] ? split[0].split(/\s/).reduce((response, word) => (response += word.slice(0, 1)), '') : 'NA';
    let last_name = split[1] ? '.' + split[1].slice(0, 3) : '';
    return first_name + last_name;
  };

  const tConvert = (time: any) => {
    // Check correct time format and split into components
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      // If time format correct
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(''); // return adjusted time or original string
  };

  const handleAddEdit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!addEditModalStates.user.value.value.trim()) {
      setAddEditModalStates(prevVal => ({ ...prevVal, user: { ...prevVal.user, error: 'Please select patient' } }));
    } else if (!addEditModalStates.doctor.value.value.trim()) {
      setAddEditModalStates(prevVal => ({ ...prevVal, doctor: { ...prevVal.doctor, error: 'Please select provider' } }));
    } else if (!addEditModalStates.appointmentDate.value) {
      setAddEditModalStates(prevVal => ({ ...prevVal, appointmentDate: { ...prevVal.appointmentDate, error: 'Please select appointment date' } }));
    } else if (!addEditModalStates.appointmentTime.value.trim()) {
      setAddEditModalStates(prevVal => ({ ...prevVal, appointmentTime: { ...prevVal.appointmentTime, error: 'Please select appointment time' } }));
    } else {
      let appointmentDate = moment(addEditModalStates.appointmentDate.value).format('MM/DD/YYYY');

      if (addEditModal.data?._id) {
        const formData = {
          appointmentId: addEditModal.data?._id,
          appointmentDate,
          appointmentTime: addEditModalStates.appointmentTime.value,
        };

        const data = await updateAppointment(formData);
        if (data?.status) {
          getAllAppointments();
          handleCloseAddModal();
        }
      } else {
        const formData = {
          user: addEditModalStates.user.value.value,
          clinic: selectedClinicId,
          doctor: addEditModalStates.doctor.value.value,
          appointmentDate,
          appointmentTime: addEditModalStates.appointmentTime.value,
        };

        const data = await addAppointment(formData);
        if (data?.status) {
          getAllAppointments();
          handleCloseAddModal();
        }
      }
    }
  };

  const handleCloseAddModal = () => {
    setAddEditModal({ status: false });
    setAddEditModalStates(startaddEditModalStatesValue);
  };

  const handleCancelAppointment = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (cancelModal.data?._id) {
      const data = await cancelAppointment({ cancelReason: cancelModalStates.reason.value });
      if (data?.status) {
        getAllAppointments();
        handleCloseCancelModal();
      }
    }
  };

  const handleCloseCancelModal = () => {
    setCancelModal({ status: false });
    setCancelModalStates(startCancelModalStatesValue);
  };

  const handleDeleteAppointment = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (deleteModal.data?._id) {
      const data = await deleteAppointment();
      if (data?.status) {
        getAllAppointments();
        setDeleteModal({ status: false });
      }
    }
  };

  return (
    <Content
      currentMenu='appointment'
      headerTitle='Appointments'
      addBtn={true}
      addBtnClick={() => setAddEditModal({ status: true })}
      addBtnTitle='Add Appointment'
      searchBox={false}
      // totalReult={appointmentsData && appointmentsData.data.result.length > 0 ? appointmentsData.data.result.length + ' appointments' : ''}
    >
      <Table>
        <TableHead data={appointmentTableHeadData} />
        <TableBody>
          <CustomTableRow>
            {appointmentsLoading ? (
              <TableSkeleton rows={7} columns={5} />
            ) : appointmentsData && appointmentsData.data.result.length > 0 ? (
              appointmentsData.data.result.map((appointment: IAppointment) => (
                <tr key={appointment._id}>
                  <TableImageRow alt={appointment.user.name} />
                  <TableRowTitle>{getEncryptedName(appointment.user.name)}</TableRowTitle>
                  <td>{appointment.doctor.name}</td>
                  <td>
                    {appointment.appointmentDateString ? appointment.appointmentDateString : <span>NA</span>} &nbsp;
                    {tConvert(appointment.appointmentTime)}
                  </td>

                  {!appointment.isCheckedIn && !appointment.isCheckedOut && appointment.cancelStatus ? (
                    <td>
                      <TableStatusWithToggle id={appointment._id} checked={false} onChange={() => {}} hideToggle={true} />
                    </td>
                  ) : (
                    <TableButtons
                      buttons={
                        userDetails.data?.user.role === 'clinic' || userDetails.data?.user.role === 'manager'
                          ? [
                              { icon: 'edit-3', tooltip: 'Edit Appointment', onClick: () => setAddEditModal({ status: true, data: appointment }) },
                              { icon: 'calendar-times-o', tooltip: 'Cancel Appointment', onClick: () => setCancelModal({ status: true, data: appointment }) },
                              { icon: 'trash', tooltip: 'Delete Appointment', variant: 'danger', onClick: () => setDeleteModal({ status: true, data: appointment }) },
                            ]
                          : [
                              { icon: 'edit-3', tooltip: 'Edit Appointment', onClick: () => setAddEditModal({ status: true, data: appointment }) },
                              { icon: 'calendar-times-o', tooltip: 'Cancel Appointment', onClick: () => setCancelModal({ status: true, data: appointment }) },
                            ]
                      }
                    />
                  )}
                </tr>
              ))
            ) : (
              <TableNoContent colSpan={appointmentTableHeadData.length} message='No appointments found' />
            )}
          </CustomTableRow>
        </TableBody>
      </Table>

      {addEditModal.status && (
        <AddEditModal
          loading={addAppointmentLoading || updateAppointmentLoading}
          addEditModal={addEditModal}
          addEditModalStates={addEditModalStates}
          setAddEditModalStates={setAddEditModalStates}
          onCloseModal={handleCloseAddModal}
          handleAddEdit={handleAddEdit}
        />
      )}
      {cancelModal.status && (
        <CancelAppointmentModal
          loading={cancelAppointmentLoading}
          cancelModal={cancelModal}
          cancelModalStates={cancelModalStates}
          setCancelModalStates={setCancelModalStates}
          onCloseModal={handleCloseCancelModal}
          handleCancelAppointment={handleCancelAppointment}
        />
      )}
      {deleteModal.status && (
        <DeleteAppointmentModal
          loading={deleteAppointmentLoading}
          deleteModal={deleteModal}
          onCloseModal={() => setDeleteModal({ status: false })}
          handleDeleteAppointment={handleDeleteAppointment}
        />
      )}
    </Content>
  );
};

export default Appointments;
