import { TableHeadDataProps } from '../../components/Table/TableHead';

export const adViewByClinicTableHeadData: TableHeadDataProps[] = [
  { id: 0, title: '' },
  { id: 2, title: 'Image Name' },
  { id: 3, title: 'Views' },
  { id: 4, title: 'Campaign' },
  { id: 5, title: 'Paid/Unpaid' },
];
export const adViewByClinicImageHeadData: TableHeadDataProps[] = [
  { id: 0, title: '' },
  { id: 2, title: 'Image Name' },
  { id: 3, title: 'Campaign' },
  { id: 4, title: 'Clinic Name' },
  { id: 5, title: 'Views' },
  { id: 6, title: 'Paid/Unpaid' },
];
