import React, { Fragment } from 'react';
import type { FC, PropsWithChildren } from 'react';

import { Modal, InputBox, ModalForm, InputsSectionColumn, ModalButtons } from '../../components/Modal';
import ModalHeading from '../../components/Modal/ModalHeading';
import ShareFileSkeleton from '../../Skeletons/ShareFileSkeleton';
import { DownloadModalState } from './DownloadFileModal';

export type IrenameModalStates = PropsWithChildren<{
  fileName: { value: string; error: string };
}>;

type RenameFileModalProps = PropsWithChildren<{
  loading: boolean;
  renameFileModal: DownloadModalState;
  renameFilePasswordInput: IrenameModalStates;
  setRenameFilePasswordInput: React.Dispatch<React.SetStateAction<IrenameModalStates>>;
  onCloseModal?: () => void;
  handleRenameFile?: (event: React.FormEvent<HTMLFormElement>) => void;
}>;

const RenameFileModal: FC<RenameFileModalProps> = props => {
  return (
    <Modal show={props.renameFileModal.status}>
      {props.loading ? (
        <ShareFileSkeleton fileProtected={props.renameFileModal.data?.protected} />
      ) : (
        props.renameFileModal.data && (
          <Fragment>
            <ModalHeading heading={`Rename ${props.renameFileModal.data.name}?`} />
            <ModalForm style={{ marginBottom: '2.5rem' }} onSubmit={props.handleRenameFile}>
              <InputsSectionColumn style={{ width: '34rem' }}>
                <InputBox
                  label={`Enter password to share file`}
                  placeholder='File name'
                  value={props.renameFilePasswordInput.fileName.value}
                  onChange={event => {
                    props.setRenameFilePasswordInput(prevVal => ({ ...prevVal, fileName: { value: event.target.value, error: '' } }));
                  }}
                  errorMessage={props.renameFilePasswordInput.fileName.error}
                  style={{ width: '30rem' }}
                />
              </InputsSectionColumn>
              <ModalButtons submitButtonLabel='Rename File' cancelButtonLabel='Cancel' onCancel={props.onCloseModal} />
            </ModalForm>
          </Fragment>
        )
      )}
    </Modal>
  );
};

export default RenameFileModal;
