import React from 'react';
import ModalForm from '../../../components/Modal/ModalForm';
import ModalButtons from '../../../components/Modal/ModalButtons';
import AddUserSkeleton from '../../../Skeletons/AddUserSkeleton';
import classes from './InsurancePolicies.module.css';
import { useGetAllProviders } from '../../../hooks/provider';
import { inputPhoneMasking, inputSSNMasking } from '../../../utils/validators';
import moment from 'moment';
import { ToggleSwitchWithLabel } from '../../../components';

const PatientDemographicForm = ({ handleEdit, closeInsurancePoliciesModal, demographicForm, loading }) => {
  const [providerName, setProviderName] = React.useState('...');
  const { providersData } = useGetAllProviders();

  React.useEffect(() => {
    if (demographicForm && demographicForm.data && providersData && providersData.data.result.length) {
      providersData.data.result.forEach(provider => {
        if (provider._id === demographicForm.data?.doctorName) setProviderName(provider.name);
      });
    }
  }, [demographicForm, providersData]);

  return (
    <React.Fragment>
      {loading ? (
        <AddUserSkeleton />
      ) : demographicForm.error ? (
        <div className={classes.form_content}>
          <div className={classes.form_container}>
            <p className={classes.form_container_heading}>User has not filled form yet.</p>
          </div>
        </div>
      ) : (
        Object.keys(demographicForm.data).length > 0 && (
          <div className={classes.form_content}>
            <div className={classes.form_heading}>
              <p className={classes.form_heading_content}>
                Who is your Doctor
                <span>{providerName}</span>
              </p>
            </div>

            <div className={classes.form_container}>
              <p className={classes.form_container_heading}>Personal Information</p>

              {/* <div className={classes.form_container_grid}>
                <div>
                  <p>Last Name</p>
                  <p>Sex</p>
                  <p>City</p>
                  <div>
                    <p>Home</p>
                    <p>Preferred Contact</p>
                  </div>
                  <p>Race</p>
                </div>
                <div>
                  <p>
                    {demographicForm.data?.lastName
                      ? demographicForm.data?.lastName
                      : '-'}
                  </p>
                  <p>
                    {demographicForm.data?.sex
                      ? demographicForm.data?.sex
                      : '-'}
                  </p>
                  <p>
                    {demographicForm.data?.city
                      ? demographicForm.data?.city
                      : '-'}
                  </p>
                  <div>
                    <p>
                      {demographicForm.data?.homePhone
                        ? demographicForm.data?.homePhone
                        : '-'}
                    </p>
                    <p>
                      {demographicForm.data?.preferredContact
                        ? demographicForm.data?.preferredContact
                        : '-'}
                    </p>
                  </div>
                  <p>
                    {demographicForm.data?.race
                      ? demographicForm.data?.race
                      : '-'}
                  </p>
                </div>
                <div>
                  <p>Last Name</p>
                  <p>Sex</p>
                  <p>City</p>
                  <div>
                    <p>Home</p>
                    <p>Preferred Contact</p>
                  </div>
                  <p>Race</p>
                </div>
                <div>
                  <p>Last Name</p>
                  <p>Sex</p>
                  <p>City</p>
                  <div>
                    <p>Home</p>
                    <p>Preferred Contact</p>
                  </div>
                  <p>Race</p>
                </div>
                <div>
                  <p>Last Name</p>
                  <p>Sex</p>
                  <p>City</p>
                  <div>
                    <p>Home</p>
                    <p>Preferred Contact</p>
                  </div>
                  <p>Race</p>
                </div>
                <div>
                  <p>Last Name</p>
                  <p>Sex</p>
                  <p>City</p>
                  <div>
                    <p>Home</p>
                    <p>Preferred Contact</p>
                  </div>
                  <p>Race</p>
                </div>
              </div> */}

              <div className={classes.form_relations}>
                <div className={classes.form_relation}>
                  <div className={classes.form_relation_text}>
                    <p>First Name</p>
                    <span>{demographicForm.data?.firstName ? demographicForm.data?.firstName : '-'}</span>
                  </div>
                  <div className={classes.form_relation_text}>
                    <p>Middle Name</p>
                    <span>{demographicForm.data?.MI ? demographicForm.data?.MI : '-'}</span>
                  </div>
                  <div className={classes.form_relation_text}>
                    <p>Last Name</p>
                    <span>{demographicForm.data?.lastName ? demographicForm.data?.lastName : '-'}</span>
                  </div>
                </div>

                <div className={classes.form_relation}>
                  <div className={classes.form_relation_text}>
                    <p>Sex</p>
                    <span>{demographicForm.data?.sex ? demographicForm.data?.sex : '-'}</span>
                  </div>
                  <div className={classes.form_relation_text}>
                    <p>Birth Date</p>
                    <span>{demographicForm.data?.patientBirthDate ? demographicForm.data?.patientBirthDate : '-'}</span>
                  </div>
                  <div className={classes.form_relation_text}>
                    <p>SSN</p>
                    <span>{demographicForm.data?.SSN ? demographicForm.data?.SSN : '-'}</span>
                  </div>
                </div>

                <div className={classes.form_relation}>
                  <div className={classes.form_relation_text} style={{ width: '100%' }}>
                    <p>Street Address</p>
                    <span style={{ width: 'calc(100% - 8rem)' }}>{demographicForm.data?.address ? demographicForm.data?.address : '-'}</span>
                  </div>
                </div>
                <div className={classes.form_relation}>
                  {/* <p className={classes.form_relation_text}>
                    Street Address
                    <span>
                      {demographicForm.data?.sex
                        ? demographicForm.data?.sex
                        : '-'}
                    </span>
                  </p> */}
                  {/* <div> */}
                  <div className={classes.form_relation_text}>
                    <p>City</p>
                    <span>{demographicForm.data?.city ? demographicForm.data?.city : '-'}</span>
                  </div>
                  <div className={classes.form_relation_text}>
                    <p>State</p>
                    <span>{demographicForm.data?.state ? demographicForm.data?.state : '-'}</span>
                  </div>
                  <div className={classes.form_relation_text}>
                    <p>ZIP</p>
                    <span>{demographicForm.data?.zip ? demographicForm.data?.zip : '-'}</span>
                  </div>
                  {/* </div> */}
                </div>

                <div className={classes.form_relation}>
                  <div className={classes.form_relation_text}>
                    <p>Home Phone</p>
                    <span>{demographicForm.data?.homePhone ? inputPhoneMasking(demographicForm.data?.homePhone) : '-'}</span>
                  </div>
                  <div className={classes.form_relation_text}>
                    <p>Work Phone</p>
                    <span>{demographicForm.data?.workPhone ? inputPhoneMasking(demographicForm.data?.workPhone) : '-'}</span>
                  </div>
                  <div className={classes.form_relation_text}>
                    <p>Cell Phone</p>
                    <span>{demographicForm.data?.cellPhone ? inputPhoneMasking(demographicForm.data?.cellPhone) : '-'}</span>
                  </div>
                </div>
                <div className={classes.form_relation}>
                  <div className={classes.form_relation_text}>
                    <p>Preferred Contact</p>
                    <span>{demographicForm.data?.preferredContact ? demographicForm.data?.preferredContact : '-'}</span>
                  </div>
                  <div className={classes.form_relation_text}>
                    <p>Preferred Language</p>
                    <span>{demographicForm.data?.preferredLanguage ? demographicForm.data?.preferredLanguage : '-'}</span>
                  </div>
                </div>

                <div className={classes.form_relation}>
                  <div className={classes.form_relation_text}>
                    <p>Race</p>
                    <span>{demographicForm.data?.race ? demographicForm.data?.race : '-'}</span>
                  </div>
                  <div className={classes.form_relation_text}>
                    <p>Ethnicity</p>
                    <span>{demographicForm.data?.ethnicity ? demographicForm.data?.ethnicity : '-'}</span>
                  </div>
                  <div className={classes.form_relation_text}>
                    <p>Marital Status</p>
                    <span>{demographicForm.data?.maritalStatus ? demographicForm.data?.maritalStatus : '-'}</span>
                  </div>
                </div>
              </div>
            </div>

            <div className={classes.form_container}>
              <p className={classes.form_container_heading}>OTHER FAMILY MEMBERS SEEN BY OUR PRACTICE</p>
              {demographicForm.data?.familyMemberList.length > 0 ? (
                demographicForm.data?.familyMemberList.map(member => (
                  <div key={member._id} className={classes.form_relation} style={{ marginTop: '1rem' }}>
                    <div className={classes.form_relation_text}>
                      <p>Name</p>
                      <span>{member?.name ? member.name : '-'}</span>
                    </div>
                    <div className={classes.form_relation_text}>
                      <p>Relationship</p>
                      <span>{member?.relationship ? member.relationship : '-'}</span>
                    </div>
                    {/* <div className={classes.form_relation_text}>
                      <p>Same Insurance</p>
                      <span>{member?.isSameInsurance ? 'Yes' : 'No'}</span>
                    </div> */}
                  </div>
                ))
              ) : (
                <div className={classes.form_relation}>
                  <p className={classes.form_relation_text}>No members</p>
                </div>
              )}
            </div>

            <div className={classes.form_container}>
              <div className={classes.form_container_heading}>RESPONSIBLE PARTY (If different from patient)</div>
              <div className={classes.form_relations}>
                <div className={classes.form_relation}>
                  <div className={classes.form_relation_text}>
                    <p>First Name</p>
                    <span>{demographicForm.data?.responsiblePartyFirstName ? demographicForm.data?.responsiblePartyFirstName : '-'}</span>
                  </div>
                  <div className={classes.form_relation_text}>
                    <p>Middle Name</p>
                    <span>{demographicForm.data?.responsiblePartyMI ? demographicForm.data?.responsiblePartyMI : '-'}</span>
                  </div>
                  <div className={classes.form_relation_text}>
                    <p>Last Name</p>
                    <span>{demographicForm.data?.responsiblePartyLastName ? demographicForm.data?.responsiblePartyLastName : '-'}</span>
                  </div>
                </div>

                <div className={classes.form_relation}>
                  <div className={classes.form_relation_text}>
                    <p>Sex</p>
                    <span>{demographicForm.data?.responsiblePartySex ? demographicForm.data?.responsiblePartySex : '-'}</span>
                  </div>
                  <div className={classes.form_relation_text}>
                    <p>Birth Date</p>
                    <span>
                      {demographicForm.data?.responsiblePartyPatientBirthDate ? moment(demographicForm.data.responsiblePartyPatientBirthDate).format('yyyy-MM-DD') : '-'}
                    </span>
                  </div>
                  <div className={classes.form_relation_text}>
                    <p>SSN</p>
                    <span>{demographicForm.data?.responsiblePartySSN ? inputSSNMasking(demographicForm.data?.responsiblePartySSN) : '-'}</span>
                  </div>
                </div>

                <div className={classes.form_relation}>
                  <div className={classes.form_relation_text} style={{ width: '100%' }}>
                    <p>Street Address</p>
                    <span style={{ width: 'calc(100% - 8rem)' }}>
                      {demographicForm.data?.responsiblePartyAddress ? demographicForm.data?.responsiblePartyAddress : '-'}
                    </span>
                  </div>
                </div>
                <div className={classes.form_relation}>
                  {/* <div className={classes.form_relation_text}>
                    <p>Street Address</p>
                    <span>{demographicForm.data?.responsiblePartyAddress ? demographicForm.data?.responsiblePartyAddress : '-'}</span>
                  </div> */}
                  <div className={classes.form_relation_text}>
                    <p>City</p>
                    <span>{demographicForm.data?.responsiblePartyCity ? demographicForm.data?.responsiblePartyCity : '-'}</span>
                  </div>
                  <div className={classes.form_relation_text}>
                    <p>State</p>
                    <span>{demographicForm.data?.responsiblePartyState ? demographicForm.data?.responsiblePartyState : '-'}</span>
                  </div>
                  <div className={classes.form_relation_text}>
                    <p>Zip</p>
                    <span>{demographicForm.data?.responsiblePartyZip ? demographicForm.data?.responsiblePartyZip : '-'}</span>
                  </div>
                </div>

                <div className={classes.form_relation}>
                  <div className={classes.form_relation_text}>
                    <p>Home</p>
                    <span>{demographicForm.data?.responsiblePartyHomePhone ? inputPhoneMasking(demographicForm.data?.responsiblePartyHomePhone) : '-'}</span>
                  </div>
                  <div className={classes.form_relation_text}>
                    <p>Work</p>
                    <span>{demographicForm.data?.responsiblePartyWorkPhone ? inputPhoneMasking(demographicForm.data?.responsiblePartyWorkPhone) : '-'}</span>
                  </div>
                  <div className={classes.form_relation_text}>
                    <p>Cell</p>
                    <span>{demographicForm.data?.responsiblePartyCellPhone ? inputPhoneMasking(demographicForm.data?.responsiblePartyCellPhone) : '-'}</span>
                  </div>
                </div>
              </div>
            </div>

            <div className={classes.form_container}>
              <p className={classes.form_container_heading}>PRIMARY INSURANCE INFORMATION</p>
              {demographicForm.data?.hasInsurance ? (
                <div className={classes.form_relations}>
                  <div className={classes.form_relation}>
                    <div className={classes.form_relation_text}>
                      <p>Insurance Company</p>
                      <span>{demographicForm.data?.insuranceCompany ? demographicForm.data?.insuranceCompany : '-'}</span>
                    </div>
                    <div className={classes.form_relation_text}>
                      <p>Phone</p>
                      <span>{demographicForm.data?.insuranceCompanyPhone ? demographicForm.data?.insuranceCompanyPhone : '-'}</span>
                    </div>
                    <div className={classes.form_relation_text}>
                      <p>ID Number</p>
                      <span>{demographicForm.data?.insuranceCompanyIDNumber ? demographicForm.data?.insuranceCompanyIDNumber : '-'}</span>
                    </div>
                  </div>

                  <div className={classes.form_relation}>
                    <div className={classes.form_relation_text}>
                      <p>Address</p>
                      <span>{demographicForm.data?.insuranceCompanyAddress ? demographicForm.data?.insuranceCompanyAddress : '-'}</span>
                    </div>
                    <div className={classes.form_relation_text}>
                      <p>Group Number</p>
                      <span>{demographicForm.data?.insuranceCompanyGroupNumber ? demographicForm.data?.insuranceCompanyGroupNumber : '-'}</span>
                    </div>
                    <div className={classes.form_relation_text}>
                      <p>Effective Date</p>
                      <span>{demographicForm.data?.insuranceCompanyEffectiveDate ? demographicForm.data?.insuranceCompanyEffectiveDate : '-'}</span>
                    </div>
                  </div>
                </div>
              ) : (
                <ToggleSwitchWithLabel
                  label="Patient don't have insurance"
                  id='no-insurance'
                  checked={!demographicForm.data?.hasInsurance}
                  style={{ marginTop: '1.5rem', fontSize: '1.3rem' }}
                />
              )}
            </div>

            <div className={classes.form_container}>
              <p className={classes.form_container_heading}>POLICY HOLDER (If different from patient)</p>
              <div className={classes.form_relations}>
                <div className={classes.form_relation}>
                  <div className={classes.form_relation_text}>
                    <p>First Name</p>
                    <span>{demographicForm.data?.policyHolderFirstName ? demographicForm.data?.policyHolderFirstName : '-'}</span>
                  </div>
                  <div className={classes.form_relation_text}>
                    <p>Middle Name</p>
                    <span>{demographicForm.data?.policyHolderMI ? demographicForm.data?.policyHolderMI : '-'}</span>
                  </div>
                  <div className={classes.form_relation_text}>
                    <p>Last Name</p>
                    <span>{demographicForm.data?.policyHolderLastName ? demographicForm.data?.policyHolderLastName : '-'}</span>
                  </div>
                </div>

                <div className={classes.form_relation}>
                  <div className={classes.form_relation_text}>
                    <p>Sex</p>
                    <span>{demographicForm.data?.policyHolderSex ? demographicForm.data?.policyHolderSex : '-'}</span>
                  </div>
                  <div className={classes.form_relation_text}>
                    <p>Birth Date</p>
                    <span>{demographicForm.data?.patientBirthDate ? demographicForm.data?.patientBirthDate : '-'}</span>
                  </div>
                  <div className={classes.form_relation_text}>
                    <p>SSN</p>
                    <span>{demographicForm.data?.policyHolderSSN ? demographicForm.data?.policyHolderSSN : '-'}</span>
                  </div>
                </div>

                <div className={classes.form_relation}>
                  <div className={classes.form_relation_text}>
                    <p>Employer</p>
                    <span>{demographicForm.data?.employer ? demographicForm.data?.employer : '-'}</span>
                  </div>
                  <div className={classes.form_relation_text}>
                    <p>Phone</p>
                    <span>{demographicForm.data?.employerPhone ? demographicForm.data?.employerPhone : '-'}</span>
                  </div>
                </div>
              </div>
            </div>

            <div className={classes.form_container}>
              <p className={classes.form_container_heading}>
                AUTHORIZATION TO PAY BENEFITS TO PHYSICIAN:
                <br />I hereby authorize payment to clinIQ, the surgical and/or medical benefits available. if any, and authorize release of any medical information
                necessary to process this and future claims. I understand that if my insurance fails to make a payment, I am ultimately responsible for payment of
                services rendered.
              </p>
              {/* <div className={classes.form_numbers}>
                <p className={classes.form_numbers_text}>
                  Patient/Gurdian Signature
                  <span>{demographicForm.data?.homePhone}</span>
                </p>
                <p className={classes.form_numbers_text}>
                  Date <span>{demographicForm.data?.workPhone}</span>
                </p>
              </div> */}
            </div>
          </div>
        )
      )}

      <ModalForm style={{ marginBottom: '2.5rem' }}>
        <ModalButtons
          submitButtonLabel={Object.keys(demographicForm.data).length > 0 ? 'Edit Patient Demographic Form' : 'Fill Patient Demographic Form'}
          onSubmit={handleEdit}
          cancelButtonLabel='Close Insurance Policies'
          onCancel={closeInsurancePoliciesModal}
        />
      </ModalForm>
    </React.Fragment>
  );
};

export default PatientDemographicForm;
