import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { loginSliceValue } from '../redux/reducers/loginSlice';
import axios from 'axios';

interface Props {
  url: string;
  method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
  headerContentType?: 'application/json' | 'multipart/form-data';
}

const useApi = ({ url, method = 'GET', headerContentType = 'application/json' }: Props) => {
  const { userDetails } = useSelector(loginSliceValue);
  const [loading, setLoading] = useState(false);

  const makeApiCall = useCallback(
    async <T, R>(
      input?: T
    ): Promise<{
      response: R | undefined;
      loading: boolean;
      error: { msg: string; status: string } | undefined;
    }> => {
      setLoading(true);
      try {
        const config = {
          url: `${process.env.REACT_APP_BASE_URL + url}`,
          method,
          data: input,
          headers: {
            'Content-Type': headerContentType,
            Authorization: 'Bearer ' + userDetails.token,
          },
        };
        if (!userDetails.token)
          return {
            response: undefined,
            loading: false,
            error: undefined,
          };

        const response = await axios(config);
        if (response) {
          return { response: response.data, loading, error: undefined };
        } else {
          return {
            response: undefined,
            loading: false,
            error: { msg: 'Error fetching Data', status: 'fail' },
          };
        }
      } catch (e: any) {
        return {
          response: undefined,
          loading,
          error: e.response && e.response && e.response.data ? e.response.data : e,
        };
      } finally {
        setLoading(false);
      }
    },
    [loading, userDetails, method, url, headerContentType]
  );

  return { loading, makeApiCall };
};

export default useApi;
