import React from 'react';
import AdCampaignsStyles from '../styles/AdCampaigns.module.css';
import SkeletonStyles from './Skeletons.module.css';

const NewAdCampaignsSkeleton = () => {
  const data = [1, 2, 3];
  return (
    <React.Fragment>
      {data &&
        data.map(d => (
          <div key={d} className={AdCampaignsStyles.content_card}>
            <div className={AdCampaignsStyles.content_card_texts + ' ' + SkeletonStyles.content_card_texts}>
              <div className={SkeletonStyles.content_card_heading + ' ' + SkeletonStyles.skeleton}></div>
              <div className={SkeletonStyles.content_card_sub_heading + ' ' + SkeletonStyles.skeleton}></div>
              <div className={SkeletonStyles.content_card_texts} style={{ marginTop: '1rem' }}>
                <div className={SkeletonStyles.content_card_desc + ' ' + SkeletonStyles.skeleton}></div>
                <div className={SkeletonStyles.content_card_desc + ' ' + SkeletonStyles.skeleton} style={{ maxWidth: '55vw' }}></div>
                <div className={SkeletonStyles.content_card_desc + ' ' + SkeletonStyles.skeleton} style={{ maxWidth: '75vw' }}></div>
              </div>
            </div>
          </div>
        ))}
    </React.Fragment>
  );
};

export default NewAdCampaignsSkeleton;
