import React, { Fragment } from 'react';
import type { FC, PropsWithChildren } from 'react';

import { Modal } from '../../components/Modal';
import ModalHeading from '../../components/Modal/ModalHeading';
import ModalForm from '../../components/Modal/ModalForm';
import InputsSection from '../../components/Modal/InputsSectionColumn';
import ModalButtons from '../../components/Modal/ModalButtons';
import ShareFileSkeleton from '../../Skeletons/ShareFileSkeleton';
import SelectBox from '../../components/Modal/SelectBox';
import { DownloadModalState } from './DownloadFileModal';
import { IFolderRes } from '../../hooks/folders/index.type';

export type IcopyModalStates = PropsWithChildren<{
  folder: { value: string; error: string };
}>;

type CopyFileModalProps = PropsWithChildren<{
  loading: boolean;
  copyFileModal: DownloadModalState;
  folders?: IFolderRes;
  copyFilePasswordInput: IcopyModalStates;
  setCopyFilePasswordInput: React.Dispatch<React.SetStateAction<IcopyModalStates>>;
  onCloseModal?: () => void;
  handleCopyFile?: (event: React.FormEvent<HTMLFormElement>) => void;
}>;

const CopyFileModal: FC<CopyFileModalProps> = props => {
  return (
    <Modal show={props.copyFileModal.status}>
      {props.loading ? (
        <ShareFileSkeleton fileProtected={props.copyFileModal.data?.protected} />
      ) : (
        props.copyFileModal.data && (
          <Fragment>
            <ModalHeading heading={`Copy ${props.copyFileModal.data.name}?`} />
            <ModalForm style={{ marginBottom: '2.5rem' }} onSubmit={props.handleCopyFile}>
              <InputsSection style={{ width: '34rem' }}>
                <SelectBox
                  label='Select destination folder'
                  value={props.copyFilePasswordInput.folder.value}
                  onChange={event => {
                    props.setCopyFilePasswordInput(prevVal => ({ ...prevVal, folder: { value: event.target.value, error: '' } }));
                  }}
                  errorMessage={props.copyFilePasswordInput.folder.error}
                >
                  <option value=''>Select destination folder</option>
                  {props.folders && props.folders.data.data.length > 0 ? (
                    props.folders.data.data.map(folder => (
                      <Fragment key={folder._id}>
                        <option value={folder._id} style={{ fontWeight: '800' }}>
                          {folder.name}
                        </option>
                        {folder.childFolders &&
                          folder.childFolders.length > 0 &&
                          folder.childFolders.map(childFolder => (
                            <option value={childFolder._id} key={childFolder._id}>
                              {childFolder.name}
                            </option>
                          ))}
                      </Fragment>
                    ))
                  ) : (
                    <option value=''>No folders created</option>
                  )}
                </SelectBox>
              </InputsSection>
              <ModalButtons submitButtonLabel='Copy File' cancelButtonLabel='Cancel' onCancel={props.onCloseModal} />
            </ModalForm>
          </Fragment>
        )
      )}
    </Modal>
  );
};

export default CopyFileModal;
