import toast from 'react-hot-toast';
import useApi from '../useApi';
import { IcheckInOutPatientReq, IcheckInOutPatientRes } from './index.type';

const useCheckInOut = () => {
  const utcTimeOffset = new Date().getTimezoneOffset();
  const { loading: checkInOutPatientLoading, makeApiCall } = useApi({ url: `/appointment/check?offset=${utcTimeOffset}`, method: 'PATCH' });

  const checkInOutPatient = async (formData: IcheckInOutPatientReq) => {
    const { response, error } = await makeApiCall<IcheckInOutPatientReq, IcheckInOutPatientRes>(formData);
    if (response?.status) {
      toast.success(response.message);
      return response;
    }

    if (!response?.status && response?.message) {
      toast.error(response.message);
    }

    if (error) {
      toast.error('Something went wrong. Please try again later');
      return { status: false };
    }
  };

  return {
    checkInOutPatientLoading,
    checkInOutPatient,
  };
};

export default useCheckInOut;
