import type { FC, PropsWithChildren } from 'react';
import FileExchangeStyles from '../../styles/FileExchangeScreen.module.css';

type AddFolderButtonProps = PropsWithChildren<{
  label: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}>;

const AddFolderButton: FC<AddFolderButtonProps> = props => {
  return (
    <button className={FileExchangeStyles.addFolder} onClick={props.onClick}>
      <svg className={FileExchangeStyles.addFolderIcon}>
        <use xlinkHref={`/assets/sprite.svg#icon-plus`} />
      </svg>
      <h4 className={FileExchangeStyles.addFolderName}>{props.label}</h4>
    </button>
  );
};

export default AddFolderButton;
