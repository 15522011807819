import { TableHeadDataProps } from '../../components/Table/TableHead';

export const appointmentTableHeadData: TableHeadDataProps[] = [
  { id: 0, title: '' },
  { id: 1, title: 'Patient' },
  { id: 2, title: 'Doctor' },
  { id: 3, title: 'Appointment At' },
  { id: 4, title: 'Actions' },
];

export const appointmentNewTableHeadData: TableHeadDataProps[] = [
  { id: 100, title: '' },
  { id: 0, title: 'Patient Name' },
  { id: 1, title: 'Appointment Date' },
  { id: 2, title: 'Appointment Type' },
  { id: 3, title: 'Appointment Location' },
  { id: 4, title: 'Provider' },
  { id: 5, title: 'Actions' },
];
