import React from 'react';
import {
  Chart as ChartJS,
  ArcElement,
  BarController,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineController,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  registerables as registerablesJS,
} from 'chart.js';
import { io, Socket } from 'socket.io-client';
import toast, { Toaster } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';

import Navigation from './navigation';
import 'react-calendar/dist/Calendar.css';
import { loginSliceValue } from './redux/reducers/loginSlice';
import { IOnlineUserResp } from './redux/reducers/onlineUsers/index.types';
import { setOnlineUsers } from './redux/reducers/onlineUsers';
import IncomingCallModal from './pageComponents/VideoCall/IncomingCallModal';
import { callReset, videoCallSliceValue } from './redux/reducers/videoCall';

ChartJS.register(...registerablesJS);

ChartJS.register(ArcElement, BarController, BarElement, CategoryScale, LinearScale, LineController, LineElement, PointElement, Title, Tooltip, Filler, Legend);

function App() {
  const dispatch = useDispatch();
  const [socket, setSocket] = React.useState<Socket | null>(null);
  const [callModal, setCallModal] = React.useState<boolean>(false);
  const [toastMessage, setToastMessage] = React.useState<string>('');
  const [showSuccessToast, setShowSuccessToast] = React.useState<boolean>(false);

  const { loading, userDetails } = useSelector(loginSliceValue);
  const callReducer = useSelector(videoCallSliceValue);

  const setupSocket = React.useCallback(
    (id: string) => {
      if (id && !socket) {
        const newSocket = io(process.env.REACT_APP_BASE_SOCKET_URL, {
          query: {
            id,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
        });

        newSocket.on('disconnect', () => {
          setSocket(null);
          setTimeout(setupSocket, 3000);
          // toast.error('You went offline!');
        });

        newSocket.on('connect', () => {
          // toast.success('You are now online!');
        });

        setSocket(newSocket);
      }
    },
    [socket]
  );

  React.useEffect(() => {
    console.log('callReducer', callReducer);
    if (callReducer.status && callReducer.status === 'Incoming') {
      setCallModal(true);
    } else {
      setCallModal(false);
    }
    if (callReducer.status && callReducer.status === 'Decline') {
      dispatch({ type: callReset });
      toast.error(`Call declined!`);
    }
  }, [callReducer, dispatch]);

  React.useEffect(() => {
    if (socket) {
      socket.on('endCallNotAccepted', () => {
        if (callReducer.status && callReducer.status === 'Incoming') {
          dispatch({ type: callReset });
          // dispatch({ type: CALL_DECLINE, payload: {} });
        }
        setCallModal(false);
      });
    }
  }, [socket]);

  React.useEffect(() => {
    if (userDetails.data?.user._id) {
      setupSocket(userDetails.data?.user._id);
    }
  }, [setupSocket, userDetails]);

  React.useEffect(() => {
    if (userDetails.status === 'fail' && socket) {
      socket.disconnect();
    }
  }, [socket, userDetails]);

  React.useEffect(() => {
    if (socket) {
      socket.on('online-users', (user: { onlineUsers: IOnlineUserResp[] }) => {
        dispatch({ type: setOnlineUsers, payload: user.onlineUsers });
      });
      // socket.on('callDecline', (user) => {
      // 	toast.success(`Call declined!`, {
      // 		style: {
      // 			borderRadius: '10px',
      // 			background: 'red',
      // 			color: '#fff'
      // 		}
      // 	});
      // });
    }
  }, [socket, dispatch, userDetails]);

  return (
    <React.Fragment>
      <Navigation socket={socket} />

      <Toaster
        toastOptions={{
          style: {
            fontSize: '1.3rem',
          },
        }}
        containerStyle={{
          zIndex: '1000000',
        }}
        position='bottom-center'
        reverseOrder={false}
      />

      <IncomingCallModal
        callModal={callModal}
        onDecline={() => {
          if (socket) {
            socket.emit('callDecline', {
              id: callReducer && callReducer.data ? callReducer.data.socketId : '',
            });
          }
        }}
      />
    </React.Fragment>
  );
}

export default App;
