import React from 'react';
import toast from 'react-hot-toast';
import useApi from '../useApi';
import { IAllPatientsRes } from './index.type';

interface PatientsData {
  label: string;
  value: string;
}

const useAllPatients = () => {
  const [allPatientsData, setAllPatientsData] = React.useState<PatientsData[]>();

  const { loading: allPatientsLoading, makeApiCall } = useApi({
    url: `/users/get-patient-list`,
    method: 'GET',
  });

  const getAllPatients = async () => {
    const { response, error } = await makeApiCall<undefined, IAllPatientsRes>();
    if (response?.status) {
      const userNamesDataTemp: PatientsData[] = [];

      response.data.result.length > 0 &&
        response.data.result.forEach(item => {
          userNamesDataTemp.push({ label: item.name, value: item._id });
        });
      setAllPatientsData(userNamesDataTemp);
    }

    if (!response?.status) {
      setAllPatientsData(undefined);
    }

    if (error) toast.error('Something went wrong while fetching patients data. Please try again later');
  };

  React.useEffect(() => {
    getAllPatients();
    // eslint-disable-next-line
  }, []);

  return {
    allPatientsData,
    allPatientsLoading,
  };
};

export default useAllPatients;
