import React from 'react';
import ModalForm from '../components/Modal/ModalForm';
import InputsSection from '../components/Modal/InputsSection';
import InputBoxSkeleton from './InputBoxSkeleton';
import ModalButtonsSkeleton from './ModalButtonsSkeleton';

const AddUserSkeleton = () => {
  return (
    <ModalForm style={{ marginBottom: '2.5rem' }}>
      <InputsSection>
        <InputBoxSkeleton />
        <InputBoxSkeleton />
        <InputBoxSkeleton />
      </InputsSection>
      <InputsSection>
        <InputBoxSkeleton />
        <InputBoxSkeleton />
        <InputBoxSkeleton />
      </InputsSection>
      <InputsSection>
        <InputBoxSkeleton />
        <InputBoxSkeleton />
      </InputsSection>
      <ModalButtonsSkeleton />
    </ModalForm>
  );
};

export default AddUserSkeleton;
