import { FC } from "react";
import { Modal, ModalButtons } from "../../components/Modal";
import ModalHeading from "../../components/Modal/ModalHeading";
import { IAdRule } from "../../hooks/adViews/index.type";
import AdViewStyles from "../../styles/AdViewScreen.module.css";

export type DeleteRulesModalState = {
	status: boolean;
	mode?: string;
	data?: IAdRule;
};

type DeleteRulesModalProps = {
	deleteRulesModal: DeleteRulesModalState;
	onDeleteRule: (id: any) => void;
	onResetRules: () => void;
	onCloseModal: () => void;
};

const DeleteRuleModal: FC<DeleteRulesModalProps> = (props) => {
	const mode = props.deleteRulesModal.mode;

	return (
		<Modal
			show={props.deleteRulesModal.status}
			style={{ maxWidth: "40vw" }}
		>
			<ModalHeading heading={props.deleteRulesModal.mode + " Rules"} />
			<div className={AdViewStyles.delete_modal_container}>
				{mode === "Delete" && (
					<div className={AdViewStyles.rule_card_upper_content_chip1}>
						{props.deleteRulesModal.data?.field}
					</div>
				)}
				<p className={AdViewStyles.delete_modal_warning}>
					Are you sure you want to
					{mode === "Delete"
						? " delete the rule for this field?"
						: " reset all the rules?"}
				</p>

				<div className={AdViewStyles.delete_modal_button_container}>
					<div
						onClick={() =>
							mode === "Delete"
								? props.onDeleteRule(
										props.deleteRulesModal.data?.id
								  )
								: props.onResetRules()
						}
						className={AdViewStyles.delete_modal_delete_button}
					>
						{`${props.deleteRulesModal.mode} ${
							mode === "Delete" ? "Rule" : "Rules"
						}`}
					</div>

					<ModalButtons
						cancelButtonLabel="Cancel"
						onCancel={props.onCloseModal}
					/>
				</div>
			</div>
		</Modal>
	);
};

export default DeleteRuleModal;
