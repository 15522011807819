import toast from 'react-hot-toast';
import useApi from '../useApi';
import { IUpdateCheckinStatusReq, IUpdateCheckinStatusRes } from './index.type';

const useUpdateCheckinStatus = () => {
  const utcTimeOffset = new Date().getTimezoneOffset();
  const { loading: updateCheckinStatusLoading, makeApiCall } = useApi({ url: `/appointment/change-status?offset=${utcTimeOffset}`, method: 'PATCH' });

  const updateCheckinStatus = async (formData: IUpdateCheckinStatusReq) => {
    const { response, error } = await makeApiCall<IUpdateCheckinStatusReq, IUpdateCheckinStatusRes>(formData);
    if (response?.status) {
      toast.success(response.message);
      return response;
    }

    if (!response?.status && response?.message) {
      toast.error(response.message);
    }

    if (error) {
      toast.error('Something went wrong. Please try again later');
      return { status: false };
    }
  };

  return {
    updateCheckinStatusLoading,
    updateCheckinStatus,
  };
};

export default useUpdateCheckinStatus;
