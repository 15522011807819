import type { FC, PropsWithChildren } from 'react';
import { InputsSectionColumn, InputBox, ModalButtons, ModalForm, Modal } from '../../components/Modal';
import ModalHeading from '../../components/Modal/ModalHeading';
import ResetPasswordSkeleton from '../../Skeletons/ResetPasswordSkeleton';
import { DeleteModalState } from './DeleteModal';

export type IresetPasswordModalStates = PropsWithChildren<{
  password: { value: string; error: string };
  passwordConfirm: { value: string; error: string };
}>;

type ResetPasswordModalProps = PropsWithChildren<{
  loading: boolean;
  resetPasswordModal: DeleteModalState;
  onCloseModal?: () => void;
  handleResetPassword?: (event: React.FormEvent<HTMLFormElement>) => void;
  resetPasswordModalStates: IresetPasswordModalStates;
  setResetPasswordModalStates: React.Dispatch<React.SetStateAction<IresetPasswordModalStates>>;
}>;

const ResetPasswordModal: FC<ResetPasswordModalProps> = props => {
  return (
    <Modal show={props.resetPasswordModal.status}>
      <ModalHeading heading={`Reset Password of ${props.resetPasswordModal.data?.name}`} />

      {props.loading ? (
        <ResetPasswordSkeleton />
      ) : (
        <ModalForm style={{ marginBottom: '2.5rem' }} onSubmit={props.handleResetPassword}>
          <InputsSectionColumn>
            <InputBox
              type='password'
              label='New Password'
              placeholder='Enter new password'
              value={props.resetPasswordModalStates.password.value}
              onChange={event => {
                props.setResetPasswordModalStates(prevVal => ({ ...prevVal, password: { value: event.target.value, error: '' } }));
              }}
              errorMessage={props.resetPasswordModalStates.password.error}
            />

            <InputBox
              type='password'
              label='Confirm Password'
              placeholder='Enter password again'
              value={props.resetPasswordModalStates.passwordConfirm.value}
              onChange={event => {
                props.setResetPasswordModalStates(prevVal => ({ ...prevVal, passwordConfirm: { value: event.target.value, error: '' } }));
              }}
              errorMessage={props.resetPasswordModalStates.passwordConfirm.error}
            />
          </InputsSectionColumn>

          <ModalButtons submitButtonLabel='Reset Password' cancelButtonLabel='Cancel' onCancel={props.onCloseModal} />
        </ModalForm>
      )}
    </Modal>
  );
};

export default ResetPasswordModal;
