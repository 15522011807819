import type { FC, PropsWithChildren } from 'react';
import classes from '../../styles/Table.module.css';

type TableRowTitleProps = PropsWithChildren<{
  style?: React.CSSProperties;
}>;

const TableRowTitle: FC<TableRowTitleProps> = props => {
  return <td className={classes.table__row_title} style={props.style}>{props.children}</td>;
};

export default TableRowTitle;
