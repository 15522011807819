import toast from 'react-hot-toast';
import useApi from '../useApi';
import { IUpdateAppointmentReq, IAddAppointmentRes } from './index.type';

const useUpdateAppointment = () => {
  const utcTimeOffset = new Date().getTimezoneOffset();
  const { loading: updateAppointmentLoading, makeApiCall } = useApi({ url: `/appointment/update-appointment?offset=${utcTimeOffset}`, method: 'PUT' });

  const updateAppointment = async (formData: IUpdateAppointmentReq) => {
    const { response, error } = await makeApiCall<IUpdateAppointmentReq, IAddAppointmentRes>(formData);
    if (response?.status) {
      toast.success(response.message);
      return response;
    }

    if (error || !response?.status) {
      toast.error('Something went wrong. Please try again later');
      return { status: false };
    }
  };

  return {
    updateAppointmentLoading,
    updateAppointment,
  };
};

export default useUpdateAppointment;
