import React from 'react';
import type { FC, PropsWithChildren } from 'react';
import AdAdminSidebar from './AdAdmin';

import AdminSidebar from './AdminSidebar';
import AdUserSidebar from './AdUserSidebar';
import ClinicManageSidebar from './ClinicManageSidebar';
import FileAdminSidebar from './FileAdminSidebar';
import FileUserSidebar from './FileUserSidebar';
import ClinicSidebar from './ClinicSidebar';
import '../../styles/SideBar.css';
import EeceptionistSidebar from './ReceptionistSidebar';

export type SideProps = PropsWithChildren<{
  currentMenu:
    | 'dashboard'
    | 'clinics'
    | 'users'
    | 'secure-files'
    | 'ad-campaigns'
    | 'view-campaign'
    | 'my-profile'
    | 'questions'
    | 'providers'
    | 'patients'
    | 'appointment'
    | 'documents'
    | 'check-in'
    | 'feedback'
    | 'patient-feedback'
    | 'admin-dashboard';
}>;

type SideBarProps = PropsWithChildren<{
  currentMenu: SideProps['currentMenu'];
  userRole: string;
}>;

const Sidebar: FC<SideBarProps> = ({ currentMenu, userRole }) => {
  return (
    <React.Fragment>
      {userRole === 'admin' ? (
        <AdminSidebar currentMenu={currentMenu} />
      ) : userRole === 'adAdmin' ? (
        <AdAdminSidebar currentMenu={currentMenu} />
      ) : userRole === 'adUser' ? (
        <AdUserSidebar currentMenu={currentMenu} />
      ) : userRole === 'fileAdmin' ? (
        <FileAdminSidebar currentMenu={currentMenu} />
      ) : userRole === 'clinic' ? (
        <ClinicSidebar currentMenu={currentMenu} />
      ) : userRole === 'manager' ? (
        <ClinicManageSidebar currentMenu={currentMenu} />
      ) : userRole === 'receptionist' ? (
        <EeceptionistSidebar currentMenu={currentMenu} />
      ) : (
        <FileUserSidebar currentMenu={currentMenu} />
      )}
    </React.Fragment>
  );
};

export default Sidebar;
