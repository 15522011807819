import type { FC, PropsWithChildren } from 'react';
import AdStatisticsStyles from '../../styles/AdStatistics.module.css';

type SummaryChipProps = PropsWithChildren<{
  count: number;
  title: string;
  percent: number;
  hidePercent?: boolean;
}>;

const SummaryChip: FC<SummaryChipProps> = props => {
  return (
    <div className={AdStatisticsStyles.statCard_2}>
      <div className={AdStatisticsStyles.group}>
        <h4
          // style={{ color: props.percent < 0 ? '#FF0000' : props.percent > 0 || props.hidePercent ? '#55b7e5' : 'var(--grey)' }}
          className={AdStatisticsStyles.statCard_2_stat}
        >
          {props.count}
        </h4>
        <p className={AdStatisticsStyles.statCard_2_title}>{props.title}</p>
      </div>
      {!props.hidePercent && (
        <p className={AdStatisticsStyles.statCard_2_percent}>
          {props.percent > 0 && '+'}
          {props.percent}%
          <svg className={AdStatisticsStyles.icon} style={{ fill: props.percent < 0 ? '#FF0000' : props.percent === 0 ? 'var(--grey)' : '#4ed28b' }}>
            <use xlinkHref={`/assets/sprite.svg#icon-arrow-${props.percent < 0 ? 'down-new' : props.percent === 0 ? 'dash' : 'up-new'}`} />
          </svg>
          {}
        </p>
      )}
    </div>
  );
};

export default SummaryChip;
