import type { FC, PropsWithChildren } from 'react';
import { Modal, ModalForm, InputsSectionColumn, InputBox, ModalButtons } from '../../components/Modal';
import ModalHeading from '../../components/Modal/ModalHeading';
import DeleteModalSkeleton from '../../Skeletons/DeleteModalSkeleton';
import { IFile } from '../../hooks/folders/index.type';
import { IdeleteModalStates } from './DeleteFolderModal';

export type DeleteFileModalState = PropsWithChildren<{
  status: boolean;
  data?: IFile;
}>;

type DeleteFileModalProps = PropsWithChildren<{
  loading: boolean;
  deleteFileModal: DeleteFileModalState;
  deleteFileInput: IdeleteModalStates;
  setDeleteFileInput: React.Dispatch<React.SetStateAction<IdeleteModalStates>>;
  onCloseModal?: () => void;
  handleDeleteFile?: (event: React.FormEvent<HTMLFormElement>) => void;
}>;

const DeleteFileModal: FC<DeleteFileModalProps> = props => {
  return (
    <Modal show={props.deleteFileModal.status}>
      <ModalHeading heading={`Delete ${props.deleteFileModal.data?.name}?`} />
      {props.loading ? (
        <DeleteModalSkeleton />
      ) : (
        props.deleteFileModal.data && (
          <ModalForm style={{ marginBottom: '2.5rem' }} onSubmit={props.handleDeleteFile}>
            <InputsSectionColumn style={{ width: '34rem' }}>
              <InputBox
                label={`Enter ${props.deleteFileModal.data.name} below to delete file`}
                placeholder={props.deleteFileModal.data.name}
                value={props.deleteFileInput.name.value}
                onChange={event => {
                  props.setDeleteFileInput(prevVal => ({ ...prevVal, name: { value: event.target.value, error: '' } }));
                }}
                errorMessage={props.deleteFileInput.name.error}
                style={{ width: '30rem' }}
              />
              <p className='dangerText'>Please note that it will not be restored in future after you delete.</p>
            </InputsSectionColumn>

            <ModalButtons submitButtonLabel='Delete File' cancelButtonLabel='Cancel' onCancel={props.onCloseModal} />
          </ModalForm>
        )
      )}
    </Modal>
  );
};

export default DeleteFileModal;
