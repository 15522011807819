import toast from 'react-hot-toast';
import useApi from '../useApi';
import { IaddEditStatusReq, IaddEditStatusRes } from './index.type';

const useAddEditStatus = ({ id }: { id?: string }) => {
  const url = id ? `/status/${id}` : '/status';
  const method = id ? 'PATCH' : 'POST';
  const { loading: addEditStatusLoading, makeApiCall } = useApi({ url, method });

  const addEditStatus = async (formData: IaddEditStatusReq) => {
    const { response, error } = await makeApiCall<IaddEditStatusReq, IaddEditStatusRes>(formData);
    if (response?.status === 'success') {
      toast.success(id ? 'Status updated successfully' : 'Status added successfully');
      return response;
    }

    if (error) {
      toast.error('Something went wrong. Please try again later');
      return { status: false };
    }
  };

  return {
    addEditStatusLoading,
    addEditStatus,
  };
};

export default useAddEditStatus;
