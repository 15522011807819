import React from 'react';
import useAllFolders from './useAllFolders';

const useGetAllFolders = ({ clinicId }: { clinicId?: string }) => {
  const { foldersData, getFoldersData, foldersDataLoading } = useAllFolders({ clinicId: clinicId });

  React.useEffect(() => {
    if (clinicId) getFoldersData();
    // eslint-disable-next-line
  }, [clinicId]);

  return {
    foldersData,
    getFoldersData,
    foldersDataLoading,
  };
};

export default useGetAllFolders;
