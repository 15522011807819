import React from 'react';
import type { FC, PropsWithChildren } from 'react';
import { IActiveClinicRes, IAd, IAdList, IImageSrcList, IPainLocationsRes, IRule } from '../../../hooks/advertisements/index.type';
import AddAdvertiseStyles from '../../../styles/AddAdvertiseScreen.module.css';
import AdCampaignsStyles from '../../../styles/AdCampaigns.module.css';
import { ModalButtons } from '../../../components/Modal';
import MultiSelectBox from '../../../components/Modal/SelectBox/MultiSelectBox';
import { insuranceFields, keys, painDurationFields, redFlagsFields, riskRatingFields, sideEffectsFields, extendedOpioidFields } from '../staticData';
import SearchSelect from '../../../components/SearchSelect';

type Step4Props = PropsWithChildren<{
  loading: boolean;
  imageSrcListTemp: IImageSrcList[];
  ruleList: IAd['advertise']['ruleList'];
  setRuleList: React.Dispatch<React.SetStateAction<IAd['advertise']['ruleList']>>;
  maxReached: { status: boolean; data?: IAdList };
  setMaxReached: React.Dispatch<React.SetStateAction<{ status: boolean; data?: IAdList }>>;
  formError?: string;
  setFormError: React.Dispatch<React.SetStateAction<string>>;
  imageSrcList: IImageSrcList[];
  activeClinic?: IActiveClinicRes;
  painLocations?: IPainLocationsRes;
  prevStep?: () => void;
  onCancel?: React.MouseEventHandler<HTMLButtonElement>;
}>;

const Step4: FC<Step4Props> = props => {
  const onMultiSelectionChange = (parentIndex: number, value: any) => {
    const temp = [...props.ruleList];
    temp[parentIndex].adsList = value;
    const tempImgSrcList = [...props.imageSrcListTemp];
    tempImgSrcList.map(d => {
      return (d.selected = false);
    });
    for (let item of temp) {
      if (item.adsList.length > 0) {
        for (let innerItem of item.adsList) {
          let index = tempImgSrcList.findIndex(e => e.value === innerItem.value);
          if (tempImgSrcList[index]) {
            tempImgSrcList[index].selected = true;
            tempImgSrcList[index].priority = 100 / item.adsList.length;
            tempImgSrcList[index].customPriority = false;
          }
        }
      }
    }
    props.setRuleList(temp);
  };

  const addRule = () => {
    //validate the form
    let status = true;
    if (props.ruleList.length) {
      props.ruleList[props.ruleList.length - 1].rules.forEach(item => {
        if (!item.value) status = false;
      });
    }
    if (status) {
      const temp: {
        adsList: IAdList[];
        rules: IRule[];
      }[] = [
        ...props.ruleList,
        {
          adsList: [],
          rules: [
            {
              field: '',
              operator: 'Contains',
              value: '',
              valueList: [],
            },
          ],
        },
      ];
      props.setRuleList(temp);
    } else props.setFormError('Please fill the form first!');
  };

  const addAnd = (index: number) => {
    let status = true;
    if (props.ruleList.length) {
      props.ruleList[index].rules.forEach(item => {
        if (!item.value) status = false;
      });
    }
    if (status) {
      const temp = [...props.ruleList];
      temp[index].rules.push({
        field: '',
        operator: 'Contains',
        value: '',
        valueList: [],
      });
      props.setRuleList(temp);
    } else props.setFormError('Please fill the form first!');
  };

  const removeRow = (parent: number, index: number) => {
    const temp = [...props.ruleList];
    temp[parent].rules.splice(index, 1);
    props.setRuleList(temp);
  };

  const removeRuleRow = (index: number) => {
    const temp = [...props.ruleList];
    temp.splice(index, 1);
    props.setRuleList(temp);
  };

  const onValueChange1 = (parentIndex: number, index: number, value: any, field: 'value') => {
    const temp = [...props.ruleList];
    if (value && Array.isArray(value)) {
      let hasAllClinic = false;
      value.forEach(val => {
        if (val.value === 'all') hasAllClinic = true;
      });
      let newValue = [];
      if (hasAllClinic && props.activeClinic) {
        newValue = props.activeClinic.data;
      } else {
        newValue = value;
      }
      temp[parentIndex].rules[index][field] = newValue;
    } else if (value.value) temp[parentIndex].rules[index][field] = value.value;
    if (value && !Array.isArray(value) && value.value === '') temp[parentIndex].rules[index][field] = value.value;
    props.setRuleList(temp);
  };

  const onValueChange = (parentIndex: number, index: number, value: any, field: 'field' | 'operator' | 'value') => {
    const temp = [...props.ruleList];
    if (value) temp[parentIndex].rules[index][field] = value;
    if (value === '') temp[parentIndex].rules[index][field] = value;
    if (field === 'field') {
      temp[parentIndex].rules[index]['value'] = '';
    }
    props.setRuleList(temp);
  };

  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        overflow: 'auto',
        paddingRight: '1rem',
      }}
    >
      <div>
        <div className={AddAdvertiseStyles.add_rule_list}>
          {props.ruleList.map((mainItem, position) => (
            <div className={AddAdvertiseStyles.advertise_left_container} key={position}>
              <div
                className='flex'
                style={{
                  margin: '1rem 0rem',
                  justifyContent: 'space-between',
                }}
              >
                <MultiSelectBox
                  placeholder='Select Ads'
                  value={[...mainItem.adsList]}
                  // value={mainItem.adsList && mainItem.adsList.length ? [...mainItem.adsList] : null}
                  onChange={e => {
                    onMultiSelectionChange(position, e);
                  }}
                  options={props.imageSrcList}
                />
                <div className={AddAdvertiseStyles.rule_row_foot}>
                  <div className={AdCampaignsStyles.add_new_btn} onClick={() => addAnd(position)}>
                    Add New AND Target
                  </div>
                  {position !== 0 && (
                    <div className={AddAdvertiseStyles.remove_button} onClick={e => removeRuleRow(position)}>
                      Delete Rule
                    </div>
                  )}
                </div>
              </div>
              {mainItem &&
                mainItem.rules &&
                mainItem.rules.length > 0 &&
                mainItem.rules.map((innerRule, index) => {
                  // const innerRuleChildren =
                  //   innerRule.field === 'riskRating'
                  //     ? riskRatingFields
                  //     : innerRule.field === 'redflag'
                  //       ? redFlagsFields
                  //       : innerRule.field === 'sideeffects'
                  //         ? sideEffectsFields
                  //         : innerRule.field === 'insurance'
                  //           ? insuranceFields
                  //           : innerRule.field === 'clinic'
                  //             ? props.activeClinic?.data
                  //               ? props.activeClinic?.data
                  //               : []
                  //             : [];
                  let innerRuleChildren: any[] = [];
                  switch (innerRule.field) {
                    case 'riskRating':
                      innerRuleChildren = riskRatingFields;
                      break;
                    case 'redflag':
                      innerRuleChildren = redFlagsFields;
                      break;
                    case 'sideeffects':
                      innerRuleChildren = sideEffectsFields;
                      break;
                    case 'insurance':
                      innerRuleChildren = insuranceFields;
                      break;
                    case 'extendedReleaseOpioid':
                      innerRuleChildren = extendedOpioidFields;
                      break;
                    case 'clinic':
                      innerRuleChildren = props.activeClinic?.data ? [{ value: 'all', label: 'All' }, ...props.activeClinic?.data] : [];
                      break;
                    case 'locationpain':
                      innerRuleChildren = props.painLocations?.data
                        ? props.painLocations?.data.map(item => ({
                            label: item,
                            value: item,
                          }))
                        : [];
                      break;
                    case 'duration':
                      innerRuleChildren = painDurationFields;
                      break;
                    default:
                      innerRuleChildren = [];
                  }

                  const innerRuleValue =
                    innerRuleChildren &&
                    innerRuleChildren.filter(function (option) {
                      if (innerRule.value && Array.isArray(innerRule.value)) return innerRule.value.find(itm => itm.value === option.value) ? true : false;
                      return option.value === innerRule.value;
                    });

                  let innerRuleValue1 = {
                    label: '',
                    value: '',
                  };
                  innerRuleChildren.forEach(option => {
                    if (option.value === innerRule.value) {
                      innerRuleValue1 = option;
                    }
                  });

                  let selectedKey = { label: '', value: '' };
                  keys.forEach(option => {
                    if (option.value === innerRule.field) {
                      selectedKey = option;
                    }
                  });

                  return (
                    <div key={index}>
                      <div className={AddAdvertiseStyles.advertise_row}>
                        <SearchSelect
                          placeholder='Select Key'
                          value={selectedKey}
                          onChange={item => {
                            onValueChange(position, index, item?.value, 'field');
                            onValueChange(position, index, '', 'value');
                          }}
                          options={keys}
                        />

                        <select
                          onChange={e => onValueChange(position, index, e.target.value, 'operator')}
                          defaultValue={innerRule.operator}
                          className={AddAdvertiseStyles.advertise_select}
                        >
                          <option className='advertise_select_option' key='Contains' selected={innerRule.operator === 'Contains'} value='Contains'>
                            Equals
                          </option>
                        </select>

                        <div
                          style={{
                            width: '100%',
                            gridColumn: index !== 0 ? '3/4' : '3/-1',
                          }}
                        >
                          {innerRule.field === 'clinic' || innerRule.field === 'locationpain' ? (
                            <MultiSelectBox
                              placeholder='Search value'
                              value={innerRuleValue}
                              onChange={item => {
                                onValueChange1(position, index, item, 'value');
                              }}
                              options={innerRuleChildren && innerRuleChildren}
                            />
                          ) : (
                            <SearchSelect
                              placeholder='Search value'
                              value={innerRuleValue1}
                              onChange={item => {
                                onValueChange1(position, index, item, 'value');
                              }}
                              options={innerRuleChildren && innerRuleChildren}
                            />
                          )}
                        </div>

                        {index !== 0 ? (
                          <button className={AddAdvertiseStyles.advertise_delete_box} onClick={e => removeRow(position, index)}>
                            <svg className={AddAdvertiseStyles.advertise_delete_icon}>
                              <use xlinkHref={`/assets/sprite.svg#icon-trash`} />
                            </svg>
                          </button>
                        ) : null}
                      </div>
                    </div>
                  );
                })}

              <div className={AddAdvertiseStyles.advertise_right_container}>
                {mainItem.adsList && mainItem.adsList.length > 1
                  ? mainItem.adsList.map((list, index) => {
                      // console.log(list);
                      return (
                        <div className={[AddAdvertiseStyles.advertise_right_container_table_body_tr, AddAdvertiseStyles.imageBox].join(' ')} key={list._id}>
                          <div className={AddAdvertiseStyles.inputBox}>
                            <input
                              type='range'
                              min='1'
                              max='100'
                              value={list.priority}
                              onChange={e => {
                                const temp = [...props.ruleList];
                                temp[position].adsList[index].priority = Number(e.target.value);
                                temp[position].adsList[index].customPriority = true;

                                let totalCustom = 0;
                                let totalCustomValue = 0;

                                for (let ads in temp[position].adsList) {
                                  if (temp[position].adsList[ads].customPriority === true) {
                                    totalCustom += 1;
                                    totalCustomValue += Number(temp[position].adsList[ads].priority);
                                  }
                                }

                                const remaining = 100 - totalCustomValue;

                                for (let ads in temp[position].adsList) {
                                  const newPriority = Number(remaining / (temp[position].adsList.length - totalCustom));

                                  if (newPriority < 10) {
                                    props.setMaxReached({
                                      status: true,
                                      data: temp[position].adsList[ads],
                                    });
                                  } else {
                                    props.setMaxReached({
                                      status: false,
                                    });
                                  }
                                  if (temp[position].adsList[ads].customPriority === false) {
                                    temp[position].adsList[ads].priority = newPriority < 10 ? 10 : newPriority;
                                  }
                                }

                                props.setRuleList(temp);
                              }}
                            />
                            <p>{Number(list.priority).toFixed(2) + ' %'}</p>
                          </div>
                          <img
                            src={process.env.REACT_APP_BASE_ASSET_URL + list.imageUrl}
                            style={{
                              width: '15rem',
                            }}
                            alt={list.imageUrl}
                          />
                        </div>
                      );
                    })
                  : null}
              </div>

              {props.maxReached.status &&
                mainItem.adsList &&
                mainItem.adsList.length > 1 &&
                mainItem.adsList.map(list =>
                  props.maxReached.status && props.maxReached.data?._id === list._id ? (
                    <p className={AddAdvertiseStyles.advertise_right_container_error} key={list._id}>
                      Sum of two or more ad image probabilities should not be more than 90%.
                    </p>
                  ) : null
                )}
            </div>
          ))}

          <div className={AdCampaignsStyles.add_new_btn} onClick={addRule}>
            Add Rule
          </div>
        </div>
      </div>
      <ModalButtons
        submitButtonLabel='Save'
        submitButtonDisabled={props.maxReached.status}
        previousButtonLabel='Previous'
        handlePrevious={props.prevStep}
        cancelButtonLabel='Cancel'
        formError={props.formError}
        loading={props.loading}
        onCancel={props.onCancel}
        style={{ paddingBottom: '1rem' }}
      />
    </div>
  );
};

export default Step4;
