// @ts-nocheck
import type { FC, PropsWithChildren } from 'react';
import { MenuItem } from 'react-contextmenu';

type MyMenuItemProps = PropsWithChildren<{
  onClick?: () => void;
}>;

const MyMenuItem: FC<MyMenuItemProps> = props => {
  return <MenuItem onClick={props.onClick}>{props.children}</MenuItem>;
};

export default MyMenuItem;
