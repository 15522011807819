import type { FC, PropsWithChildren } from 'react';
import { Modal, ModalForm, InputsSection, InputBox, ModalButtons, UploadInput, TextArea } from '../../components/Modal';
import ModalHeading from '../../components/Modal/ModalHeading';
import { IChildFolder, IFolder } from '../../hooks/folders/index.type';
import UploadFileSkeleton from '../../Skeletons/UploadFileSkeleton';

export type UploadFileModalState = PropsWithChildren<{
  status: boolean;
  data?: IFolder | IChildFolder;
}>;

export type IuploadModalStates = PropsWithChildren<{
  name: { value: string; error: string };
  file: { error: string; file?: FileList };
  uploadNote: { value: string; error: string };
  protected: { value: boolean; error: string };
  password: { value: string; error: string };
  passwordConfirm: { value: string; error: string };
}>;

type UploadFileModalProps = PropsWithChildren<{
  loading: boolean;
  uploadFileModal: UploadFileModalState;
  uploadFileInput: IuploadModalStates;
  setUploadFileInput: React.Dispatch<React.SetStateAction<IuploadModalStates>>;
  onCloseModal?: () => void;
  handleUploadFile?: (event: React.FormEvent<HTMLFormElement>) => void;
}>;

const UploadFileModal: FC<UploadFileModalProps> = props => {
  return (
    <Modal show={props.uploadFileModal.status}>
      <ModalHeading heading={`Upload File to ${props.uploadFileModal.data?.name}`} />
      {props.loading ? (
        <UploadFileSkeleton />
      ) : (
        <ModalForm style={{ marginBottom: '2.5rem' }} onSubmit={props.handleUploadFile}>
          <InputsSection>
            <InputBox
              value={props.uploadFileInput.name.value}
              onChange={event => {
                props.setUploadFileInput(prevVal => ({ ...prevVal, name: { value: event.target.value, error: '' } }));
              }}
              errorMessage={props.uploadFileInput.name.error}
              label='File Collection Name'
              placeholder='eg., Pathology Report'
            />
            <UploadInput
              label='Upload Files (can upload multiple files)'
              multiple={true}
              onChange={event => {
                props.setUploadFileInput(prevVal => ({ ...prevVal, file: { error: '', file: event.target.files ? event.target.files : undefined } }));
              }}
              errorMessage={props.uploadFileInput.file.error}
              // style={{ width: '25rem', height: '4.5rem' }}
              style={{ width: '25rem' }}
            />
          </InputsSection>

          <InputsSection>
            <TextArea
              value={props.uploadFileInput.uploadNote.value}
              onChange={event => {
                props.setUploadFileInput(prevVal => ({ ...prevVal, uploadNote: { value: event.target.value, error: '' } }));
              }}
              errorMessage={props.uploadFileInput.uploadNote.error}
              label='Any note on this upload (optional)'
              placeholder='Write a note about this upload here'
              style={{ width: '52rem' }}
            />
          </InputsSection>

          <InputsSection>
            <div className='flexCol'>
              <div className='flex'>
                <input
                  type='checkbox'
                  id='protect-password'
                  checked={props.uploadFileInput.protected.value}
                  onChange={() => props.setUploadFileInput(prevVal => ({ ...prevVal, protected: { value: !prevVal.protected.value, error: '' } }))}
                />
                <label htmlFor='protect-password'>Protect file with password</label>
              </div>
              {props.uploadFileInput.protected.value && (
                <div className='flex' style={{ gap: '2rem' }}>
                  <InputBox
                    type='password'
                    value={props.uploadFileInput.password.value}
                    onChange={event => {
                      props.setUploadFileInput(prevVal => ({ ...prevVal, password: { value: event.target.value, error: '' } }));
                    }}
                    errorMessage={props.uploadFileInput.password.error}
                    label='Password'
                    placeholder='File Password'
                  />
                  <InputBox
                    type='password'
                    value={props.uploadFileInput.passwordConfirm.value}
                    onChange={event => {
                      props.setUploadFileInput(prevVal => ({ ...prevVal, passwordConfirm: { value: event.target.value, error: '' } }));
                    }}
                    errorMessage={props.uploadFileInput.passwordConfirm.error}
                    label='Confirm Password'
                    placeholder='Confirm Password'
                  />
                </div>
              )}
            </div>
          </InputsSection>
          <ModalButtons submitButtonLabel='Upload File' cancelButtonLabel='Cancel' onCancel={props.onCloseModal} />
        </ModalForm>
      )}
    </Modal>
  );
};

export default UploadFileModal;
