import React from 'react';
import AdStatisticsStyles from '../../styles/AdStatistics.module.css';
import DateInput from './DateInput';
import { ToggleButtons } from '../Advertisements';
import SelectBox from './SelectBox';
import ExportButton from './ExportButton';

interface BoxTitleProps {
  title: string;
  selectBoxValue: string;
  selectBoxWidth?: string;
  selectBoxOptions: { value: string; label: string }[];
  selectBoxOnChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  showCustomDate?: boolean;
  fromDateValue?: string;
  fromDateOnChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  toDateValue?: string;
  toDateOnChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  selectBox2Value?: string;
  selectBox2Options?: { value: string; label: string }[];
  selectBox2OnChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  showExportButton?: boolean;
  exportDataTitle?: string;
  exportData?: unknown[];
  toggleButtons?: {
    onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
    active: boolean;
    label: string;
  }[];
}
const BoxTitle: React.FC<BoxTitleProps> = props => {
  return (
    <div className={AdStatisticsStyles.filterToggleContainer}>
      <h3 className={AdStatisticsStyles.contents_titles}>{props.title}</h3>

      <div className={AdStatisticsStyles.dateRangeContainer}>
        <div className='flex gap-1'>
          {props.showCustomDate && (
            <React.Fragment>
              <DateInput value={props.fromDateValue ? props.fromDateValue : ''} onChange={props.fromDateOnChange} />
              <DateInput value={props.toDateValue ? props.toDateValue : ''} onChange={props.toDateOnChange} />
            </React.Fragment>
          )}
          <SelectBox value={props.selectBoxValue} onChange={props.selectBoxOnChange} width={props.selectBoxWidth}>
            {props.selectBoxOptions.map(opt => (
              <option key={opt.value} value={opt.value}>
                {opt.label}
              </option>
            ))}
          </SelectBox>

          {props.selectBox2Options && (
            <SelectBox value={props.selectBox2Value ? props.selectBox2Value : ''} onChange={props.selectBox2OnChange}>
              {props.selectBox2Options.map(opt => (
                <option key={opt.value} value={opt.value}>
                  {opt.label}
                </option>
              ))}
            </SelectBox>
          )}
        </div>

        {props.toggleButtons && <ToggleButtons buttons={props.toggleButtons} />}
        {props.showExportButton && props.exportDataTitle && props.exportData && (
          <ExportButton exportTitle={props.exportDataTitle} exportData={props.exportData}>
            Export as CSV
          </ExportButton>
        )}
      </div>
    </div>
  );
};

export default BoxTitle;
