const convertDateTimeToMMSlashDDSlashYYYY = (date: Date) => {
  const day = date.toLocaleString('default', { day: '2-digit' });
  const month = date.toLocaleString('default', { month: '2-digit' });
  const year = date.toLocaleString('default', { year: 'numeric' });
  return `${month}/${day}/${year}`;
};
const formatTime = (time: string) => {
  const hrNumber = Number(time.substring(0, 2));
  const hour = hrNumber <= 12 ? time.substring(0, 2) : String(hrNumber - 12).padStart(2, '0');
  const ampm = hrNumber <= 12 ? ' AM' : ' PM';
  const newTime = hour + time.substring(2);
  return newTime + ampm;
};

export { convertDateTimeToMMSlashDDSlashYYYY, formatTime };
