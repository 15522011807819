export const race = [
  { label: 'American Indian/Alaska Native' },
  { label: 'Black/African American' },
  { label: 'Other Pacific Islander' },
  { label: 'White/Caucasian' },
  { label: 'Asian' },
  { label: 'Native Hawaiian' },
  { label: 'More than one race' },
  { label: 'Other' },
  { label: 'Unreported/Refuse to Report' },
];
export const ethenicity = [{ label: 'Hispanic or Latino' }, { label: 'Non-Hispanic' }, { label: 'Refuse to Report' }];
export const maritalStatus = [{ label: 'Single' }, { label: 'Married' }, { label: 'Divorced' }, { label: 'Separated' }, { label: 'Widowed' }];
