import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { IOnlineUserResp, IDetailsState } from './index.types';

const initialState: IDetailsState = {
  status: '',
};

const videoCallSlice = createSlice({
  name: 'video-call',
  initialState,
  reducers: {
    callReset: state => {
      state.status = '';
      state.data = undefined;
    },
    incomingCall: (state, { payload }: { payload: IOnlineUserResp[] }) => {
      state.status = 'Incoming';
      state.data = payload;
    },
    answerCall: (state, { payload }: { payload: IOnlineUserResp[] }) => {
      state.status = 'Answer';
      state.data = payload;
    },
    declineCall: state => {
      state.status = 'Decline';
      state.data = undefined;
    },
  },
});

export const { callReset, incomingCall, answerCall, declineCall } = videoCallSlice.actions;

export const videoCallSliceValue = (state: RootState) => state.videoCall;

export default videoCallSlice.reducer;
