import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { SideProps } from '.';
import { useSelector } from 'react-redux';
import { loginSliceValue } from '../../redux/reducers/loginSlice';

const EeceptionistSidebar: FC<SideProps> = ({ currentMenu }) => {
  const { userDetails } = useSelector(loginSliceValue);

  return (
    <nav className='navigation'>
      <ul className='navigation__list'>
        <li className='navigation__list--link'>
          <img src='/assets/logoWhite.png' alt='logo' className='navigation__logo' />
        </li>
        {userDetails.data?.user.modules?.questionnaire ? (
          <React.Fragment>
            <li className='navigation__list--item'>
              <Link to='/questions' className={currentMenu === 'questions' ? 'navigation__list--link-active' : 'navigation__list--link'}>
                <svg className={currentMenu === 'questions' ? 'navigation__icon-active' : 'navigation__icon'}>
                  <use xlinkHref={`/assets/sprite.svg#icon-question`} />
                </svg>
                <span>Questions</span>
              </Link>
            </li>
            <li className='navigation__list--item'>
              <Link to='/providers' className={currentMenu === 'providers' ? 'navigation__list--link-active' : 'navigation__list--link'}>
                <svg className={currentMenu === 'providers' ? 'navigation__icon-active' : 'navigation__icon'}>
                  <use xlinkHref={`/assets/sprite.svg#icon-doctor2`} />
                </svg>
                <span>Providers</span>
              </Link>
            </li>
            <li className='navigation__list--item'>
              <Link to='/patients' className={currentMenu === 'patients' ? 'navigation__list--link-active' : 'navigation__list--link'}>
                <svg className={currentMenu === 'patients' ? 'navigation__icon-active' : 'navigation__icon'}>
                  <use xlinkHref={`/assets/sprite.svg#icon-user`} />
                </svg>
                <span>Patients</span>
              </Link>
            </li>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <li className='navigation__list--item'>
              <Link to='/' className={currentMenu === 'dashboard' ? 'navigation__list--link-active' : 'navigation__list--link'}>
                <svg className={currentMenu === 'dashboard' ? 'navigation__icon-active' : 'navigation__icon'}>
                  <use xlinkHref={`/assets/sprite.svg#icon-menu2`} />
                </svg>
                <span>Dashboard</span>
              </Link>
            </li>
            <li className='navigation__list--item'>
              <Link to='/providers' className={currentMenu === 'providers' ? 'navigation__list--link-active' : 'navigation__list--link'}>
                <svg className={currentMenu === 'providers' ? 'navigation__icon-active' : 'navigation__icon'}>
                  <use xlinkHref={`/assets/sprite.svg#icon-doctor2`} />
                </svg>
                <span>Providers</span>
              </Link>
            </li>
            <li className='navigation__list--item'>
              <Link to='/users' className={currentMenu === 'users' ? 'navigation__list--link-active' : 'navigation__list--link'}>
                <svg className={currentMenu === 'users' ? 'navigation__icon-active' : 'navigation__icon'}>
                  <use xlinkHref={`/assets/sprite.svg#icon-users2`} />
                </svg>
                <span>Users</span>
              </Link>
            </li>

            <li className='navigation__list--item'>
              <Link to='/appointments' className={currentMenu === 'appointment' ? 'navigation__list--link-active' : 'navigation__list--link'}>
                <svg className={currentMenu === 'appointment' ? 'navigation__icon-active' : 'navigation__icon'}>
                  <use xlinkHref={`/assets/sprite.svg#icon-calendar`} />
                </svg>
                <span>Appointments</span>
              </Link>
            </li>

            <li className='navigation__list--item'>
              <Link to='/check-ins' className={currentMenu === 'check-in' ? 'navigation__list--link-active' : 'navigation__list--link'}>
                <svg className={currentMenu === 'check-in' ? 'navigation__icon-active' : 'navigation__icon'}>
                  <use xlinkHref={`/assets/sprite.svg#icon-check-circle`} />
                </svg>
                <span>Check-ins</span>
              </Link>
            </li>

            <li className='navigation__list--item'>
              <Link to='/patient-feedback' className={currentMenu === 'patient-feedback' ? 'navigation__list--link-active' : 'navigation__list--link'}>
                <svg className={currentMenu === 'patient-feedback' ? 'navigation__icon-active' : 'navigation__icon'}>
                  <use xlinkHref={`/assets/sprite.svg#icon-feedback`} />
                </svg>
                <span>Patient Feedback</span>
              </Link>
            </li>
          </React.Fragment>
        )}
      </ul>

      <div>
        <Link to='/my-profile' className={currentMenu === 'my-profile' ? 'navigation__list--link-active' : 'navigation__list--link'}>
          <svg className={currentMenu === 'my-profile' ? 'navigation__icon-active' : 'navigation__icon'}>
            <use xlinkHref={`/assets/sprite.svg#icon-users2`} />
          </svg>
          <span>My Profile</span>
        </Link>

        <p className='powered__text'>
          <span>
            Powered By
            <br />
            <a href='https://www.the-algo.com/' target='_blank' rel='noreferrer'>
              The Algorithm
            </a>
          </span>
          &copy; {new Date(Date.now()).getFullYear()}. clinIQ
        </p>
      </div>
      {/* <img src='./algo-logo.png' alt='The Algorithm' className='powered_logo' /> */}
    </nav>
  );
};

export default EeceptionistSidebar;
