import type { FC, PropsWithChildren } from 'react';
import { IAllStatus, IAllStatusRes } from '../../hooks/checkins/index.type';
import { checkinsStatusTableHeadData } from '../../hooks/checkins/staticData';

import ModalHeading from '../../components/Modal/ModalHeading';
import { ModalButtons, Modal, ModalForm, InputsSection, InputBox, Button } from '../../components/Modal';
import { CustomTableRow, Table, TableBody, TableButton, TableHead, TableNoContent, TableRowTitle } from '../../components/Table';
import ToggleSwitch from '../../components/ToggleSwitch';
import TableSkeleton from '../../Skeletons/TableSkeleton';
import classes from '../../styles/Table.module.css';

export interface IStatusName {
  value: string;
  error: string;
}

type AllStatusModalProps = PropsWithChildren<{
  loading: boolean;
  allStatusData?: IAllStatusRes;
  statusModal: boolean;
  inputBoxShow: boolean;
  setInputBoxShow: React.Dispatch<React.SetStateAction<boolean>>;
  onCloseModal?: () => void;
  statusName: IStatusName;
  setStatusName: React.Dispatch<React.SetStateAction<IStatusName>>;
  statusEditStatus: { status: boolean; data?: IAllStatus };
  setStatusEditStatus: React.Dispatch<React.SetStateAction<{ status: boolean; data?: IAllStatus }>>;
  handleCancelAddStatus: () => void;
  handleCreateStatus: (e: React.FormEvent<HTMLFormElement>) => void;
  setToggleStatusData: React.Dispatch<React.SetStateAction<{ data?: IAllStatus }>>;
}>;

const AllStatusModal: FC<AllStatusModalProps> = props => {
  return (
    <Modal show={props.statusModal}>
      <ModalHeading heading='List of all check-in status' />
      <Table style={{ margin: '0 2rem' }}>
        <TableHead data={checkinsStatusTableHeadData} />
        <TableBody>
          <CustomTableRow>
            {props.loading ? (
              <TableSkeleton rows={7} columns={5} />
            ) : props.allStatusData && props.allStatusData.data.data.length > 0 ? (
              props.allStatusData.data.data.map((status: IAllStatus, index: number) => (
                <tr key={status._id}>
                  <td>{index + 1}</td>
                  <TableRowTitle>{status.title ? status.title : ''}</TableRowTitle>
                  <td>{new Date(status.createdAt).toLocaleString()}</td>
                  <td>{new Date(status.updatedAt).toLocaleString()}</td>
                  <td>
                    <div className={classes.table__buttons}>
                      <ToggleSwitch id={status._id} checked={status.active} onChange={() => props.setToggleStatusData({ data: status })} />
                      <TableButton icon='edit-3' onClick={() => props.setStatusEditStatus({ status: true, data: status })} variant='normal' />
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <TableNoContent colSpan={checkinsStatusTableHeadData.length} message='No data found' />
            )}
          </CustomTableRow>
        </TableBody>
      </Table>
      {props.inputBoxShow && (
        <ModalForm style={{ marginBottom: '2.5rem' }} onSubmit={props.handleCreateStatus}>
          <InputsSection style={{ flexWrap: 'wrap', alignItems: 'flex-end' }}>
            <InputBox
              label='Status Name'
              placeholder='eg, With the doctor'
              value={props.statusName.value}
              onChange={event => {
                props.setStatusName({ value: event.target.value, error: '' });
              }}
              errorMessage={props.statusName.error}
            />
            <div className='flex'>
              <Button type='submit' label={`${props.statusEditStatus.status ? 'Update' : 'Create'}`} />
              <Button type='button' label='Cancel' onClick={props.handleCancelAddStatus} />
            </div>
          </InputsSection>
        </ModalForm>
      )}
      <ModalButtons
        submitButtonLabel='Create new status'
        onSubmit={() => props.setInputBoxShow(true)}
        cancelButtonLabel='Close'
        onCancel={props.onCloseModal}
        style={{ margin: '2rem 0' }}
      />
    </Modal>
  );
};

export default AllStatusModal;
