import toast from 'react-hot-toast';
import useApi from '../useApi';
import { IResetPasswordReq, IResetPasswordRes } from './index.type';

const useResetPassword = ({ userId }: { userId?: string }) => {
  const { loading: resetPasswordLoading, makeApiCall } = useApi({
    url: `/users/${userId}/reset-password`,
    method: 'PATCH',
  });

  const resetPassword = async (FormData: IResetPasswordReq) => {
    const { response, error } = await makeApiCall<IResetPasswordReq, IResetPasswordRes>(FormData);

    if (response?.status === 'success') {
      toast.success('User password reset successfully.');
      return response;
    }

    if (error) {
      toast.error('Something went wrong whiling changing password. Please try again later');
      return { status: 'fail' };
    }
  };

  return {
    resetPasswordLoading,
    resetPassword,
  };
};

export default useResetPassword;
