import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { loginSliceValue } from '../redux/reducers/loginSlice';
import {
  useAddFolder,
  useAllFiles,
  useAllFolders,
  useCopyFile,
  useCutFile,
  useDeleteFile,
  useDeleteFolder,
  useDownloadFile,
  useEditFile,
  useEditFolder,
  useFoldersFiles,
  useSubFolderFiles,
  useSubFolders,
  useUploadFile,
} from '../hooks/folders';

import { Content } from '../components';
import FolderListSkeleton from '../Skeletons/FolderListSkeleton';
import { IChildFolder, IFolder } from '../hooks/folders/index.type';
import FileExchangeStyles from '../styles/FileExchangeScreen.module.css';
import {
  AddFolder,
  AddFolderButton,
  FileItemWithMenu,
  FolderItemWithMenu,
  RecentlyUploadedFile,
  DeleteFolderModal,
  ShareFileModal,
  DeleteFileModal,
  FileHeading,
} from '../pageComponents/SecureFiles';
import RecentFileUploadsSkeleton from '../Skeletons/RecentFileUploadsSkeleton';
import Pagination from '../components/Pagination';
import { IdeleteModalStates, DeleteModalState } from '../pageComponents/SecureFiles/DeleteFolderModal';
import DownloadFileModal, { DownloadModalState, IdownloadModalStates } from '../pageComponents/SecureFiles/DownloadFileModal';
import CopyFileModal, { IcopyModalStates } from '../pageComponents/SecureFiles/CopyFileModal';
import CutFileModal, { IcutModalStates } from '../pageComponents/SecureFiles/CutFileModal';
import RenameFileModal, { IrenameModalStates } from '../pageComponents/SecureFiles/RenameFileModal';
import { DeleteFileModalState } from '../pageComponents/SecureFiles/DeleteFileModal';
import UploadFileModal, { IuploadModalStates, UploadFileModalState } from '../pageComponents/SecureFiles/UploadFileModal';
import { validatePassword } from '../utils/validators';

const SecureFiles = () => {
  const [userRole, setUserRole] = React.useState<string>('');
  const [pageNumber, setPageNumber] = React.useState<number>(1);
  const perpageData = 7;

  const [selectedFolder, setSelectedFolder] = React.useState<IFolder>();
  const [selectedSubFolder, setSelectedSubFolder] = React.useState<IChildFolder>();
  const [isAddFolder, setIsAddFolder] = React.useState(false);
  const [folderName, setFolderName] = React.useState('');
  const [isEditFolder, setIsEditFolder] = React.useState<{ data?: IFolder | IChildFolder; status: boolean }>({
    status: false,
  });
  const [editfolderName, setEditFolderName] = React.useState('');
  const [isAddSubFolder, setIsAddSubFolder] = React.useState(false);
  const [subFolderName, setSubFolderName] = React.useState('');

  const [deleteFolderModal, setDeleteFolderModal] = React.useState<DeleteModalState>({ status: false, isParent: true });
  const startDeleteFolderModalValue = { name: { value: '', error: '' } };
  const [deleteFolderInput, setDeleteFolderInput] = React.useState<IdeleteModalStates>(startDeleteFolderModalValue);

  const startDownloadFileModalValue = { password: { value: '', error: '' } };
  const [downloadFilePasswordInput, setDownloadFilePasswordInput] = React.useState<IdownloadModalStates>(startDownloadFileModalValue);
  const [downloadFileModal, setDownloadFileModal] = React.useState<DownloadModalState>({ status: false });

  const startShareFileModalValue = { password: { value: '', error: '' } };
  const [shareFilePasswordInput, setShareFilePasswordInput] = React.useState<IdownloadModalStates>(startShareFileModalValue);
  const [shareFileModal, setShareFileModal] = React.useState<DownloadModalState>({ status: false });

  const startCopyFileModalValue = { folder: { value: '', error: '' } };
  const [copyFilePasswordInput, setCopyFilePasswordInput] = React.useState<IcopyModalStates>(startCopyFileModalValue);
  const [copyFileModal, setCopyFileModal] = React.useState<DownloadModalState>({ status: false });

  const [cutFilePasswordInput, setCutFilePasswordInput] = React.useState<IcutModalStates>(startCopyFileModalValue);
  const [cutFileModal, setCutFileModal] = React.useState<DownloadModalState>({ status: false });

  const startRenameFileModalValue = { fileName: { value: '', error: '' } };
  const [renameFilePasswordInput, setRenameFilePasswordInput] = React.useState<IrenameModalStates>(startRenameFileModalValue);
  const [renameFileModal, setRenameFileModal] = React.useState<DownloadModalState>({ status: false });

  const [deleteFileModal, setDeleteFileModal] = React.useState<DeleteFileModalState>({ status: false });
  const startDeleteFileModalValue = { name: { value: '', error: '' } };
  const [deleteFileInput, setDeleteFileInput] = React.useState<IdeleteModalStates>(startDeleteFileModalValue);

  const [uploadFileModal, setUploadFileModal] = React.useState<UploadFileModalState>({ status: false });
  const startUploadFileModalValue = {
    name: { value: '', error: '' },
    file: { error: '' },
    uploadNote: { value: '', error: '' },
    protected: { value: false, error: '' },
    password: { value: '', error: '' },
    passwordConfirm: { value: '', error: '' },
  };
  const [uploadFileInput, setUploadFileInput] = React.useState<IuploadModalStates>(startUploadFileModalValue);

  const { userDetails } = useSelector(loginSliceValue);
  const { allFilesData, getAllFilesData, allFilesDataLoading } = useAllFiles({ pageNumber, perpageData });
  const { foldersData, getFoldersData, foldersDataLoading } = useAllFolders({});
  const { subFoldersData, getSubFoldersData, subFoldersDataLoading } = useSubFolders({ folderId: selectedFolder?._id });
  const { folderFilesData, getFolderFilesData, folderFilesDataLoading } = useFoldersFiles({ folderId: selectedFolder?._id });
  const { subFolderFilesData, getSubFolderFilesData, subFolderFilesDataLoading } = useSubFolderFiles({ folderId: selectedSubFolder?._id });

  const { addFolder } = useAddFolder();
  const { editFolder } = useEditFolder({ folderId: isEditFolder.data?._id });
  const { deleteFolder, deleteFolderLoading } = useDeleteFolder({ folderId: deleteFolderModal.data?._id });
  const { downloadFile, downloadFileLoading } = useDownloadFile();
  const { editFile, editFileLoading } = useEditFile({ fileId: renameFileModal.data?._id });
  const { copyFile, copyFileLoading } = useCopyFile();
  const { cutFile, cutFileLoading } = useCutFile();
  const { deleteFile, deleteFileLoading } = useDeleteFile({ fileId: deleteFileModal.data?._id });
  const { uploadFile, uploadFileLoading } = useUploadFile();

  React.useEffect(() => {
    if (userDetails.data?.user.role) setUserRole(userDetails.data?.user.role);
  }, [userDetails]);

  React.useEffect(() => {
    if (userRole === 'admin' || userRole === 'adAdmin' || userRole === 'fileAdmin' || userRole === 'fileUser' || userRole === 'clinic') {
      getFoldersData();
    }
    // eslint-disable-next-line
  }, [userRole]);

  React.useEffect(() => {
    if (foldersData && foldersData.data.data.length > 0) {
      setSelectedFolder(foldersData.data.data[0]);
    }
  }, [foldersData]);

  React.useEffect(() => {
    if (selectedFolder && selectedFolder._id) {
      getSubFoldersData();
      getFolderFilesData();
    }
    // eslint-disable-next-line
  }, [selectedFolder]);

  React.useEffect(() => {
    if (selectedSubFolder && selectedSubFolder._id) {
      getSubFolderFilesData();
    }
    // eslint-disable-next-line
  }, [selectedSubFolder]);

  const onPageChange = (currentPage: { selected: number }) => {
    setPageNumber(currentPage.selected + 1);
  };

  const handleAddFolder = async ({ isParent }: { isParent: boolean }) => {
    if (isParent) {
      if (folderName === '' || folderName.trim() === '') {
        alert('Enter folder name');
      } else {
        const data = await addFolder({ name: folderName });
        if (data?.status === 'success') {
          setIsAddFolder(false);
          setFolderName('');
          getFoldersData();
        }
      }
    } else {
      if (selectedFolder) {
        if (subFolderName === '' || subFolderName.trim() === '') {
          alert('Enter folder name');
        } else {
          const data = await addFolder({ name: subFolderName, parentFolder: selectedFolder._id });
          if (data?.status === 'success') {
            setIsAddSubFolder(false);
            setSubFolderName('');
            getSubFoldersData();
          }
        }
      }
    }
  };

  const handleEditFolder = async () => {
    if (isEditFolder.data)
      if (editfolderName === '' || editfolderName.trim() === '') {
        alert('Enter folder name');
      } else {
        const data = await editFolder({ name: editfolderName });
        if (data?.status === 'success') {
          setEditFolderName('');
          setIsEditFolder({ status: false });
          getFoldersData();
        }
      }
  };

  const handleDeleteFolder = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!deleteFolderInput.name.value.trim() || deleteFolderInput.name.value !== deleteFolderModal.data?.name) {
      setDeleteFolderInput(prevVal => ({ ...prevVal, name: { ...prevVal.name, error: 'Invalid folder name' } }));
    } else {
      await deleteFolder();
      if (deleteFolderModal.isParent) {
        getFoldersData();
      } else {
        getSubFoldersData();
      }
      handleCloseDeleteFolderModal();
    }
  };

  const handleCloseDeleteFolderModal = () => {
    setDeleteFolderModal({ status: false, isParent: true });
    setDeleteFolderInput(startDeleteFolderModalValue);
  };

  const handleDownloadFile = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (downloadFileModal.data)
      if (downloadFileModal.data?.protected) {
        if (!downloadFilePasswordInput.password.value.trim()) {
          setDownloadFilePasswordInput(prevVal => ({ ...prevVal, password: { ...prevVal.password, error: 'Please enter file password to download' } }));
        } else {
          const data = await downloadFile({
            contentKey: downloadFileModal.data.contentKey,
            password: downloadFilePasswordInput.password.value,
            passwordProtected: downloadFileModal.data.protected,
          });
          if (data.status === 'success') handleCloseDownloadFileModal();
        }
      } else {
        await downloadFile({
          contentKey: downloadFileModal.data.contentKey,
          passwordProtected: downloadFileModal.data.protected,
        });
        handleCloseDownloadFileModal();
      }
  };

  const handleCloseDownloadFileModal = () => {
    setDownloadFileModal({ status: false });
    setDownloadFilePasswordInput(startDownloadFileModalValue);
  };

  const handleCloseShareFileModal = () => {
    setShareFileModal({ status: false });
    setShareFilePasswordInput(startShareFileModalValue);
  };

  const getUpdatedData = () => {
    getAllFilesData();

    if (selectedSubFolder && selectedSubFolder._id) {
      getSubFolderFilesData();
      getFolderFilesData();
    } else if (selectedFolder && selectedFolder._id) {
      setSelectedSubFolder(undefined);
      getFolderFilesData();
    } else {
      setSelectedSubFolder(undefined);
    }
  };

  const handleCopyFile = async () => {
    if (copyFileModal.data)
      if (!copyFilePasswordInput.folder.value.trim()) {
        setCopyFilePasswordInput(prevVal => ({ ...prevVal, folder: { ...prevVal.folder, error: 'Please select destination folder' } }));
      } else {
        const data = await copyFile({
          fileId: copyFileModal.data._id,
          folderId: copyFilePasswordInput.folder.value,
        });

        if (data?.status === 'success') {
          getUpdatedData();
          handleCloseCopyFileModal();
        }
      }
  };

  const handleCloseCopyFileModal = () => {
    setCopyFileModal({ status: false });
    setCopyFilePasswordInput(startCopyFileModalValue);
  };

  const handleCutFile = async () => {
    if (cutFileModal.data)
      if (!cutFilePasswordInput.folder.value.trim()) {
        setCutFilePasswordInput(prevVal => ({ ...prevVal, folder: { ...prevVal.folder, error: 'Please select destination folder' } }));
      } else {
        const data = await cutFile({
          fileId: cutFileModal.data._id,
          folderId: cutFilePasswordInput.folder.value,
        });

        if (data?.status === 'success') {
          getUpdatedData();
          handleCloseCutFileModal();
        }
      }
  };

  const handleCloseCutFileModal = () => {
    setCutFileModal({ status: false });
    setCutFilePasswordInput(startCopyFileModalValue);
  };

  const handleRenameFile = async () => {
    if (renameFileModal.data)
      if (!renameFilePasswordInput.fileName.value.trim()) {
        setRenameFilePasswordInput(prevVal => ({ ...prevVal, fileName: { ...prevVal.fileName, error: 'Please enter file name' } }));
      } else {
        const data = await editFile({
          name: renameFilePasswordInput.fileName.value,
        });

        if (data?.status === 'success') {
          getUpdatedData();
          handleCloseRenameFileModal();
        }
      }
  };

  const handleCloseRenameFileModal = () => {
    setRenameFileModal({ status: false });
    setRenameFilePasswordInput(startRenameFileModalValue);
  };

  const handleDeleteFile = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!deleteFileInput.name.value.trim() || deleteFileInput.name.value !== deleteFileModal.data?.name) {
      setDeleteFileInput(prevVal => ({ ...prevVal, name: { ...prevVal.name, error: 'Invalid file name' } }));
    } else {
      await deleteFile();
      getUpdatedData();
      handleCloseDeleteFileModal();
    }
  };

  const handleCloseDeleteFileModal = () => {
    setDeleteFileModal({ status: false });
    setDeleteFileInput(startDeleteFileModalValue);
  };

  const handleUploadFile = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (uploadFileModal.data)
      if (!uploadFileInput.name.value.trim()) {
        setUploadFileInput(prevVal => ({ ...prevVal, name: { ...prevVal.name, error: 'Please enter file collection name.' } }));
      } else if (!uploadFileInput.file.file || uploadFileInput.file.file?.length === 0) {
        setUploadFileInput(prevVal => ({ ...prevVal, file: { ...prevVal.file, error: 'Please select files to upload' } }));
      } else if (uploadFileInput.protected.value && !validatePassword(uploadFileInput.password.value)) {
        setUploadFileInput(prevVal => ({ ...prevVal, password: { ...prevVal.password, error: 'Please set strong password' } }));
      } else if (uploadFileInput.protected.value && uploadFileInput.password.value !== uploadFileInput.passwordConfirm.value) {
        setUploadFileInput(prevVal => ({ ...prevVal, passwordConfirm: { ...prevVal.passwordConfirm, error: 'Passwords do not match' } }));
      } else {
        let uploadFileSize = 0;
        for (let i = 0; i < uploadFileInput.file.file.length; i++) {
          uploadFileSize += uploadFileInput.file.file[i].size;
        }

        if (uploadFileSize > 50 * 1024 * 1024) {
          setUploadFileInput(prevVal => ({ ...prevVal, file: { ...prevVal.file, error: 'Please upload file less than 50 MB' } }));
        } else {
          let fileData = new FormData();
          fileData.append('name', uploadFileInput.name.value);
          uploadFileInput.protected.value && fileData.append('password', uploadFileInput.password.value);
          fileData.append('uploadNote', uploadFileInput.uploadNote.value);
          fileData.append('protected', String(uploadFileInput.protected.value));
          fileData.append('folder', uploadFileModal.data?._id);

          for (let i = 0; i < uploadFileInput.file.file.length; i++) {
            fileData.append('files', uploadFileInput.file.file[i]);
          }
          const data = await uploadFile(fileData);
          if (data?.status) {
            getUpdatedData();
            handleCloseUploadFileModal();
          }
        }
      }
  };

  const handleCloseUploadFileModal = () => {
    setUploadFileModal({ status: false });
    setUploadFileInput(startUploadFileModalValue);
  };

  return (
    <Content currentMenu='secure-files' headerTitle='Secure File Exchange' addBtn={false} searchBox={false}>
      <div className={FileExchangeStyles.container}>
        {userRole !== 'admin' ? (
          <>
            <div className={FileExchangeStyles.folderContainer}>
              <h3 className={FileExchangeStyles.folderHeading}>Folders</h3>
              <div className={FileExchangeStyles.folders}>
                {foldersDataLoading ? (
                  <FolderListSkeleton />
                ) : (
                  foldersData?.data.data.map((folder, index) => (
                    <Fragment key={folder._id}>
                      {isEditFolder.status && isEditFolder.data?._id === folder._id ? (
                        <AddFolder
                          autoFocus={isEditFolder.status && isEditFolder.data._id === folder._id}
                          value={editfolderName}
                          onChange={e => setEditFolderName(e.target.value)}
                          onClickYes={handleEditFolder}
                          onClickNo={() => {
                            setIsEditFolder({ status: false });
                            setEditFolderName('');
                          }}
                        />
                      ) : (
                        <FolderItemWithMenu
                          id={`contextmenu${folder._id}`}
                          selected={selectedFolder?._id === folder._id}
                          onClick={() => {
                            setSelectedFolder(folder);
                            setSelectedSubFolder(undefined);
                          }}
                          index={index}
                          name={folder.name}
                          onRename={() => {
                            setIsEditFolder({ data: folder, status: true });
                            setEditFolderName(folder.name);
                          }}
                          onDelete={() => setDeleteFolderModal({ data: folder, status: true, isParent: true })}
                        />
                      )}
                    </Fragment>
                  ))
                )}

                {!foldersDataLoading &&
                  (userRole === 'admin' || userRole === 'fileAdmin' || userRole === 'adAdmin' || userRole === 'clinic') &&
                  (!isAddFolder ? (
                    <AddFolderButton label='Add New Folder' onClick={() => setIsAddFolder(true)} />
                  ) : (
                    <AddFolder
                      autoFocus={isAddFolder}
                      value={folderName}
                      onChange={e => setFolderName(e.target.value)}
                      onClickYes={() => handleAddFolder({ isParent: true })}
                      onClickNo={() => setIsAddFolder(false)}
                    />
                  ))}
              </div>
            </div>

            <div className={FileExchangeStyles.folderFiles}>
              <FileHeading
                title={selectedFolder && selectedFolder._id ? selectedFolder.name : ' '}
                uploadBtnLabel='Upload File'
                uploadBtnClick={() => setUploadFileModal({ status: true, data: selectedFolder })}
              />

              <h4 className={FileExchangeStyles.folderFilesSub}>Sub Folders</h4>
              <div className={FileExchangeStyles.folders}>
                {subFoldersDataLoading ? (
                  <FolderListSkeleton itmes={3} />
                ) : (
                  subFoldersData?.data.data.childFolders &&
                  subFoldersData?.data.data.childFolders.length > 0 &&
                  subFoldersData?.data.data.childFolders.map((folder, index) => (
                    <Fragment key={folder._id}>
                      {isEditFolder.status && isEditFolder.data?._id === folder._id ? (
                        <AddFolder
                          autoFocus={isEditFolder.status && isEditFolder.data._id === folder._id}
                          value={editfolderName}
                          onChange={e => setEditFolderName(e.target.value)}
                          onClickYes={handleEditFolder}
                          onClickNo={() => {
                            setIsEditFolder({ status: false });
                            setEditFolderName('');
                          }}
                        />
                      ) : (
                        <FolderItemWithMenu
                          id={`contextmenu${folder._id}`}
                          selected={selectedSubFolder?._id === folder._id}
                          onClick={() => {
                            setSelectedSubFolder(folder);
                          }}
                          index={index}
                          name={folder.name}
                          onRename={() => {
                            setIsEditFolder({ data: folder, status: true });
                            setEditFolderName(folder.name);
                          }}
                          onDelete={() => setDeleteFolderModal({ data: folder, status: true, isParent: false })}
                        />
                      )}
                    </Fragment>
                  ))
                )}

                {!subFoldersDataLoading &&
                  (userRole === 'admin' || userRole === 'fileAdmin' || userRole === 'adAdmin' || userRole === 'clinic') &&
                  (!isAddSubFolder ? (
                    <AddFolderButton label='Add New Sub Folder' onClick={() => setIsAddSubFolder(true)} />
                  ) : (
                    <AddFolder
                      autoFocus={isAddSubFolder}
                      value={subFolderName}
                      onChange={e => setSubFolderName(e.target.value)}
                      onClickYes={() => handleAddFolder({ isParent: false })}
                      onClickNo={() => setIsAddSubFolder(false)}
                    />
                  ))}
              </div>

              {selectedSubFolder && selectedSubFolder._id ? (
                <Fragment>
                  <FileHeading
                    title={selectedSubFolder.name + ' Files'}
                    uploadBtnLabel={'Upload File to ' + selectedSubFolder.name}
                    uploadBtnClick={() => setUploadFileModal({ status: true, data: selectedSubFolder })}
                    backBtnClick={() => setSelectedSubFolder(undefined)}
                  />
                  <div className={FileExchangeStyles.folders}>
                    {subFolderFilesDataLoading ? (
                      <FolderListSkeleton itmes={3} type='file' />
                    ) : subFolderFilesData && subFolderFilesData.data.data.length > 0 ? (
                      subFolderFilesData.data.data.map(file => (
                        <FileItemWithMenu
                          key={file._id}
                          id={`contextmenu${file._id}`}
                          onClick={() => setDownloadFileModal({ data: file, status: true })}
                          protected={file.protected}
                          name={file.name}
                          onDownload={() => setDownloadFileModal({ data: file, status: true })}
                          onShare={() => setShareFileModal({ data: file, status: true })}
                          onCopy={() => setCopyFileModal({ data: file, status: true })}
                          onCut={() => setCutFileModal({ data: file, status: true })}
                          onRename={() => {
                            setRenameFileModal({ data: file, status: true });
                            setRenameFilePasswordInput(prevVal => ({
                              ...prevVal,
                              fileName: {
                                value: file.name,
                                error: '',
                              },
                            }));
                          }}
                          onDelete={() => setDeleteFileModal({ data: file, status: true })}
                          showDelete={userRole === 'fileUser' ? (userDetails.data?.user._id === file.uploadedBy._id ? true : false) : true}
                        />
                      ))
                    ) : (
                      <p>No files found</p>
                    )}
                  </div>
                </Fragment>
              ) : (
                <Fragment>
                  <h4 className={FileExchangeStyles.folderFilesSub}>Files</h4>
                  <div className={FileExchangeStyles.folders}>
                    {folderFilesDataLoading ? (
                      <FolderListSkeleton itmes={6} type='file' />
                    ) : folderFilesData && folderFilesData?.data.data.length > 0 ? (
                      folderFilesData?.data.data.map(file => (
                        <FileItemWithMenu
                          key={file._id}
                          id={`contextmenu${file._id}`}
                          onClick={() => setDownloadFileModal({ data: file, status: true })}
                          protected={file.protected}
                          name={file.name}
                          onDownload={() => setDownloadFileModal({ data: file, status: true })}
                          onShare={() => setShareFileModal({ data: file, status: true })}
                          onCopy={() => setCopyFileModal({ data: file, status: true })}
                          onCut={() => setCutFileModal({ data: file, status: true })}
                          onRename={() => {
                            setRenameFileModal({ data: file, status: true });
                            setRenameFilePasswordInput(prevVal => ({
                              ...prevVal,
                              fileName: {
                                value: file.name,
                                error: '',
                              },
                            }));
                          }}
                          onDelete={() => setDeleteFileModal({ data: file, status: true })}
                          showDelete={userRole === 'fileUser' ? (userDetails.data?.user._id === file.uploadedBy._id ? true : false) : true}
                        />
                      ))
                    ) : (
                      <p>No files found</p>
                    )}
                  </div>
                </Fragment>
              )}
            </div>
          </>
        ) : null}

        <div className={userRole === 'admin' ? FileExchangeStyles.recentUploadsContainerAdmin : FileExchangeStyles.recentUploadsContainer}>
          <h3 className={FileExchangeStyles.folderHeading}>Recently Uploaded</h3>
          <div className={FileExchangeStyles.recentUploads}>
            {allFilesDataLoading ? (
              <RecentFileUploadsSkeleton items={perpageData} />
            ) : allFilesData && allFilesData.data.data.length > 0 ? (
              <Fragment>
                {allFilesData.data.data.map(file => (
                  <RecentlyUploadedFile
                    key={file._id}
                    name={file.name}
                    uploadedBy={file.uploadedBy && file.uploadedBy.name ? file.uploadedBy.name : ''}
                    uploadedAt={new Date(file.createdAt).toDateString()}
                    onClick={() => setDownloadFileModal({ data: file, status: true })}
                  />
                ))}
              </Fragment>
            ) : (
              <p>No secure files</p>
            )}
            <div style={{ marginTop: '1rem' }}>
              <Pagination itemsPerPage={perpageData} totalItems={allFilesData?.totalResults ? allFilesData?.totalResults : 0} onPageChange={onPageChange} />
            </div>
          </div>
        </div>
      </div>

      {deleteFolderModal.status && (
        <DeleteFolderModal
          loading={deleteFolderLoading}
          deleteFolderModal={deleteFolderModal}
          deleteFolderInput={deleteFolderInput}
          setDeleteFolderInput={setDeleteFolderInput}
          onCloseModal={handleCloseDeleteFolderModal}
          handleDeleteFolder={handleDeleteFolder}
        />
      )}
      {downloadFileModal.status && (
        <DownloadFileModal
          loading={downloadFileLoading}
          downloadFileModal={downloadFileModal}
          downloadFilePasswordInput={downloadFilePasswordInput}
          setDownloadFilePasswordInput={setDownloadFilePasswordInput}
          onCloseModal={handleCloseDownloadFileModal}
          handleDownloadFile={handleDownloadFile}
        />
      )}
      {shareFileModal.status && (
        <ShareFileModal
          shareFileModal={shareFileModal}
          shareFilePasswordInput={shareFilePasswordInput}
          setShareFilePasswordInput={setShareFilePasswordInput}
          onCloseModal={handleCloseShareFileModal}
        />
      )}

      {copyFileModal.status && (
        <CopyFileModal
          loading={copyFileLoading}
          folders={foldersData}
          copyFileModal={copyFileModal}
          copyFilePasswordInput={copyFilePasswordInput}
          setCopyFilePasswordInput={setCopyFilePasswordInput}
          onCloseModal={handleCloseCopyFileModal}
          handleCopyFile={handleCopyFile}
        />
      )}
      {cutFileModal.status && (
        <CutFileModal
          loading={cutFileLoading}
          folders={foldersData}
          cutFileModal={cutFileModal}
          cutFilePasswordInput={cutFilePasswordInput}
          setCutFilePasswordInput={setCutFilePasswordInput}
          onCloseModal={handleCloseCutFileModal}
          handleCutFile={handleCutFile}
        />
      )}
      {renameFileModal.status && (
        <RenameFileModal
          loading={editFileLoading}
          renameFileModal={renameFileModal}
          renameFilePasswordInput={renameFilePasswordInput}
          setRenameFilePasswordInput={setRenameFilePasswordInput}
          onCloseModal={handleCloseRenameFileModal}
          handleRenameFile={handleRenameFile}
        />
      )}
      {deleteFileModal.status && (
        <DeleteFileModal
          loading={deleteFileLoading}
          deleteFileModal={deleteFileModal}
          deleteFileInput={deleteFileInput}
          setDeleteFileInput={setDeleteFileInput}
          onCloseModal={handleCloseDeleteFileModal}
          handleDeleteFile={handleDeleteFile}
        />
      )}
      {uploadFileModal.status && (
        <UploadFileModal
          loading={uploadFileLoading}
          uploadFileModal={uploadFileModal}
          uploadFileInput={uploadFileInput}
          setUploadFileInput={setUploadFileInput}
          onCloseModal={handleCloseUploadFileModal}
          handleUploadFile={handleUploadFile}
        />
      )}
    </Content>
  );
};

export default SecureFiles;
