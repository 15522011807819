import type { FC, PropsWithChildren } from 'react';

export type ToggleSwitchProps = PropsWithChildren<{
  id?: string;
  checked?: boolean;
  onChange?: () => void;
}>;

const ToggleSwitch: FC<ToggleSwitchProps> = props => {
  return (
    <label className='switch'>
      <input id={props.id} checked={props.checked} onChange={props.onChange} type='checkbox' className='checkbox' name='active' />
      <span className='slider round' />
    </label>
  );
};

export default ToggleSwitch;
