import toast from 'react-hot-toast';
import useApi from '../../useApi';
import { IAddClinicNewReq, IAddClinicRes } from '../index.type';

const useAddClinicNew = ({ clinicId }: { clinicId?: string }) => {
  const url = clinicId ? `/clinic?clinicId=${clinicId}` : '/clinic';
  const method = clinicId ? 'PUT' : 'POST';

  const { loading: addClinicLoading, makeApiCall } = useApi({ url, method });

  const addClinic = async (formData: IAddClinicNewReq) => {
    const { response, error } = await makeApiCall<IAddClinicNewReq, IAddClinicRes>(formData);
    if (response?.status === 'success') {
      return response;
    }

    if (error || !response?.status) {
      toast.error('Something went wrong. Please try again later');
      return { status: 'fail' };
    }
  };

  return {
    addClinicLoading,
    addClinic,
  };
};

export default useAddClinicNew;
