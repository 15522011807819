import React from 'react';
import type { FC, PropsWithChildren } from 'react';
import { ProgressForm } from '../../../components';
import { Modal, ModalForm, InputsSectionColumn } from '../../../components/Modal';
import ModalHeading from '../../../components/Modal/ModalHeading';

import Step1, { Istep1States } from './Step1';
import Step2, { IImageNameList } from './Step2';
import { usePainLocations, useActiveClinics, useAddCampaign, useDeleteCampaignImage, useGetCampaignImageList, useUploadPresInfo } from '../../../hooks/advertisements';
import { IAd, IAdList, IImageSrcList, IUploadPresInfo } from '../../../hooks/advertisements/index.type';
import Step3 from './Step3';
import Step4 from './Step4';

export type CreateCampaignModalState = PropsWithChildren<{
  status: boolean;
  data?: IAd;
}>;
export interface IpresInfoData {
  uploadDoc?: IUploadPresInfo;
  prescriptionInfoFile?: string;
  prescriptionInfoId?: string;
  prescriptionInfoFileName?: string;
  image: string;
  data?: File;
  value: string;
  error: string;
}

type CreateCampaignModalProps = PropsWithChildren<{
  getAllAds: () => Promise<void>;
  createCampaignModal: CreateCampaignModalState;
  setCreateCampaignModal: React.Dispatch<React.SetStateAction<CreateCampaignModalState>>;
  onCloseModal: () => void;
}>;

const CreateCampaignModal: FC<CreateCampaignModalProps> = props => {
  const [isNewCampaign, setIsNewCampaign] = React.useState<IAd['advertise']>();
  const [formError, setFormError] = React.useState<string>('');
  const [step, setStep] = React.useState<number>(1);
  const startStep1StatesValue = {
    title: { value: '', error: '' },
    subTitle: { value: '', error: '' },
    description: { value: '', error: '' },
  };
  const [step1StatesStates, setStep1StatesStates] = React.useState<Istep1States>(startStep1StatesValue);

  const [imageDimensionError, setImageDimensionError] = React.useState<boolean>(false);
  const [maxReached, setMaxReached] = React.useState<{ status: boolean; data?: IAdList }>({ status: false });
  const [imageList, setImageList] = React.useState<File[]>([]);
  const [imageDeleteId, setImageDeleteId] = React.useState<{ item?: { img: IImageSrcList }; index?: number }>({});
  const [imageNameList, setImageNameList] = React.useState<IImageNameList[]>([]);
  const [imageSrcList, setImageSrcList] = React.useState<IImageSrcList[]>([]);
  const [imageSrcListTemp, setImageSrcListTemp] = React.useState<IImageSrcList[]>([]);
  const [newImgSrcList, setNewImgSrcList] = React.useState<{ img: IImageSrcList }[]>([]);

  const [ruleList, setRuleList] = React.useState<IAd['advertise']['ruleList']>([]);
  const [hasTimeValidity, setHasTimeValidity] = React.useState<string[]>([]);
  const [dateRange, setDateRange] = React.useState<{ image: string; from: { date: string; error: string }; to: { date: string; error: string } }[]>([]);

  const [hasPresInfo, sethasPresInfo] = React.useState<string[]>([]);
  const [prescriptionInfoData, setPrescriptionInfoData] = React.useState<IpresInfoData[]>([]);
  const [submitBtnDisabled, setsubmitBtnDisabled] = React.useState(false);

  const [hasVolumeLimit, sethasVolumeLimit] = React.useState<string[]>([]);
  const [volumeLimitData, setVolumeLimitData] = React.useState<
    { image: string; isPaid: boolean; min: { value: number; error: string }; max: { value: number; error: string } }[]
  >([]);

  const { activeClinicsData } = useActiveClinics();
  const { painLocationsData } = usePainLocations();
  const { addCampaign, addCampaignLoading } = useAddCampaign();
  const { uploadPresInfo, uploadPresInfoLoading } = useUploadPresInfo();
  const { deleteImageLoading, deleteImage } = useDeleteCampaignImage({ imageId: imageDeleteId.item?.img._id });
  const { getCampaignImage, getCampaignImageLoading } = useGetCampaignImageList({
    campaignId: isNewCampaign?._id ? isNewCampaign._id : props.createCampaignModal.data?._id ? props.createCampaignModal.data?._id : '',
  });

  React.useEffect(() => {
    if (
      props.createCampaignModal.data?.campaignList &&
      Array.isArray(props.createCampaignModal.data.campaignList) &&
      props.createCampaignModal.data.campaignList.length
    ) {
      sethasPresInfo(
        props.createCampaignModal.data.campaignList.filter(item => item.hasOwnProperty('prescriptionInfoId') && item.prescriptionInfoId).map(item => item._id)
      );
      // sethasVolumeLimit(
      //   addToEdit.campaignList
      //     .filter((item) => item.hasOwnProperty('volumeLimit'))
      //     .map((item) => item._id)
      // );
      if (props.createCampaignModal.data?.advertise.ruleList) setRuleList(props.createCampaignModal.data?.advertise.ruleList);
    }
  }, [props.createCampaignModal]);

  React.useEffect(() => {
    if (props.createCampaignModal.data?.advertise) {
      setRuleList(props.createCampaignModal.data.advertise.ruleList);
      setStep1StatesStates({
        title: { value: props.createCampaignModal.data.advertise.title, error: '' },
        subTitle: { value: props.createCampaignModal.data.advertise.subTitle, error: '' },
        description: { value: props.createCampaignModal.data.advertise.description, error: '' },
      });
      getImageList();
    }
    // eslint-disable-next-line
  }, [props.createCampaignModal]);

  React.useEffect(() => {
    let rangeData: { image: string; from: { date: string; error: string }; to: { date: string; error: string } }[] = [];
    let presData: { image: string; data?: File; value: string; error: string }[] = [];
    let volumeData: { image: string; isPaid: boolean; min: { value: number; error: string }; max: { value: number; error: string } }[] = [];
    imageSrcList &&
      imageSrcList.length > 0 &&
      imageSrcList.forEach(imageData => {
        rangeData.push({
          image: imageData._id,
          from: {
            date: '',
            error: '',
          },
          to: {
            date: '',
            error: '',
          },
        });
        presData.push({
          image: imageData._id,
          value: '',
          error: '',
        });
        volumeData.push({
          image: imageData._id,
          isPaid: imageData.isPaid,
          min: { value: 0, error: '' },
          max: { value: 0, error: '' },
        });
      });
    setDateRange(rangeData);
    setPrescriptionInfoData(presData);
    setVolumeLimitData(volumeData);
  }, [imageSrcList]);

  // React.useEffect(() => {
  //   // setsubmitBtnDisabled(false);
  //   volumeLimitData &&
  //     volumeLimitData.length > 0 &&
  //     volumeLimitData.forEach(data => {
  //       if (data && hasVolumeLimit.includes(data.image) && data.min && (data.min.value <= 0 || data.min.error !== '')) {
  //         setsubmitBtnDisabled(true);
  //       }
  //       if (data && hasVolumeLimit.includes(data.image) && data.max && (data.max.value === 0 || data.max.error !== '')) {
  //         setsubmitBtnDisabled(true);
  //       }
  //     });
  // }, [volumeLimitData, hasVolumeLimit]);

  React.useEffect(() => {
    setsubmitBtnDisabled(false);
    prescriptionInfoData &&
      prescriptionInfoData.length > 0 &&
      prescriptionInfoData.forEach(data => {
        if (data && data.error !== '') {
          setsubmitBtnDisabled(true);
        }

        // if (data && hasPresInfo.includes(data.image) && !data.data) {
        //   if (data.prescriptionInfoId) setsubmitBtnDisabled(false);
        //   else setsubmitBtnDisabled(true);
        // }
      });
  }, [prescriptionInfoData, hasPresInfo]);

  // React.useEffect(() => {
  //   // dateRange &&
  //   //   dateRange.length > 0 &&
  //   //   dateRange.forEach(data => {
  //   //     if (data && hasTimeValidity.includes(data.image) && data.from && data.to && data.from.date === '') {
  //   //       setsubmitBtnDisabled(true);
  //   //     }
  //   //   });
  //   imageSrcList.length > 0 &&
  //     imageSrcList.forEach(data => {
  //       if (hasTimeValidity.includes(data._id) && (!data.from || !data.to)) setsubmitBtnDisabled(true);
  //       if (data && hasVolumeLimit.includes(data._id) && (data.min.value < 0 || data.max.value <= 0)) setsubmitBtnDisabled(true);

  //       if ((hasTimeValidity.includes(data._id) && data.from && data.to) || (hasVolumeLimit.includes(data._id) && data.min.value >= 0 && data.max.value > 0))
  //         setsubmitBtnDisabled(false);
  //     });
  // }, [imageSrcList, hasTimeValidity, hasVolumeLimit]);

  // React.useEffect(() => {
  //   setDateRange(prevData => {
  //     const dateRangeTemp = [...prevData];
  //     dateRangeTemp &&
  //       dateRangeTemp.length > 0 &&
  //       dateRangeTemp.forEach(data => {
  //         if (data && !hasTimeValidity.includes(data.image) && data.from && data.to) {
  //           data.from.date = '';
  //           data.from.error = '';
  //           data.to.date = '';
  //           data.to.error = '';
  //         }
  //       });
  //     return dateRangeTemp;
  //   });
  // }, [hasTimeValidity]);

  React.useEffect(() => {
    getImageList();
    // eslint-disable-next-line
  }, []);

  const getImageList = async () => {
    if (isNewCampaign?._id || props.createCampaignModal.data?._id) {
      const data = await getCampaignImage();
      if (data?.status && data.data.length > 0) {
        const result = [...data?.data];
        let tempArray = [];
        setNewImgSrcList([]);
        tempArray = result.map(item => ({
          ...item,
          _id: item._id,
          label: item.imageName,
          value: item._id,
          imageUrl: item.imageUrl,
          selected: false,
          prescriptionInfoId: item.prescriptionInfoId,
          prescriptionInfoFile: item.prescriptionInfoFile,
          hasVolumeLimit: item.hasVolumeLimit,
          hasDateRange: item.hasDateRange,
          from: item.from,
          to: item.to,
          isPaid: item.isPaid,
          min: { value: item.hasVolumeLimit ? item.minVolume : 0 },
          max: { value: item.hasVolumeLimit ? item.maxVolume : 0 },
        }));
        const volData = result.map(item => {
          if (item.hasVolumeLimit) return { isPaid: item.isPaid, image: item._id, min: { value: item.minVolume, error: '' }, max: { value: item.maxVolume, error: '' } };
          return { image: item._id, isPaid: false, min: { value: 0, error: '' }, max: { value: 0, error: '' } };
        });
        setVolumeLimitData(volData);
        sethasVolumeLimit(result.filter(item => item.hasVolumeLimit).map(item => item._id));

        setDateRange(
          result.map(item => {
            if (item.hasDateRange) return { image: item._id, from: { date: item.from, error: '' }, to: { date: item.to, error: '' } };
            return { image: item._id, from: { date: '', error: '' }, to: { date: '', error: '' } };
          })
        );
        setHasTimeValidity(result.filter(item => item.hasDateRange).map(item => item._id));

        setImageSrcList(tempArray);
        setImageSrcListTemp(tempArray);
        tempArray.forEach(item => {
          const data = {
            img: item,
          };
          setNewImgSrcList(current => [...current, data]);
        });
      }
    }
  };

  const onFileSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormError('');
    if (e.target.files) {
      const files = Array.from(e.target.files);
      let tempArray = [...imageList];
      tempArray = [...tempArray, ...files];
      setImageList(tempArray);

      var array: IImageNameList[] = [];
      for (let file of files) {
        //Only pics
        if (!file.type.match('image')) continue;

        var picReader = new FileReader();
        picReader.addEventListener('load', function (event) {
          var picFile = event.target;
          const imageData = {
            img: picFile?.result,
            value: 0,
            isAltered: false,
          };
          array.push(imageData);
          let tempImageName = [...imageNameList];
          tempImageName = [...tempImageName, ...array];
          setImageNameList(tempImageName);
        });

        //Read the image
        picReader.readAsDataURL(file);
      }
    }
  };

  const removeImage = (index: number) => {
    const temp = [...imageNameList];
    temp.splice(index, 1);
    setImageNameList(temp);

    const tempImgList = [...imageList];
    tempImgList.splice(index, 1);
    setImageList(tempImgList);
  };

  const uploadPrescriptionInfo = async () => {
    if (prescriptionInfoData && prescriptionInfoData.length > 0 && hasPresInfo && hasPresInfo.length > 0) {
      const hasFileToUpload = prescriptionInfoData.filter(item => item.data);
      if (!hasFileToUpload.length) {
        handleCreateCampaign();
        return;
      }
      const formData = new FormData();
      prescriptionInfoData.forEach(prescriptionInfo => {
        if (prescriptionInfo.data) {
          formData.append('doc', prescriptionInfo.data);
        }
      });

      const data = await uploadPresInfo(formData);
      if (data?.status) {
        const responseData = data.data;
        const prescriptionInfoDataTemp = prescriptionInfoData
          .filter(item => item.data)
          .map((item, index) => ({
            ...item,
            uploadDoc: responseData[index],
            prescriptionInfoFile: responseData[index].docUrl,
            prescriptionInfoId: responseData[index]._id,
            prescriptionInfoFileName: responseData[index].docName,
          }));

        const newPrescriptionInfoData = prescriptionInfoData.map(item => {
          const newObject = prescriptionInfoDataTemp.find(el => item.image === el.image);
          if (newObject) return newObject;
          return item;
        });

        setPrescriptionInfoData(newPrescriptionInfoData);

        handleCreateCampaign('uploadPrescription', newPrescriptionInfoData);
      }
    } else {
      handleCreateCampaign();
    }
  };

  const handleCreateCampaign = async (type?: string, prescriptionData?: IpresInfoData[]) => {
    if (step === 1 && !step1StatesStates.title.value.trim()) {
      setStep1StatesStates(prevVal => ({ ...prevVal, title: { ...prevVal.title, error: 'Please enter campaign title' } }));
    } else if (step === 2 && !props.createCampaignModal.data && imageList.length === 0) {
      setFormError('Please upload at least one campaign image');
    } else {
      const formData = new FormData();
      if (props.createCampaignModal.data?._id) formData.append('_id', props.createCampaignModal.data?._id);
      if (isNewCampaign?._id) formData.append('_id', isNewCampaign?._id);
      formData.append('title', step1StatesStates.title.value);
      formData.append('subTitle', step1StatesStates.subTitle.value);
      formData.append('description', step1StatesStates.description.value);

      if ((ruleList.length && step === 4) || props.createCampaignModal.data?._id) {
        const newRuleList = ruleList;
        newRuleList.forEach(list => {
          list.adsList &&
            list.adsList.length > 0 &&
            list.adsList.forEach(ad => {
              ad.customPriority = false;
            });
        });

        newRuleList.forEach(list => {
          list.adsList &&
            list.adsList.length > 0 &&
            list.adsList.forEach(ad => {
              ad.maxVolume = typeof ad.max === 'object' ? Number(ad.max.value) : ad.max;
              ad.minVolume = typeof ad.min === 'object' ? Number(ad.min.value) : ad.min;
              ad.max = typeof ad.max === 'object' ? Number(ad.max.value) : ad.max;
              ad.min = typeof ad.min === 'object' ? Number(ad.min.value) : ad.min;
              return ad;
            });
        });

        formData.append('ruleList', JSON.stringify(newRuleList));
      }

      if (step) {
        if (step === 3 && imageSrcList.length > 0 && hasTimeValidity.length > 0) {
          const dateRangeUpdated: { image: string; hasDateRange: boolean; from: { date: string }; to: { date: string } }[] = [];
          imageSrcList.forEach(date => {
            if (hasTimeValidity.includes(date._id)) {
              dateRangeUpdated.push({ image: date._id, hasDateRange: true, from: { date: date.from }, to: { date: date.to } });
            } else dateRangeUpdated.push({ image: date._id, hasDateRange: false, from: { date: '' }, to: { date: '' } });
          });

          dateRangeUpdated && dateRangeUpdated.length > 0 && formData.append('dateRange', JSON.stringify(dateRangeUpdated));
        }
        if (imageSrcList.length > 0 && hasVolumeLimit.length > 0) {
          const volumeLimit = imageSrcList.map(item => ({
            image: item._id,
            isPaid: item.isPaid,
            hasVolumeLimit: item.hasVolumeLimit,
            min: String(item.min.value),
            max: String(item.max.value),
          }));
          if (step === 3)
            formData.append('volumeLimiter', JSON.stringify(volumeLimit));
        }

        if (prescriptionInfoData && prescriptionInfoData.length > 0 && hasPresInfo && hasPresInfo.length > 0 && type === 'uploadPrescription' && prescriptionData) {
          const prescriptionInfoDataUpdated = prescriptionData
            .map(item => {
              if (item.uploadDoc && Object.keys(item.uploadDoc).length) {
                item = {
                  ...item,
                  prescriptionInfoFile: item.uploadDoc.docUrl,
                  prescriptionInfoId: item.uploadDoc._id,
                };
              } else if (item.uploadDoc && Object.keys(item.uploadDoc).length === 0 && item.prescriptionInfoId) {
                item = { ...item };
              }
              return item;
            })
            .filter(date => hasPresInfo.includes(date.image));

          formData.append('prescriptionInfoData', JSON.stringify(prescriptionInfoDataUpdated));
        }
      }

      for (const img of imageList) {
        formData.append('images', img, img.name);
      }

      const data = await addCampaign(formData);
      if (data?.status) {
        if (!props.createCampaignModal.data?._id) setIsNewCampaign(data.result);
        if (step < 4) {
          setStep(count => count + 1);
          setImageList([]);
          if (step === 2) getImageList();
        } else {
          props.onCloseModal();
          props.getAllAds();
        }
      }
    }
  };

  React.useEffect(() => {
    if (imageDeleteId.item && imageDeleteId.index) {
      onImageDelete(imageDeleteId.item, imageDeleteId.index);
    }
    // eslint-disable-next-line
  }, [imageDeleteId]);

  const onImageDelete = async (item: { img: IImageSrcList }, index: number) => {
    if (window.confirm('Are you sure to delete this image?')) {
      const data = await deleteImage();
      if (data?.status) {
        let temp = [...newImgSrcList];
        temp.splice(index, 1);
        setNewImgSrcList(temp);
        let rules = [...ruleList];
        for (const ruleItem of rules) {
          const deleteIndex = ruleItem.adsList.map(elem => elem._id).indexOf(item.img._id);
          if (deleteIndex > -1) {
            ruleItem.adsList.splice(deleteIndex, 1);
          }
        }
        setRuleList(rules);
        setImageDeleteId({});
      }
    }
  };

  const prevStep = () => {
    setStep(count => count - 1);
  };

  const multiForm = () => {
    switch (step) {
      case 1:
        return <Step1 loading={addCampaignLoading} step1StatesStates={step1StatesStates} setStep1StatesStates={setStep1StatesStates} onCancel={props.onCloseModal} />;
      case 2:
        return (
          <Step2
            loading={addCampaignLoading || deleteImageLoading || getCampaignImageLoading}
            imageDimensionError={imageDimensionError}
            setImageDimensionError={setImageDimensionError}
            onFileSelected={onFileSelected}
            removeImage={removeImage}
            setImageDeleteId={setImageDeleteId}
            imageNameList={imageNameList}
            newImgSrcList={newImgSrcList}
            formError={formError}
            prevStep={prevStep}
            onCancel={props.onCloseModal}
          />
        );
      case 3:
        return (
          <Step3
            loading={uploadPresInfoLoading || addCampaignLoading}
            imageSrcList={imageSrcList}
            setImageSrcList={setImageSrcList}
            hasTimeValidity={hasTimeValidity}
            setHasTimeValidity={setHasTimeValidity}
            hasPresInfo={hasPresInfo}
            sethasPresInfo={sethasPresInfo}
            prescriptionInfoData={prescriptionInfoData}
            setPrescriptionInfoData={setPrescriptionInfoData}
            hasVolumeLimit={hasVolumeLimit}
            sethasVolumeLimit={sethasVolumeLimit}
            volumeLimitData={volumeLimitData}
            setVolumeLimitData={setVolumeLimitData}
            dateRange={dateRange}
            setDateRange={setDateRange}
            prevStep={prevStep}
            formError={formError}
            submitBtnDisabled={submitBtnDisabled}
            onCancel={props.onCloseModal}
          />
        );
      case 4:
        return (
          <Step4
            // activeClinicsDataLoading
            loading={addCampaignLoading}
            imageSrcListTemp={imageSrcListTemp}
            ruleList={ruleList}
            setRuleList={setRuleList}
            maxReached={maxReached}
            setMaxReached={setMaxReached}
            formError={formError}
            setFormError={setFormError}
            imageSrcList={imageSrcList}
            activeClinic={activeClinicsData}
            painLocations={painLocationsData}
            prevStep={prevStep}
            onCancel={props.onCloseModal}
          />
        );
      default:
        break;
    }
  };

  return (
    <Modal show={props.createCampaignModal.status} style={{ minWidth: '70vw', minHeight: '90vh' }}>
      <ModalHeading heading={props.createCampaignModal.data?.advertise._id ? 'Edit Campaign' : 'Create New Campaign'} />
      <ModalForm
        style={{ display: 'flex', flexDirection: 'column', height: '80vh' }}
        onSubmit={e => {
          e.preventDefault();
          if (step === 3) uploadPrescriptionInfo();
          else handleCreateCampaign();
        }}
      >
        <div className='flex'>
          <InputsSectionColumn style={{ width: '100%', padding: '1rem' }}>
            <ProgressForm step={step} />
          </InputsSectionColumn>
        </div>
        {multiForm()}
      </ModalForm>
    </Modal>
  );
};

export default CreateCampaignModal;
