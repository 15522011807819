import type { FC, PropsWithChildren } from 'react';
import { Modal, ModalForm, InputsSection, ModalButtons } from '../../components/Modal';
import ModalHeading from '../../components/Modal/ModalHeading';
import ToggleModalSkeleton from '../../Skeletons/ToggleModalSkeleton';
import { DeleteModalState } from './DeleteModal';

type ModalProps = PropsWithChildren<{
  loading: boolean;
  toggleModal: DeleteModalState;
  onCloseModal?: () => void;
  handleToggle?: (event: React.FormEvent<HTMLFormElement>) => void;
}>;

const ToggleModal: FC<ModalProps> = props => {
  return (
    <Modal show={props.toggleModal.status}>
      <ModalHeading heading={`${props.toggleModal.data?.active ? 'Deactivate' : 'Activate'} ${props.toggleModal.data?.name} Account`} />
      {props.loading ? (
        <ToggleModalSkeleton />
      ) : (
        props.toggleModal.data && (
          <ModalForm style={{ marginBottom: '2.5rem' }} onSubmit={props.handleToggle}>
            <InputsSection style={{ width: '34rem' }}>
              {props.toggleModal.data.active ? (
                <p className='primaryText'>
                  Deactivating will restrict {props.toggleModal.data.name} to log in to account.
                  <br />
                  <br /> <span className='dangerText'>Are you sure you want to proceed?</span>
                </p>
              ) : (
                <p className='primaryText'>
                  Activating will allow {props.toggleModal.data.name} to log in to account.
                  <br />
                  <br /> <span className='dangerText'>Are you sure you want to proceed?</span>
                </p>
              )}
            </InputsSection>

            <ModalButtons
              submitButtonLabel={props.toggleModal.data?.active ? 'Deactivate User' : 'Activate User'}
              cancelButtonLabel='Cancel'
              onCancel={props.onCloseModal}
            />
          </ModalForm>
        )
      )}
    </Modal>
  );
};

export default ToggleModal;
