import toast from 'react-hot-toast';
import useApi from '../useApi';
import { IGetCampaignImageReq } from './index.type';

const useGetCampaignImageList = ({ campaignId }: { campaignId?: string }) => {
  const url = campaignId ? `/advertise/campaign-image-list/${campaignId}` : `/advertise/campaign-image-list`;
  const { loading: getCampaignImageLoading, makeApiCall } = useApi({
    url,
    method: 'GET',
  });

  const getCampaignImage = async () => {
    const { response, error } = await makeApiCall<undefined, IGetCampaignImageReq>();
    if (response?.status) {
      return response;
    }

    if (error || !response?.status) {
      toast.error('Something went wrong while fetching ads data. Please try again later');
    }
  };

  return {
    getCampaignImage,
    getCampaignImageLoading,
  };
};

export default useGetCampaignImageList;
