import toast from 'react-hot-toast';
import useApi from '../useApi';
import { IAddFolderReq, IFolderByIdRes } from './index.type';

const useAddFolder = () => {
  const { loading: addFolderLoading, makeApiCall } = useApi({
    url: `/folders`,
    method: 'POST',
  });

  const addFolder = async (formData: IAddFolderReq) => {
    const { response, error } = await makeApiCall<IAddFolderReq, IFolderByIdRes>(formData);
    if (response?.status === 'success') {
      toast.success('New folder created!');
      return response;
    }

    if (error || response?.status === 'fail') {
      toast.error('Something went wrong creating folder. Please try again later');
      return { status: response?.status };
    }
  };

  return {
    addFolder,
    addFolderLoading,
  };
};

export default useAddFolder;
