import React from 'react';
import { useSelector } from 'react-redux';
import { loginSliceValue } from '../redux/reducers/loginSlice';
import { usePatientFeedbacks } from '../hooks/feedbacks';
import { useAllClinics } from '../hooks/clinic';

import { Content } from '../components';
import { FeedbackPatient, NoData } from '../pageComponents/Feedbacks';
import classes from '../styles/Feedback.module.css';

const FeedbackResults = () => {
  const [clinics, setClinics] = React.useState<{ value: string; label: string }[]>([]);
  const [selectedClinic, setSelectedClinic] = React.useState<string>('');

  const { userDetails } = useSelector(loginSliceValue);
  const { clinicsData, getClinicsData, clinicsDataLoading } = useAllClinics({ pageNumber: 1, perpageData: 1000 });
  const { patientFeedbacks, patientFeedbacksLoading } = usePatientFeedbacks({ clinicId: selectedClinic, role: userDetails.data?.user.role });

  React.useEffect(() => {
    if (userDetails.data?.user.role === 'admin') {
      getClinicsData();
    }
    // eslint-disable-next-line
  }, [userDetails]);

  React.useEffect(() => {
    const temp: { value: string; label: string }[] = [{ label: 'All Clinics', value: '' }];
    if (clinicsData?.data.data.length) {
      clinicsData.data.data.forEach(clinic => {
        temp.push({ label: clinic.name, value: clinic._id });
      });
    }
    setClinics(temp);
  }, [clinicsData]);

  return (
    <Content
      currentMenu='patient-feedback'
      headerTitle='Patient Feedbacks'
      dropdown={userDetails.data?.user.role === 'admin' ? true : false}
      dropdownOptions={clinics}
      dropdownValue={selectedClinic}
      dropdownChange={e => setSelectedClinic(e.target.value)}
    >
      {patientFeedbacksLoading || clinicsDataLoading ? (
        <NoData title='Loading patient feedbacks...' />
      ) : patientFeedbacks?.data.data.length ? (
        <div className='displayflex gap-1 flexWrap alignStart'>
          {patientFeedbacks.data.data.map(feedback => (
            <FeedbackPatient key={feedback._id} item={feedback} />
          ))}
        </div>
      ) : (
        <NoData title='No patient reviews found.' />
      )}
    </Content>
  );
};

export default FeedbackResults;
