import type { FC, PropsWithChildren } from 'react';
import TextBox from '../styles/TextInput.module.css';

export type ToggleSwitchWithLabelProps = PropsWithChildren<{
  label: string;
  id?: string;
  checked?: boolean;
  onChange?: () => void;
  errorMessage?: string;
  style?: React.CSSProperties;
}>;

const ToggleSwitchWithLabel: FC<ToggleSwitchWithLabelProps> = props => {
  return (
    <div className='flexCol gap-1' style={props.style}>
      <div className='flex'>
        <label htmlFor={props.id}>{props.label}</label>
        <label className='switch'>
          <input id={props.id} checked={props.checked} onChange={props.onChange} type='checkbox' className='checkbox' name='active' />
          <span className='slider round' />
        </label>
      </div>
      {props.errorMessage && <p className={TextBox.errorText}>{props.errorMessage}</p>}
    </div>
  );
};

export default ToggleSwitchWithLabel;
