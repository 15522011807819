import type { FC, PropsWithChildren } from 'react';
import { Modal, ModalForm, TextArea, InputsSectionColumn, ModalButtons } from '../../components/Modal';
import ModalHeading from '../../components/Modal/ModalHeading';
import DeleteModalSkeleton from '../../Skeletons/DeleteModalSkeleton';
import { AddEditModalState } from './AddEditModal';

export type IcancelModalStates = PropsWithChildren<{
  reason: { value: string; error: string };
}>;

type CancelModalProps = PropsWithChildren<{
  loading: boolean;
  cancelModal: AddEditModalState;
  onCloseModal?: () => void;
  handleCancelAppointment?: (event: React.FormEvent<HTMLFormElement>) => void;
  cancelModalStates: IcancelModalStates;
  setCancelModalStates: React.Dispatch<React.SetStateAction<IcancelModalStates>>;
}>;

const CancelAppointmentModal: FC<CancelModalProps> = props => {
  return (
    <Modal show={props.cancelModal.status}>
      <ModalHeading heading={`Cancel Appointment`} />
      {props.loading ? (
        <DeleteModalSkeleton />
      ) : (
        <ModalForm style={{ marginBottom: '2.5rem' }} onSubmit={props.handleCancelAppointment}>
          <InputsSectionColumn style={{ width: '34rem' }}>
            <p className='primaryText'>Are you sure you want to cancel this appointment?</p>
            <p className='dangerText'>Please note that it can not be restored back after cancelling appointment.</p>
          </InputsSectionColumn>
          <InputsSectionColumn style={{ width: '34rem' }}>
            {/* <p className="dangerText">
              Please note that it can not be restored back after cancelling
              appointment.
            </p> */}
            <TextArea
              label='Any reason to cancel this appointment (optional)'
              placeholder='Write few lines here..'
              value={props.cancelModalStates.reason.value}
              onChange={e => props.setCancelModalStates(prevVal => ({ ...prevVal, reason: { value: e.target.value, error: '' } }))}
            />
          </InputsSectionColumn>
          <ModalButtons submitButtonLabel='Cancel Appointment' cancelButtonLabel='Cancel' onCancel={props.onCloseModal} />
        </ModalForm>
      )}
    </Modal>
  );
};

export default CancelAppointmentModal;
