import React from 'react';
import toast from 'react-hot-toast';
import useApi from '../useApi';
import { IClinicStatusRes } from './index.type';

const useClinicStatus = () => {
  const [statusData, setStatusData] = React.useState<IClinicStatusRes>();

  const { loading: statusDataLoading, makeApiCall } = useApi({
    url: '/general/get-clinic-status-list',
    method: 'GET',
  });

  const getClinicStatus = async () => {
    const { response, error } = await makeApiCall<undefined, IClinicStatusRes>();
    if (response?.status) {
      setStatusData(response);
    }

    if (!response?.status) {
      setStatusData(undefined);
    }

    if (error) toast.error('Something went wrong while fetching clinic status data. Please try again later');
  };

  return {
    statusData,
    getClinicStatus,
    statusDataLoading,
  };
};

export default useClinicStatus;
