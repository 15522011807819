import toast from 'react-hot-toast';
import useApi from '../useApi';
import { IUserAnswersRes } from './index.type';

const useGetUserInsuranceForm = ({ userId, documentType }: { userId?: string; documentType: number }) => {
  const { loading: userInsuranceDataLoading, makeApiCall } = useApi({
    url: `/users/get-patient-document-list?type=${documentType}&userId=${userId}`,
    method: 'GET',
  });

  const getUserInsuranceData = async () => {
    const { response, error } = await makeApiCall<undefined, IUserAnswersRes>();
    if (response?.status) {
      return response;
    }

    if (!response?.status) {
      return response;
    }

    if (error) {
      toast.error('Something went wrong while fetching users data. Please try again later');
      return { status: 'fail' };
    }
  };

  // React.useEffect(() => {
  //   getUserInsuranceData();
  //   // eslint-disable-next-line
  // }, [userId, documentType]);

  return {
    getUserInsuranceData,
    userInsuranceDataLoading,
  };
};

export default useGetUserInsuranceForm;
