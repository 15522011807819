// @ts-nocheck
import type { FC, PropsWithChildren } from 'react';
import { ContextMenu } from 'react-contextmenu';

type MyContextMenuProps = PropsWithChildren<{
  id: string;
}>;

const MyContextMenu: FC<MyContextMenuProps> = props => {
  return <ContextMenu id={props.id}>{props.children}</ContextMenu>;
};

export default MyContextMenu;
