import toast from 'react-hot-toast';
import useApi from '../../useApi';
import { IToggleClinicReq, IToggleClinicRes } from '../index.type';

const useToggleClinic = ({ clinicId }: { clinicId?: string }) => {
  const { loading: toggleClinicLoading, makeApiCall } = useApi({
    url: `/users/${clinicId}/toggleActive`,
    method: 'PATCH',
  });

  const toggleClinic = async (FormData: IToggleClinicReq) => {
    const { response, error } = await makeApiCall<IToggleClinicReq, IToggleClinicRes>(FormData);

    if (response?.status === 'success') {
      toast.success('Clinic status changed successfully.');
      return response;
    }

    if (error) {
      toast.error('Something went wrong whiling changing clinic status. Please try again later');
      return { status: 'fail' };
    }
  };

  return {
    toggleClinicLoading,
    toggleClinic,
  };
};

export default useToggleClinic;
