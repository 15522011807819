import React, { FC } from 'react';

export interface TableHeadDataProps {
  id: number;
  title: string;
}
interface TableHeadProps {
  data: TableHeadDataProps[];
}

const TableHead: FC<TableHeadProps> = ({ data }) => {
  return (
    <thead>
      <tr>
        {data.map((head) => (
          <th key={head.id}>{head.title}</th>
        ))}
      </tr>
    </thead>
  );
};

export default TableHead;
