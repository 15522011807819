import React from 'react';
import { GoogleMap, useLoadScript, Marker, InfoWindow } from '@react-google-maps/api';
import { useGetAllAds } from '../hooks/advertisements';
import { useCampaignCount, useGetAdsByLocation, useGetAllCampaignImages, useGetCampaignImageWiseData, useGetTop10Ad } from '../hooks/adStatistics';
import { adViewByClinicImageHeadData, adViewByClinicTableHeadData } from '../hooks/adStatistics/staticData';

import { Content } from '../components';
import { ChipLink } from '../pageComponents/Advertisements';
import { BoxTitle, SummaryChip } from '../pageComponents/AdStatistics';
import { CustomTableRow, Table, TableBody, TableHead, TableImageRow, TableNoContent, TableRowTitle, TableStatusWithToggle } from '../components/Table';
import TableSkeleton from '../Skeletons/TableSkeleton';

import AdCampaignsStyles from '../styles/AdCampaigns.module.css';
import AdStatisticsStyles from '../styles/AdStatistics.module.css';

interface IadViewCountExport {
  'Image Name'?: string;
  Views: number;
  'Campaign Name'?: string;
  'Clinic Name'?: string;
  'Paid/UnPaid'?: string;
  'Start Date'?: string;
  'End Date'?: string;
}

const AdStats = () => {
  const [cardFilter, setCardFilter] = React.useState<string>('');
  const [top10clinicFilter, setTop10ClinicFilter] = React.useState<'all' | 'active'>('active');
  const [top10clinicCount, setTop10ClinicCount] = React.useState<{ value: string; date: { from: string; to: string } }>({ value: 'all', date: { from: '', to: '' } });

  const [campaignImageFilter, setCampaignImageFilter] = React.useState<'all' | 'active'>('active');
  const [selectedCampaignImage, setSelectedCampaignImage] = React.useState<string>('');
  const [campaignImageRange, setcampaignImageRange] = React.useState<{ value: string; date: { from: string; to: string } }>({ value: 'all', date: { from: '', to: '' } });
  const [campaignMapViewsRange, setCampaignMapViewsRange] = React.useState<{ value: string; date: { from: string; to: string } }>({
    value: 'all',
    date: { from: '', to: '' },
  });

  const { isLoaded } = useLoadScript({
    // googleMapsApiKey: 'AIzaSyBALbFgest7qrz8hrXs55CZJLkgKp-6NeU'
    googleMapsApiKey: 'AIzaSyCS56y2cqdpBLG6dwhXUJDh7jZoCNixxC4',
  });
  const [selectedSpeciality, setSelectedSpeciality] = React.useState<string>('');
  const [selectPosition, setSelectpostion] = React.useState<{ lat: number; lng: number }>();
  const [selectCenter, setSelectCenter] = React.useState<{ lat: number; lng: number } | undefined>({ lat: 39.83, lng: -86.45 });

  const { adsData } = useGetAllAds({ filter: 'all' });
  const { campaignCountData } = useCampaignCount({ filter: cardFilter });
  const { top10CampaignData, getTop10Campaign, getTop10CampaignLoading } = useGetTop10Ad();
  const { campaignImageData, getCampaignImageData, getCampaignImageLoading } = useGetCampaignImageWiseData();
  const { campaignImagesData, getCampaignImagesLoading } = useGetAllCampaignImages();
  const { adsByLocationData, getAdsByLocation } = useGetAdsByLocation({
    specialty: selectedSpeciality,
    fromDate: campaignMapViewsRange.date.from,
    toDate: campaignMapViewsRange.date.to,
  });

  React.useEffect(() => {
    if (top10clinicCount.date.from && top10clinicCount.date.to) {
      getTop10Campaign({ page: 0, pageSize: 10, filter: top10clinicFilter, dateRange: { from: top10clinicCount.date.from, to: top10clinicCount.date.to } });
    } else {
      top10clinicCount.value !== 'custom' && getTop10Campaign({ page: 0, pageSize: 10, filter: top10clinicFilter });
    }
    // eslint-disable-next-line
  }, [top10clinicCount, top10clinicFilter]);

  React.useEffect(() => {
    if (campaignImageRange.date.from && campaignImageRange.date.to) {
      getCampaignImageData({
        page: 0,
        pageSize: 10,
        filter: campaignImageFilter,
        imageId: selectedCampaignImage,
        dateRange: { from: campaignImageRange.date.from, to: campaignImageRange.date.to },
      });
    } else {
      campaignImageRange.value !== 'custom' && getCampaignImageData({ page: 0, pageSize: 10, filter: campaignImageFilter, imageId: selectedCampaignImage });
    }
    // eslint-disable-next-line
  }, [campaignImageRange, campaignImageFilter, selectedCampaignImage]);

  React.useEffect(() => {
    if (campaignMapViewsRange.date.from && campaignMapViewsRange.date.to) {
      getAdsByLocation();
    } else {
      campaignMapViewsRange.value !== 'custom' && getAdsByLocation();
    }
    // getAdsByLocation();
    // eslint-disable-next-line
  }, [campaignMapViewsRange, selectedSpeciality]);

  const changeInPercentage = (preValue: number, newValue: number) => {
    if (newValue > preValue) {
      return Math.floor(((newValue - preValue) / preValue) * 100);
    } else if (newValue < preValue) {
      return Math.floor(((newValue - preValue) / preValue) * 100);
    } else {
      return 0;
    }
  };

  const handleClinicFilterDateChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const today = new Date(Date.now());
    let fromDate = new Date();
    fromDate.setHours(0, 0, 0, 0);

    let toDate = new Date();
    toDate.setHours(0, 0, 0, 0);

    switch (e.target.value) {
      case 'all':
        setTop10ClinicCount({ value: e.target.value, date: { from: '', to: '' } });
        break;
      case '30days':
        fromDate.setDate(today.getDate() - 30);
        toDate.setDate(today.getDate());
        setTop10ClinicCount({ value: e.target.value, date: { from: fromDate.toISOString().split('T')[0], to: toDate.toISOString().split('T')[0] } });
        break;
      case '7days':
        fromDate.setDate(today.getDate() - 7);
        toDate.setDate(today.getDate());
        setTop10ClinicCount({ value: e.target.value, date: { from: fromDate.toISOString().split('T')[0], to: toDate.toISOString().split('T')[0] } });
        break;
      case 'custom':
        // setTop10ClinicCount(prevVal => ({ ...prevVal, value: e.target.value }));
        setTop10ClinicCount({ value: e.target.value, date: { from: '', to: '' } });
        break;
      default:
        break;
    }
  };

  const handleClinicImageFilterDateChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const today = new Date(Date.now());
    let fromDate = new Date();
    fromDate.setHours(0, 0, 0, 0);

    let toDate = new Date();
    toDate.setHours(0, 0, 0, 0);

    switch (e.target.value) {
      case 'all':
        setcampaignImageRange({ value: e.target.value, date: { from: '', to: '' } });
        break;
      case '30days':
        fromDate.setDate(today.getDate() - 30);
        toDate.setDate(today.getDate());
        setcampaignImageRange({ value: e.target.value, date: { from: fromDate.toISOString().split('T')[0], to: toDate.toISOString().split('T')[0] } });
        break;
      case '7days':
        fromDate.setDate(today.getDate() - 7);
        toDate.setDate(today.getDate());
        setcampaignImageRange({ value: e.target.value, date: { from: fromDate.toISOString().split('T')[0], to: toDate.toISOString().split('T')[0] } });
        break;
      case 'custom':
        // setcampaignImageRange(prevVal => ({ ...prevVal, value: e.target.value }));
        setcampaignImageRange({ value: e.target.value, date: { from: '', to: '' } });
        break;
      default:
        break;
    }
  };

  const handleCampaignMapViewsDateChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const today = new Date(Date.now());
    let fromDate = new Date();
    fromDate.setHours(0, 0, 0, 0);

    let toDate = new Date();
    toDate.setHours(0, 0, 0, 0);

    switch (e.target.value) {
      case 'all':
        setCampaignMapViewsRange({ value: e.target.value, date: { from: '', to: '' } });
        break;
      case '30days':
        fromDate.setDate(today.getDate() - 30);
        toDate.setDate(today.getDate());
        setCampaignMapViewsRange({ value: e.target.value, date: { from: fromDate.toISOString().split('T')[0], to: toDate.toISOString().split('T')[0] } });
        break;
      case '7days':
        fromDate.setDate(today.getDate() - 7);
        toDate.setDate(today.getDate());
        setCampaignMapViewsRange({ value: e.target.value, date: { from: fromDate.toISOString().split('T')[0], to: toDate.toISOString().split('T')[0] } });
        break;
      case 'custom':
        // setCampaignMapViewsRange(prevVal => ({ ...prevVal, value: e.target.value }));
        setCampaignMapViewsRange({ value: e.target.value, date: { from: '', to: '' } });
        break;
      default:
        break;
    }
  };

  const exportAdViewCountData = () => {
    let data: IadViewCountExport[] = [];
    if (top10CampaignData) {
      top10CampaignData.data.forEach(item => {
        const tempData: IadViewCountExport = {
          'Image Name': item.data.imageName,
          'Campaign Name': item.data.adsId.title,
          Views: item.count,
          'Paid/UnPaid': item.isPaid ? 'Yes' : 'No',
        };
        // if (top10clinicCount.date.from && top10clinicCount.date.to) tempData['Start Date'] = `[${top10clinicCount.date.from} - ${top10clinicCount.date.to}]`;
        if (top10clinicCount.date.from && top10clinicCount.date.to) {
          tempData['Start Date'] = top10clinicCount.date.from;
          tempData['End Date'] = top10clinicCount.date.to;
        }
        data.push(tempData);
      });
    }
    return data;
  };

  const exportCampaignImageCountData = () => {
    let data: IadViewCountExport[] = [];
    if (campaignImageData) {
      campaignImageData.data.forEach(item => {
        const tempData: IadViewCountExport = {
          'Image Name': item.data.imageName,
          'Campaign Name': item.data.adsId.title,
          'Clinic Name': item.clinicName ? item.clinicName : '-',
          Views: item.count,
          'Paid/UnPaid': item.data.isPaid ? 'Yes' : 'No',
        };
        // if (campaignImageRange.date.from && campaignImageRange.date.to) tempData['Date Range'] = `[${campaignImageRange.date.from} - ${campaignImageRange.date.to}]`;
        if (campaignImageRange.date.from && campaignImageRange.date.to) {
          tempData['Start Date'] = campaignImageRange.date.from.split('T')[0];
          tempData['End Date'] = campaignImageRange.date.to.split('T')[0];
        }
        data.push(tempData);
      });
    }
    return data;
  };

  const adCampaignSelectOptions = () => {
    const options: { label: string; value: string }[] = [{ label: 'All Campaigns', value: '' }];
    adsData &&
      adsData.data.length > 0 &&
      adsData.data.forEach(camp => {
        options.push({ label: camp.advertise.title, value: camp.advertise._id });
      });

    return options;
  };

  const adCampaignImageOptions = () => {
    const options: { label: string; value: string }[] = [{ label: 'All Images', value: '' }];
    campaignImagesData &&
      campaignImagesData.data.length > 0 &&
      campaignImagesData.data.forEach(img => {
        // console.log(img);
        if (campaignImageFilter === 'active')
          // debugger
          if (img.adsId.activeStatus && img.activeStatus) {
            options.push({ label: img.adsId && img.adsId.title ? img.imageName + ' (' + img.adsId.title + ')' : img.imageName, value: img._id });
          }

        if (campaignImageFilter === 'all')
          options.push({ label: img.adsId && img.adsId.title ? img.imageName + ' (' + img.adsId.title + ')' : img.imageName, value: img._id });
      });

    return options;
  };

  return (
    <Content headerTitle='Ad Statistics' currentMenu='ad-campaigns' addBtn={false} searchBox={false}>
      <div className={AdCampaignsStyles.navigation_content}>
        <div className={AdCampaignsStyles.navigation}>
          <ChipLink label='All Campaigns' icon='ad-campaign-text' to='/ad-campaigns' active={false} />
          <ChipLink label='Ad statistics' icon='graph' to='#!' active={true} />
        </div>
      </div>

      <div className={AdStatisticsStyles.upper_content}>
        <div>
          <BoxTitle
            title='Ad Summary'
            selectBoxValue={cardFilter}
            selectBoxOptions={adCampaignSelectOptions()}
            selectBoxOnChange={e => setCardFilter(e.target.value)}
            selectBoxWidth='15rem'
          />

          <div className={AdStatisticsStyles.statCards}>
            <div className={AdStatisticsStyles.statCard2}>
              {campaignCountData?.status && (
                <React.Fragment>
                  <SummaryChip
                    title='views today'
                    percent={changeInPercentage(campaignCountData.data.yesterdayCount, campaignCountData.data.todayCount)}
                    count={campaignCountData.data.todayCount}
                  />
                  <SummaryChip
                    title='views this week'
                    percent={changeInPercentage(campaignCountData.data.lastWeekCount, campaignCountData.data.weekCount)}
                    count={campaignCountData.data.weekCount}
                  />
                  <SummaryChip
                    title='views this month'
                    percent={changeInPercentage(campaignCountData.data.lastMonthCount, campaignCountData.data.monthCount)}
                    count={campaignCountData.data.monthCount}
                  />
                  <SummaryChip title='views till date' percent={0} hidePercent={true} count={campaignCountData.data.tillCount} />
                </React.Fragment>
              )}
            </div>
          </div>
        </div>

        <div className={AdStatisticsStyles.right_table}>
          <BoxTitle
            title='Ad View Count'
            selectBoxValue={top10clinicCount.value}
            selectBoxOptions={[
              { label: 'Count Till Date', value: 'all' },
              { label: 'Last 30 Days', value: '30days' },
              { label: 'Last 7 Days', value: '7days' },
              { label: 'Custom Date', value: 'custom' },
            ]}
            selectBoxOnChange={handleClinicFilterDateChange}
            showCustomDate={top10clinicCount.value === 'custom'}
            fromDateValue={top10clinicCount.date.from.split('T')[0]}
            fromDateOnChange={e => setTop10ClinicCount(prevVal => ({ ...prevVal, date: { ...prevVal.date, from: e.target.value } }))}
            toDateValue={top10clinicCount.date.to.split('T')[0]}
            toDateOnChange={e => setTop10ClinicCount(prevVal => ({ ...prevVal, date: { ...prevVal.date, to: e.target.value } }))}
            showExportButton={top10CampaignData && top10CampaignData.data.length > 0 ? true : false}
            exportDataTitle='Ads View Count'
            exportData={exportAdViewCountData()}
            toggleButtons={[
              { label: 'Active', active: top10clinicFilter === 'active', onClick: () => setTop10ClinicFilter('active') },
              { label: 'All', active: top10clinicFilter === 'all', onClick: () => setTop10ClinicFilter('all') },
            ]}
          />

          <Table style={{ height: '35rem' }}>
            <TableHead data={adViewByClinicTableHeadData} />
            <TableBody>
              <CustomTableRow>
                {getTop10CampaignLoading ? (
                  <TableSkeleton rows={5} columns={7} />
                ) : top10CampaignData && top10CampaignData.data.length > 0 ? (
                  top10CampaignData.data.map(campaign => (
                    <tr key={campaign._id}>
                      <TableImageRow
                        src={process.env.REACT_APP_BASE_ASSET_URL + campaign.data.imageUrl}
                        alt={campaign.data.imageName}
                        imageStyle={{ width: '10rem', height: 'auto', marginTop: '.5rem' }}
                      />
                      <TableRowTitle>{campaign.data.imageName}</TableRowTitle>
                      <td>{campaign.count}</td>
                      <td>{campaign.data && campaign.data.adsId.title}</td>
                      <td>
                        <TableStatusWithToggle hideToggle paidText id={campaign._id} checked={campaign.data.activeStatus && campaign.isPaid} onChange={() => {}} />
                      </td>
                    </tr>
                  ))
                ) : (
                  <TableNoContent colSpan={adViewByClinicTableHeadData.length} message='No data found' />
                )}
              </CustomTableRow>
            </TableBody>
          </Table>
        </div>
      </div>

      <div className={AdStatisticsStyles.contents}>
        <div style={{ width: '100%' }}>
          <BoxTitle
            title='Ad View Count by Ad Image'
            selectBoxValue={campaignImageRange.value}
            selectBoxOptions={[
              { label: 'Count Till Date', value: 'all' },
              { label: 'Last 30 Days', value: '30days' },
              { label: 'Last 7 Days', value: '7days' },
              { label: 'Custom Date', value: 'custom' },
            ]}
            selectBoxOnChange={handleClinicImageFilterDateChange}
            selectBox2Value={selectedCampaignImage}
            selectBox2Options={adCampaignImageOptions()}
            selectBox2OnChange={e => setSelectedCampaignImage(e.target.value)}
            showCustomDate={campaignImageRange.value === 'custom'}
            fromDateValue={campaignImageRange.date.from.split('T')[0]}
            fromDateOnChange={e => setcampaignImageRange(prevVal => ({ ...prevVal, date: { ...prevVal.date, from: e.target.value } }))}
            toDateValue={campaignImageRange.date.to.split('T')[0]}
            toDateOnChange={e => setcampaignImageRange(prevVal => ({ ...prevVal, date: { ...prevVal.date, to: e.target.value } }))}
            showExportButton={campaignImageData && campaignImageData.data.length > 0 ? true : false}
            exportDataTitle='Ads View Count by Campaign'
            exportData={exportCampaignImageCountData()}
            toggleButtons={[
              {
                label: 'Active',
                active: campaignImageFilter === 'active',
                onClick: () => {
                  setCampaignImageFilter('active');
                  adCampaignImageOptions();
                },
              },
              {
                label: 'All',
                active: campaignImageFilter === 'all',
                onClick: () => {
                  setCampaignImageFilter('all');
                  adCampaignImageOptions();
                },
              },
            ]}
          />
          <Table style={{ height: '40rem' }}>
            <TableHead data={adViewByClinicImageHeadData} />
            <TableBody>
              <CustomTableRow>
                {getCampaignImageLoading || getCampaignImagesLoading ? (
                  <TableSkeleton rows={6} columns={10} />
                ) : campaignImageData && campaignImageData.data.length > 0 ? (
                  campaignImageData.data.map(campaign => (
                    <tr key={campaign._id + campaign.adsId + Math.random() + 0.1}>
                      <TableImageRow
                        src={process.env.REACT_APP_BASE_ASSET_URL + campaign.data.imageUrl}
                        alt={campaign.data.imageName}
                        imageStyle={{ width: '10rem', height: 'auto', marginTop: '.5rem' }}
                      />
                      <TableRowTitle>{campaign.data.imageName}</TableRowTitle>
                      <td>{campaign.data && campaign.data.adsId.title}</td>
                      <td>{campaign.clinicName ? campaign.clinicName : '-'}</td>
                      <td>{campaign.count}</td>
                      <td>
                        <TableStatusWithToggle
                          hideToggle
                          paidText
                          id={campaign._id + campaign.data.adsId.title}
                          checked={campaign.data.activeStatus && campaign.data.isPaid}
                          onChange={() => {}}
                        />
                      </td>
                    </tr>
                  ))
                ) : (
                  <TableNoContent colSpan={adViewByClinicImageHeadData.length} message='No data found' />
                )}
              </CustomTableRow>
            </TableBody>
          </Table>
        </div>
      </div>

      <div className={AdStatisticsStyles.map}>
        <BoxTitle
          title='Ad views'
          selectBoxValue={campaignMapViewsRange.value}
          selectBoxOptions={[
            { label: 'Count Till Date', value: 'all' },
            { label: 'Last 30 Days', value: '30days' },
            { label: 'Last 7 Days', value: '7days' },
            { label: 'Custom Date', value: 'custom' },
          ]}
          selectBoxOnChange={handleCampaignMapViewsDateChange}
          showCustomDate={campaignMapViewsRange.value === 'custom'}
          fromDateValue={campaignMapViewsRange.date.from.split('T')[0]}
          fromDateOnChange={e => setCampaignMapViewsRange(prevVal => ({ ...prevVal, date: { ...prevVal.date, from: e.target.value } }))}
          toDateValue={campaignMapViewsRange.date.to.split('T')[0]}
          toDateOnChange={e => setCampaignMapViewsRange(prevVal => ({ ...prevVal, date: { ...prevVal.date, to: e.target.value } }))}
          selectBox2Value={selectedSpeciality}
          selectBox2Options={adCampaignSelectOptions()}
          selectBox2OnChange={e => setSelectedSpeciality(e.target.value)}
        />
        <div className={AdStatisticsStyles.map_integration}>
          {/* && !adsByLocationLoading */}
          {isLoaded ? (
            <GoogleMap zoom={5} center={selectCenter} mapContainerClassName={AdStatisticsStyles.map_container}>
              {adsByLocationData?.data &&
                adsByLocationData.data.length > 0 &&
                adsByLocationData.data.map(item => {
                  return (
                    <Marker
                      key={item.name}
                      icon={`http://maps.google.com/mapfiles/ms/icons/${item.color}-dot.png`}
                      position={{ lat: item.lat, lng: item.long }}
                      onClick={() => {
                        setSelectpostion({ lat: item.lat, lng: item.long });
                        setSelectCenter(undefined);
                      }}
                    />
                  );
                })}
              {adsByLocationData?.data &&
                selectPosition &&
                adsByLocationData.data.length > 0 &&
                adsByLocationData.data
                  .filter(item => JSON.stringify({ lat: item.lat, lng: item.long }) === JSON.stringify(selectPosition))
                  .map(filterData => (
                    <InfoWindow
                      position={{ lat: filterData.lat, lng: filterData.long }}
                      onCloseClick={() => {
                        setSelectpostion(undefined);
                      }}
                      key={filterData.name}
                    >
                      <div className={AdStatisticsStyles.infoWindow}>
                        <h2 className={AdStatisticsStyles.infoWindow_title}>{filterData.name}</h2>
                        <p>{filterData.address1 + ', ' + filterData.city + ', ' + filterData.state + ', ' + filterData.zip}</p>
                        {filterData && (
                          <div className={AdStatisticsStyles.infoWindow_ad}>
                            <div className={AdStatisticsStyles.infoWindow_ad_count}>
                              <h6>{filterData.count} views</h6>
                            </div>
                            {filterData.images && filterData.images.length ? (
                              <div style={{ display: 'flex', flexDirection: 'column', rowGap: '2px' }}>
                                {filterData.images.map((imgs, imId) => (
                                  <div key={imgs + imId} style={{ display: 'flex', columnGap: '4px' }}>
                                    <img style={{ width: '70%', maxWidth: '50rem' }} src={process.env.REACT_APP_BASE_ASSET_URL + imgs} alt={imgs} />
                                    <label style={{ width: '30%', fontWeight: 'bold' }}>{filterData.imagesCount[imId]} Views</label>
                                  </div>
                                ))}
                              </div>
                            ) : (
                              <img style={{ maxWidth: '50rem' }} src={process.env.REACT_APP_BASE_ASSET_URL + filterData.ad} alt={filterData.ad} />
                            )}
                          </div>
                        )}
                      </div>
                    </InfoWindow>
                  ))}
            </GoogleMap>
          ) : (
            <h1>loading...</h1>
          )}
        </div>
      </div>
    </Content>
  );
};

export default AdStats;
