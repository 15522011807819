import React from 'react';
import toast from 'react-hot-toast';
import useApi from '../useApi';
import { IAdsRes } from './index.type';

const useAllAds = ({ filter }: { filter: 'active' | 'all' }) => {
  const [adsData, setAdsData] = React.useState<IAdsRes>();

  const { loading: adsDataLoading, makeApiCall } = useApi({
    url: `/advertise/list?filter=${filter}`,
    method: 'GET',
  });

  const getAllAds = async () => {
    const { response, error } = await makeApiCall<undefined, IAdsRes>();
    if (response?.status) {
      setAdsData(response);
    }

    if (!response?.status) {
      setAdsData(undefined);
    }

    if (error) toast.error('Something went wrong while fetching ads data. Please try again later');
  };

  return {
    adsData,
    getAllAds,
    adsDataLoading,
  };
};

export default useAllAds;
