import { useCallback, useState } from 'react';
import axios from 'axios';

interface Props {
  url: string;
  method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
}

const useCountryApi = ({ url, method = 'GET' }: Props) => {
  const [loading, setLoading] = useState(false);

  const makeCountryApiCall = useCallback(
    async <T, R>(
      input?: T
    ): Promise<{
      response: R | undefined;
      loading: boolean;
      error: { error: string; statusCode: number } | undefined;
    }> => {
      setLoading(true);
      try {
        const config = {
          url: `https://countriesnow.space/api/v0.1${url}`,
          method,
          data: input,
          headers: {
            'Content-Type': 'application/json',
          },
        };

        const response = await axios(config);
        if (response) {
          return { response: response.data, loading, error: undefined };
        } else {
          return {
            response: undefined,
            loading: false,
            error: { error: 'Error fetching Data', statusCode: 404 },
          };
        }
      } catch (e: any) {
        return {
          response: undefined,
          loading,
          error:
            e.response && e.response && e.response.data ? e.response.data : e,
        };
      } finally {
        setLoading(false);
      }
    },
    [loading, method, url]
  );

  return { loading, makeCountryApiCall };
};

export default useCountryApi;
