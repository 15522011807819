import React from 'react';
import ModalForm from '../components/Modal/ModalForm';
import InputsSection from '../components/Modal/InputsSection';
import ModalButtonsSkeleton from './ModalButtonsSkeleton';
import SkeletonStyles from './Skeletons.module.css';

const ToggleModalSkeleton = () => {
  return (
    <ModalForm style={{ marginBottom: '2.5rem' }}>
      <InputsSection>
        <div className='flexCol'>
          <div className={[SkeletonStyles.form_input__text, SkeletonStyles.skeleton].join(' ')} style={{ width: '30rem' }} />
          <div className={[SkeletonStyles.form_input__text, SkeletonStyles.skeleton].join(' ')} />
        </div>
      </InputsSection>
      <ModalButtonsSkeleton />
    </ModalForm>
  );
};

export default ToggleModalSkeleton;
