import React from 'react';
import useAllAds from './useAllAds';

const useGetAllAds = ({ filter }: { filter: 'active' | 'all' }) => {
  const { adsData, getAllAds, adsDataLoading } = useAllAds({ filter: filter });

  React.useEffect(() => {
    getAllAds();
    // eslint-disable-next-line
  }, [filter]);

  return {
    adsData,
    getAllAds,
    adsDataLoading,
  };
};

export default useGetAllAds;
