import { Fragment } from 'react';
import type { FC, PropsWithChildren } from 'react';
import { MyContextMenu, MyContextMenuTrigger, MyMenuItem } from '../../components/ContextMenu';
import FileItem from './FileItem';

type FileItemWithMenuProps = PropsWithChildren<{
  id: string;
  name: string;
  protected: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  onDownload?: () => void;
  onShare?: () => void;
  onCopy?: () => void;
  onCut?: () => void;
  onRename?: () => void;
  onDelete?: () => void;
  showDelete: boolean;
}>;

const FileItemWithMenu: FC<FileItemWithMenuProps> = props => {
  return (
    <Fragment>
      <MyContextMenuTrigger id={props.id}>
        <FileItem onClick={props.onClick} protected={props.protected} name={props.name} />
      </MyContextMenuTrigger>

      <MyContextMenu id={props.id}>
        <MyMenuItem onClick={props.onDownload}>
          <svg className='copy'>
            <use xlinkHref={`/assets/sprite.svg#icon-file_download`} />
          </svg>
          <span>Download</span>
        </MyMenuItem>
        <MyMenuItem onClick={props.onShare}>
          <svg className='share'>
            <use xlinkHref={`/assets/sprite.svg#icon-file-share`} />
          </svg>
          <span>Share</span>
        </MyMenuItem>

        <MyMenuItem onClick={props.onCopy}>
          <svg className='copy'>
            <use xlinkHref={`/assets/sprite.svg#icon-file_copy`} />
          </svg>
          <span>Copy</span>
        </MyMenuItem>
        <MyMenuItem onClick={props.onCut}>
          <svg className='send'>
            <use xlinkHref={`/assets/sprite.svg#icon-content_cut`} />
          </svg>
          <span>Move</span>
        </MyMenuItem>
        <MyMenuItem onClick={props.onRename}>
          <svg className='watchlist'>
            <use xlinkHref={`/assets/sprite.svg#icon-edit-2`} />
          </svg>
          <span>Rename</span>
        </MyMenuItem>

        {props.showDelete && (
          <MyMenuItem onClick={props.onDelete}>
            <svg className='delete'>
              <use xlinkHref={`/assets/sprite.svg#icon-file-minus`} />
            </svg>
            <span>Delete</span>
          </MyMenuItem>
        )}
      </MyContextMenu>
    </Fragment>
  );
};

export default FileItemWithMenu;
