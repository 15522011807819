import React from 'react';

import Chart1 from './Chart1';
import Chart10 from './Chart10';
import Chart2 from './Chart2';
import Chart3 from './Chart3';
import Chart4 from './Chart4';
import Chart5 from './Chart5';
import Chart6 from './Chart6';
import Chart7 from './Chart7';
import Chart8 from './Chart8';
import Chart9 from './Chart9';
import PatientDetailModal from './PatientDetailModal';
import classes from './HomeScreen.module.css';

const AllCharts = () => {
  const [selectedDataModal, setSelectedDataModal] = React.useState({
    status: false,
    title: '',
    data: {},
  });

  const chart4Data = {
    status: true,
    count: 16373,
    data: [
      { count: 2007, date_created: '2022-01-15T00:00:00.000Z' },
      { count: 2404, date_created: '2022-02-15T00:00:00.000Z' },
      { count: 2127, date_created: '2022-03-15T00:00:00.000Z' },
      { count: 1949, date_created: '2022-04-15T00:00:00.000Z' },
      { count: 1309, date_created: '2022-05-15T00:00:00.000Z' },
      { count: 1108, date_created: '2022-06-15T00:00:00.000Z' },
      { count: 1882, date_created: '2022-07-15T00:00:00.000Z' },
      { count: 2242, date_created: '2022-08-15T00:00:00.000Z' },
      { count: 1305, date_created: '2022-09-15T00:00:00.000Z' },
    ],
  };

  const handleModalClose = () => {
    setSelectedDataModal({ status: false, title: '', data: {} });
  };

  return (
    <div className={classes.content}>
      <Chart1 data={chart4Data} setSelectedDataModal={setSelectedDataModal} />
      <Chart2 />
      <Chart3 />
      <Chart4 />
      <Chart5 setSelectedDataModal={setSelectedDataModal} />
      <Chart6 />
      <Chart7 setSelectedDataModal={setSelectedDataModal} />
      <Chart8 />
      <Chart9 />
      <Chart10 />
      {selectedDataModal.status && (
        <PatientDetailModal data={{ selectedDataModal, handleModalClose }} />
      )}
    </div>
  );
};

export default AllCharts;
