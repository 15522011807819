import React from 'react';
import SkeletonStyles from './Skeletons.module.css';
import styles from '../styles/questions.module.css';

function AnswerSkeleton() {
  return (
    <div className={styles.container2}>
      {Array(5)
        .fill(null)
        .map((item, index) => {
          return <div className={styles.card3 + ' ' + SkeletonStyles.skeleton} key={index}></div>;
        })}
    </div>
  );
}

export default AnswerSkeleton;
