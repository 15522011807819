import React from 'react';
import toast from 'react-hot-toast';
import useApi from '../useApi';
import { IMyProfileRes } from './index.type';

const useGetMyProfile = () => {
  const [myProfileData, setMyProfileData] = React.useState<IMyProfileRes>();

  const { loading: myProfileDataLoading, makeApiCall } = useApi({
    url: `/users/me`,
    method: 'GET',
  });

  const getMyProfileData = async () => {
    const { response, error } = await makeApiCall<undefined, IMyProfileRes>();
    if (response?.status === 'success') {
      setMyProfileData(response);
    }

    if (response?.status === 'fail') {
      setMyProfileData(undefined);
    }

    if (error) toast.error('Something went wrong while fetching users data. Please try again later');
  };

  React.useEffect(() => {
    getMyProfileData();
    // eslint-disable-next-line
  }, []);

  return {
    myProfileData,
    getMyProfileData,
    myProfileDataLoading,
  };
};

export default useGetMyProfile;
