import React, { useEffect, useState } from 'react';
import SkeletonStyles from './Skeletons.module.css';

const FolderListSkeleton = ({ itmes = 5, type = 'folder' }) => {
  const [folderList, setFolderList] = useState([]);

  useEffect(() => {
    const data = [];
    for (let i = 0; i < itmes; i++) {
      data.push(i);
    }
    setFolderList(data);
  }, [itmes]);

  return (
    <React.Fragment>
      {folderList &&
        folderList.length > 0 &&
        folderList.map(folder =>
          type === 'folder' ? (
            <div key={folder} className={[SkeletonStyles.folder, SkeletonStyles.skeleton].join(' ')} />
          ) : (
            <div key={folder} className={[SkeletonStyles.file, SkeletonStyles.skeleton].join(' ')} />
          )
        )}
    </React.Fragment>
  );
};

export default FolderListSkeleton;
