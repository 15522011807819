import React from 'react';
import toast from 'react-hot-toast';
import useApi from '../useApi';
import { IAdsByLocationRes } from './index.type';

const useGetAdsByLocation = ({ specialty, fromDate, toDate }: { specialty?: string; fromDate?: string; toDate?: string }) => {
  const [adsByLocationData, setAdsByLocationData] = React.useState<IAdsByLocationRes>();

  let url = specialty ? `/advertise/ads-count-by-location?Specialty=${specialty}` : `/advertise/ads-count-by-location`;

  if (fromDate && toDate && specialty) url = url + `&fromDate=${fromDate}&toDate=${toDate}`;
  else if (fromDate && toDate && !specialty) url = url + `?fromDate=${fromDate}&toDate=${toDate}`;

  const { loading: adsByLocationLoading, makeApiCall } = useApi({
    url,
    method: 'GET',
  });

  const getAdsByLocation = async () => {
    const { response, error } = await makeApiCall<undefined, IAdsByLocationRes>();
    if (response?.status) {
      setAdsByLocationData(response);
    }
    if (!response?.status && response?.message) {
      setAdsByLocationData(undefined);
      toast.error(response.message);
    }

    if (error) {
      setAdsByLocationData(undefined);
      toast.error('Something went wrong while fetching top 10 ads data. Please try again later');
    }
  };

  return {
    adsByLocationData,
    getAdsByLocation,
    adsByLocationLoading,
  };
};

export default useGetAdsByLocation;
