import React from 'react';
import toast from 'react-hot-toast';
import { useCountryApi } from '../index';
import { ICountryStatesRes } from '../../redux/reducers/credentials/index.types';

const useCountry = () => {
  const [countryInfo, setCountryInfo] = React.useState<ICountryStatesRes>({
    error: false,
    msg: '',
  });

  const { loading: countryInfoLoading, makeCountryApiCall } = useCountryApi({
    url: `/countries/states`,
    method: 'GET',
  });

  React.useEffect(() => {
    const getCountryData = async () => {
      const { response, error } = await makeCountryApiCall<undefined, ICountryStatesRes>();
      if (response && !response.error) {
        setCountryInfo(response);
      }

      if (error) toast.error('Something went wrong. Please try again later');
    };
    getCountryData();
    // eslint-disable-next-line
  }, []);

  return {
    countryInfoLoading,
    countryInfo,
  };
};

export default useCountry;
