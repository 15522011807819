import React from 'react';
import toast from 'react-hot-toast';
import useApi from '../useApi';
import { IPainLocationsRes } from './index.type';

const usePainLocations = () => {
  const [painLocationsData, setPainLocationsData] = React.useState<IPainLocationsRes>();

  const { loading: activePainLocationsDataLoading, makeApiCall } = useApi({
    url: `/advertise/pain-location/list`,
    method: 'GET',
  });

  const getAllPainLocations = async () => {
    const { response, error } = await makeApiCall<undefined, IPainLocationsRes>();
    if (response?.status) {
      setPainLocationsData(response);
    }

    if (!response?.status) {
      setPainLocationsData(undefined);
    }

    if (error) toast.error('Something went wrong while fetching ads data. Please try again later');
  };

  React.useEffect(() => {
    getAllPainLocations();
    // eslint-disable-next-line
  }, []);

  return {
    painLocationsData,
    activePainLocationsDataLoading,
  };
};

export default usePainLocations;
