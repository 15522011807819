import React from 'react';
import { Chart } from 'react-chartjs-2';
import classes from './HomeScreen.module.css';

const options = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      beginAtZero: true,
      display: true,
      grid: {
        drawOnChartArea: false,
      },
    },
    y: {
      display: false,
    },
  },
  plugins: {
    legend: {
      display: true,
      position: 'top',
    },
    title: {
      display: false,
      text: 'Re-admission rate in 2022',
    },
  },
};

const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept'];

const data = {
  labels,
  datasets: [
    {
      type: 'line',
      fill: true,
      label: 'Re-admissions',
      data: [141, 262, 105, 125, 148, 189, 243, 275, 116],
      borderColor: 'rgba(230, 111, 0, 1)',
      borderWidth: 1,
      tension: 0.5,
      pointBackgroundColor: 'rgba(230, 111, 0, 0.5)',
    },
    {
      type: 'bar',
      label: 'Admissions',
      data: [2007, 2404, 2127, 1949, 1309, 1108, 1882, 2242, 1305],
      // borderColor: 'rgba(53, 162, 235)',
      borderWidth: 0,
      borderRadius: 5,
      tension: 0.5,
      pointBackgroundColor: 'rgba(1, 194, 255, 0.5)',
    },
  ],
};

function createGradient(ctx, area) {
  const colorStart = 'rgba(1, 196, 255, 0.8)';
  const colorMid = 'rgba(0, 113, 174, 0.8)';
  const colorEnd = 'rgba(0, 113, 174, .8';

  // const gradient = ctx.createLinearGradient(0, area.bottom, 0, area.top);
  const gradient = ctx.createLinearGradient(area.left, 0, area.right, 0);

  gradient.addColorStop(0, colorStart);
  gradient.addColorStop(0.5, colorMid);
  gradient.addColorStop(1, colorEnd);

  return gradient;
}

const Chart2 = () => {
  const chartRef = React.useRef(null);
  const [chartData, setChartData] = React.useState({
    datasets: [],
  });

  React.useEffect(() => {
    const chart = chartRef.current;

    if (!chart) {
      return;
    }

    const chartData = {
      ...data,
      datasets: data.datasets.map((dataset) => ({
        ...dataset,
        backgroundColor: createGradient(chart.ctx, chart.chartArea),
      })),
    };

    setChartData(chartData);
  }, []);

  return (
    <div className={classes.chart2}>
      <p className={classes.chart4_heading}>Re-admission rate in 2022</p>
      <div style={{ width: '100%', height: '12rem' }}>
        <Chart ref={chartRef} type='bar' data={chartData} options={options} />
      </div>

      <div className={classes.chart2_stats} style={{ display: 'none' }}>
        <div className={classes.chart2_stat}>
          <p className={classes.chart2_stat_data}>
            {/* {countData && countData.locationData && countData.locationData.data
              ? countData.locationData.data
              : '...'} */}
            6664
            <span>
              {/* {
                (countData,
                countData &&
                countData.locationData &&
                countData.locationData.title
                  ? countData.locationData.title
                  : '...')
              } */}
              Inpatients
            </span>
          </p>
        </div>
        <div className={classes.chart2_stat}>
          <p className={classes.chart2_stat_data}>
            {/* {countData && countData.formData && countData.formData.data
              ? countData.formData.data
              : '...'} */}
            9709
            <span>
              {/* {
                (countData,
                countData && countData.formData && countData.formData.title
                  ? countData.formData.title
                  : '...')
              } */}
              Outpatients
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Chart2;
