import React from 'react';
import { Chart } from 'react-chartjs-2';
import { Modal } from '../../../components/Modal';
import ModalHeading from '../../../components/Modal/ModalHeading';
import ModalForm from '../../../components/Modal/ModalForm';
import InputsSection from '../../../components/Modal/InputsSection';
import ModalButtons from '../../../components/Modal/ModalButtons';

function createGradient(ctx, area) {
  const colorStart = 'rgba(1, 196, 255, 0.8)';
  const colorMid = 'rgba(0, 113, 174, 0.8)';
  const colorEnd = 'rgba(0, 113, 174, .8';

  // const gradient = ctx.createLinearGradient(0, area.bottom, 0, area.top);
  const gradient = ctx.createLinearGradient(area.left, 0, area.right, 0);

  gradient.addColorStop(0, colorStart);
  gradient.addColorStop(0.5, colorMid);
  gradient.addColorStop(1, colorEnd);

  return gradient;
}

const PatientDetailModal = ({ data: modalData }) => {
  const { selectedDataModal, handleModalClose } = modalData;

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        beginAtZero: true,
        display: true,
        grid: {
          drawOnChartArea: false,
        },
      },
      y: {
        display: true,
        grid: {
          drawOnChartArea: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
        position: 'top',
      },
      title: {
        display: false,
        text: 'Re-admission rate in 2022',
      },
    },
  };

  const labels = React.useMemo(() => ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'], []);

  const data = React.useMemo(
    () => ({
      labels,
      datasets: [
        {
          type: 'bar',
          fill: true,
          label: selectedDataModal.data.label,
          data: selectedDataModal.data.data,
          borderColor: 'rgba(230, 111, 0, 1)',
          borderWidth: 0,
          borderRadius: 8,
        },
      ],
    }),
    [labels, selectedDataModal]
  );

  const chartRef = React.useRef(null);
  const [chartData, setChartData] = React.useState({
    datasets: [],
  });

  React.useEffect(() => {
    const chart = chartRef.current;

    if (!chart) {
      return;
    }

    const chartData = {
      ...data,
      datasets: data.datasets.map(dataset => ({
        ...dataset,
        backgroundColor: createGradient(chart.ctx, chart.chartArea),
      })),
    };

    setChartData(chartData);
  }, [data]);

  return (
    <Modal show={selectedDataModal.status}>
      <ModalHeading heading={selectedDataModal.title} />

      <ModalForm style={{ marginBottom: '2.5rem' }}>
        <InputsSection style={{ width: '40vw', height: '30vh' }}>
          <Chart ref={chartRef} type='bar' data={chartData} options={options} />
        </InputsSection>
        <ModalButtons cancelButtonLabel='Close' onCancel={handleModalClose} />
      </ModalForm>
    </Modal>
  );
};

export default PatientDetailModal;
