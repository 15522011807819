import toast from 'react-hot-toast';
import useApi from '../useApi';
import { IAddFeedbackReq, IAddFeedbackRes } from './index.type';

const useAddFeedback = ({ feedbackId }: { feedbackId?: string }) => {
  const url = feedbackId ? `/feedback/${feedbackId}` : '/feedback';
  const method = feedbackId ? 'PATCH' : 'POST';

  const { loading: addFeedbackLoading, makeApiCall } = useApi({ url, method });

  const addFeedback = async (formData: IAddFeedbackReq) => {
    const { response, error } = await makeApiCall<IAddFeedbackReq, IAddFeedbackRes>(formData);
    if (response?.status === 'success') {
      toast.success(response.msg);
      return response;
    }

    if (response?.status === 'fail') {
      toast.error('Something went wrong while adding feedback data. Please try again later');
      return response;
    }

    if (error) toast.error(error.msg);
  };

  return {
    addFeedbackLoading,
    addFeedback,
  };
};

export default useAddFeedback;
