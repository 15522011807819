import toast from 'react-hot-toast';
import useApi from '../../useApi';
import { IToggleClinicRes } from '../index.type';

const useDeleteClinic = ({ clinicId }: { clinicId?: string }) => {
  const { loading: deleteClinicLoading, makeApiCall } = useApi({
    url: `/clinic?clinicId=${clinicId}`,
    method: 'DELETE',
  });

  const deleteClinic = async () => {
    const { response, error } = await makeApiCall<undefined, IToggleClinicRes>();

    if (response?.status === 'success') {
      toast.error('Clinic deleted successfully.');
      return { status: response.status };
    }

    if (error) {
      toast.error('Something went wrong whiling deleting clinic. Please try again later');
      return { status: 'fail' };
    }
  };

  return {
    deleteClinicLoading,
    deleteClinic,
  };
};

export default useDeleteClinic;
