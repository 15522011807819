import toast from 'react-hot-toast';
import useApi from '../useApi';
import { IAddUserReq, IAddUserRes } from './index.type';

const useAddUser = ({ userId }: { userId?: string }) => {
  const url = userId ? `/users/${userId}` : '/users';
  const method = userId ? 'PATCH' : 'POST';

  const { loading: addUserLoading, makeApiCall } = useApi({ url, method });

  const addUser = async (formData: IAddUserReq) => {
    const { response, error } = await makeApiCall<IAddUserReq, IAddUserRes>(formData);
    if (response?.status) {
      return response;
    }

    if (error || !response?.status) {
      toast.error('Something went wrong. Please try again later');
      return { status: false };
    }
  };

  return {
    addUserLoading,
    addUser,
  };
};

export default useAddUser;
