import React from 'react';
import type { FC, PropsWithChildren } from 'react';
import TextBoxStyles from '../InputBox/TextBox.module.css';
import SelectMultipleStyles from './SelectMultiple.module.css';

type SelectMultipleProps = PropsWithChildren<{
  label: string;
  loading: boolean;
  loadingText: string;
  data: { _id: string; name: string }[];
  userFolders: string[];
  handleAddFolder: (id: string) => void;
  noDataText: string;
  errorMessage: string;
}>;

const SelectMultiple: FC<SelectMultipleProps> = ({ label, loading, loadingText, data, userFolders, handleAddFolder, noDataText = 'No data', errorMessage }) => {
  return (
    <div className={TextBoxStyles.form_input}>
      {label && (
        <label htmlFor={label} className={TextBoxStyles.form_input__label}>
          {label}
        </label>
      )}

      <div className={SelectMultipleStyles.items}>
        {loading ? (
          <div className={SelectMultipleStyles.item}>
            <p>{loadingText}</p>
          </div>
        ) : data && data.length > 0 ? (
          data.map(d => (
            <div key={d._id} onClick={() => handleAddFolder(d._id)} className={userFolders.includes(d._id) ? SelectMultipleStyles.itemExists : SelectMultipleStyles.item}>
              <p>{d.name}</p>
            </div>
          ))
        ) : (
          <p>{noDataText}</p>
        )}
      </div>
      {errorMessage && <p className={TextBoxStyles.form_input__error}>{errorMessage}</p>}
    </div>
  );
};

export default SelectMultiple;
