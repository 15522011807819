import type { FC, PropsWithChildren } from 'react';
import classes from '../../styles/Table.module.css';

type TableRowAddressProps = PropsWithChildren<{}>;

const TableRowAddress: FC<TableRowAddressProps> = props => {
  return <td className={classes.table__row_address}>{props.children}</td>;
};

export default TableRowAddress;
