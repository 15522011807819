import React from 'react';
import classes from '../styles/Rating.module.css';

interface RatingProps {
  rating: number;
  onClick?: (rating: number) => void;
  noMargin?: boolean;
}

const Rating: React.FC<RatingProps> = props => {
  return (
    <div className={`${classes.container} displayflex alignCenter`}>
      {[...Array(5)].map((star, index) => {
        index += 1;
        return props.onClick ? (
          <button type='button' key={index} onClick={() => props.onClick && props.onClick(index)}>
            <svg className={classes.rating_icon}>
              <use xlinkHref={`/assets/sprite.svg#icon-${index <= props.rating ? 'star-filled' : 'star'}`} />
            </svg>
          </button>
        ) : (
          <div key={index}>
            <svg className={classes.rating_icon}>
              <use xlinkHref={`/assets/sprite.svg#icon-${index <= props.rating ? 'star-filled' : 'star'}`} />
            </svg>
          </div>
        );
      })}
    </div>
  );
};

export default Rating;
