import toast from 'react-hot-toast';
import useApi from '../useApi';
import { IEditFolderReq, IFolderByIdRes } from './index.type';

const useEditFile = ({ fileId }: { fileId?: string }) => {
  const { loading: editFileLoading, makeApiCall } = useApi({
    url: `/fileExchange/${fileId}`,
    method: 'PATCH',
  });

  const editFile = async (formData: IEditFolderReq) => {
    const { response, error } = await makeApiCall<IEditFolderReq, IFolderByIdRes>(formData);
    if (response?.status === 'success') {
      toast.success('File name updated!');
      return response;
    }

    if (error || response?.status === 'fail') {
      toast.error('Something went wrong editing file name. Please try again later');
      return { status: 'fail' };
    }
  };

  return {
    editFile,
    editFileLoading,
  };
};

export default useEditFile;
