import type { FC, PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logoutUser } from '../redux/reducers/loginSlice';
import { SelectBox } from './Modal';

type HeaderProps = PropsWithChildren<{
  headerTitle: string;
  addBtn?: boolean;
  addBtnTitle?: string;
  addBtnClick?: React.MouseEventHandler<HTMLButtonElement>;
  addBtnIcon?: string;
  searchBox?: boolean;
  searchPlaceholder?: string;
  handleSearchChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  totalReult?: string;
  userRole: string;
  dropdown?: boolean;
  dropdownValue?: string;
  dropdownChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  dropdownOptions?: { value: string; label: string }[];
}>;

const Header: FC<HeaderProps> = ({
  headerTitle,
  addBtn = false,
  addBtnTitle = 'Add',
  addBtnClick,
  addBtnIcon = 'plus-circle',
  searchBox = false,
  searchPlaceholder = 'Search here...',
  handleSearchChange,
  totalReult,
  userRole,
  dropdown,
  dropdownValue,
  dropdownChange,
  dropdownOptions,
}) => {
  const dispatch = useDispatch();

  const handleFocus = () => {
    const headerTextInput = document.querySelector<HTMLElement>('.headerTextInput');
    const headerTextInputIcon = document.querySelector<HTMLElement>('.headerTextInput_icon');
    if (headerTextInput) headerTextInput.style.border = '1px solid var(--grey)';
    if (headerTextInputIcon) headerTextInputIcon.style.fill = 'var(--black)';
  };

  const handleFocusOut = () => {
    const headerTextInput = document.querySelector<HTMLElement>('.headerTextInput');
    const headerTextInputIcon = document.querySelector<HTMLElement>('.headerTextInput_icon');
    if (headerTextInput) headerTextInput.style.border = '1px solid var(--light-grey)';
    if (headerTextInputIcon) headerTextInputIcon.style.fill = 'var(--light-grey)';
  };

  return (
    <div className='header'>
      <div className='header__left'>
        <div>
          <h1 className='header__title'>{headerTitle}</h1>
          {totalReult && <p className='headerResult'>{totalReult}</p>}
        </div>

        {addBtn && (
          <button className='header__button' onClick={addBtnClick}>
            {addBtnTitle}
            <svg className='header__button_icon'>
              <use xlinkHref={`/assets/sprite.svg#icon-${addBtnIcon}`} />
            </svg>
          </button>
        )}
        {searchBox && (
          <div className='headerTextInput'>
            <svg className='headerTextInput_icon'>
              <use xlinkHref={`/assets/sprite.svg#icon-search`} />
            </svg>
            <input type='text' className='headerTextBox' placeholder={searchPlaceholder} onChange={handleSearchChange} onFocus={handleFocus} onBlur={handleFocusOut} />
          </div>
        )}

        {dropdown && (
          <SelectBox
            label=''
            value={dropdownValue ? dropdownValue : ''}
            onChange={dropdownChange}
            inputContainerStyle={{ width: '25rem', boxShadow: 'unset', padding: '.8rem 1rem', borderRadius: '1rem', border: '1px solid var(--light-grey)' }}
          >
            {dropdownOptions &&
              dropdownOptions.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
          </SelectBox>
        )}
      </div>
      <div className='header__right'>
        {userRole === 'admin' && (
          <Link to='/app-notifications' className='header__link'>
            Notifications
          </Link>
        )}

        {userRole === 'admin' && (
          <Link to='/app-version' className='header__link'>
            App Version
          </Link>
        )}
        <button className='header__logout' onClick={() => dispatch(logoutUser())}>
          Logout
          <svg className='header__logout_icon'>
            <use xlinkHref={`/assets/sprite.svg#icon-sign-out`} />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default Header;
