import { TableHeadDataProps } from '../../components/Table/TableHead';

export const checkinsTableHeadData: TableHeadDataProps[] = [
  { id: 0, title: '' },
  { id: 1, title: 'Patient' },
  { id: 2, title: 'Doctor' },
  { id: 3, title: 'Appointment At' },
  { id: 4, title: 'Checked-In At' },
  { id: 5, title: 'Checked-Out At' },
  { id: 6, title: 'Status' },
  { id: 7, title: 'Actions' },
];
export const checkinsTableHistoryHeadData: TableHeadDataProps[] = [
  { id: 0, title: '' },
  { id: 1, title: 'Patient' },
  { id: 2, title: 'Doctor' },
  { id: 3, title: 'Appointment At' },
  { id: 4, title: 'Checked-In At' },
  { id: 5, title: 'Checked-Out At' },
  { id: 6, title: 'Status' },
];
export const checkinsStatusTableHeadData: TableHeadDataProps[] = [
  { id: 0, title: 'Sl. No.' },
  { id: 1, title: 'Status Name' },
  { id: 2, title: 'Created On' },
  { id: 3, title: 'Modified On' },
  { id: 4, title: 'Actions' },
];
export const checkinLobbyTableHeadData: TableHeadDataProps[] = [
  { id: 0, title: '' },
  { id: 1, title: 'Patient' },
  { id: 2, title: 'Doctor' },
  { id: 3, title: 'Appointment At' },
  { id: 4, title: 'Checked-In At' },
  { id: 5, title: 'Checked-Out At' },
  { id: 6, title: 'Status' },
  { id: 7, title: 'Estimated Total Time' },
];
