import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import Chart1 from './Chart1';
import Chart10 from './Chart10';
import Chart2 from './Chart2';
import Chart3 from './Chart3';
import Chart4 from './Chart4';
import Chart5 from './Chart5';
import Chart6 from './Chart6';
import Chart7 from './Chart7';
import Chart8 from './Chart8';
import Chart9 from './Chart9';

import PatientDetailModal from './PatientDetailModal';
import axios from 'axios';
import { loginSliceValue } from '../../../redux/reducers/loginSlice';
import classes from '../../../styles/HomeScreen.module.css';

const SuremedCharts = () => {
  const { userDetails } = useSelector(loginSliceValue);

  const [chart1Data, setChart1Data] = React.useState([]);
  const [chart2Data, setChart2Data] = React.useState([]);
  const [chart3Data, setChart3Data] = React.useState([]);
  const [chart4Data, setChart4Data] = React.useState([]);
  const [chart5Data, setChart5Data] = React.useState([]);
  const [chart6Data, setChart6Data] = React.useState([]);
  // const [chart7Data, setChart7Data] = React.useState([]);
  // const [chart8Data, setChart8Data] = React.useState([]);
  // const [chart9Data, setChart9Data] = React.useState([]);
  // const [chart10Data, setChart10Data] = React.useState([]);
  const [totalPatients, setTotalPatients] = React.useState([]);
  const [chart2Month, setChart2Month] = useState(String(new Date().getMonth() + 1));
  const [chart3Month, setChart3Month] = useState(String(new Date().getMonth() + 1));
  // const [chart4Month, setChart4Month] = useState(String(new Date().getMonth() + 1));

  const [selectedDataModal, setSelectedDataModal] = React.useState({
    status: false,
    title: '',
    data: {},
  });

  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const config = {
    headers: {
      Authorization: `Bearer ${userDetails.token}`,
      'Content-Type': 'application/json',
    },
  };

  const fetchChart2 = async () => {
    const { data: sData } = await axios.get(`${BASE_URL}/advertise/report/side-effect?year=${2022}&&month=${chart2Month}`, config);
    if (sData && sData.status) {
      setChart2Data(sData.result);
    }
  };
  const fetchChart3 = async () => {
    const { data: sData } = await axios.get(`${BASE_URL}/advertise/report/red-flag?year=${2022}&&month=${chart3Month}`, config);
    if (sData && sData.status) {
      setChart3Data(sData.result);
    }
  };
  const fetchChart4 = async () => {
    const { data: sData } = await axios.get(`${BASE_URL}/advertise/report/risk-rating?year=${2022}&&month=${String(new Date().getMonth() + 1)}`, config);
    if (sData && sData.status) {
      setChart4Data(sData.last3rdMonthData);
    }
  };
  const getChart1DataAction = async () => {
    const { data: rData } = await axios.get(`${BASE_URL}/advertise/report/red-flag`, config);
    const redFlagData = { ...rData, title: 'Total Redflags' };

    const { data: sData } = await axios.get(`${BASE_URL}/advertise/report/side-effect`, config);
    const sideEffectsData = { ...sData, title: 'Total Sideeffects' };

    return { status: true, redFlagData, sideEffectsData };
  };

  const getTotalPatientChartDataAction = async year => {
    // const { data } = await axios.get(`${BASE_URL}/advertise/report/total-patients?year=${year}`, config);

    // return data;
    return {};
  };
  const getChart5DataAction = async year => {
    const { data } = await axios.get(`${BASE_URL}/advertise/report/insurance`, config);
    return { status: true, insuranceData: data };
  };
  const getChart6DataAction = async year => {
    const { data } = await axios.get(`${BASE_URL}/advertise/report/risk-rating`, config);
    return data;
  };

  React.useEffect(() => {
    fetchChart2();
    // eslint-disable-next-line
  }, [chart2Month]);

  React.useEffect(() => {
    fetchChart3();
    // eslint-disable-next-line
  }, [chart3Month]);

  const getChartData = React.useCallback(async () => {
    fetchChart4();
    const data1 = await getChart1DataAction();
    if (data1 && data1.status) {
      setChart1Data(data1);
    }
    const totalPats = await getTotalPatientChartDataAction(new Date().getFullYear() - 1);
    if (totalPats && totalPats.status) {
      setTotalPatients(totalPats.result);
    }

    const data5 = await getChart5DataAction();
    if (data5 && data5.status) {
      setChart5Data(data5);
    }

    const data6 = await getChart6DataAction();
    if (data6 && data6.status) {
      setChart6Data(data6);
    }
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    getChartData();
  }, [getChartData]);

  const handleModalClose = () => {
    setSelectedDataModal({ status: false, title: '', data: {} });
  };

  return (
    <div className={classes.content}>
      <Chart1 totalPatients={totalPatients} data={chart1Data} setSelectedDataModal={setSelectedDataModal} />
      <Chart2 chartMonth={chart2Month} setChartMonth={setChart2Month} data={chart2Data} />
      <Chart3 chartMonth={chart3Month} setChartMonth={setChart3Month} data={chart3Data} />
      <Chart4 data={chart4Data} />
      <Chart5 data={chart5Data} setSelectedDataModal={setSelectedDataModal} />
      <Chart6 data={chart6Data} />
      <Chart7 data={[]} setSelectedDataModal={setSelectedDataModal} />
      <Chart8 data={[]} />
      <Chart9 data={[]} />
      <Chart10 data={[]} />
      {selectedDataModal.status && <PatientDetailModal data={{ selectedDataModal, handleModalClose }} />}
    </div>
  );
};

export default SuremedCharts;
