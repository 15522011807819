import React from 'react';
import type { FC, PropsWithChildren } from 'react';
import classes from '../styles/Input.module.css';

type TextInputProps = PropsWithChildren<{
  icon?: string;
  label: string;
  name?: string;
  valid?: boolean;
  required?: boolean;
  placeholder?: string;
  errorMessage?: string;
  style?: React.CSSProperties;
  type?: React.HTMLInputTypeAttribute;
  containerStyle?: React.CSSProperties;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  value?: string | ReadonlyArray<string> | number | undefined;
}>;

const Input: FC<TextInputProps> = props => {
  const [type, setType] = React.useState<React.HTMLInputTypeAttribute>(props.type ? props.type : 'text');

  const handleFocus = () => {
    const textInput = document.querySelector<HTMLElement>('.' + classes.inputBox);
    const textInputIcon = document.querySelector<HTMLElement>('.' + classes.inputBox__icon);
    if (textInput) textInput.style.border = '1px solid var(--grey)';
    if (textInputIcon) textInputIcon.style.fill = 'var(--black)';
  };

  const handleFocusOut = () => {
    const textInput = document.querySelector<HTMLElement>('.' + classes.inputBox);
    const textInputIcon = document.querySelector<HTMLElement>('.' + classes.inputBox__icon);
    if (textInput) textInput.style.border = '1px solid var(--light-grey)';
    if (textInputIcon) textInputIcon.style.fill = 'var(--grey)';
  };

  const handleShowPassword = () => {
    if (props.type === 'password') {
      type === 'text' ? setType('password') : setType('text');
    }
  };

  return (
    <div className={classes.container} style={props.containerStyle}>
      <label className={classes.label}>{props.label}</label>
      <div className={classes.inputBox}>
        {props.icon && (
          <svg className={classes.inputBox__icon}>
            <use xlinkHref={`/assets/sprite.svg#icon-${props.icon}`} />
          </svg>
        )}
        <input
          className={classes.textInput__input}
          type={type}
          placeholder={props.placeholder}
          name={props.name}
          value={props.value}
          style={props.style}
          onChange={props.onChange}
          required={props.required}
          onFocus={handleFocus}
          onBlur={handleFocusOut}
        />
        {props.valid && (
          <svg className={classes.inputBox__icon_check}>
            <use xlinkHref={`/assets/sprite.svg#icon-checkmark`} />
          </svg>
        )}
        {props.type === 'password' && (
          <button className={classes.inputBox__toggle_password} onClick={handleShowPassword} type='button'>
            <svg>
              <use xlinkHref={`/assets/sprite.svg#icon-${type === 'password' ? 'eye' : 'eye-off'}`} />
            </svg>
          </button>
        )}
      </div>
      {props.errorMessage ? <p className={classes.errorText}>{props.errorMessage}</p> : ''}
    </div>
  );
};

export default Input;
