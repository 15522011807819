import React, { FC } from 'react';
import ReactPaginate from 'react-paginate';
import classes from '../styles/Pagination.module.css';

interface PaginationProps {
  totalItems: number;
  itemsPerPage: number;
  onPageChange?(selectedItem: { selected: number }): void;
  style?: React.CSSProperties;
}

const Pagination: FC<PaginationProps> = ({ totalItems, itemsPerPage, onPageChange, style }) => {
  let pageCount;
  pageCount = totalItems === 0 ? 0 : Math.ceil(totalItems / itemsPerPage);

  return (
    <div className={classes.paginationContainer} style={style}>
      {pageCount > 1 && (
        <ReactPaginate
          breakLabel='...'
          pageRangeDisplayed={5}
          previousLabel='<'
          nextLabel='>'
          pageCount={pageCount}
          onPageChange={onPageChange}
          activeClassName={classes.paginationActiveLink}
          containerClassName={classes.paginationButtons}
          previousClassName={classes.paginationPrevious}
          nextClassName={classes.paginationNext}
          disabledClassName={classes.paginationDisabled}

          //   nextClassName={''}
          // previousLabel={'<'}
          // nextLabel={'>'}
          // pageCount={pageNo}
          // onPageChange={onPageChange}
          // marginPagesDisplayed={1}
          // pageRangeDisplayed={2}
          // containerClassName={'paginationBttns'}
          // previousLinkClassName={'previousBttn'}
          // nextLinkClassName={'nextBttn'}
          // disabledClassName={'paginationDisabled'}
          // activeClassName={'paginationActive'}
          // forcePage={pageNumber - 1}
        />
      )}
    </div>
  );
};

export default Pagination;
