import React from 'react';
import toast from 'react-hot-toast';
import useApi from '../useApi';
import { IUserAnswersRes } from './index.type';

const useGetUserAnswers = ({ userId, pageNumber, perpageData }: { userId?: string; pageNumber: number; perpageData: number }) => {
  const [userAnswerData, setUserAnswerData] = React.useState<IUserAnswersRes>();

  const { loading: userAnswersDataLoading, makeApiCall } = useApi({
    url: `/answers/answer-by-user?user=${userId}&pageNumber=${pageNumber}&pageSize=${perpageData}`,
    method: 'GET',
  });

  const getUserAnswerData = async () => {
    const { response, error } = await makeApiCall<undefined, IUserAnswersRes>();
    if (response?.status === 'success') {
      setUserAnswerData(response);
    }

    if (response?.status === 'fail') {
      setUserAnswerData(undefined);
    }

    if (error) toast.error('Something went wrong while fetching users data. Please try again later');
  };

  React.useEffect(() => {
    getUserAnswerData();
    // eslint-disable-next-line
  }, [pageNumber, userId]);

  return {
    userAnswerData,
    getUserAnswerData,
    userAnswersDataLoading,
  };
};

export default useGetUserAnswers;
