import React from 'react';
import classes from '../../../styles/HomeScreen.module.css';

const Bubbles = ({ maxValue = 30, value = 10, label = 'lorem' }) => {
  const [maxBubbles, setMaxBubbles] = React.useState([]);
  const [bubbles, setBubbles] = React.useState([]);
  const [newBubbles, setNewBubbles] = React.useState([]);

  React.useEffect(() => {
    const temp = [];

    for (let i = 0; i < maxValue; i++) {
      temp.push(i);
    }
    setMaxBubbles(temp);
  }, [maxValue]);

  React.useEffect(() => {
    const temp = [];

    for (let i = 0; i < value; i++) {
      if (i < 9) {
        temp.push({ value: i, belowAverage: true });
      } else {
        temp.push({ value: i, belowAverage: false });
      }
    }
    setBubbles(temp);
  }, [value]);

  React.useEffect(() => {
    const temp = [];

    if (maxBubbles.length === maxValue && bubbles.length === value) {
      for (let i = 0; i < maxBubbles.length; i++) {
        if (i < bubbles.length) {
          // maxBubbles[i] === bubbles[i].value;
          temp.push({ ...bubbles[i], blank: false });
        } else {
          temp.push({ value: i, belowAverage: false, blank: true });
        }
        // for (let j = 0; j < bubbles.length; j++) {
        // }
      }
    }

    setNewBubbles(temp);
  }, [maxBubbles, bubbles, maxValue, value]);

  return (
    <div className={classes.chart7_bubbles}>
      {newBubbles.length > 0
        ? newBubbles.map(bubble => {
            // let newBubble;
            // bubbles.forEach((fillBubble) => {
            //   newBubble =
            //     fillBubble.value === bubble
            //       ? { ...fillBubble, black: false }
            //       : { value: bubble, belowAverage: true, black: true };
            // });
            // const bubbleFull = bubbles.includes(bubble);
            const classDefault = bubble.blank ? classes.chart7_bubble : bubble.belowAverage ? classes.chart7_bubble_full : classes.chart7_bubble_full_below;
            return (
              <div key={bubble.value} className={classes.chart7_bubbleBox + ' ' + classDefault}>
                <p>{label}</p>
              </div>
            );
          })
        : null}
    </div>
  );
};

const Chart7 = ({ setSelectedDataModal }) => {
  const numberWithCommas = x => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  return (
    <div className={classes.chart7}>
      <div className={classes.modal_heading_inputs} style={{ justifyContent: 'space-between' }}>
        <p className={classes.chart7_heading}>Utilization by Clinic</p>
        <div className={classes.modal_heading_input_selects}>
          <button
            className={classes.modal_heading_input_btn2}
            onClick={() => {
              setSelectedDataModal({
                status: true,
                title: 'Utilization Details',
                data: {
                  monthly: {
                    label: 'Utilization',
                    data: [2, 34, 16, 2, 26, 113, 16, 20, 498, 53, 5, 1165, 1, 132, 4, 29, 595, 60, 38, 1360],

                    totalAssessmentsThisMonth: 4169,
                    totalAssessmentsTtile: 'Total Utilizations this month',
                    labels: [
                      'Anniston Medical Clinic',
                      'Bay Tree Family Care, PC',
                      'Boyde J. Harrison',
                      'Comprehensive Pain & Rehab',
                      'Eclectic Family Care, LLC',
                      'FAMILY MEDICAL ASSOCIATES',
                      'Gary L. Howard, M.D.P.C.',
                      'Hector L. Salemi, M.D.',
                      'Huntsville Pain Management',
                      'Internal Medicine Associates of Tuscaloosa',
                      'Lakeside Clinic',
                      'Madison Medical Associates',
                      'Mobile Bay Physical Medicine',
                      'Physicians Care',
                      'Pickens County Primary Care, PC',
                      'ROBERTS CLINIC',
                      'Shoals Primary Care',
                      'Simmons Clinic',
                      'South Baldwin Family Practice',
                      'Southside Pain Specialists, PC',
                    ],
                  },
                  yearly: {
                    label: 'Toal Utilizations',
                    data: [2571, 3520, 3124, 2929, 2426, 3067, 3189, 3358, 3251, 4169],
                    // data2Label: 'Total Insurances',
                    // data2: [ 1500, 1800, 2412, 1478, 1358, 1254, 2084, 1454, 2471, 2014 ],
                    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
                  },
                },
              });
            }}
          >
            Details
          </button>
        </div>
      </div>

      <div className={classes.chart7_data}>
        <div className={classes.chart7_charts}>
          <div className={classes.chart7_indication} />
          <div className={classes.chart7_chart}>
            <p className={classes.chart7_chart_title}>Southside...</p>
            <Bubbles value={Math.ceil(1360 / (5 * 10))} label='Southside Pain Specialists, PC' />
            <p className={classes.chart7_chart_value}>{numberWithCommas(1360)}</p>
          </div>
          <div className={classes.chart7_chart}>
            <p className={classes.chart7_chart_title}>Madison...</p>
            <Bubbles value={Math.ceil(1165 / (5 * 10))} label='Madison Medical Associates' />
            <p className={classes.chart7_chart_value}>{numberWithCommas(1165)}</p>
          </div>
          <div className={classes.chart7_chart}>
            <p className={classes.chart7_chart_title}>Shoals...</p>
            <Bubbles value={Math.ceil(595 / (5 * 10))} label='Shoals Primary Care' />
            <p className={classes.chart7_chart_value}>{numberWithCommas(595)}</p>
          </div>
          <div className={classes.chart7_chart}>
            <p className={classes.chart7_chart_title}>Huntsville...</p>
            <Bubbles value={Math.ceil(498 / (5 * 10))} label='Huntsville Pain Management' />
            <p className={classes.chart7_chart_value}>{numberWithCommas(498)}</p>
          </div>
        </div>

        {/* <div className={classes.chart7_stats}>
          <div className={classes.chart7_stat}>
            <p className={classes.chart7_stat_value}>4500</p>
            <p className={classes.chart7_stat_dept}>Cardio</p>
            <p className={classes.chart7_stat_text}>Patients</p>
          </div>
          <div className={classes.chart7_stat}>
            <p className={classes.chart7_stat_value}>3100</p>
            <p className={classes.chart7_stat_dept}>Neuro</p>
            <p className={classes.chart7_stat_text}>Patients</p>
          </div>
          <div className={classes.chart7_stat}>
            <p className={classes.chart7_stat_value}>2900</p>
            <p className={classes.chart7_stat_dept}>Paedics</p>
            <p className={classes.chart7_stat_text}>Patients</p>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Chart7;
