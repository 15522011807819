import toast from 'react-hot-toast';
import useApi from '../useApi';

const useDeleteFile = ({ fileId }: { fileId?: string }) => {
  const { loading: deleteFileLoading, makeApiCall } = useApi({
    url: `/fileExchange/${fileId}`,
    method: 'DELETE',
  });

  const deleteFile = async () => {
    const { error } = await makeApiCall<undefined, undefined>();

    if (error) {
      toast.error('Something went wrong whiling deleting file. Please try again later');
      return { status: false };
    } else {
      toast.error('File deleted successfully.');
    }
  };

  return {
    deleteFile,
    deleteFileLoading,
  };
};

export default useDeleteFile;
