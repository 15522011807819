import type { FC, PropsWithChildren } from 'react';
import AdCampaignsStyles from '../../styles/AdCampaigns.module.css';

type ToggleButtonsProps = PropsWithChildren<{
  buttons: {
    onClick?: React.MouseEventHandler<HTMLButtonElement>;
    active: boolean;
    label: string;
  }[];
}>;

const ToggleButtons: FC<ToggleButtonsProps> = props => {
  return (
    <div className={AdCampaignsStyles.toggle}>
      {props.buttons.map((button, ind) => (
        <button
          key={button.label + ind}
          onClick={button.onClick}
          className={(button.active ? AdCampaignsStyles.filterToggleActive : AdCampaignsStyles.filterToggle) + ' ' + AdCampaignsStyles.rightOnlyBorder}
        >
          {button.label}
        </button>
      ))}
    </div>
  );
};

export default ToggleButtons;
