import React from 'react';
import ModalForm from '../components/Modal/ModalForm';
import InputsSection from '../components/Modal/InputsSection';
import InputBoxSkeleton from './InputBoxSkeleton';
import ModalButtonsSkeleton from './ModalButtonsSkeleton';

const UploadFileSkeleton = () => {
  return (
    <ModalForm style={{ marginBottom: '2.5rem' }}>
      <InputsSection>
        <InputBoxSkeleton />
        <InputBoxSkeleton />
      </InputsSection>
      <InputsSection>
        <InputBoxSkeleton style={{ width: '52rem', height: '8rem' }} />
      </InputsSection>
      <InputsSection>
        <InputBoxSkeleton style={{ width: '30rem', height: '0rem' }} />
      </InputsSection>
      <ModalButtonsSkeleton />
    </ModalForm>
  );
};

export default UploadFileSkeleton;
