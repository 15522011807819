import React from 'react';
import { Content } from '../components';
import { useAddProvider, useDeleteProvider, useGetAllProviders } from '../hooks/provider';
import { CustomTableRow, Table, TableBody, TableButtons, TableHead, TableImageRow, TableNoContent, TableRowTitle } from '../components/Table';
import { providersTableHeadData } from '../hooks/provider/staticData';
import TableSkeleton from '../Skeletons/TableSkeleton';
import { IAddProviderReq, IProvider } from '../hooks/provider/index.type';
import AddEditModal, { AddEditModalState, IaddEditModalStates } from '../pageComponents/Providers/AddEditModal';
import DeleteModal, { DeleteModalState, IdeleteModalStates } from '../pageComponents/Providers/DeleteModal';
import { unMasking, validateEmail, validatePhone } from '../utils/validators';
import { useSelector } from 'react-redux';
import { loginSliceValue } from '../redux/reducers/loginSlice';

const Providers = () => {
  // ====================================== Add edit states start ======================================
  const [addEditModal, setAddEditModal] = React.useState<AddEditModalState>({
    status: false,
  });
  const startaddEditModalStatesValue = {
    name: { value: '', error: '' },
    email: { value: '', error: '' },
    phone: { value: '', error: '' },
    speciality: { value: '', error: '' },
    about: { value: '', error: '' },
    address: { value: '', error: '' },
  };
  const [addEditModalStates, setAddEditModalStates] = React.useState<IaddEditModalStates>(startaddEditModalStatesValue);
  // ====================================== Add edit states end ======================================

  // ====================================== Delete states start ======================================
  const [deleteModal, setDeleteModal] = React.useState<DeleteModalState>({
    status: false,
  });

  const startDeleteModalStatesValue = {
    email: { value: '', error: '' },
  };
  const [deleteModalStates, setDeleteModalStates] = React.useState<IdeleteModalStates>(startDeleteModalStatesValue);
  // ====================================== Delete states end ======================================

  // ====================================== Hooks ======================================
  const { userDetails } = useSelector(loginSliceValue);
  const { providersData, getprovidersData, providersDataLoading } = useGetAllProviders();
  const { addProviderLoading, addProvider } = useAddProvider({ providerId: addEditModal.data?._id });
  const { deleteProviderLoading, deleteProvider } = useDeleteProvider({ providerId: deleteModal.data?._id });

  const returnShortString = (text: string) => {
    return text ? (text.length <= 40 ? text : text.slice(0, 40) + '...') : '';
  };

  // ====================================== Add edit functions start ======================================
  React.useEffect(() => {
    if (addEditModal.data?._id) {
      setAddEditModalStates({
        name: { value: addEditModal.data.name, error: '' },
        email: { value: addEditModal.data.email, error: '' },
        phone: { value: addEditModal.data.phone, error: '' },
        speciality: { value: addEditModal.data.specialty, error: '' },
        about: { value: addEditModal.data.about, error: '' },
        address: { value: addEditModal.data.address, error: '' },
      });
    }
  }, [addEditModal]);

  const handleCloseModal = () => {
    setAddEditModal({ status: false });
    setAddEditModalStates(startaddEditModalStatesValue);
  };

  const handleAddProvider = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!addEditModalStates.name.value.trim()) {
      setAddEditModalStates(prevVal => ({ ...prevVal, name: { ...prevVal.name, error: 'Please enter provider name' } }));
    } else if (!addEditModalStates.email.value.trim() || !validateEmail(addEditModalStates.email.value)) {
      setAddEditModalStates(prevVal => ({ ...prevVal, email: { ...prevVal.email, error: 'Please enter valid provider email' } }));
    } else if (!addEditModalStates.phone.value.trim() || !validatePhone(unMasking(addEditModalStates.phone.value))) {
      setAddEditModalStates(prevVal => ({ ...prevVal, phone: { ...prevVal.phone, error: 'Please enter valid provider phone' } }));
    } else if (!addEditModalStates.speciality.value.trim()) {
      setAddEditModalStates(prevVal => ({ ...prevVal, speciality: { ...prevVal.speciality, error: 'Please select provider speciality' } }));
    } else if (!addEditModalStates.about.value.trim()) {
      setAddEditModalStates(prevVal => ({ ...prevVal, about: { ...prevVal.about, error: 'Please enter about provider text' } }));
    } else if (!addEditModalStates.address.value.trim()) {
      setAddEditModalStates(prevVal => ({ ...prevVal, address: { ...prevVal.address, error: 'Please enter provider address' } }));
    } else {
      const clinic =
        userDetails.data?.user.role === 'clinic' ? userDetails.data?.user._id : userDetails.data?.user.role === 'manager' && userDetails.data?.user.clinic._id;

      const formData: IAddProviderReq = {
        clinic,
        name: addEditModalStates.name.value,
        email: addEditModalStates.email.value,
        phone: unMasking(addEditModalStates.phone.value),
        specialty: addEditModalStates.speciality.value,
        about: addEditModalStates.about.value,
        address: addEditModalStates.address.value,
      };

      const data = await addProvider(formData);
      if (data?.status === 'success') {
        handleCloseModal();
        getprovidersData();
      }
    }
  };
  // ====================================== Add edit functions end ======================================

  // ====================================== Delete functions start ======================================
  const handleCloseDeleteModal = () => {
    setDeleteModal({ status: false });
    setDeleteModalStates(startDeleteModalStatesValue);
  };

  const handleDeleteProvider = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (deleteModal.data) {
      if (!deleteModalStates.email.value.trim() || deleteModalStates.email.value !== deleteModal.data?.email) {
        setDeleteModalStates(prevVal => ({ ...prevVal, email: { ...prevVal.email, error: 'Please enter valid email' } }));
      } else {
        await deleteProvider();
        handleCloseDeleteModal();
        getprovidersData();
      }
    } else {
      setDeleteModalStates(prevVal => ({ ...prevVal, email: { ...prevVal.email, error: 'Please select clinic to delete' } }));
    }
  };
  // ====================================== Delete functions end ======================================

  return (
    <Content
      currentMenu='providers'
      headerTitle='Providers'
      addBtn={true}
      addBtnClick={() => setAddEditModal({ status: true })}
      addBtnTitle='Add Provider'
      searchBox={false}
      searchPlaceholder='Search Providers'
      totalReult={providersData && providersData.data.result.length > 0 ? providersData.data.result.length + ' providers' : ''}
    >
      <Table>
        <TableHead data={providersTableHeadData} />
        <TableBody>
          <CustomTableRow>
            {providersDataLoading ? (
              <TableSkeleton rows={8} columns={10} />
            ) : providersData && providersData.data.result.length > 0 ? (
              providersData.data.result.map((provider: IProvider) => (
                <tr key={provider._id}>
                  <TableImageRow alt={provider.name} />
                  <TableRowTitle>{provider.name}</TableRowTitle>
                  <td>{provider.email}</td>
                  <td>{provider.phone}</td>
                  <td style={{ textTransform: 'capitalize' }}>{provider.specialty ? provider.specialty.replace('_', ' ') : ''}</td>
                  <td>{returnShortString(provider.about)}</td>
                  <td>{returnShortString(provider.address)}</td>

                  <TableButtons
                    buttons={[
                      { icon: 'edit', tooltip: 'Edit Provider', onClick: () => setAddEditModal({ status: true, data: provider }) },
                      { icon: 'trash', tooltip: 'Delete Provider', variant: 'danger', onClick: () => setDeleteModal({ status: true, data: provider }) },
                    ]}
                  />
                </tr>
              ))
            ) : (
              <TableNoContent colSpan={providersTableHeadData.length} message='No data found' />
            )}
          </CustomTableRow>
        </TableBody>
      </Table>

      {addEditModal.status && (
        <AddEditModal
          loading={addProviderLoading}
          addEditModal={addEditModal}
          onCloseModal={handleCloseModal}
          addEditModalStates={addEditModalStates}
          setAddEditModalStates={setAddEditModalStates}
          handleAddEdit={handleAddProvider}
        />
      )}

      {deleteModal.status && (
        <DeleteModal
          loading={deleteProviderLoading}
          deleteModal={deleteModal}
          deleteModalStates={deleteModalStates}
          setDeleteModalStates={setDeleteModalStates}
          onCloseModal={handleCloseDeleteModal}
          handleDelete={handleDeleteProvider}
        />
      )}
    </Content>
  );
};

export default Providers;
