import React from 'react';
import classes from '../../../styles/HomeScreen.module.css';

const Chart1 = ({ data, totalPatients, setSelectedDataModal }) => {
  // const months = [
  //   { value: 'Jan', count: 680 },
  //   { value: 'Feb', count: 520 },
  //   { value: 'Mar', count: 584 },
  //   { value: 'Apr', count: 598 },
  //   { value: 'May', count: 624 },
  //   { value: 'June', count: 681 },
  //   { value: 'Jul', count: 764 },
  //   { value: 'Aug', count: 605 },
  //   { value: 'Sep', count: 405 },
  //   { value: 'Oct', count: 660 },
  //   { value: 'Nov', count: 1 },
  //   { value: 'Dec', count: 1 },
  // ];

  // let totalCount = 0;

  // let totalCount = data.count ? data.count : 100;
  // data.data &&
  // 	data.data.length > 0 &&
  // 	data.data.forEach((d) => {
  // 		months[new Date(d.date_created).getMonth()].count = d.count;
  // 	});

  const [redFlags, setRedflags] = React.useState({ labels: [], count: [] });
  const [sideEffects, setSideEffects] = React.useState({
    labels: [],
    count: [],
  });

  React.useEffect(() => {
    const labelsRedFlagArray = [];
    const countsRedFlagArray = [];
    const labelsSideEffectArray = [];
    const countsSideEffectArray = [];

    if (data && data.redFlagData && data.redFlagData.result && data.redFlagData.result.data && data.redFlagData.result.data.length > 0) {
      data.redFlagData.result.data.forEach(element => {
        labelsRedFlagArray.push(element.label);
        countsRedFlagArray.push(element.count);
      });
    }

    if (data && data.sideEffectsData && data.sideEffectsData.result && data.sideEffectsData.result.data && data.sideEffectsData.result.data.length > 0) {
      data.sideEffectsData.result.data.forEach(element => {
        labelsSideEffectArray.push(element.label);
        countsSideEffectArray.push(element.count);
      });
    }
    setRedflags({ labels: labelsRedFlagArray, count: countsRedFlagArray });
    setSideEffects({
      labels: labelsSideEffectArray,
      count: countsSideEffectArray,
    });
  }, [data]);

  const numberWithCommas = x => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  return (
    <div className={classes.chart1}>
      <div className={classes.chart1_left}>
        <p className={classes.chart4_heading}>
          Total Patients in <span>2022</span>
        </p>
        <div className={classes.chart4_data}>
          <p className={classes.chart4_data_number + ' ' + classes.grad_color}>
            {/* {data.count ? numberWithCommas(totalCount) : '--'} */}
            {numberWithCommas(totalPatients.total ? totalPatients.total : 0)}
          </p>
          {/* <p className={classes.chart4_active + ' ' + classes.grad_color}>
						{data.count ? numberWithCommas(data.count - 7540) + ' active' : '--'}
					</p> */}
        </div>

        <div className={classes.chart4_chart}>
          {/* {data.data &&
          data.data.length > 0 &&
          data.data.map((month, indx) => (
            <div key={indx} className={classes.chart4_chart_bar}>
              <div style={{ height: `${month.count / 50}%` }}></div>
              <p>{months[new Date(month.date_created).getMonth()]}</p>
            </div>
          ))} */}
          {totalPatients &&
            totalPatients.data &&
            totalPatients.data.map((month, indx) => {
              const height = (month.count * 950) / totalPatients.total;
              return (
                <div key={indx} className={classes.chart4_chart_bar}>
                  <div style={{ height: `${height}%` }} />
                  {/* <p>{months[new Date(month.date_created).getMonth()]}</p> */}
                  <p>{month.month}</p>
                </div>
              );
            })}
        </div>
      </div>

      {data && (
        <div className={classes.chart1_right}>
          <div className={classes.chart1_data}>
            <p className={classes.chart1_data_value}>
              {data.redFlagData && data.redFlagData && data.redFlagData.result && data.redFlagData.result.total ? numberWithCommas(data.redFlagData.result.total) : '...'}
            </p>
            <p>{data.redFlagData && data.redFlagData.title ? data.redFlagData.title : '--'}</p>
            <div className={classes.chart1_data_chart}>
              <div style={{ height: `${(807 * 300) / 6664}%` }} />
              <div style={{ height: `${(876 * 300) / 6664}%` }} />
              <div style={{ height: `${(1124 * 300) / 6664}%` }} />
              <div style={{ height: `${(695 * 300) / 6664}%` }} />
              <div style={{ height: `${(841 * 300) / 6664}%` }} />
              <div style={{ height: `${(407 * 300) / 6664}%` }} />
              <div style={{ height: `${(718 * 300) / 6664}%` }} />
              <div style={{ height: `${(842 * 300) / 6664}%` }} />
              <div style={{ height: `${(354 * 300) / 6664}%` }} />
              <div style={{ height: `${(874 * 300) / 6664}%` }} />
              <div style={{ height: `${6664 / 6664}%` }} />
              <div style={{ height: `${6664 / 6664}%` }} />
            </div>
            <button
              disabled={!(data.redFlagData && data.redFlagData && data.redFlagData.result && data.redFlagData.result.total)}
              onClick={() => {
                setSelectedDataModal({
                  status: true,
                  title: 'Redflag Details',
                  data: {
                    monthly: {
                      label: 'Redflags',
                      // data: [ 6, 416, 11, 264, 9, 31, 60, 77 ],
                      data: redFlags.count,
                      totalAssessmentsThisMonth: data.redFlagData.result.total,
                      // labels: [
                      // 	'Diversion',
                      // 	'High BMI',
                      // 	'History of Overdose',
                      // 	'No Alt Treatment',
                      // 	'Opioid Concomitant',
                      // 	'Possible Addiction',
                      // 	'Tapering Opioid',
                      // 	'Weight Loss'
                      // ]
                      labels: redFlags.labels,
                    },
                    yearly: {
                      label: 'Redflags',
                      dataSet: [
                        {
                          type: 'line',
                          fill: false,
                          label: 'Total Assessments',
                          data: [1500, 1800, 2412, 1478, 1358, 1254, 2084, 1454, 2471, 2014],
                          borderColor: 'rgba(230, 111, 0, 1)',
                          borderWidth: 1,
                          tension: 0.5,
                          pointBackgroundColor: 'rgba(230, 111, 0, 0.5)',
                        },
                        {
                          label: 'Diversion',
                          data: [807, 876, 1124, 695, 841, 407, 718, 842, 354, 921],
                          backgroundColor: 'rgb(255, 99, 132)',
                        },
                        {
                          label: 'High BMI',
                          data: [807, 876, 1124, 695, 841, 407, 718, 842, 354, 921],
                          backgroundColor: 'rgb(53, 162, 235)',
                        },
                        {
                          type: 'bar',
                          fill: true,
                          label: 'History of Overdose',
                          data: [807, 876, 1124, 695, 841, 407, 718, 842, 354, 921],
                          borderColor: 'rgba(230, 111, 0, 1)',
                          borderWidth: 0,
                          borderRadius: 8,
                          backgroundColor: 'rgb(75, 192, 192)',
                        },
                      ],
                      labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
                    },
                  },
                });
              }}
            >
              Details
            </button>
          </div>

          <div className={classes.chart1_data}>
            <p className={classes.chart1_data_value}>
              {data.sideEffectsData && data.sideEffectsData && data.sideEffectsData.result && data.sideEffectsData.result.total
                ? numberWithCommas(data.sideEffectsData.result.total)
                : '...'}
            </p>
            <p>{data.sideEffectsData && data.sideEffectsData.title ? data.sideEffectsData.title : '--'}</p>
            <div className={classes.chart1_data_chart}>
              <div style={{ height: `${(201 * 300) / 2611}%` }} />
              <div style={{ height: `${(105 * 300) / 2611}%` }} />
              <div style={{ height: `${(265 * 300) / 2611}%` }} />
              <div style={{ height: `${(254 * 300) / 2611}%` }} />
              <div style={{ height: `${(356 * 300) / 2611}%` }} />
              <div style={{ height: `${(306 * 300) / 2611}%` }} />
              <div style={{ height: `${(298 * 300) / 2611}%` }} />
              <div style={{ height: `${(261 * 300) / 2611}%` }} />
              <div style={{ height: `${(307 * 300) / 2611}%` }} />
              <div style={{ height: `${(258 * 300) / 2611}%` }} />
              <div style={{ height: `${2611 / 2611}%` }} />
              <div style={{ height: `${2611 / 2611}%` }} />
            </div>
            <button
              disabled={!(data.sideEffectsData && data.sideEffectsData && data.sideEffectsData.result && data.sideEffectsData.result.total)}
              onClick={() => {
                // const sum = [ 268, 174, 95, 654, 63, 455, 20, 1727, 27 ].reduce(add, 0);
                // function add(accumulator, a) {
                // 	return accumulator + a;
                // }
                // console.log(sum);

                setSelectedDataModal({
                  status: true,
                  title: 'Sideeffects Details',
                  data: {
                    monthly: {
                      label: 'Sideeffects',
                      // data: [
                      // 	30,
                      // 	78,
                      // 	200,
                      // 	52,
                      // 	15,
                      // 	34,
                      // 	46,
                      // 	170,
                      // 	73,
                      // 	49,
                      // 	60,
                      // 	27,
                      // 	28,
                      // 	25,
                      // 	116,
                      // 	47,
                      // 	1,
                      // 	24,
                      // 	48,
                      // 	78,
                      // 	14
                      // ],
                      // totalAssessmentsThisMonth: 2014,
                      data: sideEffects.count,
                      totalAssessmentsThisMonth: data.sideEffectsData.result.total,
                      labels: sideEffects.labels,
                      // labels: [
                      // 	'Appetite',
                      // 	'BurnTingle',
                      // 	'Constipation',
                      // 	'Depression',
                      // 	'Diarrhea',
                      // 	'Dizzy',
                      // 	'Drowsiness',
                      // 	'DryMouth',
                      // 	'DSexDrive',
                      // 	'Headache',
                      // 	'ItchySweaty',
                      // 	'Memory',
                      // 	'Nausea',
                      // 	'PainSensitivity',
                      // 	'SleepIssues',
                      // 	'StomachIssues',
                      // 	'SuicidalThoughts',
                      // 	'Tolerance',
                      // 	'Unsteadiness',
                      // 	'Weakness',
                      // 	'Withdrawal'
                      // ]
                    },
                    yearly: {
                      label: 'Sideeffects',
                      data: [807, 876, 1124, 695, 841, 407, 718, 842, 354, 1215],
                      data2Label: 'Total Assessments',
                      data2: [1500, 1800, 2412, 1478, 1358, 1254, 2084, 1454, 2471, 2014],
                      labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
                    },
                  },
                });
              }}
            >
              Details
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Chart1;
