import React, { FC, Fragment } from 'react';

interface TableRowProps {
  children: any;
}

const TableRow: FC<TableRowProps> = ({ children }) => {
  return <Fragment>{children}</Fragment>;
};

export default TableRow;
