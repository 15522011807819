import React from 'react';
import { useSelector } from 'react-redux';
import { loginSliceValue } from '../redux/reducers/loginSlice';
import { useAllClinics } from '../hooks/clinic';
import { useAddFeedback, useAllFeedbacks, useDeleteFeedback } from '../hooks/feedbacks';
import { AddEditModalState, IaddEditModalStates } from '../pageComponents/Feedbacks/AddEditModal';
import { IAddFeedbackReq } from '../hooks/feedbacks/index.type';

import { Content } from '../components';
import { AddEditModal, FeedbackItem, NoData } from '../pageComponents/Feedbacks';
import DeleteModal, { DeleteModalState } from '../pageComponents/Feedbacks/DeleteModal';
import classes from '../styles/Feedback.module.css';

const Feedback = () => {
  // ====================================== Clinic states start ======================================
  const [clinics, setClinics] = React.useState<{ value: string; label: string }[]>([]);
  const [selectedClinic, setSelectedClinic] = React.useState<string>('');
  // ====================================== Clinic state end ======================================

  // ====================================== Add edit states start ======================================
  const [addEditModal, setAddEditModal] = React.useState<AddEditModalState>({
    status: false,
  });
  const startaddEditModalStatesValue: IaddEditModalStates = {
    title: { value: '', error: '' },
    placeholder: { value: '', error: '' },
    order: { value: '', error: '' },
    default: { value: false, error: '' },
    type: { value: 'none', error: '' },
  };
  const [addEditModalStates, setAddEditModalStates] = React.useState<IaddEditModalStates>(startaddEditModalStatesValue);
  // ====================================== Add edit states end ======================================

  // ====================================== Delete states start ======================================
  const [deleteModal, setDeleteModal] = React.useState<DeleteModalState>({
    status: false,
  });
  // ====================================== Delete states end ======================================

  // ====================================== Hooks ======================================
  const { userDetails } = useSelector(loginSliceValue);
  const { clinicsData, getClinicsData, clinicsDataLoading } = useAllClinics({ pageNumber: 1, perpageData: 1000 });
  const { feedbackData, getFeedbackData, feedbackDataLoading } = useAllFeedbacks({ role: userDetails.data?.user.role, clinicId: selectedClinic });
  const { addFeedbackLoading, addFeedback } = useAddFeedback({ feedbackId: addEditModal.data?._id });
  const { deleteFeedbackLoading, deleteFeedback } = useDeleteFeedback({ feedbackId: deleteModal.data?._id });

  // ====================================== Clinic functions start ======================================
  React.useEffect(() => {
    if (userDetails.data?.user.role === 'admin') {
      getClinicsData();
    }
    // eslint-disable-next-line
  }, [userDetails]);

  React.useEffect(() => {
    const temp: { value: string; label: string }[] = [{ label: 'Select Clinic', value: '' }];
    if (clinicsData?.data.data.length) {
      clinicsData.data.data.forEach(clinic => {
        temp.push({ label: clinic.name, value: clinic._id });
      });
    }
    setClinics(temp);
  }, [clinicsData]);
  // ====================================== Clinic functions end ======================================

  // ====================================== Add edit functions start ======================================
  React.useEffect(() => {
    if (addEditModal.data?._id) {
      setAddEditModalStates({
        title: { value: addEditModal.data.question, error: '' },
        placeholder: { value: addEditModal.data.placeholder, error: '' },
        order: { value: String(addEditModal.data.order), error: '' },
        default: { value: addEditModal.data.default, error: '' },
        type: { value: addEditModal.data.type, error: '' },
      });
    }
  }, [addEditModal]);

  const handleCloseModal = () => {
    setAddEditModal({ status: false });
    setAddEditModalStates(startaddEditModalStatesValue);
  };

  const handleAddFeedback = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!addEditModalStates.title.value.trim()) {
      setAddEditModalStates(prevVal => ({ ...prevVal, title: { ...prevVal.title, error: 'Please enter question title' } }));
    } else if (!addEditModalStates.placeholder.value.trim()) {
      setAddEditModalStates(prevVal => ({ ...prevVal, placeholder: { ...prevVal.placeholder, error: 'Please enter question placeholder' } }));
    } else if (!addEditModalStates.order.value.trim()) {
      setAddEditModalStates(prevVal => ({ ...prevVal, order: { ...prevVal.order, error: 'Please enter question order' } }));
    } else if (userDetails.data?.user.role === 'admin' && !selectedClinic && !addEditModalStates.default.value) {
      setAddEditModalStates(prevVal => ({ ...prevVal, default: { ...prevVal.default, error: 'Please mark this feedback as default' } }));
    } else if (userDetails.data?.user.role === 'admin' && addEditModal.data && !addEditModal.data?.clinic && !addEditModalStates.default.value) {
      setAddEditModalStates(prevVal => ({ ...prevVal, default: { ...prevVal.default, error: 'Please mark this feedback as default' } }));
    } else {
      const formData: IAddFeedbackReq = {
        question: addEditModalStates.title.value,
        placeholder: addEditModalStates.placeholder.value,
        order: Number(addEditModalStates.order.value),
        default: addEditModalStates.default.value,
        type: addEditModalStates.type.value,
      };

      if (userDetails.data?.user.role === 'admin' && !addEditModalStates.default.value) formData.clinic = selectedClinic;

      const data = await addFeedback(formData);
      if (data?.status === 'success') {
        handleCloseModal();
        getFeedbackData();
      }
    }
  };
  // ====================================== Add edit functions end ======================================

  // ====================================== Delete functions start ======================================
  const handleDeleteClinic = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (deleteModal.data) {
      await deleteFeedback();
      setDeleteModal({ status: false });
      getFeedbackData();
    }
  };
  // ====================================== Delete functions end ======================================

  return (
    <Content
      currentMenu='feedback'
      headerTitle='Customise Patient Feedback Questions'
      addBtn
      addBtnTitle={!selectedClinic && userDetails.data?.user.role === 'admin' ? 'Add Default Feedback' : 'Add Feedback'}
      addBtnClick={() => setAddEditModal({ status: true })}
      dropdown={userDetails.data?.user.role === 'admin' ? true : false}
      dropdownOptions={clinics}
      dropdownValue={selectedClinic}
      dropdownChange={e => setSelectedClinic(e.target.value)}
    >
      {feedbackDataLoading || clinicsDataLoading ? (
        <NoData title='Loading Feedback data' />
      ) : (
        <div className={classes.feeback_container}>
          {feedbackData?.data.data.length ? (
            feedbackData?.data.data.map(item => (
              <FeedbackItem key={item._id} item={item} setAddEditModal={setAddEditModal} setDeleteModal={setDeleteModal} role={userDetails.data?.user.role} />
            ))
          ) : !selectedClinic && userDetails.data?.user.role === 'admin' ? (
            <NoData title='Please select clinic' />
          ) : (
            <NoData title='No feedbacks found' />
          )}
        </div>
      )}

      {addEditModal.status && (
        <AddEditModal
          loading={addFeedbackLoading}
          addEditModal={addEditModal}
          selectedClinic={selectedClinic}
          role={userDetails.data?.user.role}
          onCloseModal={handleCloseModal}
          addEditModalStates={addEditModalStates}
          setAddEditModalStates={setAddEditModalStates}
          handleAddEdit={handleAddFeedback}
        />
      )}

      {deleteModal.status && (
        <DeleteModal loading={deleteFeedbackLoading} deleteModal={deleteModal} onCloseModal={() => setDeleteModal({ status: false })} handleDelete={handleDeleteClinic} />
      )}
    </Content>
  );
};

export default Feedback;
