import type { FC, PropsWithChildren } from 'react';
import { InputsSection, InputBox, ModalButtons, ModalForm, Modal, SelectBox } from '../../components/Modal';
import { ToggleSwitchWithLabel } from '../../components';
import ModalHeading from '../../components/Modal/ModalHeading';
import AddUserSkeleton from '../../Skeletons/AddUserSkeleton';

import { IFeedback } from '../../hooks/feedbacks/index.type';
import { returnOnlyNumber } from '../../utils/validators';

export type AddEditModalState = PropsWithChildren<{
  status: boolean;
  data?: IFeedback;
}>;
export type IaddEditModalStates = PropsWithChildren<{
  title: { value: string; error: string };
  placeholder: { value: string; error: string };
  order: { value: string; error: string };
  default: { value: boolean; error: string };
  type: { value: string; error: string };
}>;

type AddEditModalProps = PropsWithChildren<{
  loading: boolean;
  selectedClinic: string;
  addEditModal: AddEditModalState;
  role?: string;
  onCloseModal?: () => void;
  handleAddEdit?: (event: React.FormEvent<HTMLFormElement>) => void;
  addEditModalStates: IaddEditModalStates;
  setAddEditModalStates: React.Dispatch<React.SetStateAction<IaddEditModalStates>>;
}>;

const AddEditModal: FC<AddEditModalProps> = props => {
  return (
    <Modal show={props.addEditModal.status}>
      <ModalHeading heading={props.addEditModal.data && props.addEditModal.data._id ? 'Update Feedback Data' : 'Add New Feedback'} />
      {props.loading ? (
        <AddUserSkeleton />
      ) : (
        <ModalForm style={{ marginBottom: '2.5rem' }} onSubmit={props.handleAddEdit}>
          <InputsSection>
            <InputBox
              label='Feedback Question Title'
              placeholder='eg, How do you rate your provider experience?'
              value={props.addEditModalStates.title.value}
              onChange={event => {
                props.setAddEditModalStates(prevVal => ({ ...prevVal, title: { value: event.target.value, error: '' } }));
              }}
              errorMessage={props.addEditModalStates.title.error}
              // style={{ minWidth: '52rem' }}
            />
            <SelectBox
              label='Feedback Question Type'
              value={props.addEditModalStates.type.value}
              onChange={event => {
                props.setAddEditModalStates(prevVal => ({ ...prevVal, type: { value: event.target.value, error: '' } }));
              }}
              errorMessage={props.addEditModalStates.type.error}
            >
              <option value='none'>None</option>
              <option value='general'>General</option>
              <option value='location'>Location</option>
              <option value='provider'>Provider</option>
              <option value='appointment'>Appointment</option>
              <option value='practice'>Practice</option>
            </SelectBox>
          </InputsSection>

          <InputsSection>
            <InputBox
              label='Feedback Question Placeholder'
              placeholder='eg, Write few lines about your provider’s experience (optional)'
              value={props.addEditModalStates.placeholder.value}
              onChange={event => {
                props.setAddEditModalStates(prevVal => ({ ...prevVal, placeholder: { value: event.target.value, error: '' } }));
              }}
              errorMessage={props.addEditModalStates.placeholder.error}
            />
            <InputBox
              label='Question Order'
              placeholder='eg, 1'
              value={props.addEditModalStates.order.value}
              onChange={event => {
                props.setAddEditModalStates(prevVal => ({ ...prevVal, order: { value: returnOnlyNumber(event.target.value), error: '' } }));
              }}
              errorMessage={props.addEditModalStates.order.error}
            />
          </InputsSection>

          {props.role === 'admin' && !props.addEditModal.data?.clinic && (
            <InputsSection>
              <ToggleSwitchWithLabel
                label='Default Feedback'
                id='Feedback-default'
                checked={props.addEditModalStates.default.value}
                onChange={() => {
                  props.setAddEditModalStates(prevVal => ({ ...prevVal, default: { value: !prevVal.default.value, error: '' } }));
                }}
                errorMessage={props.addEditModalStates.default.error}
              />
            </InputsSection>
          )}

          <ModalButtons
            submitButtonLabel={props.addEditModal.data && props.addEditModal.data._id ? 'Update Feedback' : 'Add Feedback'}
            cancelButtonLabel='Cancel'
            onCancel={props.onCloseModal}
          />
        </ModalForm>
      )}
    </Modal>
  );
};

export default AddEditModal;
