import React from 'react';
import { Content } from '../components';
import { CustomTableRow, Table, TableBody, TableButtons, TableHead, TableRowAddress, TableRowTitle } from '../components/Table';
import { appointmentNewTableHeadData } from '../hooks/appointments/staticData';
import AppointmentDetailModal from '../pageComponents/Appointments/AppointmentDetailModal';

const AppointmentsNew = () => {
  const [appointmentDetailModal, setAppointmentDetailModal] = React.useState<boolean>(false);
  return (
    <Content currentMenu='appointment' headerTitle='Appointments' addBtn={false} addBtnTitle='Add Appointment' searchBox={false} totalReult={'10 appointments'}>
      <Table>
        <TableHead data={appointmentNewTableHeadData} />
        <TableBody>
          <CustomTableRow>
            {/* {false ? (
              <TableSkeleton rows={7} columns={10} />
            ) : clinicsData && clinicsData.totalResults > 0 ? (
              clinicsData.data.data.map((clinic: IClinic) => (
                <tr key={clinic._id}>
                  <TableRowTitle>{clinic.name}</TableRowTitle>
                  <td>{clinic.email}</td>
                  <td>{clinic.phone}</td>
                  <td>{clinic.address ? (clinic.address.length <= 40 ? clinic.address : clinic.address.slice(0, 40) + '...') : ''}</td>
                  <td>{clinic.clinicID ? clinic.clinicID : ''}</td>
                  <TableButtons
                    buttons={[
                      { icon: 'edit-3', onClick: () => {} },
                      { icon: 'trash', variant: 'danger', onClick: () => {} },
                    ]}
                  />
                </tr>
              ))
            ) : (
              <TableNoContent colSpan={appointmentNewTableHeadData.length} message='No data found' />
            )} */}
            <tr>
              <td></td>
              <TableRowTitle>John Smith</TableRowTitle>
              <td>08-20-2023</td>
              <td>3 Month Follow-Up</td>
              <TableRowAddress>
                Cardiology Location
                <span>795 Horsham Rd, Horsham, PA</span>
              </TableRowAddress>
              <td>David Buckley</td>
              <TableButtons
                detailButton
                detailButtonLabel='Details'
                detailButtonOnClick={() => setAppointmentDetailModal(true)}
                buttons={[
                  { icon: 'edit-3', onClick: () => {} },
                  { icon: 'trash', variant: 'danger', onClick: () => {} },
                ]}
              />
            </tr>
            <tr>
              <td></td>
              <TableRowTitle>John Smith</TableRowTitle>
              <td>08-20-2023</td>
              <td>3 Month Follow-Up</td>
              <TableRowAddress>
                Cardiology Location
                <span>795 Horsham Rd, Horsham, PA</span>
              </TableRowAddress>
              <td>David Buckley</td>
              <TableButtons
                detailButton
                detailButtonLabel='Details'
                detailButtonOnClick={() => setAppointmentDetailModal(true)}
                buttons={[
                  { icon: 'edit-3', onClick: () => {} },
                  { icon: 'trash', variant: 'danger', onClick: () => {} },
                ]}
              />
            </tr>
          </CustomTableRow>
        </TableBody>
      </Table>

      <AppointmentDetailModal status={appointmentDetailModal} onCloseModal={() => setAppointmentDetailModal(false)} />
    </Content>
  );
};

export default AppointmentsNew;
