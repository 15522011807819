import React from 'react';
import ModalForm from '../../../components/Modal/ModalForm';
import ModalButtons from '../../../components/Modal/ModalButtons';
import AddUserSkeleton from '../../../Skeletons/AddUserSkeleton';
import classes from './InsurancePolicies.module.css';
import { Button, TextArea } from '../../../components/Modal';
import { useSaveHIPAAForm } from '../../../hooks/users';
import toast from 'react-hot-toast';
import { DeleteModalState } from '../DeleteModal';
import moment from 'moment';
import { IUser } from '../../../hooks/users/index.type';

interface IState {
  cbpota: boolean;
  espufba: boolean;
  irts: boolean;
  other: boolean;
  otherText: { value: string; error: boolean };
}

interface HippaPoliciesFormProps {
  hippaPiliciesForm: {
    data: {
      printName: string;
      patientBirthDate: string;
      irts: boolean;
      cbpota: boolean;
      espufba: boolean;
      other: boolean;
      otherText: string;
      modifiedDateString: string;
      offset: number;
      isDefault: boolean;
      _id: string;
      user: string;
      clinic: string;
      createdAt: string;
      updatedAt: string;
      __v: number;
    };
    loading: boolean;
    error: string;
  };
  patient: IUser;
  loading: boolean;
  closeInsurancePoliciesModal?: React.MouseEventHandler<HTMLButtonElement>;
  getContactCareData: () => Promise<void>;
  insurancePoliciesModal: DeleteModalState;
}

const HippaPoliciesForm: React.FC<HippaPoliciesFormProps> = ({
  patient,
  closeInsurancePoliciesModal,
  hippaPiliciesForm,
  insurancePoliciesModal,
  getContactCareData,
  loading,
}) => {
  const initialState: IState = {
    cbpota: false,
    espufba: false,
    irts: false,
    other: false,
    otherText: { value: '', error: false },
  };
  const [state, setState] = React.useState<IState>(initialState);

  const { saveHIPAAForm, saveHIPAAFormLoading } = useSaveHIPAAForm();

  React.useEffect(() => {
    if (hippaPiliciesForm && hippaPiliciesForm.data) {
      const newState: IState = {
        cbpota: hippaPiliciesForm.data.cbpota,
        espufba: hippaPiliciesForm.data.espufba,
        irts: hippaPiliciesForm.data.irts,
        other: hippaPiliciesForm.data.other,
        otherText: { value: hippaPiliciesForm.data.otherText, error: false },
      };
      setState(newState);
    }
  }, [hippaPiliciesForm]);

  const handleSaveForm = async () => {
    if (insurancePoliciesModal.data) {
      if (state.other && !state.otherText.value) {
        toast.error('Please specify the reason');
        setState(prevVal => ({
          ...prevVal,
          otherText: { value: prevVal.otherText.value, error: true },
        }));
      } else {
        const formData = {
          irts: state.irts,
          cbpota: state.cbpota,
          espufba: state.espufba,
          other: state.other,
          otherText: state.otherText.value,
          user: insurancePoliciesModal.data,
        };
        const data = await saveHIPAAForm(formData);
        if (data?.status === 'success') {
          toast.success(data.message);
          await getContactCareData();
        }
      }
    }
  };

  return (
    <React.Fragment>
      {loading ? (
        <AddUserSkeleton />
      ) : (
        <div className={classes.form_content}>
          <div className={classes.form_container}>
            <p className={classes.form_container_heading}>ACKNOWLEDGMENT OF RECEIPT OF NOTICE PRIVACY PRACTICE</p>
            <p className={classes.form_relation_text} style={{ marginBottom: '2rem' }}>
              I acknowledge that I have been provided with a copy of this Practice's Notice of Privacy Practice.
            </p>

            <div className={classes.form_relation}>
              <p className={classes.form_relation_text}>
                Print Name <span>{hippaPiliciesForm.data?.printName ? hippaPiliciesForm.data?.printName : '-'}</span>
              </p>
              <p className={classes.form_relation_text}>
                Birth Date
                <span>{patient.dob ? moment(patient.dob).format('MMM DD YYYY') : '-'}</span>
              </p>
              <p className={classes.form_relation_text}>
                Today's Date{' '}
                <span>
                  {hippaPiliciesForm.data?.modifiedDateString
                    ? moment(hippaPiliciesForm.data?.modifiedDateString).format('MMM DD YYYY')
                    : moment(Date.now()).format('MMM DD YYYY')}
                </span>
              </p>
            </div>
            {/* <div className={classes.form_relations}>
                {contactCareForm.data?.patientCareList.length > 0 &&
                  contactCareForm.data?.patientCareList.map((list) => (
                    <div key={list._id} className={classes.form_relation}>
                      <p className={classes.form_relation_text}>
                        Name <span>{list.name ? list.name : '--'}</span>
                      </p>
                      <p className={classes.form_relation_text}>
                        Relationship{' '}
                        <span>
                          {list.relationship ? list.relationship : '--'}
                        </span>
                      </p>
                      <p className={classes.form_relation_text}>
                        Phone <span>{list.phone ? list.phone : '--'}</span>
                      </p>
                    </div>
                  ))}
              </div> */}
          </div>

          {/* <div className={classes.form_container}>
            <p className={classes.form_container_heading}>Patient/Gurdian Signature</p>
          </div> */}

          <div className={classes.form_container}>
            <p className={classes.form_container_heading}>For Practice Use Only</p>
            <p className={classes.form_relation_text}>
              We attempted to obtain written acknowledgment of receipt of our Notice of Privacy Practice, but acknowledgement could not be obtained because:
            </p>
            <div className={classes.form_check_box}>
              <label className='switch'>
                <input
                  checked={state.irts}
                  onChange={() =>
                    setState(prevVal => ({ ...prevVal, irts: !prevVal.irts, cbpota: false, espufba: false, other: false, otherText: { value: '', error: false } }))
                  }
                  type='checkbox'
                  className='checkbox'
                  name='active'
                />
                <span className='slider round' />
              </label>
              <p>Individual refused to sign</p>
            </div>

            <div className={classes.form_check_box}>
              <label className='switch'>
                <input
                  checked={state.cbpota}
                  onChange={() =>
                    setState(prevVal => ({ ...prevVal, cbpota: !prevVal.cbpota, irts: false, espufba: false, other: false, otherText: { value: '', error: false } }))
                  }
                  type='checkbox'
                  className='checkbox'
                  name='active'
                />
                <span className='slider round' />
              </label>
              <p>Comunications barrier prohibited obtaining the acknowledgement</p>
            </div>

            <div className={classes.form_check_box}>
              <label className='switch'>
                <input
                  checked={state.espufba}
                  onChange={() =>
                    setState(prevVal => ({ ...prevVal, espufba: !prevVal.espufba, irts: false, cbpota: false, other: false, otherText: { value: '', error: false } }))
                  }
                  type='checkbox'
                  className='checkbox'
                  name='active'
                />
                <span className='slider round' />
              </label>
              <p>An emergency situation prevented us from obtaining acknowledgement.</p>
            </div>

            <div className={classes.form_check_box}>
              <label className='switch'>
                <input
                  checked={state.other}
                  onChange={() =>
                    setState(prevVal => ({ ...prevVal, other: !prevVal.other, irts: false, cbpota: false, espufba: false, otherText: { value: '', error: false } }))
                  }
                  type='checkbox'
                  className='checkbox'
                  name='active'
                />
                <span className='slider round' />
              </label>
              <p>Other (Please specify)</p>
            </div>
            {state.other && (
              // <p className={classes.form_numbers_text}>
              //   Reason: <span style={{ width: '63rem' }}>{state.otherText.value}</span>
              // </p>
              <TextArea
                label='Reason'
                placeholder='Please specify the reason'
                value={state.otherText.value}
                onChange={event => {
                  setState(prevVal => ({ ...prevVal, otherText: { value: event.target.value, error: false } }));
                }}
                errorMessage={state.otherText.error ? 'Please specify the reason' : undefined}
              />
            )}

            <p className={classes.form_container_heading} style={{ marginTop: '2rem' }}>
              *If Patient Representative signing. Legal documentation must be included designating authority to sign or receive information. This form must be maintained
              for 6 years.
            </p>
            {saveHIPAAFormLoading ? (
              <Button label='Saving data' submitButtonStyle={{ marginTop: '1rem' }} />
            ) : (
              <Button label='Save' submitButtonStyle={{ marginTop: '1rem' }} onClick={handleSaveForm} />
            )}
          </div>
        </div>
      )}

      <ModalForm style={{ marginBottom: '2.5rem' }}>
        <ModalButtons cancelButtonLabel='Close Insurance Policies' onCancel={closeInsurancePoliciesModal} />
      </ModalForm>
    </React.Fragment>
  );
};

export default HippaPoliciesForm;
