import SkeletonStyles from "./Skeletons.module.css";
import AdViewStyles from "../styles/AdViewScreen.module.css";

const GeneratedAdSkeleton = () => {
	return (
		<div className={AdViewStyles.generated_ad_card}>
			<div
				className={
					SkeletonStyles.skeleton +
					" " +
					SkeletonStyles.generated_ad_image
				}
			></div>
			<div
				className={
					SkeletonStyles.skeleton +
					" " +
					SkeletonStyles.generated_ad_title
				}
			></div>
			<div
				className={
					SkeletonStyles.skeleton +
					" " +
					SkeletonStyles.generated_ad_info
				}
			></div>
		</div>
	);
};

export default GeneratedAdSkeleton;
