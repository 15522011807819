import React from 'react';
import toast from 'react-hot-toast';
import useApi from '../useApi';
import { IFolderFilesRes } from './index.type';

const useAllFiles = ({ pageNumber, perpageData }: { pageNumber: number; perpageData: number }) => {
  const [allFilesData, setAllFilesData] = React.useState<IFolderFilesRes>();

  const { loading: allFilesDataLoading, makeApiCall } = useApi({
    url: `/fileExchange?page=${pageNumber}&limit=${perpageData}`,
    method: 'GET',
  });

  const getAllFilesData = async () => {
    const { response, error } = await makeApiCall<undefined, IFolderFilesRes>();
    if (response?.status === 'success') {
      setAllFilesData(response);
    }

    if (response?.status === 'fail') {
      setAllFilesData(undefined);
    }

    if (error) toast.error('Something went wrong while fetching folder files. Please try again later');
  };

  React.useEffect(() => {
    getAllFilesData();
    // eslint-disable-next-line
  }, [pageNumber]);

  return {
    allFilesData,
    getAllFilesData,
    allFilesDataLoading,
  };
};

export default useAllFiles;
