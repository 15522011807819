export const validateOnlyChacters = (name: string) => {
  const re = /^[a-zA-Z \s]+$/;
  return re.test(name);
};

export const validateOnlyNumbers = (number: string) => {
  const re = /^[0-9]{1,12}$/;
  return re.test(number);
};

export const validatePassword = (password: string) => {
  const re = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}/;
  return re.test(password);
};

export const validateEmail = (email: string) => {
  const re = /[a-z0-9!#$%&'*+=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
  return re.test(email);
};

export const validatePhone = (phone: string) => {
  // const re = /^[0-9]{10}$/;
  const re = /^[0-9]{6,12}$/;
  return re.test(phone);
};

export const inputPhoneMasking = (e: string) => {
  let value = e;
  value = value.replace(/\D/g, '');
  value = value.replace(/(\d{3})(\d)/, '$1-$2');
  value = value.replace(/(\d{3})(\d)/, '$1-$2');
  value = value.substring(0, 12);

  return value;
};
export const inputSSNMasking = (e: string) => {
  let value = e;
  value = value.replace(/\D/g, '');
  value = value.replace(/^(\d{3})(\d{1,2})/, '$1-$2');
  value = value.replace(/^(\d{3})-(\d{2})(.+)/, '$1-$2-$3');
  value = value.substring(0, 11);

  return value;
};

export const unMasking = (e: string) => {
  let value = e;
  value = value.replaceAll('-', '');

  return value;
};

export const returnOnlyNumber = (data: string) => {
  let value = data;
  value = value.replace(/\D/g, '');
  return value;
};
