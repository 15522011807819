import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';

import DownloadScreenStyles from '../styles/DownloadScreen.module.css';
import { Button } from '../components/Modal';
import { useDownloadFile } from '../hooks/folders';

const ShareFileDownloadScreen = () => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const { search } = useLocation();
  let data = search.replaceAll("'", '');
  const dataParams = new URLSearchParams(data);
  const id = dataParams.get('file');

  const config = { headers: { 'Content-Type': 'application/json' } };

  const [fileData, setFileData] = useState<any>({});
  const { downloadFile, downloadFileLoading } = useDownloadFile();

  const getFile = async () => {
    const { data } = await axios.get(`${BASE_URL}/fileExchange/${id}`, config);
    if (data && data.status === 'success') {
      setFileData(data.data.data);
    }
  };

  useEffect(() => {
    getFile().then(s => {});
    //eslint-disable-next-line
  }, []);

  const handleDownload = async () => {
    await downloadFile({
      contentKey: fileData.contentKey,
      passwordProtected: fileData.protected,
    });
  };

  return (
    <div className={DownloadScreenStyles.container}>
      <img src='/assets/logo.png' alt='Logo' className={DownloadScreenStyles.logo} />
      <svg className={DownloadScreenStyles.zipIcon}>
        <use xlinkHref={`/assets/sprite.svg#icon-zip`} />
      </svg>
      <div>
        <h1 className={DownloadScreenStyles.fileName}>{fileData.name}</h1>
        <p className={DownloadScreenStyles.date}>
          Uploaded on <span>{new Date(fileData.updatedAt).toLocaleString()}</span>
        </p>
        {/* <p className={DownloadScreenStyles.date}>Date Modified 2022 jan 22</p> */}
      </div>
      <Button label={downloadFileLoading ? 'Downloading File' : 'Download File'} onClick={handleDownload} />
    </div>
  );
};

export default ShareFileDownloadScreen;
