import toast from 'react-hot-toast';
import useApi from '../useApi';
import { IAddAppointmentReq, IAddAppointmentRes } from './index.type';

const useAddAppointment = () => {
  const utcTimeOffset = new Date().getTimezoneOffset();
  const { loading: addAppointmentLoading, makeApiCall } = useApi({ url: `/appointment/take-appointment?offset=${utcTimeOffset}`, method: 'POST' });

  const addAppointment = async (formData: IAddAppointmentReq) => {
    const { response, error } = await makeApiCall<IAddAppointmentReq, IAddAppointmentRes>(formData);
    if (response?.status) {
      toast.success(response.message);
      return response;
    }

    if (!response?.status && response?.message) {
      toast.error(response.message);
    }

    if (error) {
      toast.error('Something went wrong. Please try again later');
      return { status: false };
    }
  };

  return {
    addAppointmentLoading,
    addAppointment,
  };
};

export default useAddAppointment;
