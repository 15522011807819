import React from 'react';

import ModalForm from '../../../../components/Modal/ModalForm';
import ModalButtons from '../../../../components/Modal/ModalButtons';
import AddUserSkeleton from '../../../../Skeletons/AddUserSkeleton';

import classes from '../InsurancePolicies.module.css';
import TextBox from './TextBox';
import { inputPhoneMasking, unMasking } from '../../../../utils/validators';
import moment from 'moment';
import toast from 'react-hot-toast';
import { ISaveContactCareFormReq, IUser } from '../../../../hooks/users/index.type';
import { useSaveUserInsuranceForm } from '../../../../hooks/users';

interface EditContactCareFormProps {
  patient: IUser;
  handleEdit?: (save: string) => void;
  closeInsurancePoliciesModal?: React.MouseEventHandler<HTMLButtonElement>;
  contactCareForm: any;
}

interface Istates {
  patientCareList: {
    _id: string;
    name: string;
    phone: string;
    relationship: string;
  }[];
  homePhone: {
    value: string;
    error: boolean;
  };
  workPhone: {
    value: string;
    error: boolean;
  };
  cellPhone: {
    value: string;
    error: boolean;
  };
}

const EditContactCareForm: React.FC<EditContactCareFormProps> = ({ patient, handleEdit, closeInsurancePoliciesModal, contactCareForm }) => {
  const initialStates: Istates = {
    patientCareList: [
      { _id: '', name: '', relationship: '', phone: '' },
      { _id: '', name: '', relationship: '', phone: '' },
      { _id: '', name: '', relationship: '', phone: '' },
      { _id: '', name: '', relationship: '', phone: '' },
      { _id: '', name: '', relationship: '', phone: '' },
    ],
    homePhone: { value: '', error: false },
    workPhone: { value: '', error: false },
    cellPhone: { value: '', error: false },
  };

  const [state, setState] = React.useState<Istates>(initialStates);
  const { saveUserInsuranceData, saveUserInsuranceDataLoading } = useSaveUserInsuranceForm({ userId: patient._id, formType: 'contactCare' });

  React.useEffect(() => {
    if (Object.keys(contactCareForm?.data).length) {
      contactCareForm.data.patientCareList.map((item: any) => {
        return (item.phone = inputPhoneMasking(item.phone));
      });

      const storeData: Istates = {
        patientCareList: contactCareForm.data.patientCareList,
        homePhone: {
          value: inputPhoneMasking(contactCareForm.data.homePhone),
          error: false,
        },
        workPhone: {
          value: inputPhoneMasking(contactCareForm.data.workPhone),
          error: false,
        },
        cellPhone: {
          value: inputPhoneMasking(contactCareForm.data.cellPhone),
          error: false,
        },
      };
      if (5 - contactCareForm.data.patientCareList.length > 0) {
        [...Array(5 - contactCareForm.data.patientCareList.length)].map((star, index) => {
          index += 1;
          return storeData.patientCareList.push({
            _id: '',
            name: '',
            relationship: '',
            phone: '',
          });
        });
      }
      setState(storeData);
    } else {
      const patientCareList: Istates['patientCareList'] = [];
      [...Array(5)].map((star, index) => {
        index += 1;
        return patientCareList.push({
          _id: '',
          name: '',
          relationship: '',
          phone: '',
        });
      });
      const storeData: Istates = {
        patientCareList: patientCareList,
        homePhone: {
          value: '',
          error: false,
        },
        workPhone: {
          value: '',
          error: false,
        },
        cellPhone: {
          value: '',
          error: false,
        },
      };
      setState(storeData);
    }
  }, [contactCareForm]);

  const handleSaveForm = async () => {
    let containsContactPerson = true;
    state.patientCareList.forEach(item => {
      if (item.name && item.relationship && item.phone) {
        containsContactPerson = false;
      }
    });

    if (containsContactPerson) {
      toast.error('Please enter atleast one contact person details.');
    } else if (!state.homePhone.value && !state.workPhone.value && !state.cellPhone.value) {
      toast.error('Please enter atleast one phone number.');
      setState(prevVal => ({
        ...prevVal,
        homePhone: { ...prevVal.homePhone, error: true },
        workPhone: { ...prevVal.workPhone, error: true },
        cellPhone: { ...prevVal.cellPhone, error: true },
      }));
    } else {
      const isRedirected = Object.keys(contactCareForm.data).length && contactCareForm.data?.patientCareList.length > 0 ? false : true;
      const patientCareList: ISaveContactCareFormReq['patientCareList'] = [];

      state.patientCareList.forEach(item => {
        const pushData = {
          _id: isRedirected ? undefined : item._id,
          name: item.name,
          phone: unMasking(item.phone),
          relationship: item.relationship,
        };
        // item.phone = unMasking(item.phone);
        patientCareList.push(pushData);
      });

      const formData = {
        patientCareList: patientCareList,
        isHomePhone: state.homePhone.value ? true : false,
        homePhone: unMasking(state.homePhone.value),
        isCellPhone: state.cellPhone.value ? true : false,
        cellPhone: unMasking(state.cellPhone.value),
        isWorkPhone: state.workPhone.value ? true : false,
        workPhone: unMasking(state.workPhone.value),
      };
      const data = await saveUserInsuranceData(formData);

      if (data?.status && handleEdit) {
        handleEdit('save');
      }
    }
  };

  return (
    <React.Fragment>
      {contactCareForm.loading ? (
        <AddUserSkeleton />
      ) : (
        <div className={classes.form_content}>
          <div className={classes.form_heading}>
            <p className={classes.form_heading_content}>
              Patient's Name<span>{patient.name ? patient.name : '-'}</span>
            </p>
            <p className={classes.form_heading_content}>
              Patient's BirthDate
              <span>{patient.dob ? moment(patient.dob).format('MMM DD YYYY') : '-'}</span>
            </p>
            <p className={classes.form_heading_content}>
              Today's Date
              <span>{moment(Date.now()).format('MMM DD YYYY')}</span>
            </p>
          </div>

          <div className={classes.form_container}>
            <p className={classes.form_container_heading}>
              Who can we contact regarding your care and billing?
              <br />
              People with whom we can discuss your care, test results, and billing information:
            </p>

            <div className={classes.form_relations}>
              {[...Array(5)].map((star, index) => {
                index += 1;

                return (
                  <div key={index} className={classes.form_relation}>
                    <TextBox
                      parentClassName={classes.form_relation_text}
                      label='Name'
                      placeholder='Name'
                      value={state.patientCareList[index - 1].name}
                      onChange={event => {
                        const newState = { ...state };
                        newState.patientCareList[index - 1].name = event.target.value;
                        setState(newState);
                      }}
                    />
                    <TextBox
                      parentClassName={classes.form_relation_text}
                      label='Relationship'
                      placeholder='Relationship'
                      value={state.patientCareList[index - 1].relationship}
                      onChange={event => {
                        const newState = { ...state };
                        newState.patientCareList[index - 1].relationship = event.target.value;
                        setState(newState);
                      }}
                    />
                    <TextBox
                      parentClassName={classes.form_relation_text}
                      label='Phone'
                      placeholder='Phone'
                      value={state.patientCareList[index - 1].phone}
                      onChange={event => {
                        const newState = { ...state };
                        newState.patientCareList[index - 1].phone = inputPhoneMasking(event.target.value);
                        setState(newState);
                      }}
                    />
                  </div>
                );
              })}
            </div>
          </div>

          <div className={classes.form_container}>
            <p className={classes.form_container_heading}>Can we leave care, test results, and billing information on voicemail or answering machines listed below?</p>
            <div className={classes.form_numbers}>
              <TextBox
                parentClassName={classes.form_numbers_text}
                label='Home Phone:'
                placeholder='Home Phone'
                value={state.homePhone.value}
                // error={state.homePhone.error}
                onChange={event =>
                  setState(prevVal => ({
                    ...prevVal,
                    homePhone: { value: inputPhoneMasking(event.target.value), error: false },
                    workPhone: { ...prevVal.workPhone, error: false },
                    cellPhone: { ...prevVal.cellPhone, error: false },
                  }))
                }
              />
              <TextBox
                parentClassName={classes.form_numbers_text}
                label='Work Phone:'
                placeholder='Work Phone'
                value={state.workPhone.value}
                // error={state.homePhone.error}
                onChange={event =>
                  setState(prevVal => ({
                    ...prevVal,
                    homePhone: { ...prevVal.homePhone, error: false },
                    workPhone: { value: inputPhoneMasking(event.target.value), error: false },
                    cellPhone: { ...prevVal.cellPhone, error: false },
                  }))
                }
              />
              <TextBox
                parentClassName={classes.form_numbers_text}
                label='Cell Phone:'
                placeholder='Cell Phone'
                value={state.cellPhone.value}
                // error={state.homePhone.error}
                onChange={event =>
                  setState(prevVal => ({
                    ...prevVal,
                    homePhone: { ...prevVal.homePhone, error: false },
                    workPhone: { ...prevVal.workPhone, error: false },
                    cellPhone: { value: inputPhoneMasking(event.target.value), error: false },
                  }))
                }
              />
            </div>
          </div>
        </div>
      )}

      <ModalForm style={{ marginBottom: '2.5rem' }}>
        <ModalButtons
          submitButtonLabel='Save Contact Care Form'
          onSubmit={e => {
            e.preventDefault();
            handleSaveForm();
          }}
          submitButtonDisabled={saveUserInsuranceDataLoading}
          cancelButtonLabel='Cancel'
          onCancel={closeInsurancePoliciesModal}
        />
      </ModalForm>
    </React.Fragment>
  );
};

export default EditContactCareForm;
