import toast from 'react-hot-toast';
import useApi from '../useApi';
import { IUploadFileRes } from './index.type';

const useUploadFile = () => {
  const { loading: uploadFileLoading, makeApiCall } = useApi({
    url: `/fileExchange`,
    method: 'POST',
    headerContentType: 'multipart/form-data',
  });

  const uploadFile = async (formData: FormData) => {
    const { response, error } = await makeApiCall<FormData, IUploadFileRes>(formData);
    if (response?.status === 'success') {
      toast.success('File uploaded successfully!');
      return response;
    }

    if (error || response?.status === 'fail') {
      toast.error('Something went wrong uploading file. Please try again later');
      return { status: 'fail' };
    }
  };

  return {
    uploadFile,
    uploadFileLoading,
  };
};

export default useUploadFile;
