import React from 'react';
import { ModalForm, InputsSectionColumn } from '../components/Modal';
import InputBoxSkeleton from './InputBoxSkeleton';
import ModalButtonsSkeleton from './ModalButtonsSkeleton';
import SkeletonStyles from './Skeletons.module.css';

const ShareFileSkeleton = ({ fileProtected = false }) => {
  return (
    <ModalForm style={{ marginBottom: '2.5rem' }}>
      <div className={[SkeletonStyles.form_input__text, SkeletonStyles.skeleton].join(' ')} style={{ width: '10rem', marginBottom: '2rem' }} />
      <InputsSectionColumn style={{ width: '34rem' }}>
        {fileProtected ? (
          <InputBoxSkeleton style={{ width: '100%' }} />
        ) : (
          <div className='flexCol'>
            <div className={[SkeletonStyles.form_input__text, SkeletonStyles.skeleton].join(' ')} style={{ width: '30rem' }} />
            <div className={[SkeletonStyles.form_input__text, SkeletonStyles.skeleton].join(' ')} />
          </div>
        )}
      </InputsSectionColumn>

      <ModalButtonsSkeleton />
    </ModalForm>
  );
};

export default ShareFileSkeleton;
