import React, { Fragment, useState } from 'react';
import type { FC, PropsWithChildren } from 'react';
import TextBox from './TextBox.module.css';

type InputBoxProps = PropsWithChildren<{
  icon?: string;
  type?: string;
  value: string;
  label: string;
  locked?: boolean;
  disabled?: boolean;
  placeholder: string;
  errorMessage?: string;
  defaultValue?: string;
  style?: React.CSSProperties;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}>;

const InputBox: FC<InputBoxProps> = ({ type = 'text', placeholder, value, onChange, errorMessage, label, icon, style, disabled, defaultValue, locked }) => {
  const [inputType, setInputType] = useState(type);

  const handlePasswordView = () => {
    if (type === 'password') {
      if (inputType === 'text') setInputType('password');
      else setInputType('text');
    }
  };

  const getTodaysDate = () => {
    let today = new Date();
    let dd = today.getDate();
    let mm = String(today.getMonth() + 1).padStart(2, '0');
    let yyyy = today.getFullYear();

    return yyyy + '-' + mm + '-' + dd;
  };

  return (
    <div className={TextBox.form_input}>
      {label && (
        <label htmlFor={label} className={TextBox.form_input__label}>
          {label}
        </label>
      )}
      <div className={TextBox.form_input__container} style={style}>
        {icon && (
          <svg className={TextBox.form_input__icon}>
            <use xlinkHref={`/assets/sprite.svg#icon-${icon}`} />
          </svg>
        )}
        {!locked ? (
          <Fragment>
            <input
              type={inputType}
              id={label}
              disabled={disabled ? disabled : false}
              placeholder={placeholder}
              value={value}
              defaultValue={defaultValue}
              onChange={onChange}
              className={TextBox.form_input__box}
              max={type === 'date' ? getTodaysDate() : ''}
            />
            {type === 'password' && (
              <svg className={TextBox.form_input__icon} onClick={handlePasswordView}>
                <use xlinkHref={`/assets/sprite.svg#icon-${inputType === 'text' ? 'visibility_off' : 'visibility'}`} />
              </svg>
            )}
          </Fragment>
        ) : (
          <Fragment>
            <p className={TextBox.form_input__box} style={{ height: '1.5rem', lineHeight: '1', color: value ? 'var(--grey)' : 'var(--light-grey)' }}>
              {value ? value : placeholder}
            </p>
            <svg className={TextBox.form_input__icon}>
              <use xlinkHref={`/assets/sprite.svg#icon-lock`} />
            </svg>
          </Fragment>
        )}
      </div>
      {errorMessage && <p className={TextBox.form_input__error}>{errorMessage}</p>}
    </div>
  );
};

export default InputBox;
