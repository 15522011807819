import React from 'react';
import toast from 'react-hot-toast';
import useApi from '../useApi';
import { ICheckinRes } from './index.type';

const useAllCheckins = ({ pageNumber, clinicId }: { pageNumber: number; clinicId?: string }) => {
  const [checkinsData, setCheckinsData] = React.useState<ICheckinRes>();

  const utcTimeOffset = new Date().getTimezoneOffset();
  const url = clinicId ? `/appointment/todays-check-in-list?offset=${utcTimeOffset}` : `/checkin?page=${pageNumber}`;

  const { loading: checkinsDataLoading, makeApiCall } = useApi({
    url,
    method: 'GET',
  });

  const getAllCheckins = async () => {
    const { response, error } = await makeApiCall<undefined, ICheckinRes>();
    if (response?.status) {
      setCheckinsData(response);
    }

    if (!response?.status) {
      setCheckinsData(undefined);
    }

    if (error) toast.error('Something went wrong while fetching checkins data. Please try again later');
  };

  return {
    checkinsData,
    getAllCheckins,
    checkinsDataLoading,
  };
};

export default useAllCheckins;
