import React, { FC, PropsWithChildren, SetStateAction, useState } from "react";
import { IAdRule, IAdRules } from "../../hooks/adViews/index.type";
import { Modal, ModalButtons } from "../../components/Modal";
import ModalHeading from "../../components/Modal/ModalHeading";
import AdViewStyles from "../../styles/AdViewScreen.module.css";
import RulesSelector from "./RulesSelector";
import { generateUniqueId } from "./generateId";
import { DeleteRulesModalState } from "./DeleteRuleModal";
import {
	IActiveClinicRes,
	IPainLocationsRes,
} from "../../hooks/advertisements/index.type";

export type SelectRulesModalState = PropsWithChildren<{
	status: boolean;
	mode?: string;
}>;

type SelectRulesModalProps = PropsWithChildren<{
	selectRulesModal: SelectRulesModalState;
	rulesInfo: IAdRules;
	activeClinics?: IActiveClinicRes;
	painLocations?: IPainLocationsRes;
	onCloseModal: () => void;
	onSaveModal: (data: IAdRule[]) => void;
	setRulesInfo: React.Dispatch<React.SetStateAction<IAdRules>>;
	setDeleteRulesModal: React.Dispatch<SetStateAction<DeleteRulesModalState>>;
}>;

const SelectRulesModal: FC<SelectRulesModalProps> = (props) => {
	const [adRules, setAdRules] = useState<IAdRule[]>(
		props.rulesInfo.rulesList.rules || []
	);

	React.useEffect(() => {
		setAdRules(props.rulesInfo.rulesList.rules || []);
	}, [props.rulesInfo.rulesList.rules]);

	const handleAddRule = () => {
		const rule = {
			id: generateUniqueId(),
			field: "",
			operator: "Contains",
			value: "",
			valueList: [],
		};

		setAdRules((prevAdRules) => [...prevAdRules, rule]);
	};

	const setAdRule = (
		adRule: IAdRule,
		value: any,
		field: "field" | "operator" | "value"
	) => {
		setAdRules((prevAdRules) => {
			return prevAdRules.map((rule) => {
				if (rule.id === adRule.id) {
					if (field !== "value") {
						return { ...rule, [field]: value };
					} else {
						return {
							...rule,
							[field]: value,
						};
					}
				}

				return rule;
			});
		});
	};

	if (adRules.length === 0) {
		handleAddRule();
	}

	return (
		<Modal
			show={props.selectRulesModal.status}
			style={{ minWidth: "60vw", height: "80vh", overflow: "auto" }}
		>
			<ModalHeading heading={props.selectRulesModal.mode} />
			<div
				style={{
					margin: "0 2rem",
					marginBottom: "2.5rem",
					overflowY: "auto",
				}}
			>
				<div className={AdViewStyles.model_container}>
					<div className={AdViewStyles.rules_container}>
						{adRules && adRules.length !== 0 ? (
							adRules.map((rule) => {
								return (
									<RulesSelector
										key={rule.id}
										ruleData={rule}
										activeClinics={props.activeClinics}
										painLocations={props.painLocations}
										setAdRule={setAdRule}
										setDeleteRulesModal={
											props.setDeleteRulesModal
										}
									/>
								);
							})
						) : (
							<p style={{ fontSize: "1.2rem", color: "red" }}>
								No rules selected
							</p>
						)}
					</div>

					<div>
						<div
							className={AdViewStyles.add_rule_button}
							onClick={() => handleAddRule()}
						>
							Add Rule
						</div>
					</div>
				</div>

				<ModalButtons
					submitButtonLabel="Apply"
					onSubmit={() => props.onSaveModal(adRules)}
					cancelButtonLabel="Close"
					onCancel={() => props.onCloseModal()}
				/>
			</div>
		</Modal>
	);
};

export default SelectRulesModal;
