import React from 'react';
import { useSelector } from 'react-redux';
import { Content } from '../components';

import styles from '../styles/Checkin.module.css';
import { Link } from 'react-router-dom';
import { loginSliceValue } from '../redux/reducers/loginSlice';
import { useAddEditStatus, useAllCheckins, useAllStatus, useCheckInOut, useClinicStatus, useToggleStatus, useUpdateCheckinStatus } from '../hooks/checkins';
import { AllStatusModal, CheckInHistoryModal, CheckinTable } from '../pageComponents/Checkins';
import { IAllStatus } from '../hooks/checkins/index.type';

const CheckinsScreen = () => {
  const [loggedInUserRole, setLoggedInUserRole] = React.useState('');
  const [loggedInUserClinic, setLoggedInUserClinic] = React.useState('');
  const [showCheckInHistory, setShowCheckInHistory] = React.useState(false);
  const [statusModal, setStatusModal] = React.useState(false);
  const [inputBoxShow, setInputBoxShow] = React.useState(false);
  const [statusName, setStatusName] = React.useState({ value: '', error: '' });
  const [statusEditStatus, setStatusEditStatus] = React.useState<{ status: boolean; data?: IAllStatus }>({ status: false });
  const [toggleStatusData, setToggleStatusData] = React.useState<{ data?: IAllStatus }>({});

  const { addEditStatusLoading, addEditStatus } = useAddEditStatus({ id: statusEditStatus.data?._id });
  const { toggleStatusLoading, toggleStatus } = useToggleStatus({ id: toggleStatusData.data?._id });
  const { allStatusData, getAllStatus, allStatusDataLoading } = useAllStatus();
  const { checkInOutPatientLoading, checkInOutPatient } = useCheckInOut();
  const { statusData, getClinicStatus, statusDataLoading } = useClinicStatus();
  const { updateCheckinStatusLoading, updateCheckinStatus } = useUpdateCheckinStatus();
  const { checkinsData, getAllCheckins, checkinsDataLoading } = useAllCheckins({ pageNumber: 1, clinicId: loggedInUserClinic });
  const { userDetails } = useSelector(loginSliceValue);

  React.useEffect(() => {
    if (userDetails.data?.user) {
      setLoggedInUserRole(userDetails.data?.user.role);

      if (userDetails.data?.user.role === 'clinic') {
        setLoggedInUserClinic(userDetails.data?.user._id);
      } else if (userDetails.data?.user.role === 'manager' || userDetails.data?.user.role === 'receptionist') {
        setLoggedInUserClinic(userDetails.data?.user.clinic._id);
      }
    }
  }, [userDetails]);

  React.useEffect(() => {
    if (loggedInUserClinic) {
      getAllCheckins();
      getClinicStatus();
    }
    // eslint-disable-next-line
  }, [loggedInUserClinic]);

  React.useEffect(() => {
    if (statusEditStatus.status && statusEditStatus.data?._id) {
      setInputBoxShow(true);
      setStatusName({ value: statusEditStatus.data.title, error: '' });
      // setStatusValue({ value: statusEditStatus.data.value, error: "" });
    }
  }, [statusEditStatus]);

  React.useEffect(() => {
    if (statusModal) getAllStatus();
    // eslint-disable-next-line
  }, [statusModal]);

  const handleUpdateStatus = async (appointmentId: string, statusId: string) => {
    const formData = { appointmentId, statusId };
    const data = await updateCheckinStatus(formData);
    if (data?.status) getAllCheckins();
  };

  const handleCheckInOut = async (appointmentId: string, checkStatus: 'check-in' | 'check-out') => {
    const formData = { appointmentId, checkStatus };
    const data = await checkInOutPatient(formData);
    if (data?.status) getAllCheckins();
  };

  const handleCancelAddStatus = () => {
    setInputBoxShow(false);
    setStatusName({ value: '', error: '' });
    setStatusEditStatus({ status: false });
  };

  const handleCreateStatus = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (statusName.value === '' || statusName.value.trim() === '') {
      setStatusName(prevData => ({
        ...prevData,
        error: 'Please enter status name',
      }));
    } else {
      const formData = { title: statusName.value };
      const data = await addEditStatus(formData);
      if (data?.status === 'success') {
        getAllStatus();
        getClinicStatus();
        handleCancelAddStatus();
      }
    }
  };

  React.useEffect(() => {
    if (toggleStatusData.data?._id) {
      handleToggleStatus(!toggleStatusData.data.active);
    }
    // eslint-disable-next-line
  }, [toggleStatusData]);

  const handleToggleStatus = async (status: boolean) => {
    const data = await toggleStatus({ active: status });
    if (data?.status === 'success') {
      getAllStatus();
      getClinicStatus();
      handleCancelAddStatus();
    }
  };

  return (
    <Content currentMenu='check-in' headerTitle='Patient Check-ins' addBtn={true} addBtnTitle='Check-in Status' addBtnClick={() => setStatusModal(true)}>
      <div className={styles.header_links}>
        <div className={styles.header_links_box}>
          <button onClick={() => setShowCheckInHistory(true)} className={styles.header_link}>
            View check-in history
          </button>
          <Link to='/check-in-lobby' className={styles.header_link}>
            Check-in lobby
          </Link>
        </div>
      </div>

      <CheckinTable
        loading={checkinsDataLoading || statusDataLoading || updateCheckinStatusLoading || checkInOutPatientLoading}
        loggedInUserRole={loggedInUserRole}
        checkIns={checkinsData}
        statusData={statusData}
        handleUpdateStatus={handleUpdateStatus}
        handleCheckInOut={handleCheckInOut}
      />

      {showCheckInHistory && <CheckInHistoryModal showCheckInHistory={showCheckInHistory} statusData={statusData} onCloseModal={() => setShowCheckInHistory(false)} />}

      {statusModal && (
        <AllStatusModal
          loading={allStatusDataLoading || addEditStatusLoading || toggleStatusLoading}
          allStatusData={allStatusData}
          statusModal={statusModal}
          inputBoxShow={inputBoxShow}
          setInputBoxShow={setInputBoxShow}
          statusName={statusName}
          setStatusName={setStatusName}
          statusEditStatus={statusEditStatus}
          setStatusEditStatus={setStatusEditStatus}
          onCloseModal={() => setStatusModal(false)}
          handleCancelAddStatus={handleCancelAddStatus}
          handleCreateStatus={handleCreateStatus}
          setToggleStatusData={setToggleStatusData}
        />
      )}
    </Content>
  );
};

export default CheckinsScreen;
