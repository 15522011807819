import React, { useEffect, useState } from 'react';
import SkeletonStyles from './Skeletons.module.css';

const RecentFileUploadsSkeleton = ({ items = 5 }) => {
  const [fileUploads, setFileUploads] = useState([]);

  useEffect(() => {
    const data = [];
    for (let i = 0; i < items; i++) {
      data.push(i);
    }
    setFileUploads(data);
  }, [items]);

  return (
    <React.Fragment>
      {fileUploads &&
        fileUploads.length > 0 &&
        fileUploads.map(upload => <div key={upload} className={[SkeletonStyles.fileUpload, SkeletonStyles.skeleton].join(' ')} />)}
    </React.Fragment>
  );
};

export default RecentFileUploadsSkeleton;
