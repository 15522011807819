import toast from 'react-hot-toast';
import useApi from '../useApi';
import { ICopyFileReq, ICopyFileRes } from './index.type';

const useCopyFile = () => {
  const { loading: copyFileLoading, makeApiCall } = useApi({
    url: `/fileExchange/copy`,
    method: 'POST',
  });

  const copyFile = async (formData: ICopyFileReq) => {
    const { response, error } = await makeApiCall<ICopyFileReq, ICopyFileRes>(formData);
    if (response?.status === 'success') {
      toast.success('File copied!');
      return response;
    }

    if (response?.status === 'fail') return { status: response?.status };
    if (error) toast.error('Something went wrong copying file. Please try again later');
  };

  return {
    copyFile,
    copyFileLoading,
  };
};

export default useCopyFile;
