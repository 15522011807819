import React from 'react';
import toast from 'react-hot-toast';
import useApi from '../useApi';
import { IAllProvidersRes } from './index.type';

interface PatientsData {
  label: string;
  value: string;
}

const useAllProviders = () => {
  const [allProvidersData, setAllProvidersData] = React.useState<PatientsData[]>();

  const { loading: allProvidersLoading, makeApiCall } = useApi({
    url: `/doctors/get-doctor-list`,
    method: 'GET',
  });

  const getAllProviders = async () => {
    const { response, error } = await makeApiCall<undefined, IAllProvidersRes>();
    if (response?.status) {
      const providerNamesDataTemp: PatientsData[] = [];

      response.data.result.length > 0 &&
        response.data.result.forEach(item => {
          providerNamesDataTemp.push({ label: item.name, value: item._id });
        });
      setAllProvidersData(providerNamesDataTemp);
    }

    if (!response?.status) {
      setAllProvidersData(undefined);
    }

    if (error) toast.error('Something went wrong while fetching provider data. Please try again later');
  };

  React.useEffect(() => {
    getAllProviders();
    // eslint-disable-next-line
  }, []);

  return {
    allProvidersData,
    allProvidersLoading,
  };
};

export default useAllProviders;
