import React from 'react';
import toast from 'react-hot-toast';
import useApi from '../useApi';
import { IFeedbacksRes } from './index.type';

const useAllFeedbacks = ({ clinicId, role }: { role?: string; clinicId: string }) => {
  const [feedbackData, setFeedbackData] = React.useState<IFeedbacksRes>();

  const url = role === 'admin' && clinicId ? `/feedback/clinic?clinicId=${clinicId}` : role === 'admin' && !clinicId ? `/feedback/clinic` : `/feedback`;

  const { loading: feedbackDataLoading, makeApiCall } = useApi({
    url,
    method: 'GET',
  });

  const getFeedbackData = async () => {
    const { response, error } = await makeApiCall<undefined, IFeedbacksRes>();
    if (response?.status === 'success') {
      setFeedbackData(response);
    }

    if (response?.status === 'fail') {
      setFeedbackData(undefined);
      toast.error('Something went wrong while fetching clinic data. Please try again later');
    }

    if (error) toast.error(error.msg);
  };

  React.useEffect(() => {
    getFeedbackData();
    // eslint-disable-next-line
  }, [clinicId]);

  return {
    feedbackData,
    getFeedbackData,
    feedbackDataLoading,
  };
};

export default useAllFeedbacks;
