import React from 'react';
import type { FC, PropsWithChildren } from 'react';
import { InputsSection, InputBox, ModalButtons, ModalForm, Modal, TextArea, SelectBox, SelectMultiple } from '../../components/Modal';
import ModalHeading from '../../components/Modal/ModalHeading';
import AddUserSkeleton from '../../Skeletons/AddUserSkeleton';

import { inputPhoneMasking } from '../../utils/validators';
import { IUser } from '../../hooks/users/index.type';
import { useSelector } from 'react-redux';
import { loginSliceValue } from '../../redux/reducers/loginSlice';
import { useGetAllFolders } from '../../hooks/folders';
import { IClinicsRes } from '../../hooks/clinic/index.type';

export type AddEditModalState = PropsWithChildren<{
  status: boolean;
  data?: IUser;
}>;
export type IaddEditModalStates = PropsWithChildren<{
  name: { value: string; error: string };
  email: { value: string; error: string };
  phone: { value: string; error: string };
  address: { value: string; error: string };
  password: { value: string; error: string };
  role: { value: string; error: string };
  clinic: { value: string; error: string };
  userFolders: { value: string[]; error: string };
}>;

type AddEditModalProps = PropsWithChildren<{
  loading: boolean;
  clinicsData?: IClinicsRes;
  addEditModal: AddEditModalState;
  onCloseModal?: () => void;
  handleAddEdit?: (event: React.FormEvent<HTMLFormElement>) => void;
  addEditModalStates: IaddEditModalStates;
  setAddEditModalStates: React.Dispatch<React.SetStateAction<IaddEditModalStates>>;
}>;

const AddEditModal: FC<AddEditModalProps> = props => {
  const { userDetails } = useSelector(loginSliceValue);
  const { foldersData, foldersDataLoading } = useGetAllFolders({ clinicId: props.addEditModalStates.clinic.value });

  const handleAddFolder = (id: string) => {
    if (!props.addEditModalStates.userFolders.value.includes(id)) {
      props.setAddEditModalStates(prevVal => ({ ...prevVal, userFolders: { value: [...prevVal.userFolders.value, id], error: '' } }));
    } else {
      props.setAddEditModalStates(prevVal => ({ ...prevVal, userFolders: { value: prevVal.userFolders.value.filter(d => d !== id), error: '' } }));
    }
  };

  return (
    <Modal show={props.addEditModal.status}>
      <ModalHeading heading={props.addEditModal.data && props.addEditModal.data._id ? 'Update User Data' : 'Add New User'} />
      {props.loading ? (
        <AddUserSkeleton />
      ) : (
        <ModalForm style={{ marginBottom: '2.5rem' }} onSubmit={props.handleAddEdit}>
          <InputsSection>
            <InputBox
              label='User Full Name'
              placeholder='eg, John Doe'
              value={props.addEditModalStates.name.value}
              onChange={event => {
                props.setAddEditModalStates(prevVal => ({ ...prevVal, name: { value: event.target.value, error: '' } }));
              }}
              errorMessage={props.addEditModalStates.name.error}
            />
            <InputBox
              label='User Email'
              placeholder='eg, johndoe@example.com'
              value={props.addEditModalStates.email.value}
              onChange={event => {
                props.setAddEditModalStates(prevVal => ({ ...prevVal, email: { value: event.target.value, error: '' } }));
              }}
              errorMessage={props.addEditModalStates.email.error}
            />
          </InputsSection>

          <InputsSection>
            <InputBox
              label='User Phone'
              placeholder='eg, 123-456-789'
              value={props.addEditModalStates.phone.value}
              onChange={event => {
                props.setAddEditModalStates(prevVal => ({ ...prevVal, phone: { value: inputPhoneMasking(event.target.value), error: '' } }));
              }}
              errorMessage={props.addEditModalStates.phone.error}
            />

            {props.addEditModalStates.role.value !== 'patient' && (
              <SelectBox
                label='User Role'
                value={props.addEditModalStates.role.value}
                onChange={event => {
                  props.setAddEditModalStates(prevVal => ({ ...prevVal, role: { value: event.target.value, error: '' }, userFolders: { value: [], error: '' } }));
                }}
                errorMessage={props.addEditModalStates.role.error}
              >
                <option value=''>Select User Role</option>
                {(userDetails.data?.user.role === 'admin' || userDetails.data?.user.role === 'clinic' || userDetails.data?.user.role === 'manager') && (
                  <React.Fragment>
                    <option value='manager'>Manager</option>
                    <option value='receptionist'>Receptionist</option>
                  </React.Fragment>
                )}
                {(userDetails.data?.user.role === 'admin' || userDetails.data?.user.role === 'clinic' || userDetails.data?.user.role === 'adAdmin') && (
                  <React.Fragment>
                    <option value='adAdmin'>Ad & File Admin</option>
                    <option value='adUser'>Ad User</option>
                  </React.Fragment>
                )}
                <option value='fileAdmin'>File Admin</option>
                <option value='fileUser'>File User</option>
              </SelectBox>
            )}
          </InputsSection>

          <InputsSection>
            <TextArea
              label='User Address'
              placeholder='692 SW. 53rd St. Irwin, PA 15642'
              rows={6}
              value={props.addEditModalStates.address.value}
              onChange={event => {
                props.setAddEditModalStates(prevVal => ({ ...prevVal, address: { value: event.target.value, error: '' } }));
              }}
              errorMessage={props.addEditModalStates.address.error}
            />
            <div className='flexCol gap-1'>
              {!props.addEditModal.data?._id && (
                <InputBox
                  type='password'
                  label='Password'
                  placeholder='Set Password'
                  value={props.addEditModalStates.password.value}
                  onChange={event => {
                    props.setAddEditModalStates(prevVal => ({ ...prevVal, password: { value: event.target.value, error: '' } }));
                  }}
                  errorMessage={props.addEditModalStates.password.error}
                />
              )}
              {userDetails.data?.user.role === 'admin' && (
                <SelectBox
                  label='Select Clinic'
                  value={props.addEditModalStates.clinic.value}
                  inputContainerStyle={{ width: '25rem' }}
                  onChange={event => {
                    props.setAddEditModalStates(prevVal => ({ ...prevVal, clinic: { value: event.target.value, error: '' } }));
                  }}
                  errorMessage={props.addEditModalStates.clinic.error}
                >
                  <option value=''>Select Clinic</option>
                  {props.clinicsData?.data.data.map(clinic => (
                    <option key={clinic._id} value={clinic._id}>
                      {clinic.name}
                    </option>
                  ))}
                </SelectBox>
              )}
            </div>
          </InputsSection>

          {props.addEditModalStates.clinic.value &&
            props.clinicsData?.data.data
              .filter(clin => clin._id === props.addEditModalStates.clinic.value)
              .map(clinic =>
                clinic.modules.fileSharing &&
                (props.addEditModalStates.role.value === 'receptionist' ||
                  props.addEditModalStates.role.value === 'fileUser' ||
                  props.addEditModalStates.role.value === 'adUser' ||
                  props.addEditModalStates.role.value === 'patient') ? (
                  <InputsSection key={clinic._id}>
                    <div
                      style={{
                        width: '100%',
                        maxHeight: '10rem',
                        overflow: 'auto',
                        paddingBottom: '.5rem',
                      }}
                    >
                      <SelectMultiple
                        label='Select Folders'
                        handleAddFolder={handleAddFolder}
                        userFolders={props.addEditModalStates.userFolders.value}
                        loading={foldersDataLoading}
                        loadingText='Loading folders...'
                        noDataText='No folders found'
                        errorMessage={props.addEditModalStates.userFolders.error}
                        data={foldersData?.data.data ? foldersData?.data.data : []}
                      />
                    </div>
                  </InputsSection>
                ) : null
              )}

          {/* {props.addEditModalStates.clinic.value && props.addEditModalStates.role.value === 'fileUser' ? (
            <InputsSection>
              <div
                style={{
                  width: '100%',
                  maxHeight: '10rem',
                  overflow: 'auto',
                  paddingBottom: '.5rem',
                }}
              >
                <SelectMultiple
                  label='Select Folders'
                  handleAddFolder={handleAddFolder}
                  userFolders={props.addEditModalStates.userFolders.value}
                  loading={foldersDataLoading}
                  loadingText='Loading folders...'
                  noDataText='No folders found'
                  errorMessage={props.addEditModalStates.userFolders.error}
                  data={foldersData?.data.data ? foldersData?.data.data : []}
                />
              </div>
            </InputsSection>
          ) : null} */}

          <ModalButtons
            submitButtonLabel={props.addEditModal.data && props.addEditModal.data._id ? 'Update User' : 'Add User'}
            cancelButtonLabel='Cancel'
            onCancel={props.onCloseModal}
          />
        </ModalForm>
      )}
    </Modal>
  );
};

export default AddEditModal;
