import React, { Fragment } from 'react';
import { Chart } from 'react-chartjs-2';
import classes from '../../../styles/HomeScreen.module.css';

const options = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      beginAtZero: true,
      display: true,
      grid: {
        drawOnChartArea: false,
      },
      ticks: {
        font: {
          size: 8,
        },
      },
    },
    y: {
      display: false,
    },
  },
  plugins: {
    legend: {
      display: false,
      position: 'top',
    },
    title: {
      display: false,
      text: 'Re-admission rate in 2022',
    },
  },
};

// const labels = ['Medicare', 'BlueCross...', 'Other', 'UnitedHealth..', 'Medicaid'];

// const data = {
//   labels,
//   datasets: [
//     {
//       type: 'bar',
//       label: 'Insurance',
//       data: [654, 455, 1727, 268, 174],
//       // borderColor: 'rgba(53, 162, 235)',
//       borderWidth: 0,
//       borderRadius: 5,
//       tension: 0.5,
//       pointBackgroundColor: 'rgba(1, 194, 255, 0.5)',
//     },
//   ],
// };

function createGradient(ctx, area) {
  const colorStart = 'rgba(1, 196, 255, 0.8)';
  const colorMid = 'rgba(0, 113, 174, 0.8)';
  const colorEnd = 'rgba(0, 113, 174, .8';

  // const gradient = ctx.createLinearGradient(0, area.bottom, 0, area.top);
  const gradient = ctx.createLinearGradient(area.left, 0, area.right, 0);

  gradient.addColorStop(0, colorStart);
  gradient.addColorStop(0.5, colorMid);
  gradient.addColorStop(1, colorEnd);

  return gradient;
}

const Chart5 = ({ data: apiData, setSelectedDataModal }) => {
  // console.log(apiData);

  const chartRef = React.useRef(null);
  const [insuranceData, setInsuranceData] = React.useState({
    labels: [],
    count: [],
  });
  const [chartData, setChartData] = React.useState({
    datasets: [],
  });

  React.useEffect(() => {
    const labelsArray = [];
    const countsArray = [];

    if (apiData && apiData.insuranceData && apiData.insuranceData.result && apiData.insuranceData.result.data && apiData.insuranceData.result.data.length > 0) {
      apiData.insuranceData.result.data.forEach(element => {
        labelsArray.push(element.label);
        countsArray.push(element.count);
      });
    }
    setInsuranceData({ labels: labelsArray, count: countsArray });
  }, [apiData]);

  React.useEffect(() => {
    const chart = chartRef.current;

    if (!chart) {
      return;
    }

    const chartData = {
      labels: insuranceData.labels,
      datasets: [
        {
          type: 'bar',
          label: 'Insurance',
          data: insuranceData.count,
          // borderColor: 'rgba(53, 162, 235)',
          borderWidth: 0,
          borderRadius: 5,
          tension: 0.5,
          pointBackgroundColor: 'rgba(1, 194, 255, 0.5)',
          backgroundColor: createGradient(chart.ctx, chart.chartArea),
        },
      ],
    };

    setChartData(chartData);
  }, [apiData, insuranceData]);

  const numberWithCommas = x => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  return (
    <div className={classes.chart5}>
      <div className={classes.modal_heading_inputs} style={{ justifyContent: 'space-between' }}>
        <p className={classes.chart4_heading}>Top Insurances</p>
        {apiData && apiData.insuranceData && apiData.insuranceData.result && (
          <div className={classes.modal_heading_input_selects}>
            <button
              className={classes.modal_heading_input_btn2}
              onClick={() => {
                setSelectedDataModal({
                  status: true,
                  title: 'Insurance Details',
                  data: {
                    monthly: {
                      label: 'Insurance',
                      data: insuranceData.count,
                      totalAssessmentsThisMonth: apiData.insuranceData.result.total,
                      // data: [ 268, 174, 95, 654, 63, 455, 20, 1727, 27 ],
                      // totalAssessmentsThisMonth: 3483,
                      totalAssessmentsTtile: 'Total Insurance this month',
                      labels: insuranceData.labels,
                      // labels: [
                      // 	'UnitedHealthcare',
                      // 	'Medicaid',
                      // 	'Humana',
                      // 	'Medicare',
                      // 	'Cigna',
                      // 	'BlueCrossBlueShield',
                      // 	'Aetna',
                      // 	'Other',
                      // 	'Iamuninsured'
                      // ]
                    },
                    yearly: {
                      label: 'Toal Insurance',
                      data: [2571, 3520, 3124, 2929, 2426, 3067, 3189, 3358, 3251, 3483],
                      // data2Label: 'Total Insurances',
                      // data2: [ 1500, 1800, 2412, 1478, 1358, 1254, 2084, 1454, 2471, 2014 ],
                      labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
                    },
                  },
                });
              }}
            >
              Details
            </button>
          </div>
        )}
      </div>
      <div className={classes.chart4_data}>
        <p className={classes.chart4_data_number + ' ' + classes.grad_color}>
          {apiData && apiData.insuranceData && apiData.insuranceData.result ? (
            <Fragment>
              {numberWithCommas(apiData.insuranceData.result.total)} <span className={classes.chart4_active}>this month</span>
            </Fragment>
          ) : (
            '...'
          )}
        </p>
      </div>
      <div style={{ width: '100%', height: '8rem' }}>
        <Chart ref={chartRef} type='bar' data={chartData} options={options} />
      </div>

      {/* <div className={classes.chart5_stats}>
				<div className={classes.chart5_stat}>
					<p className={classes.chart5_stat_data}>
						6664
						<span>Inpatients</span>
					</p>
				</div>
				<div className={classes.chart5_stat}>
					<p className={classes.chart5_stat_data}>
						9709
						<span>Outpatients</span>
					</p>
				</div>
			</div> */}
    </div>
  );
};

export default Chart5;
