import React from 'react';
import toast from 'react-hot-toast';
import useApi from '../useApi';
import { ICampaignImageWiseDataReq, ICampaignImageWiseDataRes } from './index.type';

const useGetCampaignImageWiseData = () => {
  const [campaignImageData, setCampaignImageData] = React.useState<ICampaignImageWiseDataRes>();

  const { loading: getCampaignImageLoading, makeApiCall } = useApi({
    url: `/advertise/campaign-image-wise?offset=${new Date().getTimezoneOffset()}`,
    method: 'POST',
  });

  const getCampaignImageData = async (formData: ICampaignImageWiseDataReq) => {
    const { response, error } = await makeApiCall<ICampaignImageWiseDataReq, ICampaignImageWiseDataRes>(formData);
    if (response?.status) {
      setCampaignImageData(response);
    }

    if (error || !response?.status) {
      setCampaignImageData(undefined);
      toast.error('Something went wrong while fetching campaign image wise data. Please try again later');
    }
  };

  return {
    campaignImageData,
    getCampaignImageData,
    getCampaignImageLoading,
  };
};

export default useGetCampaignImageWiseData;
