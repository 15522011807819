import React from 'react';
import { useSelector } from 'react-redux';
import { loginSliceValue } from '../redux/reducers/loginSlice';
import { useAllCheckins, useClinicStatus } from '../hooks/checkins';
import { CustomTableRow, Table, TableBody, TableHead, TableNoContent, TableRowTitle } from '../components/Table';
import { checkinLobbyTableHeadData } from '../hooks/checkins/staticData';
import TableSkeleton from '../Skeletons/TableSkeleton';
import { getEncryptedName, tConvert } from '../pageComponents/Checkins/CheckinTable';
import { ICheckin } from '../hooks/checkins/index.type';

const CheckinLobby = () => {
  const [loggedInUserClinic, setLoggedInUserClinic] = React.useState('');

  const { statusData, getClinicStatus, statusDataLoading } = useClinicStatus();
  const { checkinsData, getAllCheckins, checkinsDataLoading } = useAllCheckins({ pageNumber: 1, clinicId: loggedInUserClinic });
  const { userDetails } = useSelector(loginSliceValue);
  React.useEffect(() => {
    if (userDetails.data?.user) {
      if (userDetails.data?.user.role === 'clinic') {
        setLoggedInUserClinic(userDetails.data?.user._id);
      } else if (userDetails.data?.user.role === 'manager' || userDetails.data?.user.role === 'receptionist') {
        setLoggedInUserClinic(userDetails.data?.user.clinic._id);
      }
    }
  }, [userDetails]);

  React.useEffect(() => {
    if (loggedInUserClinic) {
      getAllCheckins();
      getClinicStatus();
    }
    // eslint-disable-next-line
  }, [loggedInUserClinic]);

  const secondsToHms = (n: number) => {
    var num = n;
    var hours = num / 60;
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);
    return rhours > 0 ? rhours + ' hour(s) and ' + rminutes + ' minute(s).' : rminutes + ' minute(s).';
  };

  return (
    <Table>
      <TableHead data={checkinLobbyTableHeadData} />
      <TableBody>
        <CustomTableRow>
          {statusDataLoading || checkinsDataLoading ? (
            <TableSkeleton rows={7} columns={10} />
          ) : checkinsData && checkinsData.data.result.length > 0 ? (
            checkinsData.data.result.map((checkIn: ICheckin, index: number) => (
              <tr key={checkIn._id}>
                <td></td>
                <TableRowTitle>{checkIn.user ? getEncryptedName(checkIn.user.name) : ''}</TableRowTitle>
                <td>{checkIn.doctor.name}</td>
                <td>
                  {checkIn.appointmentDateString ? checkIn.appointmentDateString : <span>NA</span>} &nbsp;
                  {tConvert(checkIn.appointmentTime)}
                </td>
                <td>{checkIn.checkInDateString ? checkIn.checkInDateString : '--'}</td>
                <td>{checkIn.checkOutDateString ? checkIn.checkOutDateString : !checkIn.isCheckedIn && !checkIn.isCheckedOut && <span>NA</span>}</td>
                <td>
                  {checkIn.status ? (
                    statusData &&
                    statusData.data &&
                    statusData.data.result.length > 0 &&
                    statusData.data.result.map(item => item._id === checkIn.status && <span key={item._id}>{item.title}</span>)
                  ) : (
                    <span>NA</span>
                  )}
                </td>
                <td>{checkIn.isCheckedOut ? secondsToHms(checkIn.estimatedDuration) : <span>--</span>}</td>
              </tr>
            ))
          ) : (
            <TableNoContent colSpan={checkinLobbyTableHeadData.length} message='No data found' />
          )}
        </CustomTableRow>
      </TableBody>
    </Table>
  );
};

export default CheckinLobby;
