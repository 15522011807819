import React from 'react';
import toast from 'react-hot-toast';
import useApi from '../useApi';
import { IProviderRes } from './index.type';
import { useSelector } from 'react-redux';
import { loginSliceValue } from '../../redux/reducers/loginSlice';

const useAllProviders = () => {
  const [providersData, setProvidersData] = React.useState<IProviderRes>();
  const { userDetails } = useSelector(loginSliceValue);

  const id = userDetails.data?.user.role === 'clinic' || userDetails.data?.user.role === 'admin' ? userDetails.data?.user._id : userDetails.data?.user.clinic._id;

  const { loading: providersDataLoading, makeApiCall } = useApi({
    url: `/doctors/get-doctor-by-clinic/${id}`,
    method: 'GET',
  });

  const getprovidersData = async () => {
    const { response, error } = await makeApiCall<undefined, IProviderRes>();
    if (response?.status) {
      setProvidersData(response);
    }

    if (!response?.status) {
      setProvidersData(undefined);
    }

    if (error) toast.error('Something went wrong while fetching providers data. Please try again later');
  };

  return {
    providersData,
    getprovidersData,
    providersDataLoading,
  };
};

export default useAllProviders;
