import type { FC, PropsWithChildren } from 'react';
import { InputsSection, InputBox, ModalButtons, ModalForm, Modal, TextArea, SelectBox } from '../../components/Modal';
import ModalHeading from '../../components/Modal/ModalHeading';
import AddUserSkeleton from '../../Skeletons/AddUserSkeleton';

import { IProvider } from '../../hooks/provider/index.type';
import { inputPhoneMasking } from '../../utils/validators';

export type AddEditModalState = PropsWithChildren<{
  status: boolean;
  data?: IProvider;
}>;
export type IaddEditModalStates = PropsWithChildren<{
  name: { value: string; error: string };
  email: { value: string; error: string };
  phone: { value: string; error: string };
  speciality: { value: string; error: string };
  about: { value: string; error: string };
  address: { value: string; error: string };
}>;

type AddEditModalProps = PropsWithChildren<{
  loading: boolean;
  addEditModal: AddEditModalState;
  onCloseModal?: () => void;
  handleAddEdit?: (event: React.FormEvent<HTMLFormElement>) => void;
  addEditModalStates: IaddEditModalStates;
  setAddEditModalStates: React.Dispatch<React.SetStateAction<IaddEditModalStates>>;
}>;

const AddEditModal: FC<AddEditModalProps> = props => {
  return (
    <Modal show={props.addEditModal.status}>
      <ModalHeading heading={props.addEditModal.data && props.addEditModal.data._id ? 'Update Provider Data' : 'Add New Provider'} />
      {props.loading ? (
        <AddUserSkeleton />
      ) : (
        <ModalForm style={{ marginBottom: '2.5rem' }} onSubmit={props.handleAddEdit}>
          <InputsSection>
            <InputBox
              label='Provider Name'
              placeholder='eg, Dr. Stephen Fern'
              value={props.addEditModalStates.name.value}
              onChange={event => {
                props.setAddEditModalStates(prevVal => ({ ...prevVal, name: { value: event.target.value, error: '' } }));
              }}
              errorMessage={props.addEditModalStates.name.error}
            />
            <InputBox
              label='Provider Email'
              placeholder='eg, johnclinic@example.com'
              value={props.addEditModalStates.email.value}
              onChange={event => {
                props.setAddEditModalStates(prevVal => ({ ...prevVal, email: { value: event.target.value, error: '' } }));
              }}
              errorMessage={props.addEditModalStates.email.error}
            />
          </InputsSection>

          <InputsSection>
            <InputBox
              label='Provider Phone'
              placeholder='eg, 123-456-789'
              value={props.addEditModalStates.phone.value}
              onChange={event => {
                props.setAddEditModalStates(prevVal => ({ ...prevVal, phone: { value: inputPhoneMasking(event.target.value), error: '' } }));
              }}
              errorMessage={props.addEditModalStates.phone.error}
            />

            <SelectBox
              label='Provider Speciality'
              value={props.addEditModalStates.speciality.value}
              onChange={event => {
                props.setAddEditModalStates(prevVal => ({ ...prevVal, speciality: { value: event.target.value, error: '' } }));
              }}
              errorMessage={props.addEditModalStates.speciality.error}
            >
              <option value=''>Select Speciality</option>
              <option value='general_physician'>General Physician</option>
              <option value='psychologist'>Psychologist</option>
              <option value='heart_surgeon'>Heart Surgeon</option>
              <option value='cardio'>Cardio</option>
              <option value='ortho'>Ortho</option>
            </SelectBox>
          </InputsSection>

          <InputsSection>
            <TextArea
              label='About Provider'
              placeholder='Write few lines about provider'
              value={props.addEditModalStates.about.value}
              onChange={event => {
                props.setAddEditModalStates(prevVal => ({ ...prevVal, about: { value: event.target.value, error: '' } }));
              }}
              errorMessage={props.addEditModalStates.about.error}
            />
            <TextArea
              label='Provider Address'
              placeholder='692 SW. 53rd St. Irwin, PA 15642'
              value={props.addEditModalStates.address.value}
              onChange={event => {
                props.setAddEditModalStates(prevVal => ({ ...prevVal, address: { value: event.target.value, error: '' } }));
              }}
              errorMessage={props.addEditModalStates.address.error}
            />
          </InputsSection>

          <ModalButtons
            submitButtonLabel={props.addEditModal.data && props.addEditModal.data._id ? 'Update Provider' : 'Add Provider'}
            cancelButtonLabel='Cancel'
            onCancel={props.onCloseModal}
          />
        </ModalForm>
      )}
    </Modal>
  );
};

export default AddEditModal;
