import { FC, Fragment } from "react";
import AdViewStyles from "../../styles/AdViewScreen.module.css";
import { IImageSrcList } from "../../hooks/advertisements/index.type";
import AdCampaignsStyles from "../../styles/AdCampaigns.module.css";

type GeneratedAdProps = {
	data?: IImageSrcList;
};

const GeneratedAd: FC<GeneratedAdProps> = (props) => {
	const dateGenerated = new Date();
	let formattedDate = dateGenerated.toLocaleString();

	return (
		<Fragment>
			{props.data && (
				<div className={AdViewStyles.generated_ad_card}>
					<img
						src={
							process.env.REACT_APP_BASE_ASSET_URL +
							props.data?.imageUrl
						}
						alt={props.data?.label}
					/>
					<p className={AdViewStyles.generated_ad_title}>
						{props.data?.imageName || props.data?.label}
					</p>
					<div
						className={AdCampaignsStyles.chips}
						style={{ paddingLeft: "1rem" }}
					>
						{props.data.value === "63f47654610d49d82a79f9e1" && (
							<p
								style={{
									width: "fit-content",
									padding: "1px 8px",
									background: "#0f407a",
									color: "white",
									borderRadius: "10px",
								}}
							>
								Default
							</p>
						)}
						<div className={AdCampaignsStyles.chip}>
							{props.data.isPaid ? (
								<div
									className={AdCampaignsStyles.dotGreen}
								></div>
							) : (
								<div className={AdCampaignsStyles.dotRed}></div>
							)}
							<p>{props.data.isPaid ? "Paid" : "Unpaid"}</p>
						</div>

						<div className={AdCampaignsStyles.chip}>
							<p className={AdCampaignsStyles.volumeText}>
								Min:
								<span className={AdCampaignsStyles.volumeValue}>
									{props.data.minVolume
										? ` ${props.data.minVolume}`
										: " NA"}
								</span>{" "}
								Max:
								<span className={AdCampaignsStyles.volumeValue}>
									{props.data.maxVolume
										? ` ${props.data.maxVolume}`
										: " NA"}
								</span>
							</p>
						</div>
					</div>

					<p
						style={{
							textAlign: "right",
							marginRight: "1rem",
							opacity: "0.8",
						}}
					>
						Result generated on: {formattedDate}
					</p>
				</div>
			)}
		</Fragment>
	);
};

export default GeneratedAd;
