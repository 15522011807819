import type { FC, PropsWithChildren } from 'react';
import Button from '../Button';
import CancelButton from '../CancelButton';
import ModalButtonsStyles from './ModalButtons.module.css';

type ModalButtonsProps = PropsWithChildren<{
  previousButtonLabel?: string;
  previousButtonDisabled?: boolean;
  handlePrevious?: React.MouseEventHandler<HTMLButtonElement>;
  submitButtonLabel?: string;
  submitButtonDisabled?: boolean;
  onSubmit?: React.MouseEventHandler<HTMLButtonElement>;
  cancelButtonLabel: string;
  onCancel?: React.MouseEventHandler<HTMLButtonElement>;
  formError?: string;
  loading?: boolean;
  style?: React.CSSProperties;
  previousButtonStyle?: React.CSSProperties;
  submitButtonStyle?: React.CSSProperties;
  cancelButtonStyle?: React.CSSProperties;
}>;

const ModalButtons: FC<ModalButtonsProps> = ({
  previousButtonLabel,
  previousButtonDisabled,
  handlePrevious,
  submitButtonLabel,
  submitButtonDisabled,
  onSubmit,
  cancelButtonLabel,
  onCancel,
  formError,
  loading,
  style,
  previousButtonStyle = {},
  submitButtonStyle = {},
  cancelButtonStyle = {},
}) => {
  return (
    <div className={ModalButtonsStyles.buttonsContainer} style={style}>
      {previousButtonLabel && (
        <Button label={previousButtonLabel} type='button' submitButtonStyle={previousButtonStyle} disabled={previousButtonDisabled} onClick={handlePrevious} />
      )}
      <div className={ModalButtonsStyles.buttons}>
        <label style={{ color: 'red' }}>{formError}</label>
        {loading ? (
          <Button label={'Saving...'} type='button' />
        ) : (
          submitButtonLabel && <Button type='submit' label={submitButtonLabel} submitButtonStyle={submitButtonStyle} disabled={submitButtonDisabled} onClick={onSubmit} />
        )}
        <CancelButton label={cancelButtonLabel} type='reset' cancelButtonStyle={cancelButtonStyle} onClick={onCancel} />
      </div>
    </div>
  );
};

export default ModalButtons;
