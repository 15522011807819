import type { FC, PropsWithChildren } from 'react';
import AdStatisticsStyles from '../../styles/AdStatistics.module.css';

type SelectBoxProps = PropsWithChildren<{
  value: string;
  onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  style?: React.CSSProperties;
  width?: string;
}>;

const SelectBox: FC<SelectBoxProps> = props => {
  return (
    <div className={AdStatisticsStyles.contents_select_box} style={props.style}>
      <select name='top-ads-card' id='top-ads-card' value={props.value} onChange={props.onChange} style={{ width: props.width }}>
        {props.children}
      </select>
    </div>
  );
};

export default SelectBox;
