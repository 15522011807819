import type { FC, PropsWithChildren } from 'react';
import { ModalButtons, Modal } from '../../components/Modal';
import ModalHeading from '../../components/Modal/ModalHeading';

import { CustomTableRow, Table, TableBody, TableHead, TableImageRow, TableNoContent, TableRowTitle } from '../../components/Table';
import TableSkeleton from '../../Skeletons/TableSkeleton';
import { ICheckin, IClinicStatusRes } from '../../hooks/checkins/index.type';
import { getEncryptedName, tConvert } from './CheckinTable';
import { checkinsTableHistoryHeadData } from '../../hooks/checkins/staticData';
import { useAllCheckinsHistory } from '../../hooks/checkins';

type AddEditModalProps = PropsWithChildren<{
  showCheckInHistory: boolean;
  onCloseModal?: () => void;
  statusData?: IClinicStatusRes;
}>;

const AddEditModal: FC<AddEditModalProps> = props => {
  const { checkinsHistoryData, checkinsHistoryDataLoading } = useAllCheckinsHistory({ pageNumber: 1 });

  return (
    <Modal show={props.showCheckInHistory}>
      <ModalHeading heading='Check-in History' />
      <Table>
        <TableHead data={checkinsTableHistoryHeadData} />
        <TableBody>
          <CustomTableRow>
            {checkinsHistoryDataLoading ? (
              <TableSkeleton rows={7} columns={5} />
            ) : checkinsHistoryData && checkinsHistoryData.data.result.length > 0 ? (
              checkinsHistoryData.data.result.map((checkIn: ICheckin) => (
                <tr key={checkIn._id}>
                  <TableImageRow alt={checkIn.user.name} />
                  <TableRowTitle>{checkIn.user ? getEncryptedName(checkIn.user.name) : ''}</TableRowTitle>
                  <td>{checkIn.doctor.name}</td>
                  <td>
                    {checkIn.appointmentDateString ? checkIn.appointmentDateString : <span>NA</span>} &nbsp;
                    {tConvert(checkIn.appointmentTime)}
                  </td>
                  <td>{checkIn.checkInDateString ? checkIn.checkInDateString : '--'}</td>
                  <td>{checkIn.checkOutDateString ? checkIn.checkOutDateString : !checkIn.isCheckedIn && !checkIn.isCheckedOut && <span>NA</span>}</td>
                  <td>
                    {checkIn.status ? (
                      props.statusData &&
                      props.statusData.data &&
                      props.statusData.data.result.length > 0 &&
                      props.statusData.data.result.map(item => item._id === checkIn.status && <span key={item._id}>{item.title}</span>)
                    ) : (
                      <span>NA</span>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <TableNoContent colSpan={checkinsTableHistoryHeadData.length} message='No data found' />
            )}
          </CustomTableRow>
        </TableBody>
      </Table>
      <ModalButtons cancelButtonLabel='Close' onCancel={props.onCloseModal} style={{ margin: '2rem 0' }} />
    </Modal>
  );
};

export default AddEditModal;
