import type { FC, PropsWithChildren } from 'react';
import FileExchangeStyles from '../../styles/FileExchangeScreen.module.css';

type FileItemProps = PropsWithChildren<{
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  name: string;
  protected: boolean;
}>;

const FileItem: FC<FileItemProps> = props => {
  return (
    <button className={FileExchangeStyles.file} onClick={props.onClick}>
      <svg className={FileExchangeStyles.fileIcon}>
        <use xlinkHref={`/assets/sprite.svg#icon-zip`} />
      </svg>
      <h4 className={FileExchangeStyles.fileNmme}>{props.name}</h4>
      {props.protected && (
        <svg className={FileExchangeStyles.file_lock}>
          <use xlinkHref={`/assets/sprite.svg#icon-lock`} />
        </svg>
      )}
    </button>
  );
};

export default FileItem;
