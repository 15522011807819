import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { SideProps } from '.';

const AdUserSidebar: FC<SideProps> = ({ currentMenu }) => {
  return (
    <nav className='navigation'>
      <ul className='navigation__list'>
        <li className='navigation__list--link'>
          <img src='/assets/logoWhite.png' alt='logo' className='navigation__logo' />
        </li>

        <li className='navigation__list--item'>
          <Link to='/ad-campaigns' className={currentMenu === 'ad-campaigns' ? 'navigation__list--link-active' : 'navigation__list--link'}>
            <svg className={currentMenu === 'ad-campaigns' ? 'navigation__icon-active' : 'navigation__icon'}>
              <use xlinkHref={`/assets/sprite.svg#icon-ads`} />
            </svg>
            <span>Ad Campaigns</span>
          </Link>
        </li>
        <li className='navigation__list--item'>
          <Link to='/view-campaign' className={currentMenu === 'view-campaign' ? 'navigation__list--link-active' : 'navigation__list--link'}>
            <svg className={currentMenu === 'view-campaign' ? 'navigation__icon-active' : 'navigation__icon'}>
              <use xlinkHref={`/assets/sprite.svg#icon-ads`} />
            </svg>
            <span>View Ads</span>
          </Link>
        </li>
        {/* <li className='navigation__list--item'>
						<Link
							to='/campaign'
							className={currentMenu === 'campaign' ? 'navigation__list--link-active' : 'navigation__list--link'}>
							<svg className={currentMenu === 'campaign' ? 'navigation__icon-active' : 'navigation__icon'}>
								<use xlinkHref={`/assets/sprite.svg#icon-ads`} />
							</svg>
							<span>Campaign</span>
						</Link>
					</li>
					<li className='navigation__list--item'>
						<Link
							to='/view-campaign'
							className={currentMenu === 'view-campaign' ? 'navigation__list--link-active' : 'navigation__list--link'}>
							<svg className={currentMenu === 'view-campaign' ? 'navigation__icon-active' : 'navigation__icon'}>
								<use xlinkHref={`/assets/sprite.svg#icon-ads`} />
							</svg>
							<span>View Ads</span>
						</Link>
					</li>
					<li className='navigation__list--item'>
						<Link
							to='/campaign-analysis'
							className={
								currentMenu === 'campaign-analysis' ? 'navigation__list--link-active' : 'navigation__list--link'
							}>
							<svg className={currentMenu === 'campaign-analysis' ? 'navigation__icon-active' : 'navigation__icon'}>
								<use xlinkHref={`/assets/sprite.svg#icon-ads`} />
							</svg>
							<span>Analysis</span>
						</Link>
					</li> */}
      </ul>
      <div>
        <Link to='/my-profile' className={currentMenu === 'my-profile' ? 'navigation__list--link-active' : 'navigation__list--link'}>
          <svg className={currentMenu === 'my-profile' ? 'navigation__icon-active' : 'navigation__icon'}>
            <use xlinkHref={`/assets/sprite.svg#icon-users2`} />
          </svg>
          <span>My Profile</span>
        </Link>

        <p className='powered__text'>
          <span>
            Powered By
            <br />
            <a href='https://www.the-algo.com/' target='_blank' rel='noreferrer'>
              The Algorithm
            </a>
          </span>
          &copy; {new Date(Date.now()).getFullYear()}. clinIQ
        </p>
      </div>
      {/* <img src='./algo-logo.png' alt='The Algorithm' className='powered_logo' /> */}
    </nav>
  );
};

export default AdUserSidebar;
