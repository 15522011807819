import toast from 'react-hot-toast';
import useApi from '../useApi';
import { ICancelAppointmentRes } from './index.type';

const useDeleteAppointment = ({ appointmentId }: { appointmentId?: string }) => {
  const { loading: deleteAppointmentLoading, makeApiCall } = useApi({
    url: `/appointment/delete-appointment/${appointmentId}`,
    method: 'DELETE',
  });

  const deleteAppointment = async () => {
    const { response, error } = await makeApiCall<undefined, ICancelAppointmentRes>();
    if (response?.status) {
      toast.success(response.message);
      return response;
    }

    if (error || !response?.status) {
      toast.error('Something went wrong. Please try again later');
      return { status: false };
    }
  };

  return {
    deleteAppointmentLoading,
    deleteAppointment,
  };
};

export default useDeleteAppointment;
