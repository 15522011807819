import type { FC, PropsWithChildren } from 'react';
import AdCampaignsStyles from '../../styles/AdCampaigns.module.css';
import { Link } from 'react-router-dom';

type ChipLinkProps = PropsWithChildren<{
  label: string;
  icon: string;
  to: string;
  active?: boolean;
}>;

const ChipLink: FC<ChipLinkProps> = props => {
  return (
    <Link to={props.to} className={`${AdCampaignsStyles.navigate} ${props.active ? AdCampaignsStyles.active : ''}`}>
      <svg className={AdCampaignsStyles.icons}>
        <use xlinkHref={`/assets/sprite.svg#icon-${props.icon}`} />
      </svg>
      {props.label}
    </Link>
  );
};

export default ChipLink;
