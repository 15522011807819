import React from 'react';
import type { FC, PropsWithChildren } from 'react';
import { ModalButtons } from '../../../components/Modal';
import { IImageSrcList } from '../../../hooks/advertisements/index.type';
import AddAdvertiseStyles from '../../../styles/AddAdvertiseScreen.module.css';
import { validateOnlyNumbers } from '../../../utils/validators';
import { IpresInfoData } from '.';

type Step3Props = PropsWithChildren<{
  loading: boolean;
  imageSrcList: IImageSrcList[];
  setImageSrcList: React.Dispatch<React.SetStateAction<IImageSrcList[]>>;
  hasTimeValidity: string[];
  setHasTimeValidity: React.Dispatch<React.SetStateAction<string[]>>;
  hasPresInfo: string[];
  sethasPresInfo: React.Dispatch<React.SetStateAction<string[]>>;
  prescriptionInfoData: IpresInfoData[];
  setPrescriptionInfoData: React.Dispatch<React.SetStateAction<IpresInfoData[]>>;
  hasVolumeLimit: string[];
  sethasVolumeLimit: React.Dispatch<React.SetStateAction<string[]>>;
  volumeLimitData: { image: string; isPaid: boolean; min: { value: number; error: string }; max: { value: number; error: string } }[];
  setVolumeLimitData: React.Dispatch<
    React.SetStateAction<{ image: string; isPaid: boolean; min: { value: number; error: string }; max: { value: number; error: string } }[]>
  >;
  dateRange: { image: string; from: { date: string; error: string }; to: { date: string; error: string } }[];
  setDateRange: React.Dispatch<React.SetStateAction<{ image: string; from: { date: string; error: string }; to: { date: string; error: string } }[]>>;
  prevStep?: () => void;
  formError?: string;
  submitBtnDisabled?: boolean;
  onCancel?: React.MouseEventHandler<HTMLButtonElement>;
}>;

const Step3: FC<Step3Props> = props => {
  const filterDate = (date: string) => {
    const newDate = new Date(date);
    if (date) {
      let year = newDate.getFullYear().toString(),
        month = (newDate.getMonth() + 1).toString(),
        day = newDate.getDate().toString();
      let fullDate = `${year}-${month.length > 1 ? month : '0' + month}-${day.length > 1 ? day : '0' + day}`;
      // let fullDate = `${day.length > 1 ? day : '0' + day}/${month.length > 1 ? month : '0' + month}/${year}`
      // console.log('Date', fullDate);
      return fullDate;
    }
    return date;
  };

  return (
    <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', overflow: 'auto', paddingRight: '1rem' }}>
      <div>
        {props.imageSrcList &&
          props.imageSrcList.length > 0 &&
          props.imageSrcList.map((image, index) => {
            return (
              <div className={AddAdvertiseStyles.ad_setting_container} key={image._id}>
                <div className={AddAdvertiseStyles.ad_setting_box}>
                  <img src={process.env.REACT_APP_BASE_ASSET_URL + image.imageUrl} alt={image.imageUrl} className={AddAdvertiseStyles.ad_setting_image} />
                  <div className={AddAdvertiseStyles.ad_setting_toggleBox} style={{ marginTop: '1rem' }}>
                    <p>Has Date Limit</p>
                    <label className='switch'>
                      <input
                        id='check-box'
                        checked={props.hasTimeValidity.includes(image._id)}
                        onChange={() => {
                          let temp = [...props.hasTimeValidity];
                          if (temp.includes(image._id)) {
                            temp = temp.filter(data => data !== image._id);
                          } else {
                            temp.push(image._id);
                          }
                          props.setHasTimeValidity(temp);
                        }}
                        type='checkbox'
                        className='checkbox'
                        name='active'
                      />
                      <span className='slider round' />
                    </label>
                  </div>

                  <div className={AddAdvertiseStyles.ad_setting_toggleBox}>
                    <p>Has Prescribing Info</p>
                    <label className='switch'>
                      <input
                        id='check-box'
                        checked={props.hasPresInfo.includes(image._id)}
                        onChange={() => {
                          let temp = [...props.hasPresInfo];
                          if (temp.includes(image._id)) {
                            temp = temp.filter(data => data !== image._id);
                            const prescriptionInfoDataTemp = [...props.prescriptionInfoData];
                            props.prescriptionInfoData &&
                              props.prescriptionInfoData.length > 0 &&
                              props.prescriptionInfoData.forEach((data, i) => {
                                if (data.image === image._id) {
                                  prescriptionInfoDataTemp[i].data = undefined;
                                  prescriptionInfoDataTemp[i].value = '';
                                  prescriptionInfoDataTemp[i].error = '';
                                }
                              });
                            props.setPrescriptionInfoData(prescriptionInfoDataTemp);
                          } else {
                            temp.push(image._id);
                          }
                          props.sethasPresInfo(temp);
                        }}
                        type='checkbox'
                        className='checkbox'
                        name='active'
                      />
                      <span className='slider round' />
                    </label>
                  </div>

                  <div className={AddAdvertiseStyles.ad_setting_toggleBox}>
                    <p>Has Volume Limit</p>
                    <label className='switch'>
                      <input
                        id='check-box'
                        checked={props.hasVolumeLimit.includes(image._id)}
                        // checked={image.hasVolumeLimit}
                        onChange={() => {
                          let temp = [...props.hasVolumeLimit];
                          if (temp.includes(image._id)) {
                            temp = temp.filter(data => data !== image._id);
                            const volumeLimitDataTemp = [...props.volumeLimitData];
                            props.volumeLimitData &&
                              props.volumeLimitData.length > 0 &&
                              props.volumeLimitData.forEach((data, i) => {
                                if (data.image === image._id) {
                                  volumeLimitDataTemp[i].min = {
                                    value: 0,
                                    error: '',
                                  };
                                  volumeLimitDataTemp[i].max = {
                                    value: 0,
                                    error: '',
                                  };
                                }
                              });
                            props.setVolumeLimitData(volumeLimitDataTemp);
                          } else {
                            temp.push(image._id);
                          }
                          props.sethasVolumeLimit(temp);
                          let temp1 = [...props.imageSrcList];
                          temp1[index].hasVolumeLimit = typeof temp1[index].hasVolumeLimit === 'boolean' ? !temp1[index].hasVolumeLimit : true;
                          props.setImageSrcList(temp1);
                        }}
                        type='checkbox'
                        className='checkbox'
                        name='active'
                      />
                      <span className='slider round' />
                    </label>
                  </div>

                  <div className={AddAdvertiseStyles.ad_setting_toggleBox}>
                    <p>Is Paid Ad</p>
                    <label className='switch'>
                      <input
                        id='check-box'
                        checked={image.isPaid}
                        // checked={image.hasVolumeLimit}
                        onChange={() => {
                          let temp1 = [...props.imageSrcList];
                          temp1[index].isPaid = typeof temp1[index].isPaid === 'boolean' ? !temp1[index].isPaid : true;
                          props.setImageSrcList(temp1);
                        }}
                        type='checkbox'
                        className='checkbox'
                        name='active'
                      />
                      <span className='slider round' />
                    </label>
                  </div>
                </div>

                <div className={AddAdvertiseStyles.ad_setting_box}>
                  <div className={AddAdvertiseStyles.ad_setting_inputs}>
                    <div className={AddAdvertiseStyles.ad_setting_input}>
                      {!props.hasTimeValidity.includes(image._id) && <span className={AddAdvertiseStyles.ad_setting_input_disabled} />}
                      <label htmlFor='from_date'>Starts on</label>
                      <div>
                        <input
                          type='date'
                          id='from_date'
                          value={filterDate(image.from)}
                          onChange={e => {
                            const dateRangeTemp = [...props.dateRange];
                            dateRangeTemp[index].image = image._id;
                            dateRangeTemp[index].from.date = e.target.value;
                            props.setDateRange(dateRangeTemp);
                            let temp = [...props.imageSrcList];
                            temp[index].from = e.target.value;
                            props.setImageSrcList(temp);
                          }}
                        />
                      </div>
                    </div>

                    <div className={AddAdvertiseStyles.ad_setting_input}>
                      {!props.hasTimeValidity.includes(image._id) && <span className={AddAdvertiseStyles.ad_setting_input_disabled} />}
                      <label htmlFor='end_date'>Ends On</label>
                      <div>
                        <input
                          type='date'
                          id='end_date'
                          value={filterDate(image.to)}
                          onChange={e => {
                            const dateRangeTemp = [...props.dateRange];
                            dateRangeTemp[index].image = image._id;
                            dateRangeTemp[index].to.date = e.target.value;
                            // console.log(dateRangeTemp);
                            props.setDateRange(dateRangeTemp);
                            let temp = [...props.imageSrcList];
                            temp[index].to = e.target.value;
                            props.setImageSrcList(temp);
                          }}
                        />
                      </div>
                    </div>

                    <div className={AddAdvertiseStyles.ad_setting_input}>
                      {!props.hasPresInfo.includes(image._id) && <span className={AddAdvertiseStyles.ad_setting_input_disabled} />}
                      <label htmlFor='pres-info'>Prescribing Info</label>
                      <span>
                        <input
                          id='pres-info'
                          type='file'
                          accept='.pdf'
                          // value={prescriptionInfo.value}
                          // value={props.prescriptionInfoData[index].value}
                          onChange={e => {
                            const prescriptionInfoDataTemp = [...props.prescriptionInfoData];
                            prescriptionInfoDataTemp[index].image = image._id;
                            prescriptionInfoDataTemp[index].value = e.target.value;

                            if (e.target.files && e.target.files.length > 0 && e.target.files[0].type === 'application/pdf') {
                              prescriptionInfoDataTemp[index].data = e.target.files[0];
                              prescriptionInfoDataTemp[index].error = '';
                            } else {
                              alert('Please upload a PDF file');
                              prescriptionInfoDataTemp[index].data = undefined;
                              prescriptionInfoDataTemp[index].error = 'Please upload a PDF file';
                            }

                            // console.log(prescriptionInfoDataTemp);
                            props.setPrescriptionInfoData(prescriptionInfoDataTemp);
                          }}
                        />
                        {image.prescriptionInfoFile && <label>{image.prescriptionInfoFile}</label>}
                        {/* {props.prescriptionInfoData[index].error && (
                          <p className={AddAdvertiseStyles.advertise_right_container_error}>{props.prescriptionInfoData[index].error}</p>
                        )} */}
                      </span>
                    </div>

                    <div className={AddAdvertiseStyles.ad_setting_input}>
                      {!props.hasVolumeLimit.includes(image._id) && <span className={AddAdvertiseStyles.ad_setting_input_disabled} />}

                      <label htmlFor='vol_min_val'>Min Value</label>
                      <span>
                        <div>
                          <input
                            type='number'
                            id='vol_min_val'
                            // value={props.volumeLimitData[index].min.value}
                            value={image.min.value}
                            onChange={e => {
                              const volumeLimitDataTemp = [...props.volumeLimitData];
                              volumeLimitDataTemp[index].image = image._id;
                              volumeLimitDataTemp[index].min.value = Number(e.target.value);
                              if (!validateOnlyNumbers(e.target.value)) {
                                volumeLimitDataTemp[index].min.error = 'Please enter only numbers';
                              } else if (validateOnlyNumbers(e.target.value)) {
                                volumeLimitDataTemp[index].min.error = '';
                              }
                              // console.log(volumeLimitDataTemp);
                              props.setVolumeLimitData(volumeLimitDataTemp);
                              let temp = [...props.imageSrcList];
                              temp[index].min.value = volumeLimitDataTemp[index].min.value;
                              props.setImageSrcList(temp);
                            }}
                          />
                        </div>
                        {/* {props.volumeLimitData[index].min?.error && (
                          <p className={AddAdvertiseStyles.advertise_right_container_error}>{props.volumeLimitData[index].min?.error}</p>
                        )} */}
                      </span>
                    </div>

                    <div className={AddAdvertiseStyles.ad_setting_input}>
                      {!props.hasVolumeLimit.includes(image._id) && <span className={AddAdvertiseStyles.ad_setting_input_disabled} />}

                      <label htmlFor='vol_max_val'>Max Value</label>
                      <span>
                        <div>
                          <input
                            type='number'
                            id='vol_max_val'
                            value={image.max.value}
                            // value={props.volumeLimitData[index].max.value}
                            onChange={e => {
                              const volumeLimitDataTemp = [...props.volumeLimitData];
                              volumeLimitDataTemp[index].image = image._id;
                              volumeLimitDataTemp[index].max.value = Number(e.target.value);
                              if (!validateOnlyNumbers(e.target.value)) {
                                volumeLimitDataTemp[index].max.error = 'Please enter only numbers';
                              } else if (validateOnlyNumbers(e.target.value)) {
                                volumeLimitDataTemp[index].max.error = '';
                              }
                              // console.log(volumeLimitDataTemp);
                              props.setVolumeLimitData(volumeLimitDataTemp);

                              let temp = [...props.imageSrcList];
                              temp[index].max.value = volumeLimitDataTemp[index].max.value;
                              props.setImageSrcList(temp);
                            }}
                          />
                        </div>
                        {/* {props.volumeLimitData[index].max?.error && (
                          <p className={AddAdvertiseStyles.advertise_right_container_error}>{props.volumeLimitData[index].max?.error}</p>
                        )} */}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
      <ModalButtons
        submitButtonLabel='Next'
        submitButtonDisabled={props.submitBtnDisabled}
        previousButtonLabel='Previous'
        handlePrevious={props.prevStep}
        cancelButtonLabel='Cancel'
        formError={props.formError}
        loading={props.loading}
        onCancel={props.onCancel}
        style={{ paddingBottom: '1rem' }}
      />
    </div>
  );
};

export default Step3;
