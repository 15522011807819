import toast from 'react-hot-toast';
import useApi from '../useApi';
import { ISaveHipaaFormsReq, ISaveHipaaFormsRes } from './index.type';

const useSaveHIPAAForm = () => {
  const { loading: saveHIPAAFormLoading, makeApiCall } = useApi({
    url: `/hipaa-policies/save-hipaa-policies-form`,
    method: 'POST',
  });

  const saveHIPAAForm = async (formData: ISaveHipaaFormsReq) => {
    const { response, error } = await makeApiCall<ISaveHipaaFormsReq, ISaveHipaaFormsRes>(formData);
    if (response?.status) {
      return response;
    }

    if (!response?.status && response?.message) {
      return response;
    }

    if (error) {
      toast.error('Something went wrong while saving the data. Please try again later');
      return { status: 'fail', message: 'Something went wrong while saving the data. Please try again later' };
    }
  };

  return {
    saveHIPAAForm,
    saveHIPAAFormLoading,
  };
};

export default useSaveHIPAAForm;
