import type { FC, PropsWithChildren } from 'react';
import ToggleSwitch from '../ToggleSwitch';
import classes from '../../styles/Table.module.css';

export type TableStatusWithToggleProps = PropsWithChildren<{
  id?: string;
  checked?: boolean;
  onChange?: () => void;
  hideToggle?: boolean;
  paidText?: boolean;
}>;

const TableStatusWithToggle: FC<TableStatusWithToggleProps> = props => {
  return (
    <div className='flex'>
      {props.checked ? (
        <p className={classes.table__status}>
          <span>•</span> {props.paidText ? 'Paid' : 'Active'}
        </p>
      ) : (
        <p className={classes.table__status_deactive}>
          <span>•</span> {props.paidText ? 'Unpaid' : 'Deactive'}
        </p>
      )}

      {!props.hideToggle && <ToggleSwitch id={props.id} checked={props.checked} onChange={props.onChange} />}
    </div>
  );
};

export default TableStatusWithToggle;
