import React from 'react';
import type { FC, PropsWithChildren } from 'react';
import { FileUpload, InputsSectionColumn, ModalButtons } from '../../../components/Modal';
import { IImageSrcList } from '../../../hooks/advertisements/index.type';
import AddAdvertiseStyles from '../../../styles/AddAdvertiseScreen.module.css';
import AdCampaignsStyles from '../../../styles/AdCampaigns.module.css';
import Step2UploadImageItem from './Step2UploadImageItem';

export interface IImageNameList {
  img?: string | ArrayBuffer | null;
  value: number;
  isAltered: boolean;
}

type Step2Props = PropsWithChildren<{
  loading: boolean;
  imageDimensionError: boolean;
  setImageDimensionError: React.Dispatch<React.SetStateAction<boolean>>;
  onFileSelected: (e: React.ChangeEvent<HTMLInputElement>) => void;
  removeImage: (index: number) => void;
  imageNameList: IImageNameList[];
  newImgSrcList: { img: IImageSrcList }[];
  prevStep?: () => void;
  formError?: string;
  setImageDeleteId: React.Dispatch<React.SetStateAction<{ item?: { img: IImageSrcList }; index?: number }>>;
  onCancel?: React.MouseEventHandler<HTMLButtonElement>;
}>;

const Step2: FC<Step2Props> = props => {
  return (
    <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', overflow: 'auto', paddingRight: '1rem' }}>
      <div>
        <InputsSectionColumn style={{ alignItems: 'center', padding: '0rem' }}>
          <FileUpload
            icon='upload'
            onChange={props.onFileSelected}
            label='Upload Campaign Ads'
            label2='Upload image must have width and height of'
            label2Span='728X90,970X90,970X254'
            multiple={true}
          />
        </InputsSectionColumn>
        {props.imageNameList.length > 0 && (
          <InputsSectionColumn>
            {props.imageDimensionError && <p className={AddAdvertiseStyles.advertise_right_container_error}>Please upload image of specified dimensions.</p>}
            <div className={AddAdvertiseStyles.advertise_right_container}>
              <Step2UploadImageItem imageNameList={props.imageNameList} setImageDimensionError={props.setImageDimensionError} removeImage={props.removeImage} />
            </div>
          </InputsSectionColumn>
        )}
        <InputsSectionColumn>
          {props.newImgSrcList && props.newImgSrcList.length + props.imageNameList.length > 0 && (
            <div className={AddAdvertiseStyles.adImage_container}>
              {props.newImgSrcList.length > 0 && <h4>Previous Uploaded:</h4>}

              <div className={AddAdvertiseStyles.advertise_right_container} style={{ marginTop: '1rem' }}>
                {props.newImgSrcList &&
                  props.newImgSrcList.length > 0 &&
                  props.newImgSrcList.map((item, index1) => {
                    return (
                      <div className={[AddAdvertiseStyles.advertise_right_container_table_body_tr, AddAdvertiseStyles.imageBox].join(' ')} key={item.img._id}>
                        <img src={process.env.REACT_APP_BASE_ASSET_URL + item.img.imageUrl} style={{ width: '15rem' }} alt={item.img.imageName} />
                        <label style={{ color: 'black', wordWrap: 'break-word', width: '80%', fontSize: '9px' }}>{item.img.label}</label>
                        <svg
                          className={AdCampaignsStyles.delete_image}
                          onClick={() => {
                            props.setImageDeleteId({ item, index: index1 });
                          }}
                        >
                          <use xlinkHref={`/assets/sprite.svg#icon-trash`} />
                        </svg>
                      </div>
                    );
                  })}
              </div>
            </div>
          )}
        </InputsSectionColumn>
      </div>
      <ModalButtons
        submitButtonLabel='Next'
        submitButtonDisabled={props.imageDimensionError}
        previousButtonLabel='Previous'
        handlePrevious={props.prevStep}
        cancelButtonLabel='Cancel'
        loading={props.loading}
        formError={props.formError}
        onCancel={props.onCancel}
        style={{ paddingBottom: '1rem' }}
      />
    </div>
  );
};

export default Step2;
