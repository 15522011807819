import { Fragment, type FC, type PropsWithChildren } from 'react';
import SelectBoxStyles from '../InputBox/TextBox.module.css';

type SelectBoxProps = PropsWithChildren<{
  name?: string;
  icon?: string;
  value: string;
  locked?: boolean;
  errorMessage?: string;
  label: string;
  style?: React.CSSProperties;
  containerStyle?: React.CSSProperties;
  inputContainerStyle?: React.CSSProperties;
  onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}>;

const SelectBox: FC<SelectBoxProps> = ({ children, name, label, icon, value, onChange, locked, errorMessage, style, containerStyle, inputContainerStyle }) => {
  return (
    <div className={SelectBoxStyles.form_input} style={containerStyle}>
      {label && (
        <label htmlFor={label} className={SelectBoxStyles.form_input__label}>
          {label}
        </label>
      )}
      <div className={SelectBoxStyles.form_input__container} style={inputContainerStyle}>
        {!locked ? (
          <Fragment>
            {icon && (
              <svg className={SelectBoxStyles.form_input__icon}>
                <use xlinkHref={`/assets/sprite.svg#icon-${icon}`} />
              </svg>
            )}
            <select value={value} name={name} id={name} className={SelectBoxStyles.form_input__box} onChange={onChange} style={style}>
              {children}
            </select>
          </Fragment>
        ) : (
          <Fragment>
            <p className={SelectBoxStyles.form_input__box} style={{ height: '1.5rem', lineHeight: '1', color: value ? 'var(--grey)' : 'var(--light-grey)' }}>
              {value ? value : label}
            </p>
            <svg className={SelectBoxStyles.form_input__icon}>
              <use xlinkHref={`/assets/sprite.svg#icon-lock`} />
            </svg>
          </Fragment>
        )}
      </div>

      {errorMessage && <p className={SelectBoxStyles.form_input__error}>{errorMessage}</p>}
    </div>
  );
};

export default SelectBox;
