import React from "react";
import toast from "react-hot-toast";
import { Content } from "../components";
import SelectRulesModal, {
	SelectRulesModalState,
} from "../pageComponents/AdView/SelectRulesModal";
import {
	IAdRule,
	IAdRules,
	IImageSrcListResArray,
	IViewRequest,
} from "../hooks/adViews/index.type";
import RuleBox from "../pageComponents/AdView/RuleBox";
import AdViewStyles from "../styles/AdViewScreen.module.css";
import DeleteRuleModal, {
	DeleteRulesModalState,
} from "../pageComponents/AdView/DeleteRuleModal";
import GeneratedAd from "../pageComponents/AdView/GeneratedAd";
import useGeneratedAd from "../hooks/adViews/useGeneratedAd";
import { useActiveClinics, usePainLocations } from "../hooks/advertisements";
import GeneratedAdSkeleton from "../Skeletons/GeneratedAdSkeleton";
import { ToggleButtons } from "../pageComponents/Advertisements";

const AdView = () => {
	const [selectRulesModal, setSelectRulesModal] =
		React.useState<SelectRulesModalState>({
			status: false,
		});
	const [deleteRulesModal, setDeleteRulesModal] =
		React.useState<DeleteRulesModalState>({
			status: false,
		});
	const [rulesInfo, setRulesInfo] = React.useState<IAdRules>({
		rulesList: {
			rules: [],
		},
		rulesCount: 0,
	});
	const [adData, setAdData] = React.useState<
		IImageSrcListResArray | undefined
	>({
		status: false,
	});
	const [allAdsView, setAllAdsView] = React.useState<Boolean>(true);
	const { generatedAd, viewRequestLoading } = useGeneratedAd();
	const { activeClinicsData } = useActiveClinics();
	const { painLocationsData } = usePainLocations();

	const updateRulesInfo = (newInfo: IAdRules) => {
		setRulesInfo(newInfo);

		localStorage.setItem("rules_info", JSON.stringify(newInfo));
		if (newInfo.rulesCount === 0) {
			localStorage.removeItem("rules_info");
		}
	};

	const updateAdData = (adData: IImageSrcListResArray) => {
		setAdData(adData);

		localStorage.setItem("ad_data", JSON.stringify(adData));
		if (adData.count === 0) {
			localStorage.removeItem("ad_data");
		}
	};

	const handleDeleteRule = (id: any) => {
		const newRules = rulesInfo.rulesList.rules.filter((rule) => {
			return rule.id !== id;
		});

		const newRulesInfo = {
			rulesList: {
				rules: newRules,
			},
			rulesCount:
				newRules.length !== rulesInfo.rulesList.rules.length
					? rulesInfo.rulesCount - 1
					: rulesInfo.rulesCount,
		};

		const newAdData = {
			status: false,
			count: 0,
			data: [],
		};

		updateRulesInfo(newRulesInfo);
		updateAdData(newAdData);
		setDeleteRulesModal({ status: false });
	};

	const handleRulesReset = () => {
		const newRulesInfo = {
			rulesList: {
				rules: [],
			},
			rulesCount: 0,
		};
		const newAdData = {
			status: false,
			count: 0,
			data: [],
		};

		updateRulesInfo(newRulesInfo);
		updateAdData(newAdData);
		setDeleteRulesModal({ status: false });
	};

	const handleRulesSet = (data: IAdRule[]) => {
		setSelectRulesModal({ status: false });
		data = data.filter((item) => {
			if (item.field === "" || item.value === "") {
				toast.error(
					"Error: Incomplete information. The Ad rule is missing essential information. Please provide the necessary field."
				);
			}
			return item.field !== "" && item.value !== "";
		});

		const newRulesInfo = {
			rulesList: {
				rules: data,
			},
			rulesCount: data.length,
		};

		updateRulesInfo(newRulesInfo);
	};

	const handleGenerateAd = async () => {
		setAdData({ status: true });
		const requestData: IViewRequest = {};

		rulesInfo.rulesList.rules.forEach((rule) => {
			const { field, value } = rule;
			requestData[field] = value;
		});

		const data: IImageSrcListResArray | undefined = await generatedAd(
			requestData
		);

		if (data) {
			updateAdData({
				status: data.status,
				data: data.data,
				randomAd: data.randomAd,
				count: data.count,
			});
		}
	};

	const getDataFromLocalStorage = (): {
		rulesData?: IAdRules;
		adData?: IImageSrcListResArray;
	} => {
		let data: { rulesData?: IAdRules; adData?: IImageSrcListResArray } = {
			rulesData: undefined,
			adData: undefined,
		};
		try {
			const rulesData = localStorage.getItem("rules_info");
			const adData = localStorage.getItem("ad_data");

			if (rulesData) data.rulesData = JSON.parse(rulesData);
			if (adData) data.adData = JSON.parse(adData);

			return data;
		} catch (error) {
			console.log("Error parsing data from local storage.", error);
			return data;
		}
	};

	React.useEffect(() => {
		const data = getDataFromLocalStorage();

		if (data) {
			setRulesInfo(
				data.rulesData || {
					rulesList: {
						rules: [],
					},
					rulesCount: 0,
				}
			);
			setAdData(data.adData);
		} else {
			setAdData({ status: false });
		}
	}, []);

	return (
		<Content
			headerTitle="Ad View"
			currentMenu="view-campaign"
			addBtn={true}
			addBtnClick={() =>
				setSelectRulesModal({
					status: true,
					mode: "Select Rules",
				})
			}
			addBtnTitle="Select Rules"
			searchBox={false}
		>
			<div
				style={{
					display: "flex",
					justifyContent: "flex-end",
					gap: "1rem",
				}}
			>
				<div
					className={AdViewStyles.generate_ad_button}
					style={{
						pointerEvents:
							rulesInfo.rulesList.rules.length === 0
								? "none"
								: "auto",
						opacity:
							rulesInfo.rulesList.rules.length === 0
								? "0.5"
								: "1",
					}}
					onClick={() => handleGenerateAd()}
				>
					Generate Ad
				</div>
				<div
					className={AdViewStyles.delete_modal_delete_button}
					onClick={() => {
						setDeleteRulesModal({
							status: true,
							mode: "Reset",
						});
					}}
				>
					Reset Rules
				</div>
			</div>
			<p className={AdViewStyles.rule_card_container_heading}>
				Total applied rules:{" "}
				<span
					style={{
						fontWeight: "bold",
					}}
				>
					{rulesInfo.rulesCount}
				</span>
			</p>

			{rulesInfo.rulesList.rules.length === 0 ? (
				<p
					style={{
						padding: "1rem",
						fontSize: "1.2rem",
						color: "red",
					}}
				>
					No rules applied yet.
				</p>
			) : (
				<div className={AdViewStyles.rule_card_container}>
					{rulesInfo.rulesList.rules.map((rule) => {
						return (
							rule.field !== "" && (
								<RuleBox
									key={rule.id}
									onEditRule={() => {
										setSelectRulesModal({
											status: true,
											mode: "Edit Rules",
										});
									}}
									onDeleteRule={() => {
										setDeleteRulesModal({
											status: true,
											mode: "Delete",
											data: rule,
										});
									}}
									adRule={rule}
								/>
							)
						);
					})}
					<div
						className={AdViewStyles.rule_card_container_bottom}
					></div>
				</div>
			)}

			{adData?.status && (
				<div className={AdViewStyles.generated_ad_container}>
					<div
						style={{
							display: "flex",
							alignItems: "center",
							gap: "1rem",
							marginBottom: "1rem",
						}}
					>
						<p
							style={{
								fontSize: "1.8rem",
								fontWeight: "bold",
								marginLeft: "1rem",
							}}
						>
							Generated Ads
						</p>
						<ToggleButtons
							buttons={[
								{
									label: "All",
									active: allAdsView === true,
									onClick: () =>
										setAllAdsView(
											(prevState) => !prevState
										),
								},
								{
									label: "Random",
									active: allAdsView === false,
									onClick: () =>
										setAllAdsView(
											(prevState) => !prevState
										),
								},
							]}
						/>
					</div>
					{allAdsView ? (
						<div style={{ fontSize: "1.5rem", marginLeft: "1rem" }}>
							{adData.count && adData.count > 0 ? (
								<p>
									Total available ads:{" "}
									<span
										style={{
											fontWeight: "bold",
										}}
									>
										{adData.count || "NA"}
									</span>
								</p>
							) : (
								<p style={{ color: "red" }}>
									No ads available.
								</p>
							)}
						</div>
					) : (
						<div style={{ fontSize: "1.5rem", marginLeft: "1rem" }}>
							<p>Randomly generated ad.</p>
						</div>
					)}
					{viewRequestLoading ? (
						<div
							className={AdViewStyles.generated_ad_card_container}
						>
							{Array(4)
								.fill(0)
								.map((_, index) => {
									return <GeneratedAdSkeleton key={index} />;
								})}
						</div>
					) : (
						<div>
							<div
								className={
									AdViewStyles.generated_ad_card_container
								}
							>
								{allAdsView &&
									adData.data &&
									adData.data.map((ad) => {
										return (
											<GeneratedAd
												data={ad}
												key={ad._id}
											/>
										);
									})}
								{!allAdsView && adData.randomAd && (
									<GeneratedAd
										data={adData.randomAd}
										key={adData.randomAd._id}
									/>
								)}
							</div>
						</div>
					)}
				</div>
			)}

			{selectRulesModal.status && (
				<SelectRulesModal
					selectRulesModal={selectRulesModal}
					rulesInfo={rulesInfo}
					activeClinics={activeClinicsData}
					painLocations={painLocationsData}
					onCloseModal={() => setSelectRulesModal({ status: false })}
					onSaveModal={handleRulesSet}
					setDeleteRulesModal={setDeleteRulesModal}
					setRulesInfo={setRulesInfo}
				/>
			)}

			{deleteRulesModal.status && (
				<DeleteRuleModal
					deleteRulesModal={deleteRulesModal}
					onDeleteRule={handleDeleteRule}
					onResetRules={handleRulesReset}
					onCloseModal={() => setDeleteRulesModal({ status: false })}
				/>
			)}
		</Content>
	);
};

export default AdView;
