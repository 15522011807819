import React, { FC } from 'react';
import classes from '../../styles/Table.module.css';

const TableNoContent: FC<{ colSpan: number; message?: string }> = ({ colSpan, message = 'No items found' }) => {
  return (
    <tr>
      <td colSpan={colSpan} className={classes.loaderRow}>
        {message}
      </td>
    </tr>
  );
};

export default TableNoContent;
