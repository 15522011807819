import toast from 'react-hot-toast';
import useApi from '../useApi';
import { IUploadPresInfoRes } from './index.type';

const useUploadPresInfo = () => {
  const { loading: uploadPresInfoLoading, makeApiCall } = useApi({
    url: `/advertise/upload-file`,
    method: 'POST',
    headerContentType: 'multipart/form-data',
  });

  const uploadPresInfo = async (formData: FormData) => {
    const { response, error } = await makeApiCall<FormData, IUploadPresInfoRes>(formData);
    if (response?.status) {
      return response;
    }

    if (error || !response?.status) {
      toast.error('Something went wrong uploading prescribing info. Please try again later');
    }
  };

  return {
    uploadPresInfo,
    uploadPresInfoLoading,
  };
};

export default useUploadPresInfo;
