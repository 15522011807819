import type { FC, PropsWithChildren } from 'react';
import classes from '../../styles/PatientEnrollment.module.css';

type CarouselItemProps = PropsWithChildren<{
  title: string;
  subTitle: string;
}>;

const CarouselItem: FC<CarouselItemProps> = props => {
  return (
    <div className={classes.carousel_item}>
      {props.children}

      <div className={classes.carousel_texts}>
        <p className={classes.carousel_title}>{props.title}</p>
        <p className={classes.carousel_subtitle}>{props.subTitle}</p>
      </div>
    </div>
  );
};

export default CarouselItem;
