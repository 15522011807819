import type { FC, PropsWithChildren } from 'react';
import { Modal, ModalForm, InputsSectionColumn, ModalButtons } from '../../components/Modal';
import ModalHeading from '../../components/Modal/ModalHeading';
import DeleteModalSkeleton from '../../Skeletons/DeleteModalSkeleton';
import { AddEditModalState } from './AddEditModal';

type DeleteModalProps = PropsWithChildren<{
  loading: boolean;
  deleteModal: AddEditModalState;
  onCloseModal?: () => void;
  handleDeleteAppointment?: (event: React.FormEvent<HTMLFormElement>) => void;
}>;

const DeleteAppointmentModal: FC<DeleteModalProps> = props => {
  return (
    <Modal show={props.deleteModal.status}>
      <ModalHeading heading={`Delete Appointment`} />
      {props.loading ? (
        <DeleteModalSkeleton />
      ) : (
        <ModalForm style={{ marginBottom: '2.5rem' }} onSubmit={props.handleDeleteAppointment}>
          <InputsSectionColumn style={{ width: '34rem' }}>
            <p className='dangerText'>
              Are you sure you want to delete this appointment?
              <br />
              Please note that it can not be restored back after deletion.
            </p>
          </InputsSectionColumn>
          <InputsSectionColumn style={{ width: '34rem' }}>
            <p className='primaryText'>If you need this appointment reference in future, we recommend to cancel this appointment, insted of deleting it.</p>
          </InputsSectionColumn>
          <ModalButtons submitButtonLabel='Delete Appointment' cancelButtonLabel='Cancel' onCancel={props.onCloseModal} />
        </ModalForm>
      )}
    </Modal>
  );
};

export default DeleteAppointmentModal;
