import React from 'react';
import toast from 'react-hot-toast';
import useApi from '../useApi';
import { IUsersRes } from './index.type';

const useGetAllUsers = ({ pageNumber, perpageData, selectedClinic }: { pageNumber: number; perpageData: number; selectedClinic: string }) => {
  const [usersData, setUsersData] = React.useState<IUsersRes>();

  const url = selectedClinic ? `/users?page=${pageNumber}&limit=${perpageData}&clinic=${selectedClinic}` : `/users?page=${pageNumber}&limit=${perpageData}`;
  const { loading: usersDataLoading, makeApiCall } = useApi({
    url,
    method: 'GET',
  });

  const getUsersData = async () => {
    const { response, error } = await makeApiCall<undefined, IUsersRes>();
    if (response?.status === 'success') {
      setUsersData(response);
    }

    if (response?.status === 'fail') {
      setUsersData(undefined);
    }

    if (error) toast.error('Something went wrong while fetching users data. Please try again later');
  };

  React.useEffect(() => {
    getUsersData();
    // eslint-disable-next-line
  }, [pageNumber, selectedClinic]);

  return {
    usersData,
    getUsersData,
    usersDataLoading,
  };
};

export default useGetAllUsers;
