import React from 'react';
import { CustomTableRow, Table, TableBody, TableHead, TableImageRow, TableNoContent, TableRowTitle } from '../../components/Table';
import { checkinsTableHeadData } from '../../hooks/checkins/staticData';
import TableSkeleton from '../../Skeletons/TableSkeleton';
import { ICheckin, ICheckinRes, IClinicStatusRes } from '../../hooks/checkins/index.type';
import classes from '../../styles/Table.module.css';
import { TimeButtonTwo } from '../../components/AppointmentTime';

interface CheckinTableProps {
  loading: boolean;
  loggedInUserRole: string;
  checkIns?: ICheckinRes;
  statusData?: IClinicStatusRes;
  handleUpdateStatus: (appointmentId: string, statusId: string) => void;
  handleCheckInOut: (appointmentId: string, checkStatus: 'check-in' | 'check-out') => void;
}
export const getEncryptedName = (name: string) => {
  let split = name.split(' ');
  let first_name = split[0] ? split[0].split(/\s/).reduce((response, word) => (response += word.slice(0, 1)), '') : 'NA';
  let last_name = split[1] ? '.' + split[1].slice(0, 3) : '';
  return first_name + last_name;
};

export const tConvert = (time: any) => {
  // Check correct time format and split into components
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

  if (time.length > 1) {
    // If time format correct
    time = time.slice(1); // Remove full string match value
    time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }
  return time.join(''); // return adjusted time or original string
};

const CheckinTable: React.FC<CheckinTableProps> = props => {
  return (
    <Table>
      <TableHead data={checkinsTableHeadData} />
      <TableBody>
        <CustomTableRow>
          {props.loading ? (
            <TableSkeleton rows={7} columns={10} />
          ) : props.checkIns && props.checkIns.data.result.length > 0 ? (
            props.checkIns.data.result.map((checkIn: ICheckin) => (
              <tr key={checkIn._id}>
                <TableImageRow alt={checkIn.user.name} />
                <TableRowTitle>{checkIn.user ? getEncryptedName(checkIn.user.name) : ''}</TableRowTitle>
                <td>{checkIn.doctor.name}</td>
                <td>
                  {checkIn.appointmentDateString ? checkIn.appointmentDateString : <span>NA</span>} &nbsp;
                  {tConvert(checkIn.appointmentTime)}
                </td>
                <td>{checkIn.checkInDateString ? checkIn.checkInDateString : '--'}</td>
                <td>{checkIn.checkOutDateString ? checkIn.checkOutDateString : !checkIn.isCheckedIn && !checkIn.isCheckedOut && <span>NA</span>}</td>
                <td>
                  {checkIn.status && checkIn.isCheckedIn && checkIn.isCheckedOut ? (
                    props.statusData &&
                    props.statusData.data &&
                    props.statusData.data.result.length > 0 &&
                    props.statusData.data.result.map(item => item._id === checkIn.status && <span key={item._id}>{item.title}</span>)
                  ) : props.loggedInUserRole === 'clinic' || props.loggedInUserRole === 'manager' ? (
                    <div className={classes.table__selectBox}>
                      <select
                        id={checkIn._id + 'selectBoxStatus'}
                        className={classes.table__select}
                        value={checkIn.status}
                        onChange={e => props.handleUpdateStatus(checkIn._id, e.target.value)}
                        style={{ width: '100%' }}
                      >
                        {props.statusData &&
                          props.statusData.data &&
                          props.statusData.data.result.length > 0 &&
                          props.statusData.data.result.map((item, index) => (
                            <option key={index} value={item._id}>
                              {item.title}
                            </option>
                          ))}
                      </select>
                    </div>
                  ) : (
                    props.statusData &&
                    props.statusData.data &&
                    props.statusData.data.result.length > 0 &&
                    props.statusData.data.result.map(item => item._id === checkIn.status && <span key={item._id}>{item.title}</span>)
                  )}
                </td>
                <td>
                  <div className='checkout'>
                    {(props.loggedInUserRole === 'clinic' || props.loggedInUserRole === 'manager') && !checkIn.isCheckedIn && !checkIn.isCheckedOut && (
                      <TimeButtonTwo onClick={() => props.handleCheckInOut(checkIn._id, 'check-in')}>Check In</TimeButtonTwo>
                    )}

                    {(props.loggedInUserRole === 'clinic' || props.loggedInUserRole === 'manager') && checkIn.isCheckedIn && !checkIn.isCheckedOut && (
                      <TimeButtonTwo onClick={() => props.handleCheckInOut(checkIn._id, 'check-out')}>Check Out</TimeButtonTwo>
                    )}
                    {checkIn.isCheckedIn && checkIn.isCheckedOut && '--'}
                  </div>
                </td>
              </tr>
            ))
          ) : (
            <TableNoContent colSpan={checkinsTableHeadData.length} message='No data found' />
          )}
        </CustomTableRow>
      </TableBody>
    </Table>
  );
};

export default CheckinTable;
