import React from 'react';
import styles from '../styles/noCampaign.module.css';

const NoCampaign = () => {
  return (
    <div className={styles.container}>
      <div>
        <h1>No Campaigns Found</h1>
        <h3>Toggle All to see inactive campaigns or,</h3>
        <h3>Try to create new campaign.</h3>
      </div>
      <img src='/assets/nodata.png' alt='No Campaigns Found.' />
    </div>
  );
};

export default NoCampaign;
