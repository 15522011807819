import React from 'react';
import { toast } from 'react-hot-toast';
import useApi from '../useApi';
import { IGetAllCampaignImageRes } from './index.type';

const useGetAllCampaignImages = () => {
  const [campaignImagesData, setCampaignImagesData] = React.useState<IGetAllCampaignImageRes>();

  const { loading: getCampaignImagesLoading, makeApiCall } = useApi({
    url: `/advertise/campaign-image-list`,
    method: 'GET',
  });

  const getCampaignImage = async () => {
    const { response, error } = await makeApiCall<undefined, IGetAllCampaignImageRes>();
    if (response?.status) {
      setCampaignImagesData(response);
    }

    if (error || !response?.status) {
      setCampaignImagesData(undefined);
      toast.error('Something went wrong while fetching ads data. Please try again later');
    }
  };

  React.useEffect(() => {
    getCampaignImage();
    // eslint-disable-next-line
  }, []);

  return {
    campaignImagesData,
    getCampaignImage,
    getCampaignImagesLoading,
  };
};

export default useGetAllCampaignImages;
