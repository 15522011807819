import type { FC, PropsWithChildren } from 'react';
import classes from './Button.module.css';

type ButtonOutlineProps = PropsWithChildren<{
  label: string;
  icon?: string;
  disabled?: boolean;
  variant?: 'primary' | 'danger';
  type?: 'submit' | 'button' | 'reset';
  style?: React.CSSProperties;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}>;

const ButtonOutline: FC<ButtonOutlineProps> = props => {
  return (
    <button
      className={props.variant === 'danger' ? classes.button_danger : classes.button}
      disabled={props.disabled}
      type={props.type}
      style={props.style}
      onClick={props.onClick}
    >
      {props.icon && (
        <svg className={classes.button_icon}>
          <use xlinkHref={`/assets/sprite.svg#icon-${props.icon}`} />
        </svg>
      )}
      {props.label}
    </button>
  );
};

export default ButtonOutline;
