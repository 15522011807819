import toast from "react-hot-toast";
import useApi from "../useApi";
import { IImageSrcListResArray, IViewRequest } from "./index.type";

const useGeneratedAd = () => {
	const { loading: viewRequestLoading, makeApiCall } = useApi({
		url: `/advertise/view-ads-with-rules`,
		method: "POST",
		headerContentType: "application/json",
	});

	const generatedAd = async (data: IViewRequest) => {
		const { response, error } = await makeApiCall<
			IViewRequest,
			IImageSrcListResArray
		>(data);
		if (response?.status) {
			return response;
		}

		if (error || !response?.status) {
			toast.error(
				"Something went wrong fetching the Ad. Please try again later"
			);
		}
	};

	return {
		generatedAd,
		viewRequestLoading,
	};
};

export default useGeneratedAd;
