import { apiCallBegan } from '../../actions/afterAuth';
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { APIErrorResp } from '../index.types';
import { IOnlineUserResp, IDetailsState } from './index.types';

const initialState: IDetailsState = {
  loading: false,
  users: [],
};

const onlineUsersSlice = createSlice({
  name: 'online-users',
  initialState,
  reducers: {
    request: state => {
      state.loading = true;
    },
    setOnlineUsers: (state, { payload }: { payload: IOnlineUserResp[] }) => {
      state.loading = false;
      state.users = payload;
    },
    resetOnlineUsers: state => {
      state = initialState;
    },
    fail: (state, { payload: data }: { payload: APIErrorResp }) => {
      state.loading = false;
      state.users = [];
    },
  },
});

export const { fail, request, setOnlineUsers, resetOnlineUsers } = onlineUsersSlice.actions;

export const onlineUsersSliceValue = (state: RootState) => state.onlineUsers;

export default onlineUsersSlice.reducer;
