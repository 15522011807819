import React from 'react';
import toast from 'react-hot-toast';
import useApi from '../useApi';
import { IFolderByIdRes } from './index.type';

const useSubFolders = ({ folderId }: { folderId?: string }) => {
  const [subFoldersData, setSubFoldersData] = React.useState<IFolderByIdRes>();

  const { loading: subFoldersDataLoading, makeApiCall } = useApi({
    url: `/folders/${folderId}`,
    method: 'GET',
  });

  const getSubFoldersData = async () => {
    const { response, error } = await makeApiCall<undefined, IFolderByIdRes>();
    if (response?.status === 'success') {
      setSubFoldersData(response);
    }

    if (response?.status === 'fail') {
      setSubFoldersData(undefined);
    }

    if (error) toast.error('Something went wrong while fetching sub folders. Please try again later');
  };

  return {
    subFoldersData,
    getSubFoldersData,
    subFoldersDataLoading,
  };
};

export default useSubFolders;
