import type { FC, PropsWithChildren } from 'react';
import FileExchangeStyles from '../../styles/FileExchangeScreen.module.css';

type RecentlyUploadedFileProps = PropsWithChildren<{
  name: string;
  uploadedBy: string;
  uploadedAt: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}>;

const RecentlyUploadedFile: FC<RecentlyUploadedFileProps> = props => {
  return (
    <button className={FileExchangeStyles.recentUpload} onClick={props.onClick}>
      <svg className={FileExchangeStyles.recentUploadIcon}>
        <use xlinkHref={`/assets/sprite.svg#icon-zip`} />
      </svg>
      <div className={FileExchangeStyles.recentUploadData}>
        <h3 className={FileExchangeStyles.recentUploadName}>{props.name}</h3>
        <p className={FileExchangeStyles.recentUploadDate}>Uploaded By {props.uploadedBy}</p>
        <p className={FileExchangeStyles.recentUploadDate}>{props.uploadedAt}</p>
      </div>
    </button>
  );
};

export default RecentlyUploadedFile;
