import React from 'react';
import type { PropsWithChildren } from 'react';

type Props = PropsWithChildren<{
  label: string;
  parentClassName?: string;
  withP?: boolean;
  parentStyle?: React.CSSProperties;
  spanStyle?: React.CSSProperties;
}>;
interface SelectBoxProps extends Props, React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement> {}

const SelectBox: React.FC<SelectBoxProps> = props => {
  return (
    <div className={props.parentClassName} style={props.parentStyle}>
      {props.withP ? <p>{props.label}</p> : props.label}
      <span style={props.spanStyle}>
        <select {...props}>{props.children}</select>
      </span>
    </div>
  );
};

export default SelectBox;
