import type { FC, PropsWithChildren } from 'react';
import { Modal, ModalForm, InputsSection, InputBox, ModalButtons } from '../../components/Modal';
import ModalHeading from '../../components/Modal/ModalHeading';
import DeleteModalSkeleton from '../../Skeletons/DeleteModalSkeleton';
import { IAd } from '../../hooks/advertisements/index.type';

export type DeleteModalState = PropsWithChildren<{
  status: boolean;
  data?: IAd;
}>;

export type IdeleteModalStates = PropsWithChildren<{
  title: { value: string; error: string };
}>;

type AddEditModalProps = PropsWithChildren<{
  loading: boolean;
  deleteModal: DeleteModalState;
  deleteModalStates: IdeleteModalStates;
  setDeleteModalStates: React.Dispatch<React.SetStateAction<IdeleteModalStates>>;
  onCloseModal?: () => void;
  handleDelete?: (event: React.FormEvent<HTMLFormElement>) => void;
}>;

const DeleteModal: FC<AddEditModalProps> = props => {
  return (
    <Modal show={props.deleteModal.status}>
      <ModalHeading heading={`Delete ${props.deleteModal.data?.advertise.title}`} />
      {props.loading ? (
        <DeleteModalSkeleton />
      ) : (
        props.deleteModal.data && (
          <ModalForm style={{ marginBottom: '2.5rem' }} onSubmit={props.handleDelete}>
            <InputsSection style={{ width: '34rem', flexWrap: 'wrap' }}>
              <InputBox
                label={`Enter ${props.deleteModal.data.advertise.title} to delete this campaign`}
                placeholder={props.deleteModal.data.advertise.title}
                value={props.deleteModalStates.title.value}
                onChange={event => {
                  props.setDeleteModalStates(prevVal => ({ ...prevVal, title: { value: event.target.value, error: '' } }));
                }}
                errorMessage={props.deleteModalStates.title.error}
                style={{ width: '30rem' }}
              />
              <p className='dangerText'>Please note that this will delete this campaign entire data and will not be restored in future.</p>
            </InputsSection>
            <InputsSection style={{ width: '34rem' }}>
              <p className='primaryText'>
                If your intension is to restrict this ad campaign from not being shown in result, we recommend to change active status to false instead of deleting.
              </p>
            </InputsSection>
            <ModalButtons submitButtonLabel='Delete Campaign' cancelButtonLabel='Cancel' onCancel={props.onCloseModal} />
          </ModalForm>
        )
      )}
    </Modal>
  );
};

export default DeleteModal;
