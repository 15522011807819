import React from 'react';
import toast from 'react-hot-toast';
import useApi from '../useApi';
import { ICampaignCountRes } from './index.type';

const useCampaignCount = ({ filter }: { filter?: string }) => {
  const [campaignCountData, setCampaignCountData] = React.useState<ICampaignCountRes>();

  const url = filter ? `/advertise/campaign-count?date=${new Date().toISOString()}&filter=${filter}` : `/advertise/campaign-count?date=${new Date().toISOString()}`;

  const { loading: campaignCountLoading, makeApiCall } = useApi({
    url,
    method: 'GET',
  });

  const getCampaignCount = async () => {
    const { response, error } = await makeApiCall<undefined, ICampaignCountRes>();
    if (response?.status) {
      setCampaignCountData(response);
    }

    if (!response?.status) {
      setCampaignCountData(undefined);
    }

    if (error) toast.error('Something went wrong while fetching ads data. Please try again later');
  };

  React.useEffect(() => {
    getCampaignCount();
    // eslint-disable-next-line
  }, [filter]);

  return {
    campaignCountData,
    campaignCountLoading,
  };
};

export default useCampaignCount;
