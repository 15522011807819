import React from 'react';
import toast from 'react-hot-toast';
import useApi from '../../useApi';
import { IClinicsNewRes } from '../index.type';

const useAllClinics = ({ pageNumber, perpageData }: { pageNumber: number; perpageData: number }) => {
  const [clinicsData, setClinicsData] = React.useState<IClinicsNewRes>();

  const { loading: clinicsDataLoading, makeApiCall } = useApi({
    url: `/clinic?page=${pageNumber}&limit=${perpageData}`,
    method: 'GET',
  });

  const getClinicsData = async () => {
    const { response, error } = await makeApiCall<undefined, IClinicsNewRes>();
    if (response?.status === 'success') {
      setClinicsData(response);
    }

    if (response?.status === 'fail') {
      setClinicsData(undefined);
    }

    if (error) toast.error('Something went wrong while fetching clinic data. Please try again later');
  };

  return {
    clinicsData,
    getClinicsData,
    clinicsDataLoading,
  };
};

export default useAllClinics;
