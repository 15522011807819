import type { FC, PropsWithChildren } from 'react';
import './Modal.css';

type ModalProps = PropsWithChildren<{
  show: boolean;
  style?: React.CSSProperties;
}>;

const Modal: FC<ModalProps> = props => {
  if (!props.show) {
    return null;
  }

  return (
    <div className='modalContainer'>
      <div className='modal' id='modal' style={props.style}>
        {props.children}
      </div>
    </div>
  );
};

export default Modal;
