import React from 'react';

import ModalForm from '../../../components/Modal/ModalForm';
import ModalButtons from '../../../components/Modal/ModalButtons';
import AddUserSkeleton from '../../../Skeletons/AddUserSkeleton';

import classes from './InsurancePolicies.module.css';
import moment from 'moment';
import { inputPhoneMasking } from '../../../utils/validators';

const ContactCareForm = ({ patient, handleEdit, closeInsurancePoliciesModal, contactCareForm, loading }) => {
  return (
    <React.Fragment>
      {loading ? (
        <AddUserSkeleton />
      ) : contactCareForm.error ? (
        <div className={classes.form_content}>
          <div className={classes.form_container}>
            <p className={classes.form_container_heading}>User has not filled form yet.</p>
          </div>
        </div>
      ) : (
        Object.keys(contactCareForm.data).length > 0 && (
          <div className={classes.form_content}>
            <div className={classes.form_heading}>
              <p className={classes.form_heading_content}>
                Patient's Name<span>{patient.name ? patient.name : '-'}</span>
              </p>
              <p className={classes.form_heading_content}>
                Patient's BirthDate
                <span>{patient.dob ? moment(patient.dob).format('MMM DD YYYY') : '-'}</span>
              </p>
              <p className={classes.form_heading_content}>
                Today's Date
                <span>{moment(contactCareForm.data?.modifiedDateString).format('MMM DD YYYY')}</span>
              </p>
            </div>

            <div className={classes.form_container}>
              <p className={classes.form_container_heading}>
                Who can we contact regarding your care and billing?
                <br />
                People with whom we can discuss your care, test results, and billing information:
              </p>
              <div className={classes.form_relations}>
                {contactCareForm.data?.patientCareList.length > 0 &&
                  contactCareForm.data?.patientCareList.map(list => (
                    <div key={list._id} className={classes.form_relation}>
                      <p className={classes.form_relation_text}>
                        Name <span>{list.name ? list.name : '--'}</span>
                      </p>
                      <p className={classes.form_relation_text}>
                        Relationship <span>{list.relationship ? list.relationship : '--'}</span>
                      </p>
                      <p className={classes.form_relation_text}>
                        Phone <span>{list.phone ? inputPhoneMasking(list.phone) : '--'}</span>
                      </p>
                    </div>
                  ))}
              </div>
            </div>

            <div className={classes.form_container}>
              <p className={classes.form_container_heading}>Can we leave care, test results, and billing information on voicemail or answering machines listed below?</p>
              <div className={classes.form_numbers}>
                <p className={classes.form_numbers_text}>
                  Home Phone: <span>{contactCareForm.data?.homePhone ? inputPhoneMasking(contactCareForm.data?.homePhone) : '--'}</span>
                </p>
                <p className={classes.form_numbers_text}>
                  Work Phone: <span>{contactCareForm.data?.workPhone ? inputPhoneMasking(contactCareForm.data?.workPhone) : '--'}</span>
                </p>
                <p className={classes.form_numbers_text}>
                  Cell Phone: <span>{contactCareForm.data?.cellPhone ? inputPhoneMasking(contactCareForm.data?.cellPhone) : '--'}</span>
                </p>
              </div>
            </div>
          </div>
        )
      )}

      <ModalForm style={{ marginBottom: '2.5rem' }}>
        <ModalButtons
          submitButtonLabel={Object.keys(contactCareForm.data).length > 0 ? 'Edit Contact Care Form' : 'Fill Contact Care Form'}
          onSubmit={handleEdit}
          cancelButtonLabel='Close Insurance Policies'
          onCancel={closeInsurancePoliciesModal}
        />
      </ModalForm>
    </React.Fragment>
  );
};

export default ContactCareForm;
