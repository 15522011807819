import React from 'react';

const ReportingSVG = () => {
  return (
    <svg viewBox='0 0 500 500' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clip-path='url(#clip0_1768_240)'>
        <path d='M489.148 445.399H447.648V445.712H489.148V445.399Z' fill='#EBEBEB' />
        <path d='M340.44 448.807H329.551V449.12H340.44V448.807Z' fill='#EBEBEB' />
        <path d='M446.395 433.771H422.35V434.084H446.395V433.771Z' fill='#EBEBEB' />
        <path d='M45.2667 435.876H-8.85107V436.189H45.2667V435.876Z' fill='#EBEBEB' />
        <path d='M64.3628 435.876H56.4312V436.189H64.3628V435.876Z' fill='#EBEBEB' />
        <path d='M207.532 441.164H90.1494V441.477H207.532V441.164Z' fill='#EBEBEB' />
        <path
          d='M222.38 369.353H-19.5521C-21.4496 369.353 -23.2694 368.599 -24.6112 367.258C-25.953 365.916 -26.7068 364.096 -26.7068 362.198V22.0921C-26.6903 20.2054 -25.9292 18.4016 -24.5892 17.0734C-23.2492 15.7451 -21.4388 14.9999 -19.5521 15H222.38C224.278 15 226.098 15.7538 227.44 17.0956C228.781 18.4373 229.535 20.2572 229.535 22.1547V362.198C229.535 364.096 228.781 365.916 227.44 367.258C226.098 368.599 224.278 369.353 222.38 369.353ZM-19.5521 15.2506C-21.3655 15.2539 -23.1037 15.9758 -24.386 17.2581C-25.6684 18.5404 -26.3902 20.2786 -26.3935 22.0921V362.198C-26.3902 364.012 -25.6684 365.75 -24.386 367.032C-23.1037 368.315 -21.3655 369.037 -19.5521 369.04H222.38C224.194 369.037 225.932 368.315 227.214 367.032C228.497 365.75 229.219 364.012 229.222 362.198V22.0921C229.219 20.2786 228.497 18.5404 227.214 17.2581C225.932 15.9758 224.194 15.2539 222.38 15.2506H-19.5521Z'
          fill='#EBEBEB'
        />
        <path
          d='M493.421 369.353H251.463C249.567 369.35 247.749 368.595 246.408 367.254C245.067 365.913 244.312 364.095 244.308 362.198V22.0921C244.328 20.2064 245.09 18.4045 246.43 17.077C247.769 15.7494 249.577 15.0032 251.463 15H493.421C495.303 15.0065 497.107 15.7546 498.442 17.0822C499.776 18.4097 500.534 20.2097 500.55 22.0921V362.198C500.55 364.092 499.8 365.908 498.464 367.249C497.127 368.59 495.314 369.347 493.421 369.353ZM251.463 15.2506C249.65 15.2539 247.911 15.9758 246.629 17.2581C245.347 18.5404 244.625 20.2786 244.622 22.0921V362.198C244.625 364.012 245.347 365.75 246.629 367.032C247.911 368.315 249.65 369.037 251.463 369.04H493.421C495.234 369.037 496.972 368.315 498.255 367.032C499.537 365.75 500.259 364.012 500.262 362.198V22.0921C500.259 20.2786 499.537 18.5404 498.255 17.2581C496.972 15.9758 495.234 15.2539 493.421 15.2506H251.463Z'
          fill='#EBEBEB'
        />
        <path d='M551.924 425.238H-74.5845V425.551H551.924V425.238Z' fill='#EBEBEB' />
        <path
          d='M236.966 272.307H233.207V227.324C233.269 222.514 231.484 217.864 228.221 214.33C224.958 210.796 220.464 208.648 215.664 208.328C213.24 208.206 210.815 208.564 208.528 209.38C206.242 210.196 204.138 211.454 202.338 213.083C200.538 214.712 199.076 216.68 198.037 218.874C196.998 221.068 196.401 223.445 196.28 225.87C196.271 226.107 196.17 226.332 196 226.497C195.83 226.663 195.603 226.757 195.366 226.76H193.436C193.313 226.76 193.191 226.735 193.078 226.688C192.965 226.641 192.862 226.571 192.776 226.484C192.689 226.396 192.621 226.293 192.576 226.179C192.53 226.065 192.507 225.943 192.509 225.82C192.759 220.156 195.159 214.801 199.218 210.844C203.278 206.887 208.693 204.625 214.361 204.519C226.791 204.306 236.916 214.906 236.916 227.349L236.966 272.307Z'
          fill='#F0F0F0'
        />
        <path d='M480.114 277.219H453.399V425.188H480.114V277.219Z' fill='#E6E6E6' />
        <path d='M460.692 277.219H171.245V425.188H460.692V277.219Z' fill='#F5F5F5' />
        <path
          d='M460.692 308.031H171.245V310.537H242.354V425.188H244.86V310.537H314.026V425.188H316.532V310.537H385.711V425.188H388.217V310.537H460.692V308.031Z'
          fill='#E0E0E0'
        />
        <path
          d='M235.149 373.814C234.484 373.814 233.847 373.55 233.377 373.08C232.907 372.61 232.643 371.972 232.643 371.308V360.031C232.643 359.366 232.907 358.729 233.377 358.259C233.847 357.789 234.484 357.525 235.149 357.525C235.813 357.525 236.451 357.789 236.921 358.259C237.391 358.729 237.655 359.366 237.655 360.031V371.308C237.655 371.972 237.391 372.61 236.921 373.08C236.451 373.55 235.813 373.814 235.149 373.814Z'
          fill='#E6E6E6'
        />
        <path
          d='M252.064 373.814C251.4 373.814 250.762 373.55 250.292 373.08C249.822 372.61 249.558 371.972 249.558 371.308V360.031C249.558 359.366 249.822 358.729 250.292 358.259C250.762 357.789 251.4 357.525 252.064 357.525C252.729 357.525 253.366 357.789 253.836 358.259C254.306 358.729 254.57 359.366 254.57 360.031V371.308C254.57 371.972 254.306 372.61 253.836 373.08C253.366 373.55 252.729 373.814 252.064 373.814Z'
          fill='#E6E6E6'
        />
        <path
          d='M378.507 373.814C377.842 373.814 377.204 373.55 376.734 373.08C376.265 372.61 376 371.972 376 371.308V360.031C376 359.366 376.265 358.729 376.734 358.259C377.204 357.789 377.842 357.525 378.507 357.525C379.171 357.525 379.809 357.789 380.279 358.259C380.749 358.729 381.013 359.366 381.013 360.031V371.308C381.013 371.972 380.749 372.61 380.279 373.08C379.809 373.55 379.171 373.814 378.507 373.814Z'
          fill='#E6E6E6'
        />
        <path
          d='M395.422 373.814C394.758 373.814 394.12 373.55 393.65 373.08C393.18 372.61 392.916 371.972 392.916 371.308V360.031C392.916 359.366 393.18 358.729 393.65 358.259C394.12 357.789 394.758 357.525 395.422 357.525C396.087 357.525 396.724 357.789 397.194 358.259C397.664 358.729 397.928 359.366 397.928 360.031V371.308C397.928 371.972 397.664 372.61 397.194 373.08C396.724 373.55 396.087 373.814 395.422 373.814Z'
          fill='#E6E6E6'
        />
        <path d='M486.379 273.974H453.174V280.452H486.379V273.974Z' fill='#E0E0E0' />
        <path d='M463.198 273.974H164.98V280.452H463.198V273.974Z' fill='#EBEBEB' />
        <path
          d='M271.524 274.713H172.673C172.035 274.713 171.423 274.459 170.972 274.008C170.521 273.557 170.268 272.945 170.268 272.307C170.268 271.669 170.521 271.057 170.972 270.606C171.423 270.155 172.035 269.901 172.673 269.901H271.524C272.162 269.901 272.774 270.155 273.225 270.606C273.676 271.057 273.93 271.669 273.93 272.307C273.93 272.945 273.676 273.557 273.225 274.008C272.774 274.459 272.162 274.713 271.524 274.713Z'
          fill='#E6E6E6'
        />
        <path d='M246.414 265.854H242.667V270.615H246.414V265.854Z' fill='#E0E0E0' />
        <path d='M248.017 267.044H241.051L242.404 258.712H246.664L248.017 267.044Z' fill='#F0F0F0' />
        <path d='M227.456 265.854H223.709V270.615H227.456V265.854Z' fill='#E0E0E0' />
        <path d='M229.072 267.044H222.092L223.458 258.712H227.706L229.072 267.044Z' fill='#F0F0F0' />
        <path d='M306.921 36.8151H304.666V212.701H306.921V36.8151Z' fill='#E0E0E0' />
        <path d='M447.322 36.8151H445.067V212.701H447.322V36.8151Z' fill='#E0E0E0' />
        <path d='M456.156 47.879H295.832L292.449 39.985H459.539L456.156 47.879Z' fill='#EBEBEB' />
        <path d='M456.156 58.7177H295.832L292.449 50.8237H459.539L456.156 58.7177Z' fill='#EBEBEB' />
        <path d='M456.156 69.5564H295.832L292.449 61.6624H459.539L456.156 69.5564Z' fill='#EBEBEB' />
        <path d='M456.156 80.395H295.832L292.449 72.501H459.539L456.156 80.395Z' fill='#EBEBEB' />
        <path d='M456.156 91.2336H295.832L292.449 83.3396H459.539L456.156 91.2336Z' fill='#EBEBEB' />
        <path d='M456.156 102.072H295.832L292.449 94.178H459.539L456.156 102.072Z' fill='#EBEBEB' />
        <path d='M456.156 112.911H295.832L292.449 105.017H459.539L456.156 112.911Z' fill='#EBEBEB' />
        <path d='M456.156 123.737H295.832L292.449 115.855H459.539L456.156 123.737Z' fill='#EBEBEB' />
        <path d='M456.156 134.588H295.832L292.449 126.681H459.539L456.156 134.588Z' fill='#EBEBEB' />
        <path d='M456.156 145.414H295.832L292.449 137.52H459.539L456.156 145.414Z' fill='#EBEBEB' />
        <path d='M456.156 156.253H295.832L292.449 148.359H459.539L456.156 156.253Z' fill='#EBEBEB' />
        <path d='M456.156 167.091H295.832L292.449 159.197H459.539L456.156 167.091Z' fill='#EBEBEB' />
        <path d='M456.156 177.93H295.832L292.449 170.036H459.539L456.156 177.93Z' fill='#EBEBEB' />
        <path d='M456.156 188.768H295.832L292.449 180.874H459.539L456.156 188.768Z' fill='#EBEBEB' />
        <path d='M456.156 199.607H295.832L292.449 191.713H459.539L456.156 199.607Z' fill='#EBEBEB' />
        <path d='M456.156 210.445H295.832L292.449 202.551H459.539L456.156 210.445Z' fill='#EBEBEB' />
        <path d='M459.539 210.446H292.449V214.956H459.539V210.446Z' fill='#E0E0E0' />
        <path d='M465.365 30.0989H286.623V40.6242H465.365V30.0989Z' fill='#E0E0E0' />
        <path
          d='M288.465 136.054C287.983 136.054 287.521 135.863 287.18 135.522C286.839 135.181 286.648 134.719 286.648 134.237V72.8392C286.648 72.3574 286.839 71.8952 287.18 71.5545C287.521 71.2138 287.983 71.0223 288.465 71.0223C288.947 71.0223 289.409 71.2138 289.75 71.5545C290.09 71.8952 290.282 72.3574 290.282 72.8392V134.237C290.282 134.719 290.09 135.181 289.75 135.522C289.409 135.863 288.947 136.054 288.465 136.054Z'
          fill='#E0E0E0'
        />
        <path d='M289.116 38.5192H287.801V74.982H289.116V38.5192Z' fill='#E0E0E0' />
        <path d='M78.3086 344.669H131.249V336.524H78.3086V344.669Z' fill='#E0E0E0' />
        <path
          d='M134.381 425.175H76.7425V345.32C76.7425 344.234 77.1742 343.191 77.9426 342.423C78.711 341.655 79.7532 341.223 80.8399 341.223H130.334C131.421 341.223 132.463 341.655 133.231 342.423C134 343.191 134.431 344.234 134.431 345.32V425.175H134.381Z'
          fill='#F0F0F0'
        />
        <path
          d='M81.2659 339.03H129.858C132.356 339.03 134.381 337.005 134.381 334.507V331.023C134.381 328.525 132.356 326.5 129.858 326.5H81.2659C78.7677 326.5 76.7426 328.525 76.7426 331.023V334.507C76.7426 337.005 78.7677 339.03 81.2659 339.03Z'
          fill='#F0F0F0'
        />
        <path d='M117.465 425.188H144.405V421.429L117.465 418.923V425.188Z' fill='#E0E0E0' />
        <path
          d='M436.784 224.404H413.466C411.177 224.407 408.983 225.318 407.365 226.936C405.746 228.555 404.836 230.749 404.832 233.037V235.919H409.481V233.025C409.479 232.501 409.581 231.981 409.781 231.496C409.98 231.011 410.273 230.571 410.644 230.199C411.014 229.828 411.454 229.533 411.938 229.332C412.422 229.131 412.941 229.028 413.466 229.028H436.784C437.842 229.031 438.856 229.454 439.603 230.203C440.349 230.952 440.769 231.967 440.769 233.025V235.907H445.418V233.025C445.414 230.737 444.503 228.545 442.884 226.928C441.266 225.312 439.072 224.404 436.784 224.404Z'
          fill='#E0E0E0'
        />
        <path
          d='M464.413 242.222V268.348C464.413 270.009 463.753 271.603 462.578 272.778C461.403 273.953 459.81 274.613 458.148 274.613H401.412C399.75 274.613 398.156 273.953 396.981 272.778C395.807 271.603 395.146 270.009 395.146 268.348V242.222C395.146 240.561 395.807 238.967 396.981 237.792C398.156 236.617 399.75 235.957 401.412 235.957H458.098C458.925 235.95 459.745 236.108 460.511 236.42C461.277 236.731 461.973 237.192 462.561 237.774C463.148 238.357 463.614 239.05 463.932 239.813C464.25 240.576 464.413 241.395 464.413 242.222Z'
          fill='#E6E6E6'
        />
        <path
          d='M455.58 242.222V268.348C455.58 270.009 454.919 271.603 453.745 272.778C452.57 273.953 450.976 274.613 449.314 274.613H392.578C390.916 274.613 389.323 273.953 388.148 272.778C386.973 271.603 386.313 270.009 386.313 268.348V242.222C386.313 240.561 386.973 238.967 388.148 237.792C389.323 236.617 390.916 235.957 392.578 235.957H449.264C450.091 235.95 450.911 236.108 451.677 236.42C452.443 236.731 453.14 237.192 453.727 237.774C454.314 238.357 454.78 239.05 455.098 239.813C455.416 240.576 455.58 241.395 455.58 242.222Z'
          fill='#F5F5F5'
        />
        <path d='M435.932 240.405H406.349V269.989H435.932V240.405Z' fill='white' />
        <path d='M423.665 247.059H418.666V264.263H423.665V247.059Z' fill='#E0E0E0' />
        <path d='M412.564 253.148V258.148H429.768V253.148H412.564Z' fill='#E0E0E0' />
        <path
          d='M232.63 477C366.807 477 475.578 470.649 475.578 462.816C475.578 454.982 366.807 448.632 232.63 448.632C98.4541 448.632 -10.3171 454.982 -10.3171 462.816C-10.3171 470.649 98.4541 477 232.63 477Z'
          fill='#F5F5F5'
        />
        <path
          d='M359.924 243.187C360.751 243.087 384.709 245.455 386.238 271.129L382.14 288.922C362.775 281.73 341.424 282.016 322.259 289.724L321.97 289.849L323.637 269.525C323.637 269.525 329.777 246.72 359.924 243.187Z'
          fill='#263238'
        />
        <path
          d='M309.615 367.185L314.427 367.599C316.185 337.046 319.636 306.613 324.764 276.442C325.688 271.566 327.57 266.923 330.302 262.78C333.033 258.637 336.56 255.078 340.678 252.309C342.417 251.054 344.313 250.03 346.316 249.264C349.999 247.686 353.951 246.836 357.957 246.758C359.583 246.758 361.208 246.871 362.819 247.096C366.453 247.654 369.922 249.002 372.981 251.043C374.414 252.14 375.786 253.316 377.09 254.564C377.779 255.277 378.42 256.034 379.008 256.832C381.121 259.864 382.578 263.303 383.285 266.93C383.993 270.557 383.936 274.292 383.117 277.895C378.017 305.476 374.457 333.32 372.454 361.296L377.266 361.622C379.233 333.865 382.743 306.24 387.779 278.873C388.74 274.609 388.799 270.191 387.951 265.903C387.103 261.615 385.367 257.552 382.854 253.975C380.377 250.487 377.162 247.587 373.437 245.482C369.712 243.376 365.569 242.118 361.302 241.796C342.507 240.543 322.948 255.817 320.053 275.414C314.884 305.789 311.4 336.426 309.615 367.185Z'
          fill='#263238'
        />
        <path
          opacity='0.3'
          d='M309.615 367.185L314.427 367.599C316.185 337.046 319.636 306.613 324.764 276.442C325.688 271.566 327.57 266.923 330.302 262.78C333.033 258.637 336.56 255.078 340.678 252.309C342.417 251.054 344.313 250.03 346.316 249.264C349.999 247.686 353.951 246.836 357.957 246.758C359.583 246.758 361.208 246.871 362.819 247.096C366.453 247.654 369.922 249.002 372.981 251.043C374.414 252.14 375.786 253.316 377.09 254.564C377.779 255.277 378.42 256.034 379.008 256.832C381.121 259.864 382.578 263.303 383.285 266.93C383.993 270.557 383.936 274.292 383.117 277.895C378.017 305.476 374.457 333.32 372.454 361.296L377.266 361.622C379.233 333.865 382.743 306.24 387.779 278.873C388.74 274.609 388.799 270.191 387.951 265.903C387.103 261.615 385.367 257.552 382.854 253.975C380.377 250.487 377.162 247.587 373.437 245.482C369.712 243.376 365.569 242.118 361.302 241.796C342.507 240.543 322.948 255.817 320.053 275.414C314.884 305.789 311.4 336.426 309.615 367.185Z'
          fill='white'
        />
        <path
          d='M377.354 364.279H266.662V361.71C266.662 359.274 267.63 356.938 269.352 355.215C271.075 353.493 273.411 352.525 275.847 352.525H368.157C370.593 352.525 372.929 353.493 374.651 355.215C376.374 356.938 377.341 359.274 377.341 361.71V364.279H377.354Z'
          fill='#263238'
        />
        <path d='M373.607 460.974H379.484L374.672 364.291H367.179L373.607 460.974Z' fill='#263238' />
        <path opacity='0.3' d='M373.607 460.974H379.484L374.672 364.291H367.179L373.607 460.974Z' fill='white' />
        <path d='M334.3 460.974H340.177L336.969 364.291H329.489L334.3 460.974Z' fill='#263238' />
        <path opacity='0.3' d='M334.3 460.974H340.177L336.969 364.291H329.489L334.3 460.974Z' fill='white' />
        <path d='M303.827 460.974H309.704L314.515 364.291H307.035L303.827 460.974Z' fill='#263238' />
        <path opacity='0.3' d='M303.827 460.974H309.704L314.515 364.291H307.035L303.827 460.974Z' fill='white' />
        <path d='M264.52 460.974H270.409L276.824 364.291H269.331L264.52 460.974Z' fill='#263238' />
        <path opacity='0.3' d='M264.52 460.974H270.409L276.824 364.291H269.331L264.52 460.974Z' fill='white' />
        <path d='M261.838 368.564H327.609V360.006H261.838V368.564Z' fill='#263238' />
        <path opacity='0.7' d='M261.838 368.564H327.609V360.006H261.838V368.564Z' fill='white' />
        <path d='M327.609 368.564H382.178V360.006H327.609V368.564Z' fill='#263238' />
        <path opacity='0.4' d='M327.609 368.564H382.178V360.006H327.609V368.564Z' fill='white' />
        <path
          d='M307.836 227.85C295.883 230.206 272.201 240.38 272.05 239.829C272.05 239.829 252.115 235.105 243.92 226.046C243.92 226.046 236.089 225.081 231.991 224.266C234.961 231.271 240.762 239.766 247.203 243.814C255.385 248.951 257.615 250.505 265.71 253.587C275.834 257.446 291.334 253.349 301.371 249.239C305.067 247.723 308.889 245.793 311.032 242.435C313.174 239.077 311.157 230.093 307.836 227.85Z'
          fill='#0F407A'
        />
        <path
          opacity='0.7'
          d='M307.836 227.85C295.883 230.206 272.201 240.38 272.05 239.829C272.05 239.829 252.115 235.105 243.92 226.046C243.92 226.046 236.089 225.081 231.991 224.266C234.961 231.271 240.762 239.766 247.203 243.814C255.385 248.951 257.615 250.505 265.71 253.587C275.834 257.446 291.334 253.349 301.371 249.239C305.067 247.723 308.889 245.793 311.032 242.435C313.174 239.077 311.157 230.093 307.836 227.85Z'
          fill='white'
        />
        <path d='M276.085 227.261H246.301C242.141 212.225 250.473 197.101 246.301 182.027H276.085C280.257 197.101 271.912 212.187 276.085 227.261Z' fill='#0F407A' />
        <g opacity='0.7'>
          <path d='M276.085 227.261H246.301C242.141 212.225 250.473 197.101 246.301 182.027H276.085C280.257 197.101 271.912 212.187 276.085 227.261Z' fill='white' />
        </g>
        <path
          d='M250.962 219.242L246.188 221.535C245.658 223.096 244.65 224.451 243.306 225.407C241.967 226.162 240.546 226.764 239.071 227.199C238.014 227.531 236.884 227.55 235.817 227.254C234.75 226.959 233.791 226.36 233.056 225.532C232.736 225.209 232.52 224.798 232.435 224.352C232.35 223.905 232.401 223.444 232.58 223.026L237.154 214.48C237.412 213.999 237.778 213.584 238.224 213.269C238.67 212.954 239.183 212.746 239.722 212.664L247.24 211.473C247.709 211.394 248.19 211.445 248.631 211.619C249.073 211.794 249.459 212.085 249.747 212.463L251.952 215.358C252.179 215.666 252.334 216.022 252.408 216.398C252.481 216.774 252.47 217.162 252.375 217.534C252.28 217.905 252.104 218.251 251.86 218.546C251.616 218.842 251.309 219.079 250.962 219.242Z'
          fill='#CE7A63'
        />
        <path d='M351.216 298.57L358.27 324.307L319.527 332.615L296.133 320.222L302.674 298.082L351.216 298.57Z' fill='#0F407A' />
        <path opacity='0.1' d='M351.216 298.57L358.27 324.307L319.527 332.615L296.133 320.222L302.674 298.082L351.216 298.57Z' fill='black' />
        <path
          d='M298.903 242.648C298.198 249.244 298.168 255.894 298.815 262.496C299.178 271.367 301.108 280.69 303.526 295.074C308.375 297.919 332.947 296.741 348.635 294.711C347.682 282.181 349.249 267.746 357.769 239.44C359.749 232.875 355.338 226.572 348.296 225.908C346.417 225.72 344.412 225.57 342.37 225.482C335.194 225.006 327.994 225.006 320.818 225.482C318.525 225.695 316.044 225.971 313.726 226.259C305.368 227.324 300.444 233.764 298.903 242.648Z'
          fill='#0F407A'
        />
        <path
          d='M302.674 298.082C318.52 299.828 334.512 299.794 350.351 297.981C350.498 297.949 350.636 297.888 350.759 297.801C350.882 297.715 350.987 297.605 351.067 297.478C351.147 297.351 351.202 297.21 351.227 297.062C351.253 296.914 351.249 296.762 351.216 296.616V296.465C350.827 295.551 350.426 294.636 350.063 293.721C349.982 293.52 349.839 293.35 349.654 293.237C349.468 293.124 349.251 293.074 349.035 293.095C333.802 294.761 318.433 294.761 303.2 293.095C303.067 293.077 302.933 293.086 302.804 293.12C302.675 293.155 302.554 293.215 302.448 293.296C302.342 293.378 302.253 293.479 302.186 293.595C302.12 293.711 302.077 293.839 302.06 293.972C302.066 293.996 302.066 294.022 302.06 294.047C301.959 294.999 301.872 295.939 301.784 296.891C301.767 297.031 301.777 297.173 301.815 297.309C301.853 297.445 301.918 297.572 302.005 297.683C302.092 297.794 302.201 297.886 302.324 297.954C302.448 298.023 302.584 298.066 302.724 298.082H302.674Z'
          fill='#0F407A'
        />
        <path
          d='M320.254 225.093C308.538 240.13 307.723 277.294 310.668 350.295C310.931 356.66 304.829 356.197 298.852 354.969C289.956 353.127 286.422 353.791 286.422 353.791C286.422 353.791 300.619 316.514 298.313 283.083C296.008 249.653 297.612 237.837 303.037 231.709C308.463 225.582 320.254 225.093 320.254 225.093Z'
          fill='#0F407A'
        />
        <path
          opacity='0.7'
          d='M320.254 225.093C308.538 240.13 307.723 277.294 310.668 350.295C310.919 356.66 305.13 355.971 298.84 354.969C294.742 354.222 290.587 353.828 286.422 353.791C286.422 353.791 300.619 316.514 298.313 283.083C296.008 249.653 297.612 237.837 303.037 231.709C308.463 225.582 320.254 225.093 320.254 225.093Z'
          fill='white'
        />
        <path
          d='M340.878 201.198C339.011 208.103 336.706 220.846 341.217 225.482C341.217 225.482 335.265 230.256 322.647 234.855C318.888 233.89 319.677 225.482 319.677 225.482C327.559 223.627 327.784 217.964 326.832 212.513L340.878 201.198Z'
          fill='#CE7A63'
        />
        <path
          opacity='0.2'
          d='M335.152 205.809L326.82 212.501C327.054 213.74 327.18 214.998 327.195 216.26C330.19 215.834 334.488 212.601 335.039 209.493C335.289 208.281 335.327 207.035 335.152 205.809Z'
          fill='black'
        />
        <path
          d='M327.947 175.825C319.742 186.915 316.203 200.776 318.086 214.443C314.126 206.925 310.568 211.022 310.279 202.552C310.154 198.291 312.372 181.012 314.264 177.191C316.156 173.369 320.028 170.286 324.288 170.462C325.827 170.471 327.303 171.072 328.411 172.141C329.451 173.256 328.887 174.609 327.947 175.825Z'
          fill='#263238'
        />
        <path
          d='M346.805 187.277C344.713 198.479 344.049 203.266 337.621 208.579C327.998 216.623 314.791 212.338 313.488 200.973C312.347 190.686 315.994 174.096 327.534 170.399C329.989 169.502 332.641 169.28 335.211 169.758C337.782 170.236 340.177 171.395 342.146 173.114C344.115 174.834 345.586 177.051 346.406 179.533C347.226 182.016 347.364 184.673 346.805 187.227V187.277Z'
          fill='#CE7A63'
        />
        <path
          d='M327.358 189.846C327.095 190.761 326.381 191.4 325.805 191.274C325.228 191.149 324.94 190.297 325.203 189.382C325.466 188.468 326.18 187.816 326.769 187.941C327.358 188.067 327.634 188.919 327.358 189.846Z'
          fill='#263238'
        />
        <path
          d='M317.836 187.553C317.572 188.468 316.871 189.107 316.282 188.981C315.693 188.856 315.417 187.991 315.693 187.089C315.969 186.187 316.658 185.523 317.247 185.661C317.836 185.799 318.111 186.663 317.836 187.553Z'
          fill='#263238'
        />
        <path d='M317.497 185.761L315.492 184.646C315.492 184.646 316.119 186.663 317.497 185.761Z' fill='#263238' />
        <path
          d='M320.504 189.746C319.078 191.664 317.415 193.395 315.555 194.896C315.928 195.337 316.398 195.687 316.928 195.917C317.459 196.148 318.035 196.253 318.612 196.224L320.504 189.746Z'
          fill='#BA4D3C'
        />
        <path
          d='M322.847 200.76C322.323 200.735 321.803 200.659 321.294 200.534C321.259 200.529 321.226 200.516 321.197 200.496C321.168 200.477 321.143 200.452 321.124 200.422C321.105 200.393 321.092 200.36 321.087 200.325C321.082 200.29 321.084 200.255 321.093 200.221C321.116 200.15 321.165 200.09 321.23 200.053C321.295 200.016 321.371 200.004 321.444 200.021C322.607 200.277 323.814 200.27 324.974 199.999C326.134 199.728 327.218 199.2 328.148 198.454C328.199 198.403 328.269 198.374 328.342 198.374C328.415 198.374 328.484 198.403 328.536 198.454C328.584 198.507 328.611 198.577 328.611 198.649C328.611 198.72 328.584 198.79 328.536 198.843C326.941 200.162 324.915 200.845 322.847 200.76Z'
          fill='#263238'
        />
        <path
          d='M319.49 171.903C322.998 167.079 332.534 165.763 338.122 167.091C357.92 171.815 348.835 188.267 360.301 197.515C356.967 203.491 353.76 202.226 349.875 204.92C347.607 206.499 341.593 211.423 338.874 211.185C339.012 205.584 341.468 199.143 340.327 193.643C339.334 188.169 336.918 183.053 333.323 178.807C329.677 174.797 324.752 171.627 319.49 171.903Z'
          fill='#263238'
        />
        <path
          opacity='0.2'
          d='M319.49 171.903C322.998 167.079 332.534 165.763 338.122 167.091C357.92 171.815 348.835 188.267 360.301 197.515C356.967 203.491 353.76 202.226 349.875 204.92C347.607 206.499 341.593 211.423 338.874 211.185C339.012 205.584 341.468 199.143 340.327 193.643C339.334 188.169 336.918 183.053 333.323 178.807C329.677 174.797 324.752 171.627 319.49 171.903Z'
          fill='black'
        />
        <path
          d='M319.49 171.903C322.998 167.079 332.534 165.763 338.122 167.091C357.92 171.815 348.835 188.267 360.301 197.515C356.967 203.491 353.76 202.226 349.875 204.92C347.607 206.499 341.593 211.423 338.874 211.185C339.012 205.584 341.468 199.143 340.327 193.643C339.334 188.169 336.918 183.053 333.323 178.807C329.677 174.797 324.752 171.627 319.49 171.903Z'
          fill='#263238'
        />
        <path
          opacity='0.1'
          d='M319.49 171.903C322.998 167.079 332.534 165.763 338.122 167.091C357.92 171.815 348.835 188.267 360.301 197.515C356.967 203.491 353.76 202.226 349.875 204.92C347.607 206.499 341.593 211.423 338.874 211.185C339.012 205.584 341.468 199.143 340.327 193.643C339.334 188.169 336.918 183.053 333.323 178.807C329.677 174.797 324.752 171.627 319.49 171.903Z'
          fill='black'
        />
        <path
          d='M346.141 198.617C345.566 199.544 344.803 200.34 343.901 200.953C342.998 201.566 341.977 201.983 340.903 202.176C338.234 202.552 337.458 199.444 338.698 196.587C339.851 194.081 342.52 190.723 344.963 191.675C347.407 192.628 347.682 196.174 346.141 198.617Z'
          fill='#CE7A63'
        />
        <path
          d='M331.631 186.187C331.563 186.166 331.502 186.129 331.451 186.079C331.401 186.028 331.364 185.967 331.343 185.899C331.112 185.261 330.727 184.691 330.222 184.238C329.717 183.786 329.107 183.465 328.448 183.305C328.317 183.287 328.199 183.217 328.119 183.111C328.039 183.006 328.005 182.873 328.022 182.741C328.016 182.721 328.016 182.699 328.022 182.679C328.056 182.531 328.144 182.402 328.27 182.318C328.396 182.234 328.55 182.201 328.699 182.228C329.537 182.413 330.315 182.808 330.96 183.375C331.604 183.943 332.094 184.665 332.383 185.473C332.431 185.613 332.423 185.766 332.36 185.899C332.297 186.033 332.183 186.136 332.045 186.187C331.912 186.24 331.764 186.24 331.631 186.187Z'
          fill='#263238'
        />
        <path
          d='M315.73 182.127C315.662 182.126 315.594 182.11 315.532 182.082C315.469 182.054 315.413 182.014 315.366 181.964C315.319 181.913 315.283 181.854 315.26 181.79C315.236 181.725 315.226 181.657 315.229 181.589C315.233 181.509 315.255 181.431 315.295 181.361C315.334 181.292 315.388 181.232 315.455 181.188C316.123 180.579 316.924 180.134 317.793 179.886C318.663 179.638 319.578 179.595 320.467 179.759C320.599 179.797 320.712 179.885 320.782 180.004C320.852 180.123 320.874 180.264 320.843 180.398C320.791 180.539 320.691 180.656 320.559 180.728C320.428 180.799 320.274 180.82 320.128 180.787C319.427 180.669 318.708 180.712 318.026 180.914C317.345 181.116 316.718 181.471 316.194 181.952C316.134 182.012 316.061 182.059 315.981 182.09C315.901 182.12 315.816 182.133 315.73 182.127Z'
          fill='#263238'
        />
        <path d='M327.02 188.054L325.015 186.939C325.015 186.939 325.642 188.906 327.02 188.054Z' fill='#263238' />
        <path
          d='M321.193 171.427C320.343 172.352 319.664 173.421 319.189 174.584C318.953 175.165 318.848 175.791 318.883 176.417C318.918 177.043 319.09 177.654 319.389 178.205C320.021 179.12 320.924 179.812 321.97 180.185C322.995 180.633 324.067 180.965 325.166 181.175C324.587 180.694 324.161 180.056 323.939 179.337C323.716 178.619 323.707 177.851 323.913 177.128C324.034 177.838 324.308 178.513 324.715 179.108C325.121 179.702 325.651 180.202 326.268 180.574C327.396 181.238 328.661 181.601 329.827 182.19C333.498 184.032 340.34 193.68 335.277 213.741C339.751 212.087 346.028 196.337 344.988 191.675C343.948 187.014 343.735 175.035 331.819 170.85C328.599 169.735 324.464 170.499 321.193 171.427Z'
          fill='#263238'
        />
        <path d='M263.317 450.273L252.553 451.313L246.176 420.99L258.869 419.524L263.317 450.273Z' fill='#CE7A63' />
        <path
          d='M248.105 448.807C249.103 447.792 250.443 447.184 251.864 447.103L263.404 446.301C263.664 446.268 263.927 446.337 264.137 446.494C264.347 446.65 264.489 446.882 264.532 447.141L266.763 457.854C266.89 458.442 266.779 459.056 266.455 459.563C266.131 460.07 265.62 460.429 265.033 460.56H264.983C260.623 461.688 257.879 460.773 251.2 461.237C247.09 461.525 238.357 463.154 232.756 462.227C227.155 461.3 227.869 455.749 230.25 455.636C238.845 455.36 245.887 451.05 248.105 448.807Z'
          fill='#263238'
        />
        <g opacity='0.2'>
          <path d='M246.176 421.003L249.884 438.633L261.299 436.252L258.881 419.537L246.176 421.003Z' fill='black' />
        </g>
        <path
          d='M313.638 358.126C313.212 358.126 312.773 358.126 312.322 358.126C311.589 358.116 310.857 358.061 310.13 357.963L252.491 354.881C261.801 363.414 263.329 431.791 263.329 431.791L260.573 432.38L248.043 435.049L245.75 435.538C245.75 435.538 240.524 413.146 234.472 387.459C231.415 374.453 227.456 361.008 225.701 348.102C225.224 344.671 224.948 341.215 224.874 337.752C224.874 336.687 224.874 335.635 224.874 334.582C224.868 334.474 224.868 334.365 224.874 334.256C224.874 333.805 224.874 333.379 224.975 333.003C225.265 330.919 226.133 328.957 227.481 327.34L227.907 326.851C228.047 326.687 228.198 326.532 228.358 326.387C228.66 326.075 228.978 325.778 229.31 325.498C229.661 325.21 230.012 324.921 230.388 324.646L231.352 323.982C231.753 323.731 232.154 323.493 232.605 323.255C232.816 323.13 233.034 323.017 233.257 322.916C233.909 322.566 234.585 322.24 235.299 321.939L235.913 321.676L236.753 321.363C237.004 321.25 237.254 321.162 237.505 321.075C238.269 320.799 239.058 320.536 239.873 320.323L240.286 320.197H240.399C262.264 314.008 299.454 318.782 299.454 318.782C299.454 318.782 309.278 311.514 319.715 310.136L321.194 310.299L325.842 310.812C325.909 310.806 325.976 310.806 326.043 310.812C341.417 337.589 331.882 357.926 313.638 358.126Z'
          fill='#0F407A'
        />
        <g opacity='0.1'>
          <path
            d='M313.638 358.126C313.212 358.126 312.773 358.126 312.322 358.126C311.589 358.116 310.857 358.061 310.13 357.963L252.491 354.881C261.801 363.414 263.329 431.791 263.329 431.791L260.573 432.38L248.043 435.049L245.75 435.538C245.75 435.538 240.524 413.146 234.472 387.459C231.415 374.453 227.456 361.008 225.701 348.102C225.224 344.671 224.948 341.215 224.874 337.752C224.874 336.687 224.874 335.635 224.874 334.582C224.868 334.474 224.868 334.365 224.874 334.256C224.874 333.805 224.874 333.379 224.975 333.003C225.265 330.919 226.133 328.957 227.481 327.34L227.907 326.851C228.047 326.687 228.198 326.532 228.358 326.387C228.66 326.075 228.978 325.778 229.31 325.498C229.661 325.21 230.012 324.921 230.388 324.646L231.352 323.982C231.753 323.731 232.154 323.493 232.605 323.255C232.816 323.13 233.034 323.017 233.257 322.916C233.909 322.566 234.585 322.24 235.299 321.939L235.913 321.676L236.753 321.363C237.004 321.25 237.254 321.162 237.505 321.075C238.269 320.799 239.058 320.536 239.873 320.323L240.286 320.197H240.399C262.264 314.008 299.454 318.782 299.454 318.782C299.454 318.782 309.278 311.514 319.715 310.136L321.194 310.299L325.842 310.812C325.909 310.806 325.976 310.806 326.043 310.812C341.417 337.589 331.882 357.926 313.638 358.126Z'
            fill='black'
          />
        </g>
        <path d='M192.058 398.787L188.975 403.511L182.109 414.036L170.618 415.239L179.327 396.945L185.241 384.49L192.058 398.787Z' fill='#CE7A63' />
        <path
          d='M173.513 407.357L183.023 413.297C183.237 413.416 183.399 413.609 183.48 413.84C183.561 414.071 183.554 414.323 183.462 414.55L178.801 425.426C178.669 425.686 178.488 425.917 178.267 426.107C178.045 426.297 177.789 426.442 177.512 426.532C177.235 426.623 176.943 426.659 176.652 426.636C176.362 426.614 176.078 426.535 175.819 426.403H175.756C171.32 423.709 169.328 422.193 163.702 418.885L149.706 410.765C144.919 407.959 147.5 403.122 149.768 403.861C159.792 407.194 166.596 407.62 171.07 406.919C171.91 406.77 172.776 406.926 173.513 407.357Z'
          fill='#263238'
        />
        <g opacity='0.2'>
          <path d='M192.058 398.787L188.988 403.511H188.975L179.327 396.945L185.241 384.49L192.058 398.787Z' fill='black' />
        </g>
        <path
          d='M317.347 359.179C316.078 359.026 314.822 358.784 313.588 358.452C313.162 358.352 312.723 358.226 312.272 358.089C301.697 355.044 286.472 346.711 272.301 338.504C259.244 330.986 247.128 323.468 240.412 320.41H240.299L239.885 320.536C239.071 320.761 238.281 321.024 237.517 321.3C237.261 321.375 237.01 321.467 236.765 321.576L235.926 321.889L235.312 322.152C234.598 322.453 233.921 322.791 233.269 323.129C233.044 323.23 232.831 323.355 232.618 323.468C232.192 323.706 231.791 323.944 231.365 324.194L230.4 324.871C230.024 325.147 229.673 325.422 229.322 325.711C228.99 325.991 228.672 326.288 228.37 326.6C228.207 326.751 228.057 326.913 227.919 327.064L227.493 327.553C226.145 329.17 225.277 331.131 224.987 333.216C224.987 333.642 224.899 334.081 224.887 334.469C224.887 335.522 224.887 336.574 224.887 337.639C224.948 341.123 225.212 344.599 225.676 348.052V348.315C209.938 376.696 193.574 402.433 193.574 402.433L190.792 400.516L180.768 393.649L179.039 392.396C179.039 392.396 196.506 351.473 208.522 315.01C210.72 308.305 214.992 302.472 220.723 298.355C226.453 294.238 233.343 292.049 240.399 292.105C270.058 292.368 309.002 308.757 309.002 308.757L319.69 309.898L321.169 310.06L325.817 310.574C325.884 310.568 325.951 310.568 326.018 310.574C328.524 310.85 331.331 311.05 334.05 311.251C337.054 311.468 339.917 312.612 342.243 314.527C344.568 316.442 346.241 319.032 347.031 321.939C355 350.984 336.042 361.472 317.347 359.179Z'
          fill='#0F407A'
        />
        <path
          d='M317.347 359.179C316.078 359.026 314.822 358.784 313.588 358.452C313.162 358.352 312.723 358.226 312.272 358.089C301.697 355.044 286.472 346.711 272.301 338.504C259.244 330.986 247.128 323.468 240.412 320.41H240.299L239.885 320.536C239.071 320.761 238.281 321.024 237.517 321.3C237.261 321.375 237.01 321.467 236.765 321.576L235.926 321.889L235.312 322.152C234.598 322.453 233.921 322.791 233.269 323.129C233.044 323.23 232.831 323.355 232.618 323.468C232.192 323.706 231.791 323.944 231.365 324.194L230.4 324.871C230.024 325.147 229.673 325.422 229.322 325.711C228.99 325.991 228.672 326.288 228.37 326.6C228.207 326.751 228.057 326.913 227.919 327.064L227.493 327.553C226.145 329.17 225.277 331.131 224.987 333.216C224.987 333.642 224.899 334.081 224.887 334.469C224.887 335.522 224.887 336.574 224.887 337.639C224.948 341.123 225.212 344.599 225.676 348.052V348.315C209.938 376.696 193.574 402.433 193.574 402.433L190.792 400.516L180.768 393.649L179.039 392.396C179.039 392.396 196.506 351.473 208.522 315.01C210.72 308.305 214.992 302.472 220.723 298.355C226.453 294.238 233.343 292.049 240.399 292.105C270.058 292.368 309.002 308.757 309.002 308.757L319.69 309.898L321.169 310.06L325.817 310.574C325.884 310.568 325.951 310.568 326.018 310.574C328.524 310.85 331.331 311.05 334.05 311.251C337.054 311.468 339.917 312.612 342.243 314.527C344.568 316.442 346.241 319.032 347.031 321.939C355 350.984 336.042 361.472 317.347 359.179Z'
          fill='#0F407A'
        />
        <path
          d='M341.706 224.404C341.706 224.404 333.548 231.647 331.118 249.126C327.233 277.307 329.263 331.637 330.717 362.675C330.769 363.734 331.071 364.766 331.597 365.686C332.124 366.607 332.861 367.389 333.748 367.971C334.635 368.552 335.646 368.916 336.701 369.032C337.755 369.148 338.821 369.014 339.814 368.639C349.499 365.03 365.325 367.862 372.392 361.735C372.906 352.387 356.642 304.622 358.22 279.9C359.799 255.178 362.882 233.639 359.686 228.978C356.491 224.316 341.706 224.404 341.706 224.404Z'
          fill='#0F407A'
        />
        <path
          opacity='0.7'
          d='M341.706 224.404C341.706 224.404 333.548 231.647 331.118 249.126C327.233 277.307 329.476 331.637 330.942 362.675C330.992 363.733 331.292 364.765 331.818 365.686C332.343 366.606 333.079 367.39 333.965 367.971C334.851 368.553 335.862 368.917 336.915 369.033C337.969 369.149 339.035 369.014 340.027 368.639C349.712 365.03 365.325 367.862 372.43 361.735C372.943 352.387 356.679 304.622 358.258 279.9C359.837 255.178 362.919 233.639 359.724 228.978C356.529 224.316 341.706 224.404 341.706 224.404Z'
          fill='white'
        />
        <path
          d='M341.342 261.494C341.288 261.49 341.235 261.476 341.186 261.453C341.137 261.429 341.093 261.396 341.057 261.355C341.021 261.314 340.993 261.267 340.975 261.215C340.958 261.164 340.95 261.109 340.954 261.055C340.957 260.982 340.979 260.911 341.019 260.849C341.059 260.787 341.114 260.737 341.179 260.704L342.921 259.852C344.548 259.072 345.9 257.815 346.795 256.248C347.69 254.681 348.087 252.879 347.933 251.081L346.517 234.128C346.412 232.865 345.885 231.675 345.021 230.749C344.157 229.822 343.007 229.214 341.755 229.021C340.503 228.829 339.222 229.064 338.12 229.688C337.018 230.312 336.158 231.289 335.678 232.461L329.213 248.174C328.519 249.84 328.348 251.677 328.722 253.442C329.095 255.208 329.996 256.818 331.305 258.06L332.721 259.401C332.797 259.48 332.839 259.586 332.839 259.695C332.839 259.805 332.797 259.911 332.721 259.99C332.683 260.029 332.637 260.06 332.586 260.081C332.536 260.102 332.482 260.113 332.427 260.113C332.372 260.113 332.318 260.102 332.267 260.081C332.217 260.06 332.171 260.029 332.132 259.99L330.716 258.649C329.288 257.288 328.307 255.525 327.904 253.594C327.501 251.662 327.695 249.654 328.461 247.836L334.977 232.16C335.531 230.818 336.521 229.701 337.787 228.988C339.053 228.276 340.521 228.01 341.957 228.232C343.392 228.455 344.711 229.154 345.702 230.216C346.692 231.278 347.297 232.643 347.419 234.09L348.823 251.018C348.984 252.987 348.542 254.957 347.555 256.668C346.567 258.378 345.082 259.747 343.297 260.591L341.555 261.431C341.494 261.477 341.418 261.499 341.342 261.494Z'
          fill='#263238'
        />
        <path
          d='M347.181 237.837C347.081 237.831 346.985 237.791 346.912 237.722C346.839 237.653 346.792 237.561 346.78 237.461C346.498 236.132 345.819 234.92 344.832 233.985C343.845 233.051 342.598 232.439 341.255 232.23C339.913 232.02 338.538 232.224 337.314 232.814C336.09 233.403 335.074 234.351 334.4 235.531C334.389 235.592 334.365 235.65 334.329 235.7C334.293 235.75 334.247 235.792 334.193 235.823C334.139 235.854 334.079 235.872 334.017 235.877C333.956 235.883 333.894 235.874 333.836 235.853C333.777 235.832 333.725 235.798 333.681 235.755C333.637 235.711 333.603 235.658 333.582 235.6C333.56 235.542 333.552 235.48 333.557 235.418C333.562 235.357 333.581 235.297 333.611 235.243C334.354 233.888 335.495 232.794 336.881 232.112C338.267 231.429 339.83 231.189 341.357 231.426C342.884 231.663 344.301 232.365 345.414 233.436C346.528 234.506 347.285 235.894 347.582 237.411C347.593 237.518 347.561 237.626 347.493 237.71C347.425 237.795 347.326 237.849 347.219 237.862L347.181 237.837Z'
          fill='#263238'
        />
        <path
          d='M341.781 229.015C341.727 229.012 341.674 228.998 341.626 228.974C341.578 228.95 341.535 228.917 341.5 228.876C341.464 228.835 341.438 228.787 341.422 228.736C341.405 228.685 341.4 228.63 341.405 228.577C341.58 226.534 341.405 222.8 339.488 221.785C339.44 221.759 339.398 221.724 339.364 221.681C339.33 221.639 339.305 221.59 339.289 221.537C339.274 221.485 339.27 221.43 339.276 221.376C339.282 221.322 339.299 221.269 339.325 221.222C339.351 221.174 339.387 221.132 339.429 221.098C339.472 221.064 339.521 221.039 339.573 221.023C339.625 221.008 339.68 221.004 339.734 221.01C339.789 221.016 339.841 221.032 339.889 221.059C342.645 222.562 342.244 228.025 342.182 228.652C342.172 228.752 342.126 228.844 342.052 228.912C341.978 228.979 341.881 229.016 341.781 229.015Z'
          fill='#263238'
        />
        <path
          d='M333.348 259.827C333.222 260.616 332.709 261.18 332.195 261.08C331.681 260.98 331.368 260.316 331.493 259.539C331.618 258.762 332.12 258.186 332.633 258.286C333.147 258.386 333.46 259.063 333.348 259.827Z'
          fill='#263238'
        />
        <path
          d='M342.282 261.23C342.169 261.995 341.655 262.559 341.141 262.483C340.628 262.408 340.314 261.707 340.44 260.93C340.565 260.153 341.066 259.601 341.58 259.677C342.094 259.752 342.407 260.441 342.282 261.23Z'
          fill='#263238'
        />
        <path
          d='M319.69 244.979C319.264 246.721 317.986 247.911 316.883 247.635C315.781 247.36 315.154 245.756 315.63 243.964C316.106 242.172 317.334 241.032 318.437 241.32C319.54 241.608 320.116 243.237 319.69 244.979Z'
          fill='#263238'
        />
        <path
          d='M316.131 244.377H316.044C315.989 244.374 315.936 244.36 315.887 244.337C315.838 244.313 315.795 244.28 315.759 244.239C315.722 244.198 315.695 244.151 315.677 244.099C315.659 244.048 315.652 243.993 315.655 243.939C316.094 236.922 320.266 225.307 324.84 221.51C324.877 221.469 324.923 221.437 324.974 221.415C325.024 221.393 325.079 221.381 325.134 221.381C325.19 221.381 325.244 221.393 325.295 221.415C325.346 221.437 325.391 221.469 325.429 221.51C325.468 221.548 325.499 221.594 325.52 221.645C325.541 221.695 325.552 221.75 325.552 221.804C325.552 221.859 325.541 221.913 325.52 221.964C325.499 222.015 325.468 222.06 325.429 222.099C325.401 222.131 325.367 222.156 325.329 222.174C320.918 225.933 316.858 237.097 316.445 244.002C316.441 244.09 316.409 244.175 316.352 244.243C316.295 244.311 316.218 244.358 316.131 244.377Z'
          fill='#263238'
        />
        <path
          d='M320.931 245.154C320.642 246.645 319.54 247.66 318.424 247.497C317.836 247.266 317.357 246.822 317.083 246.252C316.809 245.683 316.76 245.031 316.946 244.427C317.222 242.924 318.337 241.921 319.452 242.084C320.04 242.315 320.519 242.76 320.793 243.329C321.067 243.899 321.116 244.55 320.931 245.154Z'
          fill='white'
        />
        <path
          d='M321.018 245.292C320.629 247.134 319.614 248.487 318.775 248.312C317.935 248.136 317.522 246.482 317.973 244.628C318.424 242.773 319.376 241.433 320.216 241.621C321.055 241.809 321.369 243.437 321.018 245.292Z'
          fill='#263238'
        />
        <path
          d='M304.102 285.363L277.964 285.84C277.469 285.841 276.991 285.663 276.617 285.338C276.244 285.013 276.001 284.563 275.934 284.073L270.922 244.077C270.89 243.849 270.907 243.617 270.972 243.396C271.037 243.176 271.149 242.972 271.3 242.798C271.451 242.625 271.638 242.486 271.847 242.391C272.056 242.296 272.284 242.247 272.514 242.247L298.689 241.796C299.184 241.795 299.663 241.973 300.036 242.298C300.41 242.623 300.652 243.072 300.719 243.563L305.731 283.559C305.76 283.788 305.739 284.021 305.67 284.241C305.601 284.461 305.485 284.663 305.331 284.835C305.176 285.006 304.986 285.142 304.774 285.233C304.562 285.324 304.333 285.369 304.102 285.363Z'
          fill='#263238'
        />
        <path
          d='M289.141 245.117L282.876 245.23C282.261 245.234 281.665 245.012 281.203 244.605C280.74 244.198 280.444 243.635 280.37 243.024L279.894 239.29L290.67 239.09L291.121 242.774C291.173 243.062 291.16 243.358 291.084 243.641C291.008 243.924 290.871 244.187 290.682 244.41C290.493 244.634 290.257 244.813 289.99 244.935C289.724 245.058 289.434 245.119 289.141 245.117Z'
          fill='#263238'
        />
        <path
          opacity='0.7'
          d='M289.141 245.117L282.876 245.23C282.261 245.234 281.665 245.012 281.203 244.605C280.74 244.198 280.444 243.635 280.37 243.024L279.894 239.29L290.67 239.09L291.121 242.774C291.173 243.062 291.16 243.358 291.084 243.641C291.008 243.924 290.871 244.187 290.682 244.41C290.493 244.634 290.257 244.813 289.99 244.935C289.724 245.058 289.434 245.119 289.141 245.117Z'
          fill='white'
        />
        <path
          d='M299.892 241.859H298.639C299.133 241.863 299.609 242.043 299.981 242.367C300.353 242.691 300.597 243.137 300.669 243.626L305.681 283.634C305.713 283.862 305.696 284.094 305.631 284.315C305.566 284.535 305.454 284.739 305.303 284.913C305.152 285.086 304.966 285.225 304.757 285.32C304.547 285.415 304.32 285.464 304.09 285.464H305.343C305.572 285.465 305.799 285.416 306.008 285.321C306.217 285.225 306.403 285.086 306.553 284.912C306.703 284.738 306.814 284.534 306.877 284.314C306.941 284.093 306.956 283.861 306.922 283.634L301.91 243.626C301.838 243.139 301.596 242.695 301.226 242.371C300.856 242.047 300.384 241.866 299.892 241.859Z'
          fill='white'
        />
        <path opacity='0.2' d='M350.702 234.854C351.091 235.707 342.62 269.463 342.62 269.463L349.073 272.307L350.702 234.854Z' fill='black' />
        <path
          d='M364.598 256.657C363.132 269.901 361.779 275.076 360.839 276.517C355.977 294.473 340.515 285.439 338.285 284.699C334.426 283.446 330.441 280.815 326.757 279.111C319.59 275.866 312.824 273.297 306.12 269.175L309.553 261.769C309.553 261.769 335.278 267.358 338.648 268.034C340.114 268.36 347.419 269.563 347.256 265.641C348.008 264.313 348.672 241.621 350.777 232.561C351.021 231.546 351.469 230.591 352.093 229.755L352.306 229.479C353.709 227.574 354.637 227.587 357.205 227.687C362.806 227.913 365.45 232.236 366.039 237.812C366.59 243.049 365.225 251.495 364.598 256.657Z'
          fill='#0F407A'
        />
        <g opacity='0.7'>
          <path
            d='M364.598 256.657C363.132 269.901 361.779 275.076 360.839 276.517C355.977 294.473 340.515 285.439 338.285 284.699C334.426 283.446 330.441 280.815 326.757 279.111C319.59 275.866 312.824 273.297 306.12 269.175L309.553 261.769C309.553 261.769 335.278 267.358 338.648 268.034C340.114 268.36 347.419 269.563 347.256 265.641C348.008 264.313 348.672 241.621 350.777 232.561C351.021 231.546 351.469 230.591 352.093 229.755L352.306 229.479C353.709 227.574 354.637 227.587 357.205 227.687C362.806 227.913 365.45 232.236 366.039 237.812C366.59 243.049 365.225 251.495 364.598 256.657Z'
            fill='white'
          />
        </g>
        <path
          d='M309.854 262.646C310.055 263.549 310.069 264.483 309.894 265.391C309.719 266.299 309.36 267.162 308.839 267.925C308.317 268.689 307.644 269.337 306.862 269.83C306.08 270.323 305.204 270.65 304.29 270.791C302.242 271.093 300.166 271.172 298.1 271.029L297.624 258.311L307.36 260.216C307.968 260.333 308.528 260.626 308.971 261.058C309.414 261.49 309.721 262.042 309.854 262.646Z'
          fill='#CE7A63'
        />
        <path
          d='M297.624 258.311L298.138 271.067L293.414 271.342C292.027 271.43 290.653 271.024 289.536 270.196C288.42 269.368 287.632 268.172 287.312 266.819L287.249 266.518C287.066 265.742 287.043 264.937 287.181 264.151C287.318 263.366 287.614 262.616 288.05 261.948C288.486 261.28 289.053 260.708 289.716 260.266C290.38 259.824 291.126 259.521 291.911 259.376L297.624 258.311Z'
          fill='#CE7A63'
        />
        <path d='M182.522 250.968L179.126 285.401H184.677L182.522 250.968Z' fill='#263238' />
        <path d='M223.96 285.201L184.101 285.363L182.159 250.981L222.005 250.805L223.96 285.201Z' fill='#0F407A' />
        <path opacity='0.6' d='M223.96 285.201L184.101 285.363L182.159 250.981L222.005 250.805L223.96 285.201Z' fill='white' />
        <path d='M222.005 250.805L182.159 250.981L181.783 244.54L221.641 244.377L222.005 250.805Z' fill='#263238' />
        <path
          d='M187.747 242.385C187.728 242.101 187.603 241.835 187.396 241.64C187.189 241.445 186.916 241.335 186.632 241.332C186.494 241.33 186.358 241.357 186.231 241.41C186.104 241.464 185.99 241.543 185.896 241.644C185.801 241.744 185.729 241.863 185.683 241.993C185.637 242.122 185.619 242.26 185.63 242.397C185.63 242.773 185.843 246.269 185.868 246.645C185.886 246.93 186.011 247.198 186.218 247.395C186.424 247.592 186.697 247.705 186.983 247.71C187.12 247.711 187.256 247.683 187.381 247.628C187.507 247.574 187.621 247.495 187.715 247.395C187.809 247.295 187.881 247.177 187.928 247.048C187.974 246.919 187.994 246.782 187.985 246.645C187.973 246.344 187.772 242.686 187.747 242.385Z'
          fill='#0F407A'
        />
        <path
          d='M195.416 242.36C195.4 242.074 195.276 241.805 195.069 241.607C194.862 241.409 194.587 241.298 194.301 241.295C194.162 241.295 194.026 241.323 193.899 241.378C193.772 241.433 193.658 241.513 193.564 241.615C193.47 241.716 193.398 241.835 193.352 241.966C193.306 242.096 193.288 242.235 193.298 242.373C193.298 242.748 193.511 246.244 193.536 246.62C193.558 246.905 193.686 247.171 193.895 247.366C194.104 247.561 194.378 247.67 194.664 247.673C194.801 247.673 194.937 247.645 195.063 247.591C195.189 247.537 195.302 247.457 195.396 247.357C195.49 247.258 195.562 247.14 195.609 247.011C195.655 246.882 195.675 246.745 195.666 246.608C195.641 246.307 195.441 242.661 195.416 242.36Z'
          fill='#0F407A'
        />
        <path
          d='M203.097 242.322C203.075 242.038 202.947 241.771 202.738 241.577C202.529 241.382 202.255 241.272 201.969 241.27C201.832 241.268 201.695 241.294 201.568 241.348C201.441 241.402 201.327 241.481 201.233 241.581C201.139 241.681 201.066 241.8 201.02 241.93C200.975 242.06 200.956 242.198 200.967 242.335C200.967 242.711 201.192 246.207 201.205 246.583C201.224 246.87 201.351 247.139 201.56 247.337C201.769 247.534 202.045 247.645 202.333 247.648C202.47 247.646 202.606 247.617 202.732 247.561C202.858 247.506 202.971 247.425 203.065 247.324C203.159 247.223 203.231 247.105 203.278 246.975C203.324 246.845 203.343 246.708 203.335 246.57C203.31 246.269 203.109 242.623 203.097 242.322Z'
          fill='#0F407A'
        />
        <path
          d='M210.765 242.297C210.746 242.01 210.619 241.741 210.41 241.544C210.201 241.346 209.925 241.235 209.637 241.232C209.501 241.234 209.366 241.263 209.241 241.318C209.116 241.373 209.003 241.452 208.91 241.552C208.816 241.651 208.743 241.768 208.696 241.896C208.649 242.025 208.628 242.161 208.635 242.297C208.635 242.673 208.861 246.182 208.886 246.558C208.905 246.841 209.03 247.107 209.237 247.303C209.444 247.498 209.716 247.608 210.001 247.61C210.138 247.612 210.275 247.586 210.402 247.532C210.529 247.478 210.643 247.399 210.737 247.299C210.831 247.199 210.904 247.08 210.95 246.95C210.995 246.82 211.014 246.682 211.003 246.545C210.991 246.244 210.778 242.598 210.765 242.297Z'
          fill='#0F407A'
        />
        <path
          d='M218.434 242.26C218.415 241.976 218.289 241.71 218.083 241.515C217.876 241.32 217.603 241.21 217.319 241.207C217.181 241.205 217.044 241.232 216.918 241.285C216.791 241.339 216.677 241.418 216.582 241.518C216.488 241.619 216.416 241.738 216.37 241.867C216.324 241.997 216.306 242.135 216.316 242.272C216.316 242.648 216.529 246.144 216.554 246.52C216.567 246.806 216.691 247.075 216.899 247.272C217.107 247.468 217.383 247.576 217.669 247.572C217.807 247.575 217.944 247.548 218.07 247.494C218.197 247.441 218.311 247.361 218.406 247.261C218.5 247.161 218.572 247.042 218.618 246.912C218.664 246.783 218.682 246.645 218.672 246.507C218.622 246.207 218.446 242.56 218.434 242.26Z'
          fill='#0F407A'
        />
        <g opacity='0.7'>
          <path
            d='M187.747 242.385C187.728 242.101 187.603 241.835 187.396 241.64C187.189 241.445 186.916 241.335 186.632 241.332C186.494 241.33 186.358 241.357 186.231 241.41C186.104 241.464 185.99 241.543 185.896 241.644C185.801 241.744 185.729 241.863 185.683 241.993C185.637 242.122 185.619 242.26 185.63 242.397C185.63 242.773 185.843 246.269 185.868 246.645C185.886 246.93 186.011 247.198 186.218 247.395C186.424 247.592 186.697 247.705 186.983 247.71C187.12 247.711 187.256 247.683 187.381 247.628C187.507 247.574 187.621 247.495 187.715 247.395C187.809 247.295 187.881 247.177 187.928 247.048C187.974 246.919 187.994 246.782 187.985 246.645C187.973 246.344 187.772 242.686 187.747 242.385Z'
            fill='white'
          />
          <path
            d='M195.416 242.36C195.4 242.074 195.276 241.805 195.069 241.607C194.862 241.409 194.587 241.298 194.301 241.295C194.162 241.294 194.026 241.323 193.899 241.378C193.772 241.433 193.658 241.513 193.564 241.614C193.47 241.716 193.398 241.835 193.352 241.966C193.306 242.096 193.288 242.235 193.298 242.372C193.298 242.748 193.511 246.244 193.536 246.62C193.558 246.905 193.686 247.171 193.895 247.366C194.104 247.561 194.378 247.67 194.664 247.673C194.801 247.673 194.937 247.645 195.063 247.591C195.189 247.537 195.302 247.457 195.396 247.357C195.49 247.257 195.562 247.139 195.609 247.01C195.655 246.882 195.675 246.744 195.666 246.608C195.641 246.307 195.441 242.661 195.416 242.36Z'
            fill='white'
          />
          <path
            d='M203.097 242.322C203.075 242.038 202.947 241.771 202.738 241.577C202.529 241.382 202.255 241.272 201.969 241.27C201.832 241.268 201.695 241.294 201.568 241.348C201.441 241.402 201.327 241.481 201.233 241.581C201.139 241.681 201.066 241.8 201.02 241.93C200.975 242.06 200.956 242.198 200.967 242.335C200.967 242.711 201.192 246.207 201.205 246.583C201.224 246.87 201.351 247.139 201.56 247.337C201.769 247.534 202.045 247.645 202.333 247.648C202.47 247.646 202.606 247.617 202.732 247.561C202.858 247.506 202.971 247.425 203.065 247.324C203.159 247.223 203.231 247.105 203.278 246.975C203.324 246.845 203.343 246.708 203.335 246.57C203.31 246.269 203.109 242.623 203.097 242.322Z'
            fill='white'
          />
          <path
            d='M210.765 242.297C210.746 242.01 210.619 241.741 210.41 241.543C210.201 241.346 209.925 241.235 209.637 241.232C209.501 241.234 209.366 241.263 209.241 241.318C209.116 241.372 209.003 241.452 208.91 241.551C208.816 241.651 208.743 241.768 208.696 241.896C208.649 242.024 208.628 242.161 208.635 242.297C208.635 242.673 208.861 246.182 208.886 246.558C208.905 246.841 209.03 247.107 209.237 247.302C209.444 247.498 209.716 247.608 210.001 247.61C210.138 247.612 210.275 247.586 210.402 247.532C210.529 247.478 210.643 247.399 210.737 247.299C210.831 247.198 210.904 247.08 210.95 246.95C210.995 246.82 211.014 246.682 211.003 246.545C210.991 246.244 210.778 242.598 210.765 242.297Z'
            fill='white'
          />
          <path
            d='M218.434 242.26C218.415 241.976 218.289 241.71 218.083 241.515C217.876 241.32 217.603 241.21 217.319 241.207C217.181 241.205 217.044 241.232 216.918 241.285C216.791 241.339 216.677 241.418 216.582 241.518C216.488 241.619 216.416 241.738 216.37 241.867C216.324 241.997 216.306 242.135 216.316 242.272C216.316 242.648 216.529 246.144 216.554 246.52C216.567 246.806 216.691 247.075 216.899 247.272C217.107 247.468 217.383 247.576 217.669 247.572C217.807 247.575 217.944 247.548 218.07 247.494C218.197 247.441 218.311 247.361 218.406 247.261C218.5 247.161 218.572 247.042 218.618 246.912C218.664 246.783 218.682 246.645 218.672 246.507C218.622 246.207 218.446 242.56 218.434 242.26Z'
            fill='white'
          />
        </g>
        <path d='M225.075 284.236L185.216 284.399L182.159 250.981L222.005 250.805L225.075 284.236Z' fill='#0F407A' />
        <path opacity='0.8' d='M225.075 284.236L185.216 284.399L182.159 250.981L222.005 250.805L225.075 284.236Z' fill='white' />
        <path d='M213.572 259.063L193.837 259.138L193.298 253.261L213.033 253.173L213.572 259.063Z' fill='#0F407A' />
        <path opacity='0.6' d='M213.572 259.063L193.837 259.138L193.298 253.261L213.033 253.173L213.572 259.063Z' fill='white' />
        <path d='M191.218 266.756L185.943 266.781L185.454 261.506L190.73 261.481L191.218 266.756Z' fill='white' />
        <path d='M198.686 266.718L193.423 266.744L192.935 261.481L198.21 261.456L198.686 266.718Z' fill='white' />
        <path d='M191.857 273.861L186.595 273.886L186.106 268.611L191.381 268.586L191.857 273.861Z' fill='#0F407A' />
        <path d='M192.509 280.966L187.246 280.991L186.758 275.715L192.033 275.703L192.509 280.966Z' fill='#0F407A' />
        <path d='M199.338 273.836L194.075 273.848L193.586 268.586L198.849 268.56L199.338 273.836Z' fill='#0F407A' />
        <path opacity='0.2' d='M199.338 273.836L194.075 273.848L193.586 268.586L198.849 268.56L199.338 273.836Z' fill='white' />
        <path d='M206.818 273.798L201.543 273.823L201.067 268.548L206.33 268.523L206.818 273.798Z' fill='#0F407A' />
        <path opacity='0.2' d='M206.818 273.798L201.543 273.823L201.067 268.548L206.33 268.523L206.818 273.798Z' fill='white' />
        <path d='M214.286 273.773L209.024 273.786L208.535 268.523L213.81 268.498L214.286 273.773Z' fill='#0F407A' />
        <path opacity='0.2' d='M214.286 273.773L209.024 273.786L208.535 268.523L213.81 268.498L214.286 273.773Z' fill='white' />
        <path d='M221.767 273.736L216.492 273.761L216.015 268.485L221.278 268.473L221.767 273.736Z' fill='#0F407A' />
        <path opacity='0.2' d='M221.767 273.736L216.492 273.761L216.015 268.485L221.278 268.473L221.767 273.736Z' fill='white' />
        <path d='M199.989 280.94L194.714 280.965L194.238 275.69L199.513 275.665L199.989 280.94Z' fill='#0F407A' />
        <path opacity='0.2' d='M199.989 280.94L194.714 280.965L194.238 275.69L199.513 275.665L199.989 280.94Z' fill='white' />
        <path d='M207.457 280.903L202.194 280.928L201.706 275.653L206.981 275.64L207.457 280.903Z' fill='#0F407A' />
        <path opacity='0.2' d='M207.457 280.903L202.194 280.928L201.706 275.653L206.981 275.64L207.457 280.903Z' fill='white' />
        <path d='M214.938 280.878L209.675 280.903L209.186 275.627L214.461 275.602L214.938 280.878Z' fill='#0F407A' />
        <path opacity='0.2' d='M214.938 280.878L209.675 280.903L209.186 275.627L214.461 275.602L214.938 280.878Z' fill='white' />
        <path d='M206.166 266.694L200.891 266.719L200.415 261.443L205.678 261.418L206.166 266.694Z' fill='#0F407A' />
        <path opacity='0.2' d='M206.166 266.694L200.891 266.719L200.415 261.443L205.678 261.418L206.166 266.694Z' fill='white' />
        <path d='M213.635 266.656L208.372 266.681L207.884 261.418L213.159 261.393L213.635 266.656Z' fill='#0F407A' />
        <path opacity='0.2' d='M213.635 266.656L208.372 266.681L207.884 261.418L213.159 261.393L213.635 266.656Z' fill='white' />
        <path d='M221.115 266.631L215.84 266.656L215.364 261.381L220.627 261.356L221.115 266.631Z' fill='#0F407A' />
        <path opacity='0.2' d='M221.115 266.631L215.84 266.656L215.364 261.381L220.627 261.356L221.115 266.631Z' fill='white' />
        <path d='M222.418 280.84L217.143 280.865L216.667 275.602L221.93 275.577L222.418 280.84Z' fill='white' />
        <path d='M130.134 295.362V303.006H85.2255L80.3763 462.427H76.2539V295.362H130.134Z' fill='#263238' />
        <path opacity='0.7' d='M130.134 295.362V303.006H85.2255L80.3763 462.427H76.2539V295.362H130.134Z' fill='white' />
        <path d='M76.2539 462.427H72.357L71.104 295.362H76.2539V462.427Z' fill='#263238' />
        <g opacity='0.3'>
          <path d='M76.2539 462.427H72.357L71.104 295.362H76.2539V462.427Z' fill='white' />
        </g>
        <path d='M142.914 295.362V462.427H138.83L135.196 303.006H130.384V295.362H142.914Z' fill='#263238' />
        <path d='M139.293 462.427H135.396L130.384 295.362H135.534L139.293 462.427Z' fill='#263238' />
        <g opacity='0.3'>
          <path d='M139.293 462.427H135.396L130.384 295.362H135.534L139.293 462.427Z' fill='white' />
        </g>
        <path opacity='0.7' d='M142.914 295.362V462.427H138.83L135.196 303.006H130.384V295.362H142.914Z' fill='white' />
        <path d='M387.967 462.427H384.083L379.071 295.362H384.208L387.967 462.427Z' fill='#263238' />
        <path opacity='0.3' d='M379.071 295.362H384.208L387.967 462.427H384.083' fill='white' />
        <path d='M321.206 295.362H76.2791V308.03H321.206V295.362Z' fill='#263238' />
        <path opacity='0.5' d='M321.206 295.362H76.2791V308.03H321.206V295.362Z' fill='white' />
        <path d='M325.191 295.362V462.427H329.301L334.15 302.993H383.882L387.516 462.427H391.601V295.362H325.191Z' fill='#263238' />
        <path opacity='0.7' d='M325.191 295.362V462.427H329.301L334.15 302.993H383.882L387.516 462.427H391.601V295.362H325.191Z' fill='white' />
        <path d='M325.191 462.427H321.294L320.041 295.362H325.191V462.427Z' fill='#263238' />
        <path opacity='0.3' d='M325.191 462.427H321.294L320.041 295.362H325.191V462.427Z' fill='white' />
        <path d='M321.971 285.752H64.2876V295.362H321.971V285.752Z' fill='#263238' />
        <path opacity='0.6' d='M321.971 285.752H64.2876V295.362H321.971V285.752Z' fill='white' />
        <path d='M398.417 285.752H315.994V295.362H398.417V285.752Z' fill='#263238' />
        <path opacity='0.7' d='M398.417 285.752H315.994V295.362H398.417V285.752Z' fill='white' />
        <path d='M169.942 265.353H19.5045C40.5677 189.169 -1.55872 113.023 19.5045 36.8525H169.942C148.878 113.023 191.055 189.169 169.942 265.353Z' fill='#0F407A' />
        <path
          opacity='0.7'
          d='M169.942 265.353H19.5045C40.5677 189.169 -1.55872 113.023 19.5045 36.8525H169.942C148.878 113.023 191.055 189.169 169.942 265.353Z'
          fill='white'
        />
        <path
          d='M152.45 84.6927H84.4734C84.2973 84.6927 84.1284 84.6227 84.0038 84.4982C83.8793 84.3737 83.8093 84.2047 83.8093 84.0286C83.8093 83.8525 83.8793 83.6836 84.0038 83.559C84.1284 83.4345 84.2973 83.3645 84.4734 83.3645H152.45C152.626 83.3645 152.795 83.4345 152.919 83.559C153.044 83.6836 153.114 83.8525 153.114 84.0286C153.114 84.2047 153.044 84.3737 152.919 84.4982C152.795 84.6227 152.626 84.6927 152.45 84.6927Z'
          fill='#263238'
        />
        <path
          d='M152.775 98.2003H84.7989C84.6213 98.1974 84.4515 98.1272 84.3236 98.0039C84.1957 97.8807 84.1193 97.7135 84.1098 97.5362C84.1046 97.4508 84.117 97.3653 84.1462 97.2849C84.1754 97.2045 84.2207 97.1309 84.2794 97.0687C84.3381 97.0064 84.4089 96.9569 84.4875 96.9231C84.5661 96.8893 84.6507 96.8719 84.7363 96.8721H152.712C152.891 96.8748 153.063 96.9445 153.193 97.0675C153.323 97.1905 153.402 97.3577 153.414 97.5362C153.418 97.6222 153.403 97.708 153.373 97.7884C153.342 97.8689 153.296 97.9423 153.236 98.0043C153.176 98.0663 153.105 98.1157 153.026 98.1494C152.946 98.183 152.861 98.2003 152.775 98.2003Z'
          fill='#263238'
        />
        <path
          d='M153.653 111.77H25.845C25.6643 111.766 25.4913 111.696 25.3578 111.574C25.2243 111.452 25.1393 111.286 25.1183 111.106C25.1095 111.022 25.1185 110.937 25.1447 110.857C25.171 110.776 25.2139 110.703 25.2706 110.64C25.3274 110.577 25.3967 110.527 25.4741 110.493C25.5515 110.459 25.6352 110.442 25.7197 110.442H153.527C153.708 110.445 153.881 110.514 154.012 110.637C154.144 110.759 154.226 110.927 154.242 111.106C154.25 111.189 154.242 111.273 154.216 111.353C154.191 111.432 154.149 111.506 154.094 111.568C154.038 111.63 153.97 111.681 153.894 111.716C153.819 111.75 153.736 111.769 153.653 111.77Z'
          fill='#263238'
        />
        <path
          d='M164.278 227.6H36.4327C36.3461 227.601 36.26 227.585 36.18 227.552C36.0999 227.519 36.0276 227.47 35.9675 227.408C35.9075 227.345 35.861 227.271 35.8311 227.19C35.8012 227.108 35.7884 227.022 35.7936 226.935C35.8063 226.758 35.8858 226.592 36.0161 226.471C36.1463 226.35 36.3176 226.283 36.4953 226.284H164.303C164.388 226.282 164.472 226.298 164.551 226.33C164.629 226.363 164.7 226.411 164.759 226.472C164.818 226.533 164.864 226.606 164.893 226.686C164.922 226.766 164.935 226.851 164.93 226.935C164.924 227.108 164.853 227.271 164.733 227.394C164.612 227.517 164.45 227.59 164.278 227.6Z'
          fill='#263238'
        />
        <path
          d='M77.0305 250.818H33.8766C33.794 250.82 33.7119 250.803 33.6368 250.769C33.5617 250.734 33.4955 250.683 33.4434 250.619C33.3913 250.554 33.3547 250.479 33.3364 250.398C33.3181 250.318 33.3186 250.234 33.3378 250.154C33.3742 249.972 33.4705 249.808 33.6114 249.687C33.7523 249.567 33.9295 249.497 34.1147 249.489H77.231C77.3137 249.487 77.3957 249.504 77.4708 249.539C77.546 249.573 77.6121 249.624 77.6642 249.689C77.7163 249.753 77.7529 249.828 77.7713 249.909C77.7896 249.989 77.7891 250.073 77.7698 250.154C77.7352 250.329 77.6444 250.489 77.511 250.609C77.3777 250.729 77.2091 250.802 77.0305 250.818Z'
          fill='#263238'
        />
        <path
          d='M162.16 250.818H119.044C118.961 250.82 118.879 250.803 118.804 250.769C118.729 250.734 118.663 250.683 118.611 250.619C118.559 250.554 118.522 250.479 118.504 250.398C118.486 250.318 118.486 250.234 118.505 250.154C118.54 249.971 118.635 249.805 118.777 249.684C118.918 249.564 119.096 249.495 119.282 249.489H162.398C162.481 249.487 162.563 249.504 162.638 249.539C162.713 249.573 162.78 249.624 162.832 249.689C162.884 249.753 162.92 249.828 162.939 249.909C162.957 249.989 162.957 250.073 162.937 250.154C162.901 250.335 162.805 250.5 162.664 250.62C162.523 250.74 162.346 250.81 162.16 250.818Z'
          fill='#263238'
        />
        <path
          d='M59.7515 67.777C59.7515 73.4532 55.4787 76.9867 48.6748 76.9867H43.5375V84.0161H37.6357V58.5172H48.6748C55.4787 58.5172 59.7515 62.0507 59.7515 67.777ZM53.7746 67.777C53.7746 64.9702 51.9452 63.3288 48.349 63.3288H43.5375V72.1751H48.349C51.9703 72.1751 53.7746 70.5462 53.7746 67.777Z'
          fill='#0F407A'
        />
        <path
          d='M63.7986 98.5887L57.6463 89.7424L51.5942 98.5887H44.8154L54.2506 85.62L45.2916 73.0898H51.9702L57.8343 81.3598L63.5982 73.0898H69.976L61.0796 85.407L70.59 98.5887H63.7986Z'
          fill='#0F407A'
        />
        <path
          d='M33.9017 120.253C33.9017 121.657 32.8492 122.534 31.1576 122.534H29.9046V124.276H28.426V118.01H31.1576C32.8492 117.898 33.9017 118.775 33.9017 120.253ZM32.4232 120.253C32.4232 119.564 31.9721 119.151 31.0824 119.151H29.8294V121.343H31.0824C31.9219 121.281 32.4232 120.88 32.4232 120.253Z'
          fill='#263238'
        />
        <path
          d='M37.8484 119.289V120.542H37.5226C37.3493 120.541 37.1777 120.576 37.0188 120.646C36.8598 120.715 36.7169 120.816 36.5992 120.943C36.4814 121.071 36.3913 121.221 36.3346 121.385C36.2779 121.548 36.2557 121.722 36.2696 121.895V124.188H34.8286V119.339H36.1693V119.978C36.3733 119.737 36.6324 119.548 36.9246 119.428C37.2169 119.308 37.5337 119.26 37.8484 119.289Z'
          fill='#263238'
        />
        <path
          d='M43.3494 122.183H39.6906C39.757 122.488 39.9352 122.758 40.1902 122.939C40.4452 123.12 40.7586 123.199 41.0689 123.16C41.2995 123.174 41.5303 123.138 41.7462 123.056C41.9621 122.974 42.1583 122.847 42.3219 122.684L43.0737 123.499C42.6948 123.865 42.2207 124.118 41.7054 124.228C41.19 124.339 40.654 124.302 40.1582 124.124C39.6624 123.945 39.2266 123.631 38.9003 123.217C38.574 122.803 38.3703 122.306 38.3123 121.782C38.3121 121.446 38.3793 121.114 38.51 120.805C38.6406 120.496 38.8319 120.217 39.0726 119.983C39.3134 119.749 39.5986 119.566 39.9114 119.445C40.2241 119.323 40.5581 119.266 40.8935 119.276C41.2264 119.262 41.5586 119.317 41.869 119.438C42.1795 119.559 42.4615 119.743 42.6971 119.978C42.9326 120.214 43.1167 120.496 43.2377 120.806C43.3586 121.117 43.4137 121.449 43.3995 121.782C43.387 121.907 43.3619 122.07 43.3494 122.183ZM39.6655 121.356H42.0588C41.9795 121.101 41.8205 120.877 41.6052 120.719C41.3899 120.56 41.1296 120.475 40.8622 120.475C40.5948 120.475 40.3344 120.56 40.1191 120.719C39.9038 120.877 39.7448 121.101 39.6655 121.356Z'
          fill='#263238'
        />
        <path
          d='M43.8384 123.762L44.3145 122.747C44.8194 123.058 45.4006 123.223 45.9936 123.223C46.6451 123.223 46.8957 123.048 46.8957 122.772C46.8957 121.983 43.9637 122.772 43.9637 120.855C43.9637 119.94 44.7907 119.289 46.2066 119.289C46.8544 119.275 47.4956 119.421 48.0736 119.715L47.5974 120.717C47.1748 120.473 46.6944 120.348 46.2066 120.354C45.5801 120.354 45.3044 120.554 45.3044 120.805C45.3044 121.632 48.2365 120.805 48.2365 122.747C48.2365 123.637 47.3969 124.288 45.9434 124.288C45.2076 124.302 44.4812 124.12 43.8384 123.762Z'
          fill='#263238'
        />
        <path
          d='M48.7373 121.782C48.7363 121.436 48.8068 121.094 48.9443 120.778C49.0818 120.461 49.2834 120.175 49.5364 119.94C49.7894 119.705 50.0883 119.524 50.4144 119.41C50.7405 119.296 51.0867 119.25 51.4313 119.276C51.8788 119.24 52.3268 119.34 52.7161 119.564C53.1054 119.787 53.4176 120.124 53.6115 120.529L52.5214 121.105C52.4159 120.907 52.259 120.741 52.0672 120.624C51.8754 120.507 51.6558 120.444 51.4313 120.441C51.0757 120.441 50.7346 120.583 50.4832 120.834C50.2318 121.085 50.0905 121.426 50.0905 121.782C50.0905 122.138 50.2318 122.479 50.4832 122.73C50.7346 122.981 51.0757 123.123 51.4313 123.123C51.6576 123.129 51.8807 123.069 52.0741 122.951C52.2674 122.833 52.4227 122.662 52.5214 122.459L53.6115 123.06C53.4136 123.462 53.1005 123.795 52.7122 124.019C52.3239 124.242 51.878 124.344 51.4313 124.313C51.0846 124.339 50.7363 124.293 50.4085 124.177C50.0806 124.061 49.7805 123.879 49.5271 123.641C49.2738 123.402 49.0727 123.114 48.9367 122.794C48.8007 122.474 48.7328 122.13 48.7373 121.782Z'
          fill='#263238'
        />
        <path
          d='M57.4209 119.289V120.542H57.1077C56.9343 120.541 56.7628 120.576 56.6038 120.646C56.4449 120.715 56.302 120.816 56.1842 120.943C56.0665 121.07 55.9764 121.221 55.9196 121.384C55.8629 121.548 55.8408 121.722 55.8546 121.895V124.188H54.4763V119.339H55.7293V119.978C55.9339 119.734 56.1949 119.544 56.4897 119.423C56.7845 119.303 57.1041 119.257 57.4209 119.289Z'
          fill='#263238'
        />
        <path
          d='M58.0847 117.898C58.0877 117.788 58.1131 117.68 58.1596 117.58C58.206 117.481 58.2724 117.392 58.3547 117.319C58.4369 117.246 58.5332 117.191 58.6377 117.157C58.7421 117.123 58.8525 117.11 58.9618 117.121C59.0695 117.109 59.1786 117.119 59.2822 117.151C59.3857 117.183 59.4817 117.235 59.564 117.306C59.6462 117.376 59.7131 117.463 59.7604 117.561C59.8077 117.658 59.8345 117.764 59.8389 117.873C59.8389 118.105 59.7465 118.328 59.582 118.493C59.4176 118.657 59.1945 118.75 58.9618 118.75C58.7292 118.75 58.5061 118.657 58.3416 118.493C58.1771 118.328 58.0847 118.105 58.0847 117.873V117.898ZM58.2601 119.364H59.6635V124.213H58.2351L58.2601 119.364Z'
          fill='#263238'
        />
        <path
          d='M66.2545 121.782C66.2737 122.109 66.2249 122.436 66.1113 122.743C65.9977 123.05 65.8217 123.33 65.5945 123.565C65.3673 123.8 65.0937 123.986 64.7911 124.111C64.4885 124.235 64.1633 124.296 63.8362 124.288C63.5641 124.299 63.2928 124.252 63.0402 124.151C62.7875 124.049 62.5592 123.895 62.3702 123.699V125.955H60.9668V119.364H62.3075V119.915C62.4995 119.703 62.7364 119.537 63.0009 119.429C63.2654 119.32 63.5508 119.272 63.8362 119.289C64.1622 119.281 64.4863 119.341 64.788 119.465C65.0897 119.589 65.3626 119.773 65.5897 120.007C65.8167 120.242 65.993 120.52 66.1074 120.825C66.2218 121.131 66.2719 121.456 66.2545 121.782ZM64.8261 121.782C64.809 121.539 64.7213 121.306 64.5736 121.111C64.426 120.917 64.2248 120.77 63.9949 120.689C63.765 120.607 63.5163 120.595 63.2793 120.652C63.0422 120.71 62.8273 120.836 62.6607 121.014C62.4941 121.192 62.3832 121.415 62.3415 121.656C62.2999 121.896 62.3293 122.144 62.4262 122.367C62.5231 122.591 62.6832 122.782 62.8869 122.916C63.0906 123.05 63.3291 123.122 63.5731 123.123C63.7453 123.123 63.9157 123.088 64.0737 123.02C64.2318 122.951 64.374 122.851 64.4916 122.725C64.6092 122.6 64.6996 122.451 64.7571 122.289C64.8147 122.126 64.8381 121.954 64.8261 121.782Z'
          fill='#263238'
        />
        <path
          d='M70.3142 124.012C69.9707 124.232 69.5685 124.341 69.1614 124.326C68.922 124.359 68.6781 124.338 68.4482 124.263C68.2183 124.189 68.0084 124.063 67.8345 123.895C67.6606 123.727 67.5272 123.522 67.4445 123.295C67.3617 123.067 67.3318 122.824 67.357 122.584V120.592H66.6052V119.502H67.357V118.324H68.7604V119.502H70.0134V120.592H68.7604V122.559C68.749 122.642 68.7563 122.727 68.7819 122.807C68.8075 122.887 68.8507 122.961 68.9084 123.022C68.9661 123.083 69.0368 123.131 69.1152 123.161C69.1937 123.192 69.2779 123.204 69.3619 123.198C69.5638 123.202 69.7615 123.14 69.9257 123.023L70.3142 124.012Z'
          fill='#263238'
        />
        <path
          d='M70.9282 117.898C70.9312 117.788 70.9567 117.68 71.0031 117.58C71.0495 117.481 71.1159 117.392 71.1982 117.319C71.2804 117.246 71.3767 117.191 71.4812 117.157C71.5856 117.123 71.696 117.111 71.8053 117.121C71.9121 117.111 72.0197 117.122 72.1218 117.155C72.224 117.187 72.3183 117.241 72.3992 117.311C72.4801 117.381 72.5459 117.467 72.5924 117.564C72.6389 117.661 72.6653 117.766 72.6699 117.873C72.6443 118.086 72.5416 118.282 72.3811 118.424C72.2206 118.567 72.0136 118.645 71.7991 118.645C71.5846 118.645 71.3775 118.567 71.217 118.424C71.0566 118.282 70.9538 118.086 70.9282 117.873V117.898ZM71.0911 119.364H72.507V124.213H71.0911V119.364Z'
          fill='#263238'
        />
        <path
          d='M73.4469 121.782C73.4142 121.248 73.5426 120.717 73.8153 120.258C74.0881 119.798 74.4926 119.43 74.9765 119.203C75.4604 118.976 76.0014 118.899 76.5294 118.983C77.0574 119.067 77.548 119.308 77.9377 119.673C78.3275 120.039 78.5983 120.514 78.7152 121.036C78.8321 121.557 78.7896 122.102 78.5933 122.599C78.3969 123.096 78.0558 123.523 77.6141 123.824C77.1724 124.126 76.6504 124.287 76.1158 124.288C75.7733 124.31 75.4298 124.262 75.1067 124.146C74.7836 124.03 74.4877 123.849 74.2375 123.614C73.9873 123.379 73.7879 123.095 73.6519 122.78C73.5159 122.465 73.4461 122.125 73.4469 121.782ZM77.3438 121.782C77.3267 121.539 77.2389 121.306 77.0913 121.111C76.9436 120.917 76.7425 120.77 76.5126 120.689C76.2827 120.607 76.0339 120.595 75.7969 120.652C75.5599 120.71 75.3449 120.836 75.1784 121.014C75.0118 121.192 74.9009 121.415 74.8592 121.656C74.8175 121.896 74.847 122.143 74.9438 122.367C75.0407 122.591 75.2008 122.782 75.4046 122.916C75.6083 123.05 75.8468 123.122 76.0907 123.123C76.2629 123.123 76.4334 123.088 76.5914 123.02C76.7494 122.951 76.8917 122.851 77.0093 122.725C77.1268 122.599 77.2172 122.451 77.2748 122.288C77.3323 122.126 77.3558 121.954 77.3438 121.782Z'
          fill='#263238'
        />
        <path
          d='M84.6612 121.431V124.213H83.2954V121.707C83.2954 120.93 82.932 120.567 82.3055 120.567C81.679 120.567 81.1527 120.98 81.1527 121.82V124.225H79.7368V119.376H81.0901V119.94C81.2975 119.727 81.5476 119.56 81.8239 119.45C82.1003 119.34 82.3967 119.289 82.6939 119.301C82.97 119.288 83.2456 119.335 83.5014 119.44C83.7572 119.545 83.9869 119.704 84.1744 119.907C84.3619 120.11 84.5027 120.352 84.5868 120.615C84.6709 120.879 84.6963 121.157 84.6612 121.431Z'
          fill='#263238'
        />
        <path
          d='M85.5883 120.166C85.5829 120.051 85.6016 119.936 85.6432 119.829C85.6847 119.721 85.7482 119.624 85.8296 119.542C85.911 119.461 86.0085 119.398 86.1158 119.356C86.2231 119.314 86.3379 119.296 86.4529 119.301C86.5684 119.296 86.6837 119.315 86.7917 119.356C86.8996 119.397 86.9979 119.461 87.0802 119.542C87.1626 119.623 87.2273 119.72 87.2702 119.828C87.3132 119.935 87.3336 120.05 87.33 120.166C87.3335 120.282 87.3132 120.397 87.2703 120.505C87.2274 120.613 87.163 120.711 87.0808 120.794C86.9987 120.876 86.9006 120.94 86.7926 120.983C86.6846 121.026 86.569 121.046 86.4529 121.043C86.3378 121.045 86.2236 121.023 86.117 120.979C86.0105 120.936 85.914 120.871 85.8332 120.789C85.7524 120.707 85.689 120.609 85.6469 120.502C85.6048 120.395 85.5849 120.281 85.5883 120.166ZM85.5883 123.398C85.5829 123.283 85.6016 123.169 85.6432 123.061C85.6847 122.954 85.7482 122.857 85.8296 122.775C85.911 122.694 86.0085 122.63 86.1158 122.589C86.2231 122.547 86.3379 122.528 86.4529 122.534C86.5684 122.529 86.6837 122.547 86.7917 122.589C86.8996 122.63 86.9979 122.693 87.0802 122.775C87.1626 122.856 87.2273 122.953 87.2702 123.06C87.3132 123.168 87.3336 123.283 87.33 123.398C87.3334 123.515 87.3131 123.631 87.2704 123.74C87.2277 123.849 87.1634 123.947 87.0814 124.031C86.9995 124.114 86.9015 124.179 86.7935 124.224C86.6855 124.268 86.5696 124.29 86.4529 124.288C86.3367 124.29 86.2214 124.268 86.114 124.224C86.0066 124.179 85.9095 124.113 85.8285 124.03C85.7475 123.947 85.6844 123.848 85.6431 123.739C85.6018 123.63 85.5832 123.514 85.5883 123.398Z'
          fill='#263238'
        />
        <path
          d='M201.696 118.953L179.416 111.86C175.387 110.577 171.081 112.803 169.799 116.832L145.475 193.234C144.192 197.263 146.418 201.569 150.447 202.852L172.727 209.945C176.756 211.228 181.062 209.002 182.344 204.973L206.668 128.571C207.951 124.542 205.725 120.236 201.696 118.953Z'
          fill='black'
        />
        <path
          opacity='0.8'
          d='M207.019 126.518C207.005 127.229 206.887 127.934 206.668 128.611L182.347 205.045C182.264 205.305 182.168 205.56 182.059 205.809C181.3 207.539 179.927 208.926 178.206 209.704C176.484 210.481 174.536 210.594 172.736 210.019L150.445 202.927C148.545 202.318 146.957 200.991 146.021 199.229C145.085 197.466 144.874 195.408 145.433 193.492L159.467 149.424L169.754 116.833C170.055 115.872 170.543 114.98 171.189 114.208C171.835 113.436 172.627 112.799 173.52 112.333C174.412 111.867 175.388 111.583 176.391 111.495C177.394 111.407 178.405 111.517 179.365 111.821L201.706 119C202.194 119.156 202.664 119.362 203.109 119.614C204.332 120.295 205.344 121.299 206.034 122.517C206.723 123.735 207.064 125.12 207.019 126.518Z'
          fill='white'
        />
        <g opacity='0.6'>
          <path
            opacity='0.5'
            d='M153.114 169.259L163.226 137.52L191.858 115.818L203.135 119.577C204.36 120.258 205.373 121.265 206.062 122.485C206.752 123.705 207.092 125.092 207.044 126.493L153.114 169.259Z'
            fill='white'
          />
        </g>
        <path
          opacity='0.5'
          d='M167.248 124.764L169.754 116.833C170.055 115.872 170.542 114.98 171.189 114.208C171.835 113.436 172.627 112.799 173.52 112.333C174.412 111.867 175.388 111.583 176.391 111.495C177.394 111.407 178.404 111.517 179.364 111.821L182.71 112.886L167.248 124.764Z'
          fill='white'
        />
        <path
          d='M182.622 124.451C182.446 125.004 182.11 125.491 181.657 125.853C181.204 126.215 180.654 126.434 180.076 126.483C179.498 126.532 178.919 126.408 178.411 126.128C177.903 125.847 177.49 125.423 177.224 124.908C176.958 124.393 176.85 123.81 176.915 123.234C176.98 122.658 177.214 122.113 177.588 121.67C177.962 121.227 178.459 120.905 179.016 120.744C179.573 120.583 180.165 120.591 180.718 120.767C181.086 120.882 181.427 121.07 181.722 121.318C182.018 121.566 182.261 121.87 182.438 122.212C182.615 122.555 182.722 122.929 182.754 123.313C182.786 123.697 182.741 124.084 182.622 124.451Z'
          fill='#0F407A'
        />
        <path
          d='M177.034 141.968C176.858 142.518 176.523 143.003 176.071 143.363C175.619 143.722 175.071 143.939 174.496 143.987C173.92 144.035 173.344 143.911 172.839 143.632C172.334 143.352 171.923 142.929 171.658 142.415C171.394 141.902 171.287 141.322 171.353 140.748C171.418 140.175 171.652 139.633 172.024 139.192C172.397 138.752 172.892 138.431 173.447 138.272C174.002 138.112 174.592 138.121 175.142 138.297C175.507 138.414 175.846 138.601 176.139 138.849C176.432 139.096 176.673 139.399 176.849 139.74C177.024 140.081 177.131 140.454 177.163 140.836C177.195 141.218 177.151 141.603 177.034 141.968Z'
          fill='#0F407A'
        />
        <path
          d='M171.458 159.498C171.284 160.052 170.95 160.541 170.497 160.905C170.045 161.269 169.494 161.49 168.916 161.54C168.338 161.591 167.757 161.468 167.249 161.189C166.74 160.909 166.325 160.485 166.058 159.97C165.791 159.454 165.682 158.871 165.747 158.294C165.811 157.717 166.045 157.172 166.42 156.729C166.794 156.285 167.292 155.962 167.85 155.802C168.408 155.641 169.001 155.65 169.554 155.826C169.92 155.942 170.26 156.129 170.554 156.376C170.849 156.623 171.091 156.926 171.268 157.267C171.445 157.608 171.553 157.98 171.586 158.363C171.618 158.746 171.575 159.132 171.458 159.498Z'
          fill='#0F407A'
        />
        <path
          d='M165.883 177.028C165.707 177.58 165.371 178.068 164.917 178.43C164.464 178.791 163.914 179.01 163.336 179.059C162.758 179.108 162.179 178.985 161.671 178.704C161.164 178.424 160.751 177.999 160.484 177.484C160.218 176.969 160.11 176.387 160.175 175.81C160.24 175.234 160.474 174.69 160.848 174.247C161.222 173.804 161.719 173.481 162.276 173.32C162.833 173.16 163.425 173.168 163.978 173.344C164.346 173.459 164.687 173.646 164.983 173.894C165.278 174.142 165.521 174.446 165.698 174.789C165.875 175.131 165.983 175.505 166.014 175.89C166.046 176.274 166.001 176.661 165.883 177.028Z'
          fill='#0F407A'
        />
        <path
          d='M160.307 194.545C160.133 195.099 159.798 195.588 159.346 195.952C158.893 196.316 158.343 196.537 157.764 196.587C157.186 196.638 156.606 196.515 156.097 196.236C155.588 195.956 155.174 195.532 154.907 195.016C154.639 194.501 154.531 193.918 154.595 193.341C154.66 192.764 154.894 192.219 155.268 191.775C155.643 191.332 156.14 191.009 156.698 190.849C157.256 190.688 157.849 190.697 158.402 190.873C159.138 191.112 159.751 191.631 160.107 192.318C160.464 193.005 160.535 193.805 160.307 194.545Z'
          fill='#0F407A'
        />
        <path
          d='M196.706 128.937C196.436 129.632 195.911 130.199 195.237 130.52C194.564 130.842 193.793 130.895 193.082 130.668C192.371 130.441 191.774 129.952 191.411 129.299C191.048 128.647 190.948 127.881 191.131 127.158C191.228 126.769 191.405 126.405 191.649 126.088C191.893 125.771 192.199 125.507 192.549 125.312C192.899 125.118 193.285 124.997 193.683 124.957C194.081 124.917 194.484 124.96 194.865 125.081C195.246 125.203 195.599 125.402 195.9 125.665C196.202 125.928 196.447 126.25 196.619 126.611C196.792 126.972 196.889 127.365 196.904 127.765C196.919 128.165 196.851 128.564 196.706 128.937Z'
          fill='#0F407A'
        />
        <path
          d='M191.131 146.454C190.955 147.004 190.621 147.489 190.17 147.849C189.719 148.209 189.172 148.427 188.597 148.476C188.022 148.525 187.445 148.403 186.94 148.124C186.435 147.846 186.023 147.424 185.757 146.912C185.492 146.4 185.384 145.82 185.447 145.247C185.51 144.673 185.742 144.131 186.114 143.689C186.485 143.248 186.978 142.926 187.532 142.764C188.086 142.603 188.676 142.609 189.226 142.783C189.964 143.019 190.579 143.537 190.936 144.225C191.293 144.913 191.363 145.715 191.131 146.454Z'
          fill='#0F407A'
        />
        <path
          d='M185.555 163.984C185.381 164.538 185.046 165.027 184.594 165.391C184.141 165.755 183.591 165.976 183.012 166.026C182.434 166.077 181.854 165.954 181.345 165.675C180.836 165.395 180.422 164.971 180.155 164.455C179.887 163.94 179.779 163.357 179.843 162.78C179.908 162.203 180.142 161.658 180.516 161.214C180.891 160.771 181.388 160.448 181.946 160.288C182.504 160.127 183.097 160.136 183.65 160.312C184.386 160.551 184.999 161.07 185.355 161.757C185.712 162.444 185.783 163.244 185.555 163.984Z'
          fill='#0F407A'
        />
        <path
          d='M179.966 181.513C179.696 182.209 179.17 182.775 178.497 183.097C177.823 183.419 177.053 183.472 176.342 183.245C175.631 183.018 175.033 182.528 174.67 181.876C174.308 181.224 174.208 180.458 174.39 179.734C174.488 179.346 174.664 178.982 174.908 178.665C175.152 178.347 175.459 178.083 175.808 177.889C176.158 177.694 176.544 177.573 176.943 177.534C177.341 177.494 177.743 177.536 178.124 177.658C178.506 177.78 178.858 177.978 179.16 178.241C179.462 178.504 179.706 178.826 179.879 179.188C180.051 179.549 180.148 179.941 180.163 180.341C180.178 180.742 180.111 181.14 179.966 181.513Z'
          fill='#0F407A'
        />
        <path
          d='M174.39 199.03C174.215 199.58 173.881 200.066 173.43 200.426C172.979 200.786 172.431 201.004 171.856 201.053C171.281 201.102 170.705 200.979 170.2 200.701C169.694 200.422 169.283 200 169.017 199.488C168.751 198.976 168.643 198.397 168.707 197.823C168.77 197.25 169.002 196.708 169.373 196.266C169.744 195.824 170.238 195.502 170.792 195.341C171.346 195.179 171.935 195.186 172.486 195.359C173.224 195.595 173.839 196.114 174.195 196.802C174.552 197.49 174.622 198.291 174.39 199.03Z'
          fill='#0F407A'
        />
        <g opacity='0.4'>
          <path
            d='M182.623 124.451C182.447 125.004 182.111 125.491 181.658 125.853C181.205 126.215 180.654 126.434 180.076 126.483C179.499 126.532 178.919 126.408 178.412 126.128C177.904 125.847 177.491 125.423 177.225 124.908C176.958 124.393 176.851 123.81 176.916 123.234C176.98 122.658 177.214 122.113 177.588 121.67C177.962 121.227 178.459 120.905 179.016 120.744C179.574 120.583 180.166 120.591 180.718 120.767C181.086 120.882 181.428 121.07 181.723 121.318C182.018 121.566 182.261 121.87 182.438 122.212C182.616 122.555 182.723 122.929 182.755 123.313C182.786 123.697 182.742 124.084 182.623 124.451Z'
            fill='white'
          />
          <path
            d='M177.034 141.968C176.858 142.518 176.523 143.003 176.071 143.363C175.619 143.722 175.071 143.939 174.496 143.987C173.92 144.035 173.344 143.911 172.839 143.632C172.334 143.352 171.923 142.929 171.659 142.415C171.394 141.902 171.288 141.322 171.353 140.748C171.418 140.175 171.652 139.633 172.025 139.192C172.398 138.752 172.893 138.431 173.448 138.272C174.003 138.112 174.592 138.121 175.142 138.297C175.508 138.414 175.846 138.601 176.139 138.849C176.432 139.096 176.673 139.399 176.849 139.74C177.025 140.081 177.131 140.454 177.163 140.836C177.195 141.218 177.151 141.603 177.034 141.968Z'
            fill='white'
          />
          <path
            d='M171.458 159.498C171.284 160.052 170.95 160.542 170.497 160.905C170.045 161.269 169.494 161.49 168.916 161.54C168.338 161.591 167.757 161.469 167.249 161.189C166.74 160.909 166.325 160.485 166.058 159.97C165.791 159.454 165.682 158.871 165.747 158.294C165.811 157.717 166.045 157.172 166.42 156.729C166.794 156.285 167.292 155.962 167.85 155.802C168.408 155.641 169.001 155.65 169.554 155.827C169.92 155.942 170.26 156.129 170.554 156.376C170.849 156.623 171.091 156.926 171.268 157.267C171.445 157.608 171.553 157.981 171.586 158.363C171.618 158.746 171.575 159.132 171.458 159.498Z'
            fill='white'
          />
          <path
            d='M165.883 177.027C165.707 177.58 165.371 178.068 164.917 178.429C164.464 178.791 163.914 179.01 163.336 179.059C162.758 179.108 162.179 178.984 161.671 178.704C161.164 178.424 160.751 177.999 160.484 177.484C160.218 176.969 160.11 176.386 160.175 175.81C160.24 175.234 160.474 174.69 160.848 174.247C161.222 173.803 161.719 173.481 162.276 173.32C162.833 173.16 163.425 173.168 163.978 173.344C164.346 173.459 164.687 173.646 164.983 173.894C165.278 174.142 165.521 174.446 165.698 174.789C165.875 175.131 165.983 175.505 166.014 175.889C166.046 176.274 166.001 176.661 165.883 177.027Z'
            fill='white'
          />
          <path
            d='M160.307 194.545C160.133 195.099 159.798 195.588 159.346 195.952C158.893 196.316 158.343 196.537 157.764 196.587C157.186 196.638 156.606 196.515 156.097 196.236C155.588 195.956 155.174 195.532 154.907 195.016C154.639 194.501 154.531 193.918 154.595 193.341C154.66 192.764 154.894 192.219 155.268 191.775C155.643 191.332 156.14 191.009 156.698 190.849C157.256 190.688 157.849 190.697 158.402 190.873C159.138 191.112 159.751 191.631 160.107 192.318C160.464 193.005 160.535 193.805 160.307 194.545Z'
            fill='white'
          />
        </g>
        <g opacity='0.4'>
          <path
            d='M196.706 128.937C196.436 129.632 195.911 130.199 195.237 130.52C194.564 130.842 193.793 130.895 193.082 130.668C192.371 130.441 191.774 129.952 191.411 129.299C191.048 128.647 190.948 127.881 191.131 127.158C191.228 126.769 191.405 126.405 191.649 126.088C191.893 125.771 192.199 125.507 192.549 125.312C192.899 125.118 193.285 124.997 193.683 124.957C194.081 124.917 194.484 124.96 194.865 125.081C195.246 125.203 195.599 125.402 195.9 125.665C196.202 125.928 196.447 126.25 196.619 126.611C196.792 126.972 196.889 127.365 196.904 127.765C196.919 128.165 196.851 128.564 196.706 128.937Z'
            fill='white'
          />
          <path
            d='M191.131 146.454C190.955 147.004 190.621 147.489 190.17 147.849C189.719 148.209 189.172 148.427 188.597 148.476C188.022 148.525 187.445 148.403 186.94 148.124C186.435 147.846 186.023 147.424 185.757 146.912C185.492 146.399 185.384 145.82 185.447 145.247C185.51 144.673 185.742 144.131 186.114 143.689C186.485 143.248 186.978 142.926 187.532 142.764C188.086 142.603 188.676 142.609 189.226 142.783C189.964 143.019 190.579 143.537 190.936 144.225C191.293 144.913 191.363 145.714 191.131 146.454Z'
            fill='white'
          />
          <path
            d='M185.555 163.984C185.381 164.538 185.046 165.027 184.594 165.391C184.141 165.755 183.591 165.976 183.013 166.026C182.434 166.077 181.854 165.954 181.345 165.675C180.836 165.395 180.422 164.971 180.155 164.455C179.887 163.94 179.779 163.357 179.844 162.78C179.908 162.203 180.142 161.658 180.516 161.214C180.891 160.771 181.389 160.448 181.946 160.288C182.504 160.127 183.097 160.136 183.65 160.312C184.387 160.551 184.999 161.07 185.356 161.757C185.712 162.444 185.784 163.244 185.555 163.984Z'
            fill='white'
          />
          <path
            d='M179.966 181.513C179.696 182.209 179.17 182.775 178.497 183.097C177.823 183.419 177.053 183.471 176.342 183.245C175.631 183.018 175.033 182.528 174.67 181.876C174.308 181.224 174.208 180.458 174.39 179.734C174.488 179.346 174.664 178.982 174.908 178.665C175.152 178.347 175.459 178.083 175.808 177.889C176.158 177.694 176.544 177.573 176.943 177.534C177.341 177.494 177.743 177.536 178.124 177.658C178.506 177.78 178.858 177.978 179.16 178.241C179.462 178.504 179.706 178.826 179.879 179.187C180.051 179.549 180.148 179.941 180.163 180.341C180.178 180.741 180.111 181.14 179.966 181.513Z'
            fill='white'
          />
          <path
            d='M174.39 199.031C174.215 199.58 173.881 200.066 173.43 200.426C172.979 200.786 172.431 201.004 171.856 201.053C171.281 201.102 170.705 200.98 170.2 200.701C169.694 200.423 169.283 200.001 169.017 199.488C168.751 198.976 168.643 198.397 168.707 197.823C168.77 197.25 169.002 196.708 169.373 196.266C169.744 195.824 170.238 195.502 170.792 195.341C171.346 195.18 171.935 195.186 172.486 195.359C173.224 195.595 173.839 196.114 174.195 196.802C174.552 197.49 174.622 198.291 174.39 199.031Z'
            fill='white'
          />
        </g>
        <path
          opacity='0.7'
          d='M159.529 206.711L121.037 214.656C119.266 215.019 117.423 214.664 115.913 213.671C114.402 212.677 113.348 211.125 112.98 209.355L101.903 155.651C101.182 152.19 101.606 148.589 103.111 145.391C104.617 142.192 107.122 139.57 110.248 137.921C111.608 137.2 113.064 136.676 114.571 136.367L134.457 132.27C136.555 131.834 138.718 131.816 140.823 132.218C142.928 132.62 144.934 133.433 146.724 134.611C148.514 135.789 150.054 137.308 151.256 139.083C152.458 140.857 153.298 142.851 153.728 144.95L164.817 198.655C165 199.531 165.009 200.435 164.842 201.314C164.676 202.194 164.337 203.032 163.846 203.781C163.354 204.529 162.72 205.173 161.98 205.676C161.239 206.179 160.406 206.531 159.529 206.711Z'
          fill='white'
        />
        <path d='M154.231 147.413L102.395 158.11L110.62 197.968L162.456 187.271L154.231 147.413Z' fill='#0F407A' />
        <path opacity='0.4' d='M154.231 147.413L102.395 158.11L110.62 197.968L162.456 187.271L154.231 147.413Z' fill='white' />
        <path
          d='M108.447 124.785L136.672 118.96C137.128 118.866 137.598 118.863 138.055 118.95C138.513 119.038 138.948 119.215 139.337 119.471C139.726 119.727 140.061 120.057 140.323 120.442C140.584 120.827 140.767 121.26 140.861 121.716L141.963 127.054L106.743 134.322L105.632 128.935C105.544 128.478 105.547 128.008 105.641 127.551C105.735 127.095 105.918 126.662 106.18 126.277C106.441 125.891 106.776 125.561 107.165 125.305C107.554 125.049 107.99 124.872 108.447 124.785Z'
          fill='#0F407A'
        />
        <path
          opacity='0.7'
          d='M140.521 132.157C138.51 131.819 136.454 131.857 134.457 132.27L114.621 136.367C113.114 136.676 111.658 137.2 110.298 137.921L109.446 133.773L139.619 127.508L140.521 132.157Z'
          fill='white'
        />
        <path
          opacity='0.3'
          d='M109.735 140.878C109.718 140.674 109.637 140.48 109.503 140.325C109.369 140.171 109.189 140.062 108.99 140.016C108.79 139.97 108.581 139.989 108.393 140.069C108.205 140.15 108.047 140.288 107.943 140.465C106.543 142.71 105.902 145.345 106.113 147.983C106.79 152.494 116.413 201.675 117.892 209.205C118.019 209.825 118.356 210.382 118.844 210.784C119.169 211.043 119.55 211.224 119.956 211.313C120.362 211.402 120.783 211.397 121.187 211.298L122.653 210.934L110.123 146.642C110.123 146.642 109.897 143.56 109.735 140.878Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_1768_240'>
          <rect width='500' height='500' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ReportingSVG;
