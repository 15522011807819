import React from 'react';
import ModalForm from '../../../../components/Modal/ModalForm';
import ModalButtons from '../../../../components/Modal/ModalButtons';
import AddUserSkeleton from '../../../../Skeletons/AddUserSkeleton';
import classes from '../InsurancePolicies.module.css';
import { useGetAllProviders } from '../../../../hooks/provider';
import { inputPhoneMasking, inputSSNMasking, returnOnlyNumber, unMasking } from '../../../../utils/validators';
import { ISaveDemographicFormReq, IUser } from '../../../../hooks/users/index.type';
import TextBox from './TextBox';
import SelectBox from './SelectBox';
import { ethenicity, maritalStatus, race } from './staticData';
import moment from 'moment';
import { useCountry } from '../../../../hooks/generic';
import toast from 'react-hot-toast';
import { useSaveUserInsuranceForm } from '../../../../hooks/users';
import { ToggleSwitchWithLabel } from '../../../../components';

interface EditPatientDemographicFormProps {
  patient: IUser;
  handleEdit?: (save: string) => void;
  closeInsurancePoliciesModal?: React.MouseEventHandler<HTMLButtonElement>;
  demographicForm: any;
}

interface Istates {
  doctor: { value: string; error: boolean };
  lastName: { value: string; error: boolean };
  firstName: { value: string; error: boolean };
  MI: { value: string; error: boolean };
  sex: { value: string; error: boolean };
  birthDate: { value: string; error: boolean };
  SSN: { value: string; error: boolean };
  streetAddress: { value: string; error: boolean };
  city: { value: string; error: boolean };
  state: { value: string; error: boolean };
  zip: { value: string; error: boolean };
  homePhone: { value: string; error: boolean };
  workPhone: { value: string; error: boolean };
  cellPhone: { value: string; error: boolean };
  preferredContact: { value: string; error: boolean };
  preferredLangage: { value: string; error: boolean };
  race: { value: string; error: boolean };
  ethenicity: { value: string; error: boolean };
  maritalStatus: { value: string; error: boolean };
  otherFamilyMembers: {
    _id: string;
    name: string;
    relationship: string;
  }[];
  responsibleParty: {
    [key: string]: string;
    lastName: string;
    firstName: string;
    MI: string;
    sex: string;
    birthDate: string;
    SSN: string;
    address: string;
    city: string;
    state: string;
    zip: string;
    home: string;
    work: string;
    cell: string;
  };
  hasInsurance: boolean;
  primaryInsurance: {
    [key: string]: string;
    company: string;
    phone: string;
    address: string;
    id: string;
    group: string;
    date: string;
  };
  policyHolder: {
    [key: string]: string;
    lastName: string;
    firstName: string;
    MI: string;
    sex: string;
    birthDate: string;
    SSN: string;
    employer: string;
    phone: string;
  };
}
interface dropDownProps {
  label: string;
  value: string;
}

const EditPatientDemographicForm: React.FC<EditPatientDemographicFormProps> = ({ patient, handleEdit, closeInsurancePoliciesModal, demographicForm }) => {
  const initialStates: Istates = {
    doctor: { value: '', error: false },
    lastName: { value: '', error: false },
    firstName: { value: '', error: false },
    MI: { value: '', error: false },
    sex: { value: 'male', error: false },
    birthDate: { value: '', error: false },
    SSN: { value: '', error: false },
    streetAddress: { value: '', error: false },
    city: { value: '', error: false },
    state: { value: '', error: false },
    zip: { value: '', error: false },
    homePhone: { value: '', error: false },
    workPhone: { value: '', error: false },
    cellPhone: { value: '', error: false },
    preferredContact: { value: 'cell', error: false },
    preferredLangage: { value: '', error: false },
    race: { value: '', error: false },
    ethenicity: { value: '', error: false },
    maritalStatus: { value: '', error: false },
    hasInsurance: true,
    otherFamilyMembers: [
      { _id: '', name: '', relationship: '' },
      { _id: '', name: '', relationship: '' },
      { _id: '', name: '', relationship: '' },
      { _id: '', name: '', relationship: '' },
    ],
    responsibleParty: {
      lastName: '',
      firstName: '',
      MI: '',
      sex: 'male',
      birthDate: '',
      SSN: '',
      address: '',
      city: '',
      state: '',
      zip: '',
      home: '',
      work: '',
      cell: '',
    },
    primaryInsurance: {
      company: '',
      phone: '',
      address: '',
      id: '',
      group: '',
      date: '',
    },
    policyHolder: {
      lastName: '',
      firstName: '',
      MI: '',
      sex: 'male',
      birthDate: '',
      SSN: '',
      employer: '',
      phone: '',
    },
  };
  const [state, setState] = React.useState<Istates>(initialStates);
  const [doctorsDropdown, setDoctorsDropdown] = React.useState<dropDownProps[]>([]);
  const [states, setStates] = React.useState<dropDownProps[]>([]);

  const { providersData } = useGetAllProviders();
  const { countryInfoLoading, countryInfo } = useCountry();
  const { saveUserInsuranceData, saveUserInsuranceDataLoading } = useSaveUserInsuranceForm({ userId: patient._id, formType: 'demographic' });

  React.useEffect(() => {
    if (providersData && providersData.data.result.length) {
      const stat: dropDownProps[] = [];
      providersData.data.result.forEach(doctor => {
        stat.unshift({ label: doctor.name, value: doctor._id });
      });
      stat.unshift({ label: 'Select Doctor', value: '' });
      setDoctorsDropdown(stat);
    }
  }, [providersData]);

  React.useEffect(() => {
    if (countryInfo.data?.length) {
      const stat: dropDownProps[] = [];
      stat.push({ label: 'Select state', value: '' });
      countryInfo.data
        .filter(country => country.iso3 === 'USA')[0]
        .states.forEach(state => {
          stat.push({ label: state.name, value: state.state_code });
        });
      setStates(stat);
    }
  }, [countryInfo.data]);

  React.useEffect(() => {
    if (patient) {
      const nameSplitted = patient.name.split(' ');
      const firstName = nameSplitted[0];
      const lastName = nameSplitted.length > 1 ? nameSplitted[nameSplitted.length - 1] : '';
      const birthDate = patient.dob;

      setState(prevVal => ({
        ...prevVal,
        firstName: { value: firstName, error: false },
        lastName: { value: lastName, error: false },
        birthDate: {
          value: birthDate,
          error: false,
        },
      }));
    }
    if (Object.keys(demographicForm?.data).length && patient) {
      // demographicForm.data.patientCareList.map(item => {
      //   item.phone = inputPhoneMasking(item.phone);
      // }););

      const storeData: Istates = {
        doctor: {
          value: demographicForm.data.doctorName,
          error: false,
        },
        lastName: {
          value: demographicForm.data.lastName,
          error: false,
        },
        firstName: {
          value: demographicForm.data.firstName,
          error: false,
        },
        MI: {
          value: demographicForm.data.MI,
          error: false,
        },
        sex: {
          value: demographicForm.data.sex,
          error: false,
        },
        birthDate: {
          value: patient.dob ? moment(patient.dob).format('yyyy-MM-DD') : '',
          error: false,
        },
        SSN: {
          value: demographicForm.data.SSN,
          error: false,
        },
        streetAddress: {
          value: demographicForm.data.address,
          error: false,
        },
        city: {
          value: demographicForm.data.city,
          error: false,
        },
        state: {
          value: demographicForm.data.state,
          error: false,
        },
        zip: {
          value: demographicForm.data.zip,
          error: false,
        },
        homePhone: {
          value: inputPhoneMasking(demographicForm.data.homePhone),
          error: false,
        },
        workPhone: {
          value: inputPhoneMasking(demographicForm.data.workPhone),
          error: false,
        },
        cellPhone: {
          value: inputPhoneMasking(demographicForm.data.cellPhone),
          error: false,
        },
        preferredContact: {
          value: demographicForm.data.preferredContact,
          error: false,
        },
        preferredLangage: {
          value: demographicForm.data.preferredLanguage,
          error: false,
        },
        race: {
          value: demographicForm.data.race,
          error: false,
        },
        ethenicity: {
          value: demographicForm.data.ethnicity,
          error: false,
        },
        maritalStatus: {
          value: demographicForm.data.maritalStatus,
          error: false,
        },
        otherFamilyMembers: demographicForm.data.familyMemberList,
        responsibleParty: {
          lastName: demographicForm.data.responsiblePartyLastName,
          firstName: demographicForm.data.responsiblePartyFirstName,
          MI: demographicForm.data.responsiblePartyMI,
          sex: demographicForm.data.responsiblePartySex,
          birthDate: demographicForm.data.responsiblePartyPatientBirthDate ? moment(demographicForm.data.responsiblePartyPatientBirthDate).format('yyyy-MM-DD') : '',
          SSN: inputSSNMasking(demographicForm.data.responsiblePartySSN),
          address: demographicForm.data.responsiblePartyAddress,
          city: demographicForm.data.responsiblePartyCity,
          state: demographicForm.data.responsiblePartyState,
          zip: demographicForm.data.responsiblePartyZip,
          home: inputPhoneMasking(demographicForm.data.responsiblePartyHomePhone),
          work: inputPhoneMasking(demographicForm.data.responsiblePartyWorkPhone),
          cell: inputPhoneMasking(demographicForm.data.responsiblePartyCellPhone),
        },
        hasInsurance: demographicForm.data.hasInsurance,
        primaryInsurance: {
          company: demographicForm.data.insuranceCompany,
          phone: demographicForm.data.insuranceCompanyPhone,
          address: demographicForm.data.insuranceCompanyAddress,
          id: demographicForm.data.insuranceCompanyIDNumber,
          group: demographicForm.data.insuranceCompanyGroupNumber,
          date: demographicForm.data.insuranceCompanyEffectiveDate ? moment(demographicForm.data.insuranceCompanyEffectiveDate).format('yyyy-MM-DD') : '',
        },
        policyHolder: {
          lastName: demographicForm.data.policyHolderLastName,
          firstName: demographicForm.data.policyHolderFirstName,
          MI: demographicForm.data.policyHolderMI,
          sex: demographicForm.data.policyHolderSex,
          birthDate: demographicForm.data.policyHolderPatientBirthDate ? moment(demographicForm.data.policyHolderPatientBirthDate).format('yyyy-MM-DD') : '',
          SSN: inputSSNMasking(demographicForm.data.policyHolderSSN),
          employer: demographicForm.data.employer,
          phone: demographicForm.data.employerPhone,
        },
      };

      if (5 - demographicForm.data.familyMemberList.length > 0) {
        [...Array(4 - demographicForm.data.familyMemberList.length)].map((star, index) => {
          index += 1;
          return storeData.otherFamilyMembers.push({
            _id: '',
            name: '',
            relationship: '',
          });
        });
      }
      setState(storeData);
    }
  }, [demographicForm, patient]);

  const handleSaveForm = async () => {
    if (!state.doctor.value) {
      setState(prevVal => ({
        ...prevVal,
        doctor: { ...prevVal.doctor, error: true },
      }));
      toast.error('Please select doctor.');
      //   else if (!state.firstName.value) {
      //   setState(prevVal => ({
      //     ...prevVal,
      //     firstName: { ...prevVal.firstName, error: true },
      //   }));
      //   toast.error('Please enter your first name.');
      // } else if (!state.lastName.value) {
      //   setState(prevVal => ({
      //     ...prevVal,
      //     lastName: { ...prevVal.lastName, error: true },
      //   }));
      //   toast.error('Please enter your last name.');
      // } else if (!state.sex.value) {
      //   setState(prevVal => ({
      //     ...prevVal,
      //     sex: { ...prevVal.sex, error: true },
      //   }));
      //   toast.error('Please select your sex type.');
      // } else if (!state.birthDate.value) {
      //   setState(prevVal => ({
      //     ...prevVal,
      //     birthDate: { ...prevVal.birthDate, error: true },
      //   }));
      //   toast.error('Please select your birth date.');
      // }
    } else if (!state.streetAddress.value) {
      setState(prevVal => ({
        ...prevVal,
        streetAddress: { ...prevVal.streetAddress, error: true },
      }));
      toast.error('Please enter street address.');
    } else if (!state.city.value) {
      setState(prevVal => ({
        ...prevVal,
        city: { ...prevVal.city, error: true },
      }));
      toast.error('Please enter city.');
    } else if (!state.state.value) {
      setState(prevVal => ({
        ...prevVal,
        state: { ...prevVal.state, error: true },
      }));
      toast.error('Please select state.');
    } else if (!state.zip.value) {
      setState(prevVal => ({
        ...prevVal,
        zip: { ...prevVal.zip, error: true },
      }));
      toast.error('Please enter zip code.');
    } else if (!state.cellPhone.value) {
      setState(prevVal => ({
        ...prevVal,
        cellPhone: { ...prevVal.cellPhone, error: true },
      }));
      toast.error('Please enter cell phone number.');
    } else if (!state.preferredContact.value) {
      setState(prevVal => ({
        ...prevVal,
        preferredContact: { ...prevVal.preferredContact, error: true },
      }));
      toast.error('Please select preferred contact number.');
    } else if (!state.homePhone.value && state.preferredContact.value === 'home') {
      setState(prevVal => ({
        ...prevVal,
        homePhone: { ...prevVal.homePhone, error: true },
      }));
      toast.error('Please enter home phone number.');
    } else if (!state.workPhone.value && state.preferredContact.value === 'work') {
      setState(prevVal => ({
        ...prevVal,
        workPhone: { ...prevVal.workPhone, error: true },
      }));
      toast.error('Please enter work phone number.');
    } else if (!state.preferredLangage.value) {
      setState(prevVal => ({
        ...prevVal,
        preferredLangage: { ...prevVal.preferredLangage, error: true },
      }));
      toast.error('Please select preferred language.');
    } else if (!state.race.value) {
      setState(prevVal => ({
        ...prevVal,
        race: { ...prevVal.race, error: true },
      }));
      toast.error('Please select race.');
    } else if (!state.ethenicity.value) {
      setState(prevVal => ({
        ...prevVal,
        ethenicity: { ...prevVal.ethenicity, error: true },
      }));
      toast.error('Please select ethnicity.');
    } else if (!state.maritalStatus.value) {
      setState(prevVal => ({
        ...prevVal,
        maritalStatus: { ...prevVal.maritalStatus, error: true },
      }));
      toast.error('Please select marital status.');
    } else {
      let hasValidRespParty = false;
      let hasValidInsurance = false;
      let hasValidPolicyHolder = false;

      if (
        !Object.keys(state.responsibleParty)
          .filter(item => item !== 'sex')
          .every(key => state.responsibleParty[key] === '')
      ) {
        if (
          Object.keys(state.responsibleParty)
            .filter(item => item !== 'sex')
            .every(key => state.responsibleParty[key] !== '')
        )
          hasValidRespParty = true;
      } else hasValidRespParty = true;

      if (state.hasInsurance) {
        // if (
        //   !Object.keys(state.primaryInsurance).every(
        //     key => state.primaryInsurance[key] === '',
        //   )
        // ) {
        //   if (
        //     Object.keys(state.primaryInsurance).every(
        //       key => state.primaryInsurance[key] !== '',
        //     )
        //   )
        //     hasValidInsurance = true;
        // } else hasValidInsurance = true;
        if (Object.keys(state.primaryInsurance).every(key => state.primaryInsurance[key] !== '')) hasValidInsurance = true;
      } else hasValidInsurance = true;

      if (
        !Object.keys(state.policyHolder)
          .filter(item => item !== 'sex')
          .every(key => state.policyHolder[key] === '')
      ) {
        if (
          Object.keys(state.policyHolder)
            .filter(item => item !== 'sex')
            .every(key => state.policyHolder[key] !== '')
        )
          hasValidPolicyHolder = true;
      } else hasValidPolicyHolder = true;

      if (!hasValidRespParty) toast.error('Please enter responsible party data correctly.');
      else if (!hasValidInsurance) toast.error('Please enter insurance data correctly.');
      else if (!hasValidPolicyHolder) toast.error('Please enter policy holder data correctly.');
      else {
        const otherFamilyMembers: ISaveDemographicFormReq['familyMemberList'] = [];
        state.otherFamilyMembers.forEach(item => {
          otherFamilyMembers.push({
            name: item.name,
            relationship: item.relationship,
          });
        });
        const formData: ISaveDemographicFormReq = {
          doctorName: state.doctor.value,
          firstName: state.firstName.value,
          lastName: state.lastName.value,
          MI: state.MI.value,
          sexMaleFemale: state.sex.value,
          patientBirthDate: state.birthDate.value,
          SSN: unMasking(state.SSN.value),
          address: state.streetAddress.value,
          city: state.city.value,
          state: state.state.value,
          zip: state.zip.value,
          homePhone: unMasking(state.homePhone.value),
          workPhone: unMasking(state.workPhone.value),
          cellPhone: unMasking(state.cellPhone.value),
          preferredContact: state.preferredContact.value,
          preferredLanguage: state.preferredLangage.value,
          race: state.race.value,
          ethnicity: state.ethenicity.value,
          maritalStatus: state.maritalStatus.value,
          familyMemberList: otherFamilyMembers,
          responsiblePartyLastName: state.responsibleParty.lastName,
          responsiblePartyFirstName: state.responsibleParty.firstName,
          responsiblePartyMI: state.responsibleParty.MI,
          responsiblePartySex: state.responsibleParty.sex,
          responsiblePartyPatientBirthDate: state.responsibleParty.birthDate,
          responsiblePartySSN: unMasking(state.responsibleParty.SSN),
          responsiblePartyAddress: state.responsibleParty.address,
          responsiblePartyCity: state.responsibleParty.city,
          responsiblePartyState: state.responsibleParty.state,
          responsiblePartyZip: state.responsibleParty.zip,
          responsiblePartyHomePhone: unMasking(state.responsibleParty.home),
          responsiblePartyWorkPhone: unMasking(state.responsibleParty.work),
          responsiblePartyCellPhone: unMasking(state.responsibleParty.cell),
          hasInsurance: state.hasInsurance,
          insuranceCompany: state.primaryInsurance.company,
          insuranceCompanyPhone: unMasking(state.primaryInsurance.phone),
          insuranceCompanyAddress: state.primaryInsurance.address,
          insuranceCompanyIDNumber: state.primaryInsurance.id,
          insuranceCompanyGroupNumber: state.primaryInsurance.group,
          insuranceCompanyEffectiveDate: state.primaryInsurance.date,
          policyHolderLastName: state.policyHolder.lastName,
          policyHolderFirstName: state.policyHolder.firstName,
          policyHolderMI: state.policyHolder.MI,
          policyHolderSex: state.policyHolder.sex,
          policyHolderPatientBirthDate: state.policyHolder.birthDate,
          policyHolderSSN: unMasking(state.policyHolder.SSN),
          employer: state.policyHolder.employer,
          employerPhone: unMasking(state.policyHolder.phone),
        };

        const data = await saveUserInsuranceData(formData);

        if (data?.status && handleEdit) {
          handleEdit('save');
        }
      }
    }
  };

  return (
    <React.Fragment>
      {demographicForm.loading ? (
        <AddUserSkeleton />
      ) : (
        <div className={classes.form_content}>
          <div className={classes.form_heading}>
            <SelectBox
              withP
              parentClassName={classes.form_heading_content}
              label='Who is your Doctor'
              value={state.doctor.value}
              // error={state.doctor.error}
              onChange={e =>
                setState(prevVal => ({
                  ...prevVal,
                  doctor: { value: e.target.value, error: false },
                }))
              }
            >
              {doctorsDropdown.map(ra => (
                <option key={ra.value} value={ra.value}>
                  {ra.label}
                </option>
              ))}
            </SelectBox>
          </div>

          <div className={classes.form_container}>
            <p className={classes.form_container_heading}>Personal Information</p>

            <div className={classes.form_relations}>
              <div className={classes.form_relation}>
                <div className={classes.form_relation_text}>
                  <p>First Name</p>
                  <span>{demographicForm.data?.firstName ? demographicForm.data?.firstName : '-'}</span>
                </div>
                <TextBox
                  withP
                  parentClassName={classes.form_relation_text}
                  placeholder='Middle Name'
                  label='Middle Name'
                  value={state.MI.value}
                  // error={state.MI.error}
                  onChange={e =>
                    setState(prevVal => ({
                      ...prevVal,
                      MI: { value: e.target.value, error: false },
                    }))
                  }
                />
                {/* <TextBox
                  withP
                  parentClassName={classes.form_relation_text}
                  placeholder='Last Name'
                  label='Last Name'
                  value={state.lastName.value}
                  // error={state.lastName.error}
                  onChange={e =>
                    setState(prevVal => ({
                      ...prevVal,
                      lastName: { value: e.target.value, error: false },
                    }))
                  }
                /> */}
                <div className={classes.form_relation_text}>
                  <p>Last Name</p>
                  <span>{demographicForm.data?.lastName ? demographicForm.data?.lastName : '-'}</span>
                </div>
              </div>

              <div className={classes.form_relation}>
                {/* <SelectBox
                  withP
                  parentClassName={classes.form_relation_text}
                  label='Sex'
                  value={state.sex.value}
                  // error={state.birthDate.error}
                  onChange={e =>
                    setState(prevVal => ({
                      ...prevVal,
                      sex: { value: e.target.value, error: false },
                    }))
                  }
                >
                  <option value='male'>Male</option>
                  <option value='female'>Female</option>
                  <option value='other'>Other</option>
                </SelectBox> */}
                <div className={classes.form_relation_text}>
                  <p>Sex</p>
                  <span>{demographicForm.data?.sex ? demographicForm.data?.sex : '-'}</span>
                </div>
                <div className={classes.form_relation_text}>
                  <p>Birth Date</p>
                  <span>{demographicForm.data?.patientBirthDate ? demographicForm.data?.patientBirthDate : '-'}</span>
                </div>
                {/* <TextBox
                  withP
                  type='date'
                  parentClassName={classes.form_relation_text}
                  placeholder='Birth Date'
                  label='Birth Date'
                  value={state.birthDate.value}
                  // error={state.birthDate.error}
                  onChange={e =>
                    setState(prevVal => ({
                      ...prevVal,
                      birthDate: { value: e.target.value, error: false },
                    }))
                  }
                /> */}
                <TextBox
                  withP
                  parentClassName={classes.form_relation_text}
                  placeholder='SSN'
                  label='SSN'
                  value={state.SSN.value}
                  // error={state.SSN.error}
                  onChange={e =>
                    setState(prevVal => ({
                      ...prevVal,
                      SSN: { value: inputSSNMasking(e.target.value), error: false },
                    }))
                  }
                />
              </div>
              <div className={classes.form_relation}>
                <TextBox
                  withP
                  placeholder='Street Address'
                  label='Street Address'
                  parentClassName={classes.form_relation_text}
                  parentStyle={{ width: '100%' }}
                  style={{ width: '100%' }}
                  spanStyle={{ width: 'calc(100% - 8rem)' }}
                  value={state.streetAddress.value}
                  // error={state.streetAddress.error}
                  onChange={e =>
                    setState(prevVal => ({
                      ...prevVal,
                      streetAddress: { value: e.target.value, error: false },
                    }))
                  }
                />
              </div>

              <div className={classes.form_relation}>
                <TextBox
                  withP
                  placeholder='City'
                  label='City'
                  parentClassName={classes.form_relation_text}
                  value={state.city.value}
                  // error={state.city.error}
                  onChange={e =>
                    setState(prevVal => ({
                      ...prevVal,
                      city: { value: e.target.value, error: false },
                    }))
                  }
                />

                <SelectBox
                  withP
                  parentClassName={classes.form_relation_text}
                  label='State'
                  value={state.state.value}
                  // error={state.birthDate.error}
                  onChange={e =>
                    setState(prevVal => ({
                      ...prevVal,
                      state: { value: e.target.value, error: false },
                    }))
                  }
                >
                  {states.map(state => (
                    <option key={state.value} value={state.value}>
                      {state.label}
                    </option>
                  ))}
                </SelectBox>

                <TextBox
                  withP
                  placeholder='ZIP'
                  label='ZIP'
                  parentClassName={classes.form_relation_text}
                  value={state.zip.value}
                  // error={state.zip.error}
                  onChange={e =>
                    setState(prevVal => ({
                      ...prevVal,
                      zip: { value: returnOnlyNumber(e.target.value), error: false },
                    }))
                  }
                />
              </div>

              <div className={classes.form_relation}>
                <TextBox
                  withP
                  placeholder='Home Phone'
                  label='Home Phone'
                  parentClassName={classes.form_relation_text}
                  value={state.homePhone.value}
                  // error={state.homePhone.error}
                  onChange={e =>
                    setState(prevVal => ({
                      ...prevVal,
                      homePhone: { value: inputPhoneMasking(e.target.value), error: false },
                    }))
                  }
                />
                <TextBox
                  withP
                  placeholder='Work Phone'
                  label='Work Phone'
                  parentClassName={classes.form_relation_text}
                  value={state.workPhone.value}
                  // error={state.workPhone.error}
                  onChange={e =>
                    setState(prevVal => ({
                      ...prevVal,
                      workPhone: { value: inputPhoneMasking(e.target.value), error: false },
                    }))
                  }
                />
                <TextBox
                  withP
                  placeholder='Cell Phone'
                  label='Cell Phone'
                  parentClassName={classes.form_relation_text}
                  value={state.cellPhone.value}
                  // error={state.cellPhone.error}
                  onChange={e =>
                    setState(prevVal => ({
                      ...prevVal,
                      cellPhone: { value: inputPhoneMasking(e.target.value), error: false },
                    }))
                  }
                />
              </div>
              <div className={classes.form_relation}>
                <SelectBox
                  withP
                  parentClassName={classes.form_relation_text}
                  label='Preferred Contact'
                  value={state.preferredContact.value}
                  // error={state.birthDate.error}
                  onChange={e =>
                    setState(prevVal => ({
                      ...prevVal,
                      preferredContact: { value: e.target.value, error: false },
                    }))
                  }
                >
                  <option value='home'>Home</option>
                  <option value='work'>Work</option>
                  <option value='cell'>Cell</option>
                </SelectBox>
                <TextBox
                  withP
                  placeholder='Preferred Language'
                  label='Preferred Language'
                  parentClassName={classes.form_relation_text}
                  value={state.preferredLangage.value}
                  // error={state.preferredLangage.error}
                  onChange={e =>
                    setState(prevVal => ({
                      ...prevVal,
                      preferredLangage: { value: e.target.value, error: false },
                    }))
                  }
                />
              </div>

              <div className={classes.form_relation}>
                <SelectBox
                  withP
                  parentClassName={classes.form_relation_text}
                  label='Race'
                  value={state.race.value}
                  // error={state.race.error}
                  onChange={e =>
                    setState(prevVal => ({
                      ...prevVal,
                      race: { value: e.target.value, error: false },
                    }))
                  }
                >
                  <option value=''>Select Race</option>
                  {race.map(ra => (
                    <option key={ra.label} value={ra.label}>
                      {ra.label}
                    </option>
                  ))}
                </SelectBox>
                <SelectBox
                  withP
                  parentClassName={classes.form_relation_text}
                  label='Ethnicity'
                  value={state.ethenicity.value}
                  // error={state.ethenicity.error}
                  onChange={e =>
                    setState(prevVal => ({
                      ...prevVal,
                      ethenicity: { value: e.target.value, error: false },
                    }))
                  }
                >
                  <option value=''>Select Ethnicity</option>
                  {ethenicity.map(eth => (
                    <option key={eth.label} value={eth.label}>
                      {eth.label}
                    </option>
                  ))}
                </SelectBox>
                <SelectBox
                  withP
                  parentClassName={classes.form_relation_text}
                  label='Marital Status'
                  value={state.maritalStatus.value}
                  // error={state.maritalStatus.error}
                  onChange={e =>
                    setState(prevVal => ({
                      ...prevVal,
                      maritalStatus: { value: e.target.value, error: false },
                    }))
                  }
                >
                  <option value=''>Select Status</option>
                  {maritalStatus.map(marital => (
                    <option key={marital.label} value={marital.label}>
                      {marital.label}
                    </option>
                  ))}
                </SelectBox>
              </div>
            </div>
          </div>

          <div className={classes.form_container}>
            <p className={classes.form_container_heading}>OTHER FAMILY MEMBERS SEEN BY OUR PRACTICE</p>
            {state.otherFamilyMembers.map((member, index) => (
              <div className={classes.form_relation} key={index} style={{ marginTop: '1rem' }}>
                <TextBox
                  withP
                  placeholder='Name'
                  label='Name'
                  parentClassName={classes.form_relation_text}
                  value={member.name}
                  onChange={e => {
                    const newState = { ...state };
                    newState.otherFamilyMembers[index].name = e.target.value;
                    setState(newState);
                  }}
                />
                <TextBox
                  withP
                  placeholder='Relationship'
                  label='Relationship'
                  parentClassName={classes.form_relation_text}
                  value={member.relationship}
                  onChange={e => {
                    const newState = { ...state };
                    newState.otherFamilyMembers[index].relationship = e.target.value;
                    setState(newState);
                  }}
                />
              </div>
            ))}
          </div>

          <div className={classes.form_container}>
            <div className={classes.form_container_heading}>RESPONSIBLE PARTY (If different from patient)</div>
            <div className={classes.form_relations}>
              <div className={classes.form_relation}>
                <TextBox
                  withP
                  placeholder='First Name'
                  label='First Name'
                  parentClassName={classes.form_relation_text}
                  value={state.responsibleParty.firstName}
                  onChange={e =>
                    setState(prevVal => ({
                      ...prevVal,
                      responsibleParty: {
                        ...prevVal.responsibleParty,
                        firstName: e.target.value,
                      },
                    }))
                  }
                />
                <TextBox
                  withP
                  placeholder='Middle Name'
                  label='Middle Name'
                  parentClassName={classes.form_relation_text}
                  value={state.responsibleParty.MI}
                  onChange={e =>
                    setState(prevVal => ({
                      ...prevVal,
                      responsibleParty: {
                        ...prevVal.responsibleParty,
                        MI: e.target.value,
                      },
                    }))
                  }
                />
                <TextBox
                  withP
                  placeholder='Last Name'
                  label='Last Name'
                  parentClassName={classes.form_relation_text}
                  value={state.responsibleParty.lastName}
                  onChange={e =>
                    setState(prevVal => ({
                      ...prevVal,
                      responsibleParty: {
                        ...prevVal.responsibleParty,
                        lastName: e.target.value,
                      },
                    }))
                  }
                />
              </div>

              <div className={classes.form_relation}>
                <SelectBox
                  withP
                  parentClassName={classes.form_relation_text}
                  label='Sex'
                  value={state.responsibleParty.sex}
                  onChange={e =>
                    setState(prevVal => ({
                      ...prevVal,
                      responsibleParty: {
                        ...prevVal.responsibleParty,
                        sex: e.target.value,
                      },
                    }))
                  }
                >
                  <option value='male'>Male</option>
                  <option value='female'>Female</option>
                  <option value='other'>Other</option>
                </SelectBox>
                <TextBox
                  withP
                  type='date'
                  placeholder='Birth Date'
                  label='Birth Date'
                  parentClassName={classes.form_relation_text}
                  value={state.responsibleParty.birthDate}
                  onChange={e =>
                    setState(prevVal => ({
                      ...prevVal,
                      responsibleParty: {
                        ...prevVal.responsibleParty,
                        birthDate: e.target.value,
                      },
                    }))
                  }
                />
                <TextBox
                  withP
                  placeholder='SSN'
                  label='SSN'
                  parentClassName={classes.form_relation_text}
                  value={state.responsibleParty.SSN}
                  onChange={e =>
                    setState(prevVal => ({
                      ...prevVal,
                      responsibleParty: {
                        ...prevVal.responsibleParty,
                        SSN: inputSSNMasking(e.target.value),
                      },
                    }))
                  }
                />
              </div>

              <div className={classes.form_relation}>
                <TextBox
                  withP
                  placeholder='Street Address'
                  label='Street Address'
                  parentClassName={classes.form_relation_text}
                  parentStyle={{ width: '100%' }}
                  style={{ width: '100%' }}
                  spanStyle={{ width: 'calc(100% - 8rem)' }}
                  value={state.responsibleParty.address}
                  onChange={e =>
                    setState(prevVal => ({
                      ...prevVal,
                      responsibleParty: {
                        ...prevVal.responsibleParty,
                        address: e.target.value,
                      },
                    }))
                  }
                />
              </div>

              <div className={classes.form_relation}>
                <TextBox
                  withP
                  placeholder='City'
                  label='City'
                  parentClassName={classes.form_relation_text}
                  value={state.responsibleParty.city}
                  onChange={e =>
                    setState(prevVal => ({
                      ...prevVal,
                      responsibleParty: {
                        ...prevVal.responsibleParty,
                        city: e.target.value,
                      },
                    }))
                  }
                />
                <SelectBox
                  withP
                  parentClassName={classes.form_relation_text}
                  label='State'
                  value={state.responsibleParty.state}
                  onChange={e =>
                    setState(prevVal => ({
                      ...prevVal,
                      responsibleParty: {
                        ...prevVal.responsibleParty,
                        state: e.target.value,
                      },
                    }))
                  }
                >
                  {states.map(state => (
                    <option key={state.value} value={state.value}>
                      {state.label}
                    </option>
                  ))}
                </SelectBox>
                <TextBox
                  withP
                  placeholder='Zip'
                  label='Zip'
                  parentClassName={classes.form_relation_text}
                  value={state.responsibleParty.zip}
                  onChange={e =>
                    setState(prevVal => ({
                      ...prevVal,
                      responsibleParty: {
                        ...prevVal.responsibleParty,
                        zip: returnOnlyNumber(e.target.value),
                      },
                    }))
                  }
                />
              </div>

              <div className={classes.form_relation}>
                <TextBox
                  withP
                  placeholder='Home Phone'
                  label='Home Phone'
                  parentClassName={classes.form_relation_text}
                  value={state.responsibleParty.home}
                  onChange={e =>
                    setState(prevVal => ({
                      ...prevVal,
                      responsibleParty: {
                        ...prevVal.responsibleParty,
                        home: inputPhoneMasking(e.target.value),
                      },
                    }))
                  }
                />
                <TextBox
                  withP
                  placeholder='Work Phone'
                  label='Work Phone'
                  parentClassName={classes.form_relation_text}
                  value={state.responsibleParty.work}
                  onChange={e =>
                    setState(prevVal => ({
                      ...prevVal,
                      responsibleParty: {
                        ...prevVal.responsibleParty,
                        work: inputPhoneMasking(e.target.value),
                      },
                    }))
                  }
                />
                <TextBox
                  withP
                  placeholder='Cell Phone'
                  label='Cell Phone'
                  parentClassName={classes.form_relation_text}
                  value={state.responsibleParty.cell}
                  onChange={e =>
                    setState(prevVal => ({
                      ...prevVal,
                      responsibleParty: {
                        ...prevVal.responsibleParty,
                        cell: inputPhoneMasking(e.target.value),
                      },
                    }))
                  }
                />
              </div>
            </div>
          </div>

          <div className={classes.form_container}>
            <p className={classes.form_container_heading}>PRIMARY INSURANCE INFORMATION</p>
            {state.hasInsurance ? (
              <div className={classes.form_relations}>
                <div className={classes.form_relation}>
                  <TextBox
                    withP
                    placeholder='Insurance Company'
                    label='Insurance Company'
                    parentClassName={classes.form_relation_text}
                    value={state.primaryInsurance.company}
                    onChange={e =>
                      setState(prevVal => ({
                        ...prevVal,
                        primaryInsurance: {
                          ...prevVal.primaryInsurance,
                          company: e.target.value,
                        },
                      }))
                    }
                  />
                  <TextBox
                    withP
                    placeholder='Phone'
                    label='Phone'
                    parentClassName={classes.form_relation_text}
                    value={state.primaryInsurance.phone}
                    onChange={e =>
                      setState(prevVal => ({
                        ...prevVal,
                        primaryInsurance: {
                          ...prevVal.primaryInsurance,
                          phone: inputPhoneMasking(e.target.value),
                        },
                      }))
                    }
                  />
                  <TextBox
                    withP
                    placeholder='ID Number'
                    label='ID Number'
                    parentClassName={classes.form_relation_text}
                    value={state.primaryInsurance.id}
                    onChange={e =>
                      setState(prevVal => ({
                        ...prevVal,
                        primaryInsurance: {
                          ...prevVal.primaryInsurance,
                          id: e.target.value,
                        },
                      }))
                    }
                  />
                </div>

                <div className={classes.form_relation}>
                  <TextBox
                    withP
                    placeholder='Address'
                    label='Address'
                    parentClassName={classes.form_relation_text}
                    value={state.primaryInsurance.address}
                    onChange={e =>
                      setState(prevVal => ({
                        ...prevVal,
                        primaryInsurance: {
                          ...prevVal.primaryInsurance,
                          address: e.target.value,
                        },
                      }))
                    }
                  />
                  <TextBox
                    withP
                    placeholder='Group Number'
                    label='Group Number'
                    parentClassName={classes.form_relation_text}
                    value={state.primaryInsurance.group}
                    onChange={e =>
                      setState(prevVal => ({
                        ...prevVal,
                        primaryInsurance: {
                          ...prevVal.primaryInsurance,
                          group: e.target.value,
                        },
                      }))
                    }
                  />
                  <TextBox
                    withP
                    type='date'
                    placeholder='Effective Date'
                    label='Effective Date'
                    parentClassName={classes.form_relation_text}
                    value={state.primaryInsurance.date}
                    onChange={e =>
                      setState(prevVal => ({
                        ...prevVal,
                        primaryInsurance: {
                          ...prevVal.primaryInsurance,
                          date: e.target.value,
                        },
                      }))
                    }
                  />
                </div>
              </div>
            ) : null}

            <ToggleSwitchWithLabel
              label="Patient don't have insurance"
              id='no-insurance'
              checked={!state.hasInsurance}
              onChange={() =>
                setState(prevVal => ({
                  ...prevVal,
                  hasInsurance: !prevVal.hasInsurance,
                }))
              }
              style={{ marginTop: '1.5rem', fontSize: '1.3rem' }}
            />
          </div>

          <div className={classes.form_container}>
            <p className={classes.form_container_heading}>POLICY HOLDER (If different from patient)</p>
            <div className={classes.form_relations}>
              <div className={classes.form_relation}>
                <TextBox
                  withP
                  placeholder='First Name'
                  label='First Name'
                  parentClassName={classes.form_relation_text}
                  value={state.policyHolder.firstName}
                  onChange={e =>
                    setState(prevVal => ({
                      ...prevVal,
                      policyHolder: {
                        ...prevVal.policyHolder,
                        firstName: e.target.value,
                      },
                    }))
                  }
                />
                <TextBox
                  withP
                  placeholder='Middle Name'
                  label='Middle Name'
                  parentClassName={classes.form_relation_text}
                  value={state.policyHolder.MI}
                  onChange={e =>
                    setState(prevVal => ({
                      ...prevVal,
                      policyHolder: {
                        ...prevVal.policyHolder,
                        MI: e.target.value,
                      },
                    }))
                  }
                />
                <TextBox
                  withP
                  placeholder='Last Name'
                  label='Last Name'
                  parentClassName={classes.form_relation_text}
                  value={state.policyHolder.lastName}
                  onChange={e =>
                    setState(prevVal => ({
                      ...prevVal,
                      policyHolder: {
                        ...prevVal.policyHolder,
                        lastName: e.target.value,
                      },
                    }))
                  }
                />
              </div>

              <div className={classes.form_relation}>
                <SelectBox
                  withP
                  parentClassName={classes.form_relation_text}
                  label='Sex'
                  value={state.policyHolder.sex}
                  onChange={e =>
                    setState(prevVal => ({
                      ...prevVal,
                      policyHolder: {
                        ...prevVal.policyHolder,
                        sex: e.target.value,
                      },
                    }))
                  }
                >
                  <option value='male'>Male</option>
                  <option value='female'>Female</option>
                  <option value='other'>Other</option>
                </SelectBox>
                <TextBox
                  withP
                  type='date'
                  placeholder='Birth Date'
                  label='Birth Date'
                  parentClassName={classes.form_relation_text}
                  value={state.policyHolder.birthDate}
                  onChange={e =>
                    setState(prevVal => ({
                      ...prevVal,
                      policyHolder: {
                        ...prevVal.policyHolder,
                        birthDate: e.target.value,
                      },
                    }))
                  }
                />
                <TextBox
                  withP
                  placeholder='SSN'
                  label='SSN'
                  parentClassName={classes.form_relation_text}
                  value={state.policyHolder.SSN}
                  onChange={e =>
                    setState(prevVal => ({
                      ...prevVal,
                      policyHolder: {
                        ...prevVal.policyHolder,
                        SSN: inputSSNMasking(e.target.value),
                      },
                    }))
                  }
                />
              </div>

              <div className={classes.form_relation}>
                <TextBox
                  withP
                  placeholder='Employer'
                  label='Employer'
                  parentClassName={classes.form_relation_text}
                  value={state.policyHolder.employer}
                  onChange={e =>
                    setState(prevVal => ({
                      ...prevVal,
                      policyHolder: {
                        ...prevVal.policyHolder,
                        employer: e.target.value,
                      },
                    }))
                  }
                />
                <TextBox
                  withP
                  placeholder='Phone'
                  label='Phone'
                  parentClassName={classes.form_relation_text}
                  value={state.policyHolder.phone}
                  onChange={e =>
                    setState(prevVal => ({
                      ...prevVal,
                      policyHolder: {
                        ...prevVal.policyHolder,
                        phone: inputPhoneMasking(e.target.value),
                      },
                    }))
                  }
                />
              </div>
            </div>
          </div>

          <div className={classes.form_container}>
            <p className={classes.form_container_heading}>
              AUTHORIZATION TO PAY BENEFITS TO PHYSICIAN:
              <br />I hereby authorize payment to clinIQ, the surgical and/or medical benefits available. if any, and authorize release of any medical information
              necessary to process this and future claims. I understand that if my insurance fails to make a payment, I am ultimately responsible for payment of services
              rendered.
            </p>
            {/* <div className={classes.form_numbers}>
                <p className={classes.form_numbers_text}>
                  Patient/Gurdian Signature
                  <span>{demographicForm.data?.homePhone}</span>
                </p>
                <p className={classes.form_numbers_text}>
                  Date <span>{demographicForm.data?.workPhone}</span>
                </p>
              </div> */}
          </div>
        </div>
      )}

      <ModalForm style={{ marginBottom: '2.5rem' }}>
        <ModalButtons
          submitButtonLabel='Save Patient Demographic Form'
          onSubmit={e => {
            e.preventDefault();
            handleSaveForm();
          }}
          submitButtonDisabled={countryInfoLoading || saveUserInsuranceDataLoading}
          cancelButtonLabel='Cancel'
          onCancel={closeInsurancePoliciesModal}
        />
      </ModalForm>
    </React.Fragment>
  );
};

export default EditPatientDemographicForm;
