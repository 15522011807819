import React from 'react';
import type { FC, PropsWithChildren } from 'react';
import Select, { ActionMeta, InputActionMeta, OnChangeValue } from 'react-select';

type MultiSelectBoxProps = PropsWithChildren<{
  icon?: string;
  inputValue?: string;
  value: { label: string; value: string }[];
  errorMessage?: string;
  placeholder: string;
  options: { label: string; value: string }[];
  onInputChange?: (newValue: string, actionMeta: InputActionMeta) => void;
  onChange: (newValue: OnChangeValue<{ label: string; value: string }, boolean>, actionMeta: ActionMeta<{ label: string; value: string }>) => void;
  // onChange: (newValue: SingleValue<{ label: string; value: string }>, actionMeta: ActionMeta<{ label: string; value: string }>) => void;
}>;

const MultiSelectBox: FC<MultiSelectBoxProps> = props => {
  const colourStyles = {
    menuList: (styles: any) => ({
      ...styles,
      backgroundColor: '#FFF',
      zIndex: 999,
      width: '100%',
    }),
    option: (styles: any, { isFocused, isSelected }: { isFocused: boolean; isSelected: boolean }) => ({
      ...styles,
      color: isSelected ? '#000' : '#000',
      backgroundColor: isSelected ? '#FFF' : '#FFF',
      zIndex: 1,
    }),
    menu: (base: any) => ({
      ...base,
      zIndex: 999,
    }),
  };

  // const NoOptionsMessage = (props: any) => {
  //   return (
  //     <components.NoOptionsMessage {...props}>
  //       <span>No ads found with name</span>
  //     </components.NoOptionsMessage>
  //   );
  // };
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
      }}
    >
      <div style={{ width: '100%' }}>
        {props.icon && (
          <label style={{ top: '0rem' }}>
            <svg>
              <use xlinkHref={`/assets/sprite.svg#icon-${props.icon}`} />
            </svg>
          </label>
        )}
        <div>
          <Select
            value={props.value}
            isMulti={true}
            options={props.options}
            inputValue={props.inputValue}
            onChange={props.onChange}
            styles={colourStyles}
            placeholder={props.placeholder}
            className='textBox__input--box'
            onInputChange={props.onInputChange}
            isClearable={false}
          />
        </div>
      </div>

      {props.errorMessage && <p className='form_input__error'>{props.errorMessage}</p>}
    </div>
  );
};

export default MultiSelectBox;
