import React from 'react';
import toast from 'react-hot-toast';
import useApi from '../useApi';
import { IShareFileReq, IShareFileRes } from './index.type';

const useShareFile = () => {
  const [shareFilesData, setShareFilesData] = React.useState<IShareFileRes>();

  const { loading: shareFileLoading, makeApiCall } = useApi({
    url: `/fileExchange/share-file`,
    method: 'POST',
  });

  const shareFile = async (formData: IShareFileReq) => {
    const { response, error } = await makeApiCall<IShareFileReq, IShareFileRes>(formData);
    if (response?.status === 'success') {
      setShareFilesData(response);
    }

    if (error || response?.status === 'fail') {
      toast.error('Something went wrong while sharing file. Please try again later');
    }
  };

  return {
    shareFilesData,
    shareFile,
    shareFileLoading,
  };
};

export default useShareFile;
