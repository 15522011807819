import React from 'react';
import type { FC, PropsWithChildren } from 'react';
import { ButtonOutline, Modal, ModalButtons } from '../../components/Modal';
import ModalHeading from '../../components/Modal/ModalHeading';
import classes from '../../styles/Appointments.module.css';

type AddEditModalProps = PropsWithChildren<{
  status: boolean;
  onCloseModal?: () => void;
}>;

const AppointmentDetailModal: FC<AddEditModalProps> = props => {
  return (
    <Modal show={props.status} style={{ width: '86rem' }}>
      <ModalHeading heading='Appointment Details' />

      <div className={classes.detail_container}>
        <div className={classes.detail_container_left}>
          <h4 className={classes.detail_container_title}>Patient Details</h4>
          <h2 className={classes.detail_title}>John Smith</h2>
          <p className={classes.detail_text}>08-20-2023 | 3 Month Follow-Up</p>
          <p className={classes.detail_chip}>10 minutes</p>

          <h2 className={classes.detail_title + ' ' + classes.margin_top}>Details</h2>
          <p className={classes.detail_text}>
            <span>Lorem ipsum dolor sit amet</span>
            Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore
            veritatis et quasi architecto beatae vitae dicta sunt explicabo.
          </p>
        </div>
        <div className={classes.detail_container_right}>
          <div className={classes.detail_container_right_top}>
            <h4 className={classes.detail_container_title}>Clinic Details</h4>
            <h2 className={classes.detail_title}>Cardiology Location</h2>
            <p className={classes.detail_text}>215-657-7010</p>
            <p className={classes.detail_address}>795 Horsham Rd, Horsham, PA - 190441</p>

            <h2 className={classes.detail_title + ' ' + classes.margin_top}>David Buckley</h2>
            <p className={classes.detail_text}>215-657-7010</p>
            <p className={classes.detail_address}>410 Owens Dr, Melbourne, FL - 32901</p>
          </div>
          <div className={classes.detail_container_right_bottom}>
            <ButtonOutline label='RESCHEDULE' icon='edit-new' />
            <ButtonOutline label='CANCEL' icon='close' variant='danger' />
          </div>
        </div>
      </div>
      <ModalButtons cancelButtonLabel='Close' onCancel={props.onCloseModal} style={{ marginRight: '2rem', marginBottom: '2rem' }} />
    </Modal>
  );
};

export default AppointmentDetailModal;
