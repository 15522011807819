import React from 'react';
import toast from 'react-hot-toast';
import useApi from '../useApi';
import { IPatientFeedbacksRes } from './index.type';

const usePatientFeedbacks = ({ clinicId, role }: { role?: string; clinicId?: string }) => {
  const [patientFeedbacks, setPatientFeedbacks] = React.useState<IPatientFeedbacksRes>();

  const url = role === 'admin' && clinicId ? `/feedback/get-results?clinicId=${clinicId}` : `/feedback/get-results`;

  const { loading: patientFeedbacksLoading, makeApiCall } = useApi({
    url,
    method: 'GET',
  });

  const getPatientFeedbacks = async () => {
    const { response, error } = await makeApiCall<undefined, IPatientFeedbacksRes>();
    if (response?.status === 'success') {
      setPatientFeedbacks(response);
    }

    if (response?.status === 'fail') {
      setPatientFeedbacks(undefined);
      toast.error('Something went wrong while fetching patient feedbacks data. Please try again later');
    }

    if (error) {
      setPatientFeedbacks(undefined);
      toast.error(error.msg);
    }
  };

  React.useEffect(() => {
    getPatientFeedbacks();
    // eslint-disable-next-line
  }, [clinicId]);

  return {
    patientFeedbacks,
    getPatientFeedbacks,
    patientFeedbacksLoading,
  };
};

export default usePatientFeedbacks;
