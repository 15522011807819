import React, { useRef, useState } from 'react';
import { Chart } from 'react-chartjs-2';
import classes from './HomeScreen.module.css';

const options = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      beginAtZero: true,
      grid: {
        drawOnChartArea: false,
      },
      ticks: {
        font: {
          size: 10,
        },
      },
    },
    y: {
      display: false,
    },
  },
  plugins: {
    legend: {
      display: false,
      position: 'top',
      // labels: false,
      // labels: {
      //   font: {
      //     size: 10,
      //     family: 'inherit',
      //   },
      // }
    },
    title: {
      display: false,
      text: 'Chart.js Line Chart',
    },
  },
};

let labels = [
  'Cardio',
  'Surgery',
  'Neuro',
  'Paedics',
  'Gyno',
  'Derma',
  'Physio',
];

const data1 = {
  datasets: [
    {
      fill: false,
      label: 'Patients',
      // data: [68, 74, 62, 43, 35, 46, 34],
      data: [257, 225, 256, 108, 126, 71, 258],
      // borderColor: 'rgba(53, 162, 235,0)',
      borderWidth: 2,
      tension: 0.5,
      pointBackgroundColor: '#FF7B01',
      pointBorderColor: '#FF7B01',
    },
    // {
    //   fill: false,
    //   label: 'Outpatients',
    //   data: [189, 151, 194, 65, 91, 25, 224],
    //   // borderColor: 'rgba(53, 162, 235,0)',
    //   borderWidth: 2,
    //   tension: 0.5,
    //   pointBackgroundColor: '#FF7B01',
    //   pointBorderColor: '#FF7B01',
    // },
  ],
};

function createGradient(ctx, area) {
  const colorStart = 'rgba(1, 196, 255, 0.5)';
  const colorMid = 'rgba(0, 113, 174, 0.5)';
  const colorEnd = 'rgba(0, 113, 174, .5';

  // const gradient = ctx.createLinearGradient(0, area.bottom, 0, area.top);
  const gradient = ctx.createLinearGradient(area.left, 0, area.right, 0);

  gradient.addColorStop(0, colorStart);
  gradient.addColorStop(0.5, colorMid);
  gradient.addColorStop(1, colorEnd);

  return gradient;
}

const Chart5 = () => {
  const chartRef = useRef(null);
  const [chartData, setChartData] = useState({
    datasets: [],
  });
  // useEffect(() => {
  //   const chart = chartRef.current;

  //   if (!chart) {
  //     return;
  //   }
  //   if (apiData.data) {
  //     const chartData = {
  //       labels: apiData.data.map((item) => item.encounter),
  //       datasets: data1.datasets.map((dataset) => ({
  //         ...dataset,
  //         data: apiData.data.map((item) => item.count),
  //         borderColor: createGradient(chart.ctx, chart.chartArea),
  //       })),
  //     };

  //     setChartData(chartData);
  //   }
  // }, [apiData]);

  React.useEffect(() => {
    const chart = chartRef.current;

    if (!chart) {
      return;
    }

    const chartData = {
      ...data1,
      labels: labels,
      datasets: data1.datasets.map((dataset) => ({
        ...dataset,
        backgroundColor: createGradient(chart.ctx, chart.chartArea),
      })),
    };

    setChartData(chartData);
  }, []);

  return (
    <div className={classes.chart5}>
      <p className={classes.chart4_heading}>
        (In & Out) Patient in Sept <span>2022</span>
      </p>
      <div className={classes.chart4_data}>
        <p className={classes.chart4_data_number + ' ' + classes.grad_color}>
          {/* {apiData.count} */}
          1305
        </p>
      </div>
      <div style={{ height: '80px' }}>
        <Chart ref={chartRef} type='line' data={chartData} options={options} />
      </div>
    </div>
  );
};

export default Chart5;
