import React from 'react';

interface TextBoxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  parentClassName?: string;
  withP?: boolean;
  parentStyle?: React.CSSProperties;
  spanStyle?: React.CSSProperties;
}

const TextBox: React.FC<TextBoxProps> = props => {
  return (
    <div className={props.parentClassName} style={props.parentStyle}>
      {props.withP ? <p>{props.label}</p> : props.label}
      <span style={props.spanStyle}>
        <input {...props} />
      </span>
    </div>
  );
};

export default TextBox;
