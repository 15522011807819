import toast from 'react-hot-toast';
import useApi from '../useApi';

const useDeleteCampaign = ({ campaignId }: { campaignId?: string }) => {
  const { loading: deleteCampaignLoading, makeApiCall } = useApi({
    url: `/advertise/campaign-remove/${campaignId}`,
    method: 'DELETE',
  });

  const deleteCampaign = async () => {
    const { error } = await makeApiCall<undefined, undefined>();

    if (error) {
      toast.error('Something went wrong whiling deleting campaign. Please try again later');
      return { status: false };
    } else {
      toast.error('Campaign deleted successfully.');
    }
  };

  return {
    deleteCampaignLoading,
    deleteCampaign,
  };
};

export default useDeleteCampaign;
