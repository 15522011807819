import React from 'react';
import toast from 'react-hot-toast';
import useApi from '../useApi';
import { IFolderFilesRes } from './index.type';

const useFoldersFiles = ({ folderId }: { folderId?: string }) => {
  const [folderFilesData, setFolderFilesData] = React.useState<IFolderFilesRes>();

  const { loading: folderFilesDataLoading, makeApiCall } = useApi({
    url: `/fileExchange?folder=${folderId}`,
    method: 'GET',
  });

  const getFolderFilesData = async () => {
    const { response, error } = await makeApiCall<undefined, IFolderFilesRes>();
    if (response?.status === 'success') {
      setFolderFilesData(response);
    }

    if (response?.status === 'fail') {
      setFolderFilesData(undefined);
    }

    if (error) toast.error('Something went wrong while fetching folder files. Please try again later');
  };

  return {
    folderFilesData,
    getFolderFilesData,
    folderFilesDataLoading,
  };
};

export default useFoldersFiles;
