import toast from 'react-hot-toast';
import useApi from '../useApi';
import { ICancelAppointmentRes } from './index.type';

const useDeleteCampaignImage = ({ imageId }: { imageId?: string }) => {
  const { loading: deleteImageLoading, makeApiCall } = useApi({
    url: `/advertise/ads/${imageId}`,
    method: 'DELETE',
  });

  const deleteImage = async () => {
    const { response, error } = await makeApiCall<undefined, ICancelAppointmentRes>();
    if (response?.status) {
      toast.success(response.message);
      return response;
    }

    if (error || !response?.status) {
      toast.error('Something went wrong. Please try again later');
      return { status: false };
    }
  };

  return {
    deleteImageLoading,
    deleteImage,
  };
};

export default useDeleteCampaignImage;
