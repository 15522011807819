import type { FC, PropsWithChildren } from 'react';
import CancelButtonStyles from './CancelButton.module.css';

type CancelButtonProps = PropsWithChildren<{
  label: string;
  type?: 'submit' | 'button' | 'reset';
  cancelButtonStyle?: React.CSSProperties;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}>;

const CancelButton: FC<CancelButtonProps> = ({ label, type, onClick, cancelButtonStyle = {} }) => {
  return (
    <button onClick={onClick} type={type} className={CancelButtonStyles.button} style={cancelButtonStyle}>
      {label}
    </button>
  );
};

export default CancelButton;
