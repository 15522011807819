import { FC } from "react";
import AdViewStyles from "../../styles/AdViewScreen.module.css";
import { IAdRule } from "../../hooks/adViews/index.type";

type RulesBoxProps = {
	adRule: IAdRule;
	onEditRule: () => void;
	onDeleteRule: () => void;
};

const RuleBox: FC<RulesBoxProps> = (props) => {
	return (
		<div className={AdViewStyles.rule_card}>
			<div className={AdViewStyles.rule_card_upper_content}>
				<div className={AdViewStyles.rule_card_upper_content_chip1}>
					{props.adRule.field}
				</div>
				<div className={AdViewStyles.rule_card_upper_content_chip2}>
					{props.adRule.operator === "Contains"
						? "Equals"
						: props.adRule.operator}
				</div>
			</div>
			<div className={AdViewStyles.rule_card_lower_content}>
				{Array.isArray(props.adRule.value) ? (
					props.adRule.value.map((valueItem) => {
						return (
							<div
								className={
									AdViewStyles.rule_card_lower_content_chip
								}
								key={valueItem.value}
							>
								{valueItem.label}
							</div>
						);
					})
				) : (
					<div className={AdViewStyles.rule_card_lower_content_chip}>
						{props.adRule.value}
					</div>
				)}
			</div>
			<div className={AdViewStyles.rule_card_options}>
				<div
					className={AdViewStyles.add_rule_button}
					onClick={props.onEditRule}
				>
					Edit
				</div>
				<div
					className={AdViewStyles.remove_button}
					onClick={props.onDeleteRule}
				>
					Delete
				</div>
			</div>
		</div>
	);
};

export default RuleBox;
