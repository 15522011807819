import type { FC, PropsWithChildren } from 'react';
import TextBoxStyles from '../InputBox/TextBox.module.css';

type TextAreaProps = PropsWithChildren<{
  placeholder: string;
  rows?: number;
  value: string;
  errorMessage?: string;
  label: string;
  disabled?: boolean;
  defaultValue?: string;
  style?: React.CSSProperties;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
}>;

const TextArea: FC<TextAreaProps> = ({ placeholder, rows = 4, value, onChange, errorMessage, label, style, disabled, defaultValue }) => {
  return (
    <div className={TextBoxStyles.form_input}>
      {label && (
        <label htmlFor={label} className={TextBoxStyles.form_input__label}>
          {label}
        </label>
      )}
      <div className={TextBoxStyles.form_input__container} style={style}>
        <textarea
          id={label}
          disabled={disabled ? disabled : false}
          placeholder={placeholder}
          value={value}
          defaultValue={defaultValue}
          onChange={onChange}
          className={TextBoxStyles.form_input__box}
          rows={rows}
        />
      </div>
      {errorMessage && <p className={TextBoxStyles.form_input__error}>{errorMessage}</p>}
    </div>
  );
};

export default TextArea;
