import toast from 'react-hot-toast';
import useApi from '../useApi';
import { IToggleCampaignRes, IToggleCampaignReq } from './index.type';

const useToggleCampaign = ({ campaignId }: { campaignId?: string }) => {
  const { loading: toggleCampaignLoading, makeApiCall } = useApi({
    url: `/advertise/${campaignId}/toggle-status`,
    method: 'POST',
  });

  const toggleCampaign = async (formData: IToggleCampaignReq) => {
    const { response, error } = await makeApiCall<IToggleCampaignReq, IToggleCampaignRes>(formData);
    if (response?.status) {
      toast.success(response.message);
      return response;
    }

    if (!response?.status) {
      response?.message && toast.success(response.message);
      return response;
    }

    if (error) {
      toast.error('Something went wrong while changing campaign status. Please try again later');
      return { status: false };
    }
  };

  return {
    toggleCampaign,
    toggleCampaignLoading,
  };
};

export default useToggleCampaign;
