import React from 'react';
import classes from './HomeScreen.module.css';

const Chart4 = () => {
  return (
    <div className={classes.chart4}>
      <p className={classes.chart4_heading}>
        Bed Occupancy Rate (Last <span>3</span> months)
      </p>
      {/* <div className={classes.chart4_data}>
        <p className={classes.chart4_data_number + ' ' + classes.grad_color}>
          5284
        </p>
        <p className={classes.chart4_active + ' ' + classes.grad_color}>
          4710 active
        </p>
      </div> */}

      <div className={classes.chart1_bar} style={{ marginTop: '1rem' }}>
        <div className={classes.chart1_bar_graph}>
          <div
            className={classes.chart1_bar_graph_value}
            style={{ width: '57%' }}
          ></div>
          <div
            className={classes.chart1_bar_graph_arrow}
            style={{ left: 'calc(57% - 0.4rem)' }}
          ></div>
        </div>

        <div className={classes.chart1_bar_values}>
          <p>September</p>
          <p className={classes.chart1_bar_values_num}>57%</p>
        </div>
      </div>

      <div className={classes.chart1_bar}>
        <div className={classes.chart1_bar_graph}>
          <div
            className={classes.chart1_bar_graph_value}
            style={{ width: '64%' }}
          ></div>
          <div
            className={classes.chart1_bar_graph_arrow}
            style={{ left: 'calc(64% - 0.4rem)' }}
          ></div>
        </div>
        <div className={classes.chart1_bar_values}>
          <p>August</p>
          <p className={classes.chart1_bar_values_num}>64%</p>
        </div>
      </div>

      <div className={classes.chart1_bar}>
        <div className={classes.chart1_bar_graph}>
          <div
            className={classes.chart1_bar_graph_value}
            style={{ width: '58%' }}
          ></div>
          <div
            className={classes.chart1_bar_graph_arrow}
            style={{ left: 'calc(58% - 0.4rem)' }}
          ></div>
        </div>
        <div className={classes.chart1_bar_values}>
          <p>July</p>
          <p className={classes.chart1_bar_values_num}>58%</p>
        </div>
      </div>
    </div>
  );
};

export default Chart4;
