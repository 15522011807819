import toast from 'react-hot-toast';
import useApi from '../useApi';
import { IEditFolderReq, IFolderByIdRes } from './index.type';

const useEditFolder = ({ folderId }: { folderId?: string }) => {
  const { loading: editFolderLoading, makeApiCall } = useApi({
    url: `/folders/${folderId}`,
    method: 'PATCH',
  });

  const editFolder = async (formData: IEditFolderReq) => {
    const { response, error } = await makeApiCall<IEditFolderReq, IFolderByIdRes>(formData);
    if (response?.status === 'success') {
      toast.success('Folder name updated!');
      return response;
    }

    if (response?.status === 'fail') {
      return { status: response?.status };
    }

    if (error) toast.error('Something went wrong editing folder. Please try again later');
  };

  return {
    editFolder,
    editFolderLoading,
  };
};

export default useEditFolder;
