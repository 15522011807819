import React from 'react';
import { Content } from '../components';
import { useAddUser, useDeleteUser, useGetAllUsers, useResetPassword, useToggleUser } from '../hooks/users';
import { CustomTableRow, Table, TableBody, TableButtons, TableHead, TableImageRow, TableNoContent, TableRowTitle, TableStatusWithToggle } from '../components/Table';
import { adminUsersTableHeadData, usersTableHeadData } from '../hooks/users/staticData';
import TableSkeleton from '../Skeletons/TableSkeleton';
import { IAddUserReq, IUser } from '../hooks/users/index.type';
import Pagination from '../components/Pagination';
import { useSelector } from 'react-redux';
import { loginSliceValue } from '../redux/reducers/loginSlice';
import { TableHeadDataProps } from '../components/Table/TableHead';
import { AddEditModalState, IaddEditModalStates } from '../pageComponents/Users/AddEditModal';
import { AddEditModal, InsurancePolicies, QuestionnaireModel, ResetPasswordModal, ToggleModal } from '../pageComponents/Users';
import { useAllClinics } from '../hooks/clinic';
import { unMasking, validateEmail, validatePassword, validatePhone } from '../utils/validators';
import DeleteModal, { DeleteModalState, IdeleteModalStates } from '../pageComponents/Users/DeleteModal';
import { IresetPasswordModalStates } from '../pageComponents/Users/ResetPasswordModal';
import { TableButtonProps } from '../components/Table/TableButton';
import { useNavigate } from 'react-router-dom';
import { Socket } from 'socket.io-client';
import { onlineUsersSliceValue } from '../redux/reducers/onlineUsers';
import toast from 'react-hot-toast';

interface UsersCallScreenProps {
  socket: Socket | null;
}

const Users: React.FC<UsersCallScreenProps> = ({ socket }) => {
  const navigate = useNavigate();
  const [tableData, setTableData] = React.useState<TableHeadDataProps[]>(usersTableHeadData);
  const [pageNumber, setPageNumber] = React.useState<number>(1);
  const perpageData = 10;

  const [clinics, setClinics] = React.useState<{ value: string; label: string }[]>([]);
  const [selectedClinic, setSelectedClinic] = React.useState<string>('');

  // ====================================== Add edit states start ======================================
  const [addEditModal, setAddEditModal] = React.useState<AddEditModalState>({
    status: false,
  });
  const startaddEditModalStatesValue = {
    name: { value: '', error: '' },
    email: { value: '', error: '' },
    phone: { value: '', error: '' },
    address: { value: '', error: '' },
    password: { value: '', error: '' },
    role: { value: '', error: '' },
    clinic: { value: '', error: '' },
    userFolders: { value: [], error: '' },
  };
  const [addEditModalStates, setAddEditModalStates] = React.useState<IaddEditModalStates>(startaddEditModalStatesValue);
  // ====================================== Add edit states end ======================================

  // ====================================== Toggle states start ======================================
  const [toggleModal, setToggleModal] = React.useState<DeleteModalState>({
    status: false,
  });
  // ====================================== Toggle states end ======================================

  // ====================================== Reset Password States start ======================================
  const [resetPasswordModal, setResetPasswordModal] = React.useState<DeleteModalState>({
    status: false,
  });

  const startResetPasswordModalStatesValue = {
    password: { value: '', error: '' },
    passwordConfirm: { value: '', error: '' },
  };
  const [resetPasswordModalStates, setResetPasswordModalStates] = React.useState<IresetPasswordModalStates>(startResetPasswordModalStatesValue);
  // ====================================== Reset Password States end ======================================

  // ====================================== Delete states start ======================================
  const [deleteModal, setDeleteModal] = React.useState<DeleteModalState>({
    status: false,
  });

  const startDeleteModalStatesValue = {
    email: { value: '', error: '' },
  };
  const [deleteModalStates, setDeleteModalStates] = React.useState<IdeleteModalStates>(startDeleteModalStatesValue);
  // ====================================== Delete states end ======================================

  // ====================================== Questionnaire states start ======================================
  const [questionnaireModal, setQuestionnaireModal] = React.useState<DeleteModalState>({
    status: false,
  });
  // ====================================== Questionnaire states end ======================================

  // ====================================== Questionnaire states start ======================================
  const [insurancePoliciesModal, setInsurancePoliciesModal] = React.useState<DeleteModalState>({
    status: false,
  });
  // ====================================== Questionnaire states end ======================================

  // ====================================== Hooks ======================================
  const { userDetails } = useSelector(loginSliceValue);
  const onlineUsers = useSelector(onlineUsersSliceValue);
  const { clinicsData, getClinicsData, clinicsDataLoading } = useAllClinics({ pageNumber: 1, perpageData: 1000 });
  const { usersData, getUsersData, usersDataLoading } = useGetAllUsers({ pageNumber, perpageData, selectedClinic });
  const { addUserLoading, addUser } = useAddUser({ userId: addEditModal.data?._id });
  const { toggleUserLoading, toggleUser } = useToggleUser({ userId: toggleModal.data?._id });
  const { deleteUserLoading, deleteUser } = useDeleteUser({ clinicId: deleteModal.data?._id });
  const { resetPasswordLoading, resetPassword } = useResetPassword({ userId: resetPasswordModal.data?._id });

  // ====================================== Basic functions start ======================================
  const onPageChange = (currentPage: { selected: number }) => {
    setPageNumber(currentPage.selected + 1);
  };

  const returnShortAddress = (address: string) => {
    return address ? (address.length <= 40 ? address : address.slice(0, 40) + '...') : '';
  };
  const returnRoleText = (role: string) => {
    return role ? (role.startsWith('file') ? 'File ' + role.split('file')[1] : role === 'adAdmin' ? 'Ad & File Admin' : role === 'adUser' ? 'Ad User' : role) : '-';
  };

  React.useEffect(() => {
    if (userDetails.data?.user.role === 'admin') {
      getClinicsData();
      setTableData(adminUsersTableHeadData);
    } else {
      setTableData(usersTableHeadData);
    }
    // eslint-disable-next-line
  }, [userDetails]);

  React.useEffect(() => {
    const temp: { value: string; label: string }[] = [{ label: 'All Clinics', value: '' }];
    if (clinicsData?.data.data.length) {
      clinicsData.data.data.forEach(clinic => {
        temp.push({ label: clinic.name, value: clinic._id });
      });
    }
    setClinics(temp);
  }, [clinicsData]);

  const getTableButtons = (user: IUser) => {
    const buttons: TableButtonProps[] = [];
    buttons.push({ icon: 'video-on', tooltip: `Video Call`, onClick: () => requestVideoCallToSelectedUser(user) });
    if (user.role === 'admin' || userDetails.data?.user._id === user._id) {
      buttons.splice(0, buttons.length);
    } else if (user.role === 'patient') {
      if (userDetails.data?.user.role === 'clinic' || userDetails.data?.user.role === 'receptionist' || userDetails.data?.user.role === 'manager') {
        if (user.clinic?.modules.questionnaire) {
          buttons.push({ icon: 'question', tooltip: 'View Questionnaire Response', onClick: () => setQuestionnaireModal({ status: true, data: user }) });
        }
        buttons.push({ icon: 'document', tooltip: 'View User Submitted Form', onClick: () => setInsurancePoliciesModal({ status: true, data: user }) });
        buttons.push({ icon: 'edit-3', tooltip: 'Edit User', onClick: () => setAddEditModal({ status: true, data: user }) });
        buttons.push({ icon: 'trash', tooltip: 'Delete User', variant: 'danger', onClick: () => setDeleteModal({ status: true, data: user }) });
      } else {
        if (user.clinic?.modules.questionnaire) {
          buttons.push({ icon: 'question', tooltip: 'View Questionnaire Response', onClick: () => setQuestionnaireModal({ status: true, data: user }) });
        }
        buttons.push({ icon: 'edit-3', tooltip: 'Edit User', onClick: () => setAddEditModal({ status: true, data: user }) });
        buttons.push({ icon: 'trash', tooltip: 'Delete User', variant: 'danger', onClick: () => setDeleteModal({ status: true, data: user }) });
      }
    } else {
      buttons.push({ icon: 'lock', tooltip: 'Reset Password', onClick: () => setResetPasswordModal({ status: true, data: user }) });
      buttons.push({ icon: 'edit-3', tooltip: 'Edit User', onClick: () => setAddEditModal({ status: true, data: user }) });
      buttons.push({ icon: 'trash', tooltip: 'Delete User', variant: 'danger', onClick: () => setDeleteModal({ status: true, data: user }) });
    }

    return buttons;
  };
  // ====================================== Basic functions end ======================================

  // ====================================== Add edit functions start ======================================
  React.useEffect(() => {
    const folders: string[] = [];
    addEditModal.data && addEditModal.data.uploadFolder.length > 0 && addEditModal.data.uploadFolder.forEach(folder => folders.push(folder._id));

    if (addEditModal.data?._id) {
      setAddEditModalStates({
        name: { value: addEditModal.data.name, error: '' },
        email: { value: addEditModal.data.email, error: '' },
        phone: { value: addEditModal.data.phone, error: '' },
        address: { value: addEditModal.data.address, error: '' },
        password: { value: '', error: '' },
        role: { value: addEditModal.data.role, error: '' },
        clinic: { value: addEditModal.data.clinic ? addEditModal.data.clinic._id : '', error: '' },
        userFolders: { value: folders, error: '' },
      });
    }
  }, [addEditModal]);

  const handleCloseModal = () => {
    setAddEditModal({ status: false });
    setAddEditModalStates(startaddEditModalStatesValue);
  };

  const handleAddUser = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (userDetails.data) {
      if (!addEditModalStates.name.value.trim()) {
        setAddEditModalStates(prevVal => ({ ...prevVal, name: { ...prevVal.name, error: 'Please enter user name' } }));
      } else if (!addEditModalStates.email.value.trim() || !validateEmail(addEditModalStates.email.value)) {
        setAddEditModalStates(prevVal => ({ ...prevVal, email: { ...prevVal.email, error: 'Please enter valid user email' } }));
      } else if (!addEditModalStates.phone.value.trim() || !validatePhone(unMasking(addEditModalStates.phone.value))) {
        setAddEditModalStates(prevVal => ({ ...prevVal, phone: { ...prevVal.phone, error: 'Please enter valid user phone' } }));
      } else if (!addEditModalStates.role.value.trim()) {
        setAddEditModalStates(prevVal => ({ ...prevVal, role: { ...prevVal.role, error: 'Please select user role' } }));
      } else if (!addEditModalStates.address.value.trim()) {
        setAddEditModalStates(prevVal => ({ ...prevVal, address: { ...prevVal.address, error: 'Please enter user address' } }));
      } else if (userDetails.data.user.role === 'admin' && !addEditModalStates.clinic.value.trim()) {
        setAddEditModalStates(prevVal => ({ ...prevVal, clinic: { ...prevVal.clinic, error: 'Please select user clinic' } }));
      } else if (addEditModalStates.clinic.value === 'fileUser' && addEditModalStates.userFolders.value.length === 0) {
        setAddEditModalStates(prevVal => ({ ...prevVal, clinic: { ...prevVal.clinic, error: 'Please select folder' } }));
      } else {
        const formData: IAddUserReq = {
          name: addEditModalStates.name.value,
          email: addEditModalStates.email.value,
          phone: unMasking(addEditModalStates.phone.value),
          address: addEditModalStates.address.value,
          role: addEditModalStates.role.value,
          clinic:
            userDetails.data.user.role === 'admin'
              ? addEditModalStates.clinic.value
              : userDetails.data.user.role === 'clinic'
              ? userDetails.data.user._id
              : userDetails.data.user.clinic._id,
          uploadFolder: addEditModalStates.userFolders.value,
        };

        if (!addEditModal.data?._id) {
          if (addEditModalStates.password.value === '' || addEditModalStates.password.value.trim() === '') {
            setAddEditModalStates(prevVal => ({ ...prevVal, password: { ...prevVal.password, error: 'Please set clinic password' } }));
          } else {
            formData.password = addEditModalStates.password.value;
            formData.passwordConfirm = addEditModalStates.password.value;
          }
        }

        const data = await addUser(formData);
        if (data?.status === 'success') {
          handleCloseModal();
          getUsersData();
        }
      }
    }
  };
  // ====================================== Add edit functions end ======================================

  // ====================================== Toggle functions start ======================================
  const handleToggleUser = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (toggleModal.data) {
      const data = await toggleUser({ active: !toggleModal.data.active });
      if (data?.status === 'success') {
        setToggleModal({ status: false });
        getUsersData();
      }
    }
  };
  // ====================================== Toggle functions end ======================================

  // ====================================== Reset Password functions start ======================================
  const handleCloseResetPasswordModal = () => {
    setResetPasswordModal({ status: false });
    setResetPasswordModalStates(startResetPasswordModalStatesValue);
  };

  const handleResetPassword = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (resetPasswordModal.data) {
      if (!resetPasswordModalStates.password.value.trim() || !validatePassword(resetPasswordModalStates.password.value)) {
        setResetPasswordModalStates(prevVal => ({ ...prevVal, password: { ...prevVal.password, error: 'Please enter strong password' } }));
      } else if (resetPasswordModalStates.passwordConfirm.value !== resetPasswordModalStates.password.value) {
        setResetPasswordModalStates(prevVal => ({ ...prevVal, passwordConfirm: { ...prevVal.passwordConfirm, error: 'Passwords do not match' } }));
      } else {
        const data = await resetPassword({ password: resetPasswordModalStates.password.value });
        if (data?.status === 'success') {
          handleCloseResetPasswordModal();
          getUsersData();
        }
      }
    } else {
      setDeleteModalStates(prevVal => ({ ...prevVal, email: { ...prevVal.email, error: 'Please select clinic to delete' } }));
    }
  };
  // ====================================== Reset Password functions end ======================================

  // ====================================== Delete functions start ======================================
  const handleCloseDeleteModal = () => {
    setDeleteModal({ status: false });
    setDeleteModalStates(startDeleteModalStatesValue);
  };

  const handleDeleteClinic = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (deleteModal.data) {
      if (!deleteModalStates.email.value.trim() || deleteModalStates.email.value !== deleteModal.data?.email) {
        setDeleteModalStates(prevVal => ({ ...prevVal, email: { ...prevVal.email, error: 'Please enter valid email' } }));
      } else {
        await deleteUser();
        handleCloseDeleteModal();
        getUsersData();
      }
    } else {
      setDeleteModalStates(prevVal => ({ ...prevVal, email: { ...prevVal.email, error: 'Please select clinic to delete' } }));
    }
  };
  // ====================================== Delete functions end ======================================

  const checkOnlineAndGetData = (currentUserId: string) => {
    return onlineUsers && onlineUsers.users && onlineUsers.users.length > 0 && onlineUsers.users.find(onlineUser => onlineUser['userId'] === currentUserId);
  };

  const requestVideoCallToSelectedUser = async (selectedUser: IUser) => {
    if (socket) {
      const id = checkOnlineAndGetData(selectedUser._id);
      if (id) {
        // const emitMessage = {
        // 	chatroomId: id.socketId,
        // 	user: selectedUser._id,
        // 	me: socket.id,
        // 	from: userLogin.userInfo.data.user._id,
        // 	name: userLogin.userInfo.data.user.name
        // };
        // socket.emit('share-video-socket-id', emitMessage);

        navigate(`/make/call/video/${selectedUser._id}/${selectedUser.name}/${id.socketId}`);

        // window.open(
        //   `/#/make/call/video/${selectedUser._id}/${selectedUser.name}/${id.socketId}`,
        //   '',
        //   'width=1200, height=850, top=100, left=100'
        // );
      } else {
        toast.error(`${selectedUser.name} is offline.`);
      }
      // socket.on('requested-video-socket-id', (data) => {
      // 	console.log(data);
      // 	history.push(`/make/call/video/${selectedUser._id}/${selectedUser.name}/${data.requestedId}`)
      // })
      // socket.on('share-my-video-socket-id', (data) => {
      // 	console.log('selected', data);
      // 	socket.emit('requested-video-socket-id', { requestedId: socket.id, to: data.me })
      // })
      // socket.on("callUser", ({ from, name: callerName, signal }) => {
      // 	console.log('Got a incoming call');
      // 	// setCall({ isReceivingCall: true, from, name: callerName, signal });
      // });
    }
  };

  return (
    <Content
      currentMenu='users'
      headerTitle='Users'
      addBtn={true}
      addBtnClick={() => setAddEditModal({ status: true })}
      addBtnTitle='Add User'
      searchBox={false}
      searchPlaceholder='Search Users'
      totalReult={usersData && usersData.totalResults > 0 ? usersData.totalResults + ' users' : ''}
      dropdown={userDetails.data?.user.role === 'admin' ? true : false}
      dropdownOptions={clinics}
      dropdownValue={selectedClinic}
      dropdownChange={e => setSelectedClinic(e.target.value)}
    >
      <Table>
        <TableHead data={tableData} />
        <TableBody>
          <CustomTableRow>
            {usersDataLoading || clinicsDataLoading ? (
              <TableSkeleton rows={9} columns={10} />
            ) : usersData && usersData.totalResults > 0 ? (
              usersData.data.data.map((user: IUser) => (
                <tr key={user._id}>
                  <TableImageRow alt={user.name} />
                  <TableRowTitle>{user.name}</TableRowTitle>
                  <td>{user.email}</td>
                  <td>{user.phone}</td>
                  <td>{returnShortAddress(user.address)}</td>
                  <td style={{ textTransform: 'capitalize' }}>{returnRoleText(user.role)}</td>
                  {userDetails.data?.user.role === 'admin' && <td>{user.clinic ? user.clinic.name : '-'}</td>}
                  <td>
                    <TableStatusWithToggle
                      id={user._id}
                      checked={user.active}
                      onChange={() => setToggleModal({ status: true, data: user })}
                      hideToggle={user.role === 'admin' || userDetails.data?.user._id === user._id}
                    />
                  </td>
                  <TableButtons
                    // buttons={
                    //   user.role === 'admin' || userDetails.data?.user._id === user._id
                    //     ? []
                    //     : user.role === 'patient'
                    //     ? userDetails.data?.user.role === 'clinic' || userDetails.data?.user.role === 'receptionist' || userDetails.data?.user.role === 'manager'
                    //       ? [
                    //           { icon: 'question', onClick: () => setQuestionnaireModal({ status: true, data: user }) },
                    //           { icon: 'document', onClick: () => setInsurancePoliciesModal({ status: true, data: user }) },
                    //           { icon: 'edit-3', onClick: () => setAddEditModal({ status: true, data: user }) },
                    //           { icon: 'trash', variant: 'danger', onClick: () => setDeleteModal({ status: true, data: user }) },
                    //         ]
                    //       : [
                    //           { icon: 'question', onClick: () => setQuestionnaireModal({ status: true, data: user }) },
                    //           { icon: 'edit-3', onClick: () => setAddEditModal({ status: true, data: user }) },
                    //           { icon: 'trash', variant: 'danger', onClick: () => setDeleteModal({ status: true, data: user }) },
                    //         ]
                    //     : [
                    //         { icon: 'lock', onClick: () => setResetPasswordModal({ status: true, data: user }) },
                    //         { icon: 'edit-3', onClick: () => setAddEditModal({ status: true, data: user }) },
                    //         { icon: 'trash', variant: 'danger', onClick: () => setDeleteModal({ status: true, data: user }) },
                    //       ]
                    // }
                    buttons={getTableButtons(user)}
                  />
                </tr>
              ))
            ) : (
              <TableNoContent colSpan={tableData.length} message='No data found' />
            )}
          </CustomTableRow>
        </TableBody>
      </Table>

      <Pagination itemsPerPage={perpageData} totalItems={usersData ? usersData.totalResults : 0} onPageChange={onPageChange} />

      {addEditModal.status && (
        <AddEditModal
          loading={addUserLoading}
          clinicsData={clinicsData}
          addEditModal={addEditModal}
          onCloseModal={handleCloseModal}
          addEditModalStates={addEditModalStates}
          setAddEditModalStates={setAddEditModalStates}
          handleAddEdit={handleAddUser}
        />
      )}

      {toggleModal.status && (
        <ToggleModal loading={toggleUserLoading} toggleModal={toggleModal} onCloseModal={() => setToggleModal({ status: false })} handleToggle={handleToggleUser} />
      )}

      {resetPasswordModal.status && (
        <ResetPasswordModal
          loading={resetPasswordLoading}
          resetPasswordModal={resetPasswordModal}
          resetPasswordModalStates={resetPasswordModalStates}
          setResetPasswordModalStates={setResetPasswordModalStates}
          onCloseModal={handleCloseResetPasswordModal}
          handleResetPassword={handleResetPassword}
        />
      )}

      {deleteModal.status && (
        <DeleteModal
          loading={deleteUserLoading}
          deleteModal={deleteModal}
          deleteModalStates={deleteModalStates}
          setDeleteModalStates={setDeleteModalStates}
          onCloseModal={handleCloseDeleteModal}
          handleDelete={handleDeleteClinic}
        />
      )}

      {questionnaireModal.status && <QuestionnaireModel questionnaireModal={questionnaireModal} onCancel={() => setQuestionnaireModal({ status: false })} />}

      {insurancePoliciesModal.status && (
        <InsurancePolicies insurancePoliciesModal={insurancePoliciesModal} closeInsurancePoliciesModal={() => setInsurancePoliciesModal({ status: false })} />
      )}
    </Content>
  );
};

export default Users;
