import type { FC, PropsWithChildren } from 'react';
import classes from '../../styles/Table.module.css';

type TableImageRowProps = PropsWithChildren<{
  src?: string;
  alt: string;
  imageStyle?: React.CSSProperties;
}>;

const TableImageRow: FC<TableImageRowProps> = props => {
  return (
    <td className={classes.table__profile_container}>
      <img src={props.src ? props.src : '/assets/profile.png'} alt={props.alt} className={classes.table__profile_image} style={props.imageStyle} />
    </td>
  );
};

export default TableImageRow;
