import React from 'react';
import toast from 'react-hot-toast';
import useApi from '../useApi';
import { IActiveClinicRes } from './index.type';

const useActiveClinics = () => {
  const [activeClinicsData, setActiveClinicsData] = React.useState<IActiveClinicRes>();

  const { loading: activeClinicsDataLoading, makeApiCall } = useApi({
    url: `/advertise/clinic-list`,
    method: 'GET',
  });

  const getAllActiveClinics = async () => {
    const { response, error } = await makeApiCall<undefined, IActiveClinicRes>();
    if (response?.status) {
      setActiveClinicsData(response);
    }

    if (!response?.status) {
      setActiveClinicsData(undefined);
    }

    if (error) toast.error('Something went wrong while fetching ads data. Please try again later');
  };

  React.useEffect(() => {
    getAllActiveClinics();
    // eslint-disable-next-line
  }, []);

  return {
    activeClinicsData,
    activeClinicsDataLoading,
  };
};

export default useActiveClinics;
