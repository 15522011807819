import React from 'react';
import { Chart, Doughnut } from 'react-chartjs-2';
import './circleChart.scss';
import classes from './HomeScreen.module.css';

const options = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      beginAtZero: true,
      display: true,
    },
    y: {
      display: false,
    },
  },
  plugins: {
    legend: {
      display: false,
      position: 'top',
      // labels: false,
      // labels: {
      //   font: {
      //     size: 10,
      //     family: 'inherit',
      //   },
      // }
    },
    title: {
      display: false,
      text: 'Chart.js Line Chart',
    },
  },
};

const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept'];

const data = {
  labels,
  datasets: [
    {
      // fill: true,
      label: 'Total Revenue (in million)',
      data: [4, 6.1, 13.9, 6.7, 15.4, 11.6, 7.4, 9.7, 10],
      // borderColor: 'rgba(53, 162, 235,0)',
      borderWidth: 0,
      borderRadius: 8,
      tension: 0.5,
      // pointBackgroundColor: 'rgba(255, 123, 1, 0)',
    },
    {
      type: 'line',
      fill: true,
      label: 'Total Expenses(in million)',
      data: [7, 6.8, 9.5, 8.1, 12, 10.6, 9.5, 8.4, 6],
      // borderColor: 'rgba(53, 162, 235)',
      borderWidth: 0,
      tension: 0.5,
      pointBackgroundColor: 'rgba(255, 123, 1, 1)',
    },
    {
      type: 'line',
      fill: true,
      label: 'Operational Expenses(in million)',
      data: [2.5, 2.8, 7.4, 4.1, 10, 8.6, 4.5, 6.4, 5],
      // borderColor: 'rgba(53, 162, 235)',
      borderWidth: 0,
      tension: 0.5,
      pointBackgroundColor: 'rgba(255, 123, 1, 1)',
    },
  ],
};

const options2 = {
  responsive: true,
  cutout: 30,
  plugins: {
    legend: {
      display: false,
      position: 'top',
    },
    title: {
      display: false,
      text: 'Chart.js Line Chart',
    },
    tooltip: {
      enabled: false,
    },
  },
};

const data2 = {
  labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
  datasets: [
    {
      label: '# of Votes',
      data: [(40.7 * 100) / 84.8, (40.7 * 100) / 84.8 - 100],
      backgroundColor: ['#ffffff', '#0070ae'],
      borderColor: '#0070ae',
      borderWidth: 0,
      borderRadius: 5,
    },
  ],
};
const data3 = {
  labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
  datasets: [
    {
      label: '# of Votes',
      data: [71, 29],
      backgroundColor: ['#ffffff', '#0070ae'],
      borderColor: '#0070ae',
      borderWidth: 0,
      borderRadius: 5,
    },
  ],
};
const data4 = {
  labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
  datasets: [
    {
      label: '# of Votes',
      data: [100, 0],
      backgroundColor: ['#ffffff', '#0070ae'],
      borderColor: '#0070ae',
      borderWidth: 0,
      borderRadius: 5,
    },
  ],
};

function createGradient(ctx, area) {
  const colorStart = 'rgba(1, 196, 255, 0.5)';
  const colorMid = 'rgba(0, 113, 174, 0.5)';
  const colorEnd = 'rgba(0, 113, 174, .5';

  // const gradient = ctx.createLinearGradient(0, area.bottom, 0, area.top);
  const gradient = ctx.createLinearGradient(area.left, 0, area.right, 0);

  gradient.addColorStop(0, colorStart);
  gradient.addColorStop(0.5, colorMid);
  gradient.addColorStop(1, colorEnd);

  return gradient;
}

function createGradient2(ctx, area) {
  const colorStart = 'rgba(255, 145, 1, 0.5)';
  const colorMid = 'rgba(174, 64, 0, 0.5)';
  const colorEnd = 'rgba(174, 64, 0, .5';

  // const gradient = ctx.createLinearGradient(0, area.bottom, 0, area.top);
  const gradient = ctx.createLinearGradient(area.left, 0, area.right, 0);

  gradient.addColorStop(0, colorStart);
  gradient.addColorStop(0.5, colorMid);
  gradient.addColorStop(1, colorEnd);

  return gradient;
}

function createGradient3(ctx, area) {
  const colorStart = 'rgba(255, 60, 1, 0.5)';
  const colorMid = 'rgba(174, 26, 0, 0.5)';
  const colorEnd = 'rgba(174, 26, 0, .5';

  // const gradient = ctx.createLinearGradient(0, area.bottom, 0, area.top);
  const gradient = ctx.createLinearGradient(area.left, 0, area.right, 0);

  gradient.addColorStop(0, colorStart);
  gradient.addColorStop(0.5, colorMid);
  gradient.addColorStop(1, colorEnd);

  return gradient;
}

const Chart6 = () => {
  const chartRef = React.useRef(null);
  const [chartData, setChartData] = React.useState({
    datasets: [],
  });

  React.useEffect(() => {
    const chart = chartRef.current;

    if (!chart) {
      return;
    }

    const chartData = {
      ...data,
      datasets: data.datasets.map((dataset, index) => ({
        ...dataset,
        backgroundColor:
          index === 0
            ? createGradient(chart.ctx, chart.chartArea)
            : index === 1
            ? createGradient2(chart.ctx, chart.chartArea)
            : createGradient3(chart.ctx, chart.chartArea),
      })),
    };

    setChartData(chartData);
  }, []);

  return (
    <div className={classes.chart6}>
      <div className={classes.chart6_left}>
        <div className={classes.chart6_left_top}>
          <div className={classes.chart6_left_top_data}>
            <p className={classes.chart6_left_top_data_number}>10M</p>
            <div className={classes.chart6_left_top_data_bar}>
              <div style={{ width: '60%' }}></div>
            </div>
            <p className={classes.chart6_left_top_data_text}>
              Total Revenue in Sept 2022
            </p>
          </div>

          <div className={classes.chart6_left_top_data}>
            <p className={classes.chart6_left_top_data_number}>6M</p>
            <div
              className={
                classes.chart6_left_top_data_bar +
                ' ' +
                classes.chart6_left_top_data_bar_red
              }
            >
              <div style={{ width: '60%' }}></div>
            </div>
            <p className={classes.chart6_left_top_data_text}>
              Total Expenses in Sept 2022
            </p>
          </div>

          <div className={classes.chart6_left_top_data}>
            <p className={classes.chart6_left_top_data_number}>5M</p>
            <div
              className={
                classes.chart6_left_top_data_bar +
                ' ' +
                classes.chart6_left_top_data_bar_red2
              }
            >
              <div style={{ width: '50%' }}></div>
            </div>
            <p className={classes.chart6_left_top_data_text}>
              Operational Expenses in Sept 2022
            </p>
          </div>
        </div>

        <div className={classes.chart6_area_chart}>
          <Chart ref={chartRef} type='bar' data={chartData} options={options} />
        </div>
      </div>

      <div className={classes.chart6_right}>
        <p className={classes.chart6_right_title}>2022 Financial Summary</p>
        <div className={classes.chart6_right_charts}>
          <div className={classes.chart6_right_chart}>
            <div className={classes.chart6_right_chart_circle}>
              <Doughnut data={data2} options={options2} />
              <p>
                34.5 <span>M</span>
              </p>
              {/* <figure className='chart_two animate'>
                <svg role='img' xmlns='http://www.w3.org/2000/svg'>
                  <title>[title here]</title>
                  <desc>[long description here]</desc>
                  <circle className={'circle_background'} />
                  <circle className={'circle_foreground'} />
                </svg>
                <figcaption>50% of all males like donuts.</figcaption>
              </figure> */}
            </div>

            <div className={classes.chart6_right_bar}>
              <div className={classes.chart6_right_bar_chart}>
                <div style={{ height: `${(807 * 100 * 3.5) / 6664}%` }}></div>
                <div style={{ height: `${(876 * 100 * 3.5) / 6664}%` }}></div>
                <div style={{ height: `${(1124 * 100 * 3.5) / 6664}%` }}></div>
                <div style={{ height: `${(695 * 100 * 3.5) / 6664}%` }}></div>
                <div style={{ height: `${(841 * 100 * 3.5) / 6664}%` }}></div>
                <div style={{ height: `${(407 * 100 * 3.5) / 6664}%` }}></div>
                <div style={{ height: `${(718 * 100 * 3.5) / 6664}%` }}></div>
                <div style={{ height: `${(842 * 100 * 3.5) / 6664}%` }}></div>
                <div style={{ height: `${(354 * 100 * 3.5) / 6664}%` }}></div>
                <div style={{ height: `1%` }}></div>
                <div style={{ height: `1%` }}></div>
                <div style={{ height: `1%` }}></div>
              </div>
              <p className={classes.chart6_right_bar_text}>
                Contribution in total revenue from in-patients in year 2022
                (price in million)
              </p>
            </div>
          </div>

          <div className={classes.chart6_right_chart}>
            <div className={classes.chart6_right_chart_circle}>
              <Doughnut data={data3} options={options2} />
              <p>
                50.3 <span>M</span>
              </p>
              {/* <figure className='chart_two animate'>
                <svg role='img' xmlns='http://www.w3.org/2000/svg'>
                  <title>[title here]</title>
                  <desc>[long description here]</desc>
                  <circle className={'circle_background'} />
                  <circle className={'circle_foreground'} />
                </svg>
                <figcaption>50% of all males like donuts.</figcaption>
              </figure> */}
            </div>

            <div className={classes.chart6_right_bar}>
              <div className={classes.chart6_right_bar_chart}>
                <div style={{ height: `${(1200 * 100 * 3.5) / 9709}%` }}></div>
                <div style={{ height: `${(1528 * 100 * 3.5) / 9709}%` }}></div>
                <div style={{ height: `${(1043 * 100 * 3.5) / 9709}%` }}></div>
                <div style={{ height: `${(1254 * 100 * 3.5) / 9709}%` }}></div>
                <div style={{ height: `${(468 * 100 * 3.5) / 9709}%` }}></div>
                <div style={{ height: `${(701 * 100 * 3.5) / 9709}%` }}></div>
                <div style={{ height: `${(1164 * 100 * 3.5) / 9709}%` }}></div>
                <div style={{ height: `${(1400 * 100 * 3.5) / 9709}%` }}></div>
                <div style={{ height: `${(951 * 100 * 3.5) / 9709}%` }}></div>
                <div style={{ height: `1%` }}></div>
                <div style={{ height: `1%` }}></div>
                <div style={{ height: `1%` }}></div>
              </div>
              <p className={classes.chart6_right_bar_text}>
                Contribution in total revenue from out-patients in year 2022
                (price in million)
              </p>
            </div>
          </div>

          <div className={classes.chart6_right_chart}>
            <div className={classes.chart6_right_chart_circle}>
              <Doughnut data={data4} options={options2} />
              <p>
                84.8 <span>M</span>
              </p>
              {/* <figure className='chart_two animate'>
                <svg role='img' xmlns='http://www.w3.org/2000/svg'>
                  <title>[title here]</title>
                  <desc>[long description here]</desc>
                  <circle className={'circle_background'} />
                  <circle className={'circle_foreground'} />
                </svg>
                <figcaption>50% of all males like donuts.</figcaption>
              </figure> */}
            </div>

            <div className={classes.chart6_right_bar}>
              <div className={classes.chart6_right_bar_chart}>
                <div
                  style={{ height: `${((1200 + 807) * 100 * 3.5) / 9709}%` }}
                ></div>
                <div
                  style={{ height: `${((1528 + 876) * 100 * 3.5) / 9709}%` }}
                ></div>
                <div
                  style={{ height: `${((1043 + 1124) * 100 * 3.5) / 9709}%` }}
                ></div>
                <div
                  style={{ height: `${((1254 + 695) * 100 * 3.5) / 9709}%` }}
                ></div>
                <div
                  style={{ height: `${((468 + 841) * 100 * 3.5) / 9709}%` }}
                ></div>
                <div
                  style={{ height: `${((701 + 407) * 100 * 3.5) / 9709}%` }}
                ></div>
                <div
                  style={{ height: `${((1164 + 718) * 100 * 3.5) / 9709}%` }}
                ></div>
                <div
                  style={{ height: `${((1400 + 842) * 100 * 3.5) / 9709}%` }}
                ></div>
                <div
                  style={{ height: `${((951 + 354) * 100 * 3.5) / 9709}%` }}
                ></div>
                <div style={{ height: `1%` }}></div>
                <div style={{ height: `1%` }}></div>
                <div style={{ height: `1%` }}></div>
              </div>
              <p className={classes.chart6_right_bar_text}>
                Contribution in total revenue from in-patients and out-patients
                in year 2022 (price in million)
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chart6;
