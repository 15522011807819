import React from 'react';
import toast from 'react-hot-toast';
import useApi from '../useApi';
import { IAllStatusRes } from './index.type';

const useAllStatus = () => {
  const [allStatusData, setAllStatusData] = React.useState<IAllStatusRes>();

  const { loading: allStatusDataLoading, makeApiCall } = useApi({
    url: '/status',
    method: 'GET',
  });

  const getAllStatus = async () => {
    const { response, error } = await makeApiCall<undefined, IAllStatusRes>();
    if (response?.status) {
      setAllStatusData(response);
    }

    if (!response?.status) {
      setAllStatusData(undefined);
    }

    if (error) toast.error('Something went wrong while fetching clinic status data. Please try again later');
  };

  return {
    allStatusData,
    getAllStatus,
    allStatusDataLoading,
  };
};

export default useAllStatus;
