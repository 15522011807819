import React, { Fragment } from 'react';
import type { FC, PropsWithChildren } from 'react';
import toast from 'react-hot-toast';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useShareFile } from '../../hooks/folders';

import { Modal, ModalForm, InputsSection, InputBox, ModalButtons } from '../../components/Modal';
import ModalHeading from '../../components/Modal/ModalHeading';
import ShareFileSkeleton from '../../Skeletons/ShareFileSkeleton';
import { DownloadModalState, IdownloadModalStates } from './DownloadFileModal';

type ShareFileModalProps = PropsWithChildren<{
  shareFileModal: DownloadModalState;
  shareFilePasswordInput: IdownloadModalStates;
  setShareFilePasswordInput: React.Dispatch<React.SetStateAction<IdownloadModalStates>>;
  onCloseModal: () => void;
}>;

const ShareFileModal: FC<ShareFileModalProps> = props => {
  const { shareFilesData, shareFile, shareFileLoading } = useShareFile();

  const handleShareFile = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (props.shareFileModal.data)
      if (props.shareFileModal.data.protected) {
        if (!props.shareFilePasswordInput.password.value.trim()) {
          props.setShareFilePasswordInput(prevVal => ({ ...prevVal, password: { ...prevVal.password, error: 'Please enter file password to share' } }));
        } else {
          shareFile({
            id: props.shareFileModal.data._id,
            password: props.shareFilePasswordInput.password.value,
          });
        }
      } else {
        shareFile({
          id: props.shareFileModal.data._id,
        });
      }
  };
  return (
    <Modal show={props.shareFileModal.status}>
      {shareFileLoading ? (
        <ShareFileSkeleton fileProtected={props.shareFileModal.data?.protected} />
      ) : shareFilesData?.link ? (
        props.shareFileModal.data && (
          <Fragment>
            <div className='modal__heading--container'>
              <a
                href={`/#/share-file?file=${props.shareFileModal.data._id}&link='${encodeURIComponent(shareFilesData.link)}'`}
                target='_blank'
                rel='noreferrer'
                className='modal_share_heading'
              >
                Share File ({props.shareFileModal.data.name})
              </a>
            </div>
            <div className='share-link-container'>
              <p>{`${window.location.hostname}:${window.location.port}/#/share-file?file=${props.shareFileModal.data._id}&link='${encodeURIComponent(
                shareFilesData?.link
              )}'`}</p>
              <CopyToClipboard
                text={`${window.location.hostname}:${window.location.port}/#/share-file?file='${props.shareFileModal.data._id}'&link='${encodeURIComponent(
                  shareFilesData.link
                )}'`}
                onCopy={() => {
                  toast.success('Link Copied..!');
                  props.onCloseModal();
                }}
              >
                <button className='share-button'>Copy</button>
              </CopyToClipboard>
            </div>
            <p className='modal__subtext'>This link is valid for 24 hours only.</p>
            <div className='modal__actions'>
              <button className='modal__button--cancel' onClick={props.onCloseModal}>
                Cancel
              </button>
            </div>
          </Fragment>
        )
      ) : (
        props.shareFileModal.data && (
          <Fragment>
            <ModalHeading heading={`Share ${props.shareFileModal.data.name}?`} />
            <ModalForm style={{ marginBottom: '2.5rem', flexWrap: 'wrap' }} onSubmit={handleShareFile}>
              <InputsSection style={{ width: '34rem' }}>
                {props.shareFileModal.data.protected ? (
                  <InputBox
                    label={`Enter password to share file`}
                    type='password'
                    placeholder='••••••••••••••••'
                    value={props.shareFilePasswordInput.password.value}
                    onChange={event => {
                      props.setShareFilePasswordInput(prevVal => ({ ...prevVal, password: { value: event.target.value, error: '' } }));
                    }}
                    errorMessage={props.shareFilePasswordInput.password.error}
                    style={{ width: '30rem' }}
                  />
                ) : (
                  <p className='modal__subtext' style={{ margin: '0', marginBottom: '0' }}>
                    Please click on share button and copy the link
                    <br /> to share this file.
                  </p>
                )}
              </InputsSection>
              <ModalButtons submitButtonLabel='Share File' cancelButtonLabel='Cancel' onCancel={props.onCloseModal} />
            </ModalForm>
          </Fragment>
        )
      )}
    </Modal>
  );
};

export default ShareFileModal;
