import React from 'react';
import type { FC, PropsWithChildren } from 'react';
import { InputsSection, InputBox, ModalButtons, ModalForm, Modal, TextArea } from '../../components/Modal';
import ModalHeading from '../../components/Modal/ModalHeading';
import EditProfileSkeleton from '../../Skeletons/EditProfileSkeleton';

import { inputPhoneMasking } from '../../utils/validators';
import { IUser } from '../../hooks/users/index.type';

export type EditProfileModalState = PropsWithChildren<{
  status: boolean;
  data?: IUser;
}>;
export type IeditProfileModalStates = PropsWithChildren<{
  name: { value: string; error: string };
  phone: { value: string; error: string };
  address: { value: string; error: string };
}>;

type EditProfileModalProps = PropsWithChildren<{
  loading: boolean;
  editProfileModal: EditProfileModalState;
  onCloseModal?: () => void;
  handleEditProfile?: (event: React.FormEvent<HTMLFormElement>) => void;
  editProfileModalStates: IeditProfileModalStates;
  setEditProfileModalStates: React.Dispatch<React.SetStateAction<IeditProfileModalStates>>;
}>;

const EditMyProfileModal: FC<EditProfileModalProps> = props => {
  return (
    <Modal show={props.editProfileModal.status}>
      <ModalHeading heading='Update your profile' />
      {props.loading ? (
        <EditProfileSkeleton />
      ) : (
        <ModalForm style={{ marginBottom: '2.5rem' }} onSubmit={props.handleEditProfile}>
          <InputsSection>
            <InputBox
              label='Full Name'
              placeholder='eg, John Doe'
              value={props.editProfileModalStates.name.value}
              onChange={event => {
                props.setEditProfileModalStates(prevVal => ({ ...prevVal, name: { value: event.target.value, error: '' } }));
              }}
              errorMessage={props.editProfileModalStates.name.error}
            />

            <InputBox
              label='Phone'
              placeholder='eg, 123-456-789'
              value={props.editProfileModalStates.phone.value}
              onChange={event => {
                props.setEditProfileModalStates(prevVal => ({ ...prevVal, phone: { value: inputPhoneMasking(event.target.value), error: '' } }));
              }}
              errorMessage={props.editProfileModalStates.phone.error}
            />
          </InputsSection>

          <InputsSection>
            <TextArea
              label='Address'
              placeholder='692 SW. 53rd St. Irwin, PA 15642'
              rows={6}
              value={props.editProfileModalStates.address.value}
              onChange={event => {
                props.setEditProfileModalStates(prevVal => ({ ...prevVal, address: { value: event.target.value, error: '' } }));
              }}
              errorMessage={props.editProfileModalStates.address.error}
              style={{ width: '52rem' }}
            />
          </InputsSection>

          <ModalButtons submitButtonLabel='Save' cancelButtonLabel='Cancel' onCancel={props.onCloseModal} />
        </ModalForm>
      )}
    </Modal>
  );
};

export default EditMyProfileModal;
