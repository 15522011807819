import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import './circleChartBig.scss';
import classes from './HomeScreen.module.css';

const Chart8 = () => {
  const options2 = {
    responsive: true,
    cutout: 55,
    plugins: {
      legend: {
        display: false,
        position: 'top',
      },
      title: {
        display: false,
        text: 'Chart.js Line Chart',
      },
      tooltip: {
        enabled: true,
      },
    },
  };

  const data2 = {
    labels: [
      'Cardio',
      'Surgery',
      'Neuro',
      'Paedics',
      'Gyno',
      'Derma',
      'Physio',
    ],
    datasets: [
      {
        label: 'Satisfaction rate',
        data: [75, 68, 80, 65, 55, 70, 65],
        backgroundColor: [
          'rgba(1, 196, 255, 1)',
          '#01fff2',
          '#00ff80',
          '#01fff2',
        ],
        borderColor: '#0070ae',
        borderWidth: 0,
      },
    ],
  };
  return (
    <div className={classes.chart9}>
      <div className={classes.chart8_chart}>
        {/* <p className={classes.grad_color}>Patient Satisfaction</p> */}
        <div className={classes.chart8_circle}>
          <div className={classes.chart8_circle_info}>
            <p className={classes.chart8_circle_info_num}>68.2%</p>
            <p className={classes.chart8_circle_info_text}>
              Service Satisfaction
            </p>
          </div>
          <div className={classes.chart8_circle_chart}>
            <Doughnut data={data2} options={options2} />
          </div>
          {/* <figure className='chart_eight animate'>
            <svg role='img' xmlns='http://www.w3.org/2000/svg'>
              <title>[title here]</title>
              <desc>[long description here]</desc>
              <circle className={'circle_background_eight'} />
              <circle className={'circle_foreground_eight'} />
            </svg>
            <figcaption>50% of all males like donuts.</figcaption>
          </figure> */}
        </div>
      </div>
    </div>
  );
};

export default Chart8;
