import React from 'react';
import type { FC, PropsWithChildren } from 'react';
import AddAdvertiseStyles from '../../../styles/AddAdvertiseScreen.module.css';
import { IImageNameList } from './Step2';
import { InputsSectionColumn } from '../../../components/Modal';

type Step2UploadImageItemProps = PropsWithChildren<{
  imageNameList: IImageNameList[];
  removeImage: (index: number) => void;
  setImageDimensionError: React.Dispatch<React.SetStateAction<boolean>>;
}>;

const Step2UploadImageItem: FC<Step2UploadImageItemProps> = props => {
  // const onImgLoad = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
  //   const { offsetHeight, offsetWidth } = event.currentTarget;
  //   const ratio = Number(offsetWidth / offsetHeight).toFixed(1);
  //   if (ratio === Number(8.088888889).toFixed(1) || ratio === Number(10.777777778).toFixed(1) || ratio === Number(3.818897638).toFixed(1)) {
  //     props.setImageDimensionError(false);
  //   } else {
  //     props.setImageDimensionError(true);
  //   }
  // };

  const getMeta = (url: string, cb: (err: any, img?: HTMLImageElement) => void) => {
    const img = new Image();
    img.onload = () => cb(null, img);
    img.onerror = err => cb(err);
    img.src = url;
  };

  React.useEffect(() => {
    props.imageNameList.forEach((image, ind) => {
      if (image.img)
        getMeta(image.img.toString(), (err, img) => {
          if (img) {
            const ratio = Number(img.naturalWidth / img.naturalHeight).toFixed(1);
            if (ratio === Number(8.088888889).toFixed(1) || ratio === Number(10.777777778).toFixed(1) || ratio === Number(3.818897638).toFixed(1)) {
              props.setImageDimensionError(false);
            } else {
              props.setImageDimensionError(true);
            }
            // console.log(img?.naturalWidth, img?.naturalHeight);
          }
        });
    });
  }, [props]);

  return (
    <InputsSectionColumn>
      <div className={AddAdvertiseStyles.advertise_right_container}>
        {props.imageNameList.map((imgData, ind) => (
          <div className={[AddAdvertiseStyles.advertise_right_container_table_body_tr, AddAdvertiseStyles.imageBox].join(' ')} key={ind + 1}>
            {/* onLoad={onImgLoad} */}
            <img src={imgData.img?.toString()} alt={ind + 'ad'} style={{ height: '4rem' }} />
            <svg className={AddAdvertiseStyles.advertise_img_delete_icon} onClick={e => props.removeImage(ind)}>
              <use xlinkHref={`/assets/sprite.svg#icon-trash`} />
            </svg>
          </div>
        ))}
      </div>
    </InputsSectionColumn>
  );
};

export default Step2UploadImageItem;
