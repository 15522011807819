import type { FC, PropsWithChildren } from 'react';
import fileUploadBox from './FileUpload.module.css';

type FileUploadProps = PropsWithChildren<{
  icon: string;
  image?: string;
  label: string;
  label2: string;
  multiple?: boolean;
  label2Span: string;
  errorMessage?: string;
  style?: React.CSSProperties;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}>;

const FileUpload: FC<FileUploadProps> = ({ onChange, errorMessage, label, label2, label2Span, icon, image, style, multiple }) => {
  return (
    <div>
      <div className='modalContainer__form--sections'>
        <div className={fileUploadBox.form_input} style={style}>
          <label htmlFor='uploadFiles' className='btn-file'>
            {image ? (
              <img alt={image} src={image} className='fileUpload__label--image' />
            ) : (
              <span>
                <svg className='fileUpload__label--icon'>
                  <use xlinkHref={`/assets/sprite.svg#icon-${icon}`} />
                </svg>{' '}
                <br />
                {label}
                {label2 && (
                  <p className={fileUploadBox.fileUpload__label_two}>
                    {label2}
                    {label2Span && label2Span.split(',').length > 0 && label2Span.split(',').map((text, index) => <span key={index + text}>{text}</span>)}
                  </p>
                )}
              </span>
            )}
            <input type='file' name='uploadFiles' id='uploadFiles' accept='image/*' onChange={onChange} multiple={multiple} />
          </label>
        </div>
      </div>
      {errorMessage && <p className={fileUploadBox.form_input__error}>{errorMessage}</p>}
    </div>
  );
};

export default FileUpload;
