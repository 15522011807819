import toast from 'react-hot-toast';
import useApi from '../useApi';
import { IChangePasswordReq, IChangePasswordRes } from './index.type';
import { useDispatch } from 'react-redux';
import { logoutUser } from '../../redux/reducers/loginSlice';

const useChangePassword = () => {
  const dispatch = useDispatch();
  const { loading: changePasswordLoading, makeApiCall } = useApi({ url: `/users/updatePassword`, method: 'POST' });

  const changePassword = async (formData: IChangePasswordReq) => {
    const { response, error } = await makeApiCall<IChangePasswordReq, IChangePasswordRes>(formData);
    if (response?.status === 'success') {
      toast.success('Password changed successfully');
      dispatch(logoutUser());
    }

    if (error?.msg) {
      toast.error(error?.msg);
    } else if (error) {
      toast.error('Something went wrong. Please try again later');
    }
  };

  return {
    changePasswordLoading,
    changePassword,
  };
};

export default useChangePassword;
