import React, { useEffect, useRef } from 'react';
import { Modal } from '../../components/Modal';
import ModalHeading from '../../components/Modal/ModalHeading';
import ModalForm from '../../components/Modal/ModalForm';
import ModalButtons from '../../components/Modal/ModalButtons';
import { useDispatch, useSelector } from 'react-redux';
import { answerCall, declineCall, videoCallSliceValue } from '../../redux/reducers/videoCall';

const IncomingCallModal = ({ callModal, onDecline }) => {
  // const { deleteEmail, setDeleteEmail, deleteUserData } = data;

  const callReducer = useSelector(videoCallSliceValue);
  const Audio = useRef();
  const dispatch = useDispatch();
  useEffect(() => {
    if (callModal) Audio?.current?.play();
  }, [callModal]);
  return (
    <Modal show={callModal}>
      <audio src='/assets/teams.mp3' loop ref={Audio} />
      <ModalHeading heading={`Incoming call from ${callReducer && callReducer.data ? callReducer.data.name : ''}`} />
      <ModalButtons
        submitButtonLabel='Answer'
        onSubmit={() => {
          Audio?.current?.pause();
          dispatch({ type: answerCall, payload: callReducer.data });
        }}
        cancelButtonLabel='Decline'
        onCancel={() => {
          Audio?.current?.pause();
          dispatch({ type: declineCall, payload: {} });
          onDecline();
        }}
      />
    </Modal>
  );
};

export default IncomingCallModal;
