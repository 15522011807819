import toast from 'react-hot-toast';
import useApi from '../useApi';

const useDeleteProvider = ({ providerId }: { providerId?: string }) => {
  const { loading: deleteProviderLoading, makeApiCall } = useApi({
    url: `/doctors/${providerId}`,
    method: 'DELETE',
  });

  const deleteProvider = async () => {
    const { error } = await makeApiCall<undefined, undefined>();

    if (error) {
      toast.error('Something went wrong whiling deleting provider. Please try again later');
      return { status: false };
    } else {
      toast.error('Provider deleted successfully.');
    }
  };

  return {
    deleteProviderLoading,
    deleteProvider,
  };
};

export default useDeleteProvider;
