import toast from 'react-hot-toast';
import useApi from '../useApi';
import { ISaveContactCareFormReq, ISaveDemographicFormReq, ISaveInsuranceRes } from './index.type';

const useSaveUserInsuranceForm = ({ userId, formType }: { userId?: string; formType: 'contactCare' | 'demographic' }) => {
  const { loading: saveUserInsuranceDataLoading, makeApiCall } = useApi({
    url: `/contact-care/add-insurance-form/${userId}/${formType}`,
    method: 'POST',
  });

  const saveUserInsuranceData = async (formData: ISaveContactCareFormReq | ISaveDemographicFormReq) => {
    const { response, error } = await makeApiCall<ISaveContactCareFormReq | ISaveDemographicFormReq, ISaveInsuranceRes>(formData);
    if (response?.status) {
      toast.success(response.message);
      return response;
    }

    if (!response?.status) {
      return response;
    }

    if (error) {
      toast.error('Something went wrong while saving user insurance data. Please try again later');
      return { status: 'fail' };
    }
  };

  // React.useEffect(() => {
  //   saveUserInsuranceData();
  //   // eslint-disable-next-line
  // }, [userId, documentType]);

  return {
    saveUserInsuranceData,
    saveUserInsuranceDataLoading,
  };
};

export default useSaveUserInsuranceForm;
