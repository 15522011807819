import React from 'react';
import ButtonSkeleton from './ButtonSkeleton';
import MyProfileStyles from '../styles/MyProfileScreen.module.css';
import InputBoxSkeleton from './InputBoxSkeleton';
import SkeletonStyles from './Skeletons.module.css';

const MyProfileSkeleton = () => {
  return (
    <div className={MyProfileStyles.content}>
      <div className={MyProfileStyles.content_left}>
        <div>
          <div className={SkeletonStyles.content_left_profile_image + ' ' + SkeletonStyles.skeleton} />
          <div className={SkeletonStyles.content_left_profile_name + ' ' + SkeletonStyles.skeleton} />
          <div className={SkeletonStyles.content_left_profile_role + ' ' + SkeletonStyles.skeleton} />
          <div className={SkeletonStyles.content_left_profile_role + ' ' + SkeletonStyles.skeleton} style={{ marginTop: '.5rem', width: '12rem' }} />

          <div className={MyProfileStyles.content_left_contacts}>
            <div className={MyProfileStyles.content_left_contact}>
              <div className={MyProfileStyles.content_left_contact_box + ' ' + MyProfileStyles.content_left_contact_box_red}>
                <svg className={MyProfileStyles.content_left_contact_box_icon}>
                  <use xlinkHref={`/assets/sprite.svg#icon-mobile`} />
                </svg>
              </div>
              <div className={MyProfileStyles.content_left_contact_data}>
                <p className={MyProfileStyles.content_left_contact_data_title}>Phone</p>
                <div className={SkeletonStyles.content_left_contact_data_field + ' ' + SkeletonStyles.skeleton}></div>
              </div>
            </div>

            <div className={MyProfileStyles.content_left_contact}>
              <div className={MyProfileStyles.content_left_contact_box + ' ' + MyProfileStyles.content_left_contact_box_blue}>
                <svg className={MyProfileStyles.content_left_contact_box_icon}>
                  <use xlinkHref={`/assets/sprite.svg#icon-email`} />
                </svg>
              </div>
              <div className={MyProfileStyles.content_left_contact_data}>
                <p className={MyProfileStyles.content_left_contact_data_title}>Email</p>
                <div className={SkeletonStyles.content_left_contact_data_field + ' ' + SkeletonStyles.skeleton}></div>
              </div>
            </div>

            <div className={MyProfileStyles.content_left_contact}>
              <div className={MyProfileStyles.content_left_contact_box + ' ' + MyProfileStyles.content_left_contact_box_primary}>
                <svg className={MyProfileStyles.content_left_contact_box_icon}>
                  <use xlinkHref={`/assets/sprite.svg#icon-map-pin`} />
                </svg>
              </div>
              <div className={MyProfileStyles.content_left_contact_data}>
                <p className={MyProfileStyles.content_left_contact_data_title}>Address</p>
                <div className={SkeletonStyles.content_left_contact_data_field + ' ' + SkeletonStyles.skeleton}></div>
              </div>
            </div>
          </div>
        </div>

        <div className={MyProfileStyles.content_left_bottom}>
          <ButtonSkeleton />
        </div>
      </div>

      <div className={MyProfileStyles.content_right}>
        <div className={MyProfileStyles.changePasswordSection}>
          <div className={MyProfileStyles.changePasswordSection_left}>
            <h2 className={MyProfileStyles.changePasswordSection_heading}>Change Password</h2>
            <InputBoxSkeleton style={{ width: '20.5rem' }} />
            <InputBoxSkeleton style={{ width: '20.5rem' }} />
            <ButtonSkeleton />
          </div>
          <div className={MyProfileStyles.changePasswordSection_right}>
            <p className={MyProfileStyles.changePasswordSection_text}>We recommend to not share your password with anyone.</p>
            <p className={MyProfileStyles.changePasswordSection_text}>Also change your password frequently which adds an extra layer of security in your account.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyProfileSkeleton;
