import type { FC, PropsWithChildren } from 'react';
import styles from './AppointmentTime.module.css';

type TimeButtonProps = PropsWithChildren<{
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset';
  style?: React.CSSProperties;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}>;

export const TimeButton: FC<TimeButtonProps> = ({ disabled, type = 'button', style, onClick, children }) => {
  return (
    <button disabled={disabled} type={type} className={styles.timeButton} style={style} onClick={onClick}>
      {children}
    </button>
  );
};

export const TimeButtonTwo: FC<TimeButtonProps> = ({ disabled, type = 'button', style, onClick, children }) => {
  return (
    <button disabled={disabled} type={type} className={styles.timeButton2} style={style} onClick={onClick}>
      {children}
    </button>
  );
};

type AppointmentTimeProps = PropsWithChildren<{}>;

const AppointmentTime: FC<AppointmentTimeProps> = ({ children }) => {
  return <div className={styles.timeDiv}>{children}</div>;
};

export default AppointmentTime;
