import React from 'react';
import type { FC, PropsWithChildren } from 'react';
import moment from 'moment';
import { CalendarBox } from '../../components';
import { InputsSection, ModalButtons, ModalForm, Modal, InputsSectionColumn } from '../../components/Modal';
import SearchSelect, { SearchSelectLocked } from '../../components/SearchSelect';
import ModalHeading from '../../components/Modal/ModalHeading';
import AddUserSkeleton from '../../Skeletons/AddUserSkeleton';

import { IAppointment } from '../../hooks/appointments/index.type';
import AppointmentTime, { TimeButton } from '../../components/AppointmentTime';
import { useAllPatients, useAllProviders, useProviderTiming } from '../../hooks/appointments';

export type AddEditModalState = PropsWithChildren<{
  status: boolean;
  data?: IAppointment;
}>;
export type IaddEditModalStates = PropsWithChildren<{
  user: { value: { label: string; value: string }; error: string };
  doctor: { value: { label: string; value: string }; error: string };
  appointmentDate: { value: string; error: string };
  appointmentTime: { value: string; error: string };
}>;

type AddEditModalProps = PropsWithChildren<{
  loading: boolean;
  addEditModal: AddEditModalState;
  onCloseModal?: () => void;
  handleAddEdit?: (event: React.FormEvent<HTMLFormElement>) => void;
  addEditModalStates: IaddEditModalStates;
  setAddEditModalStates: React.Dispatch<React.SetStateAction<IaddEditModalStates>>;
}>;

const AddEditModal: FC<AddEditModalProps> = props => {
  const timeCheck = (date1: string, userTime: string) => {
    let dd = moment.utc(date1.toLocaleString()).format('MM/DD/YYYY') + ' ';
    let stt = new Date(dd + userTime);
    let stt1 = stt.getTime();
    let a = moment(stt1);
    if (a.diff(new Date()) < 0) {
      return true;
    } else {
      return false;
    }
  };
  const { allPatientsData, allPatientsLoading } = useAllPatients();
  const { allProvidersData, allProvidersLoading } = useAllProviders();
  const { providerTimingData, getProviderTiming, providerTimingLoading } = useProviderTiming({
    doctorId: props.addEditModalStates.doctor.value.value,
    date: moment(props.addEditModalStates.appointmentDate.value).format('MM/DD/YYYY'),
  });

  React.useEffect(() => {
    if (props.addEditModalStates.doctor.value.value && props.addEditModalStates.appointmentDate.value) {
      getProviderTiming();
    }
    // eslint-disable-next-line
  }, [props.addEditModalStates.doctor.value, props.addEditModalStates.appointmentDate]);

  return (
    <Modal show={props.addEditModal.status} style={{ width: '58rem' }}>
      <ModalHeading heading={props.addEditModal.data && props.addEditModal.data._id ? 'Modify Appointment' : 'Create Appointment'} />
      {props.loading || allPatientsLoading || allProvidersLoading ? (
        <AddUserSkeleton />
      ) : (
        <ModalForm style={{ marginBottom: '2.5rem' }} onSubmit={props.handleAddEdit}>
          {props.addEditModal.data?._id ? (
            <InputsSection>
              <SearchSelectLocked value={props.addEditModalStates.user.value} errorMessage={props.addEditModalStates.user.error} />
              <SearchSelectLocked value={props.addEditModalStates.doctor.value} errorMessage={props.addEditModalStates.doctor.error} />
            </InputsSection>
          ) : (
            <InputsSection style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
              {allPatientsData && (
                <div>
                  <SearchSelect
                    placeholder='Select Patient'
                    value={props.addEditModalStates.user.value} // set selected value
                    options={allPatientsData} // set list of the data
                    onChange={user => user && props.setAddEditModalStates(prevVal => ({ ...prevVal, user: { value: user, error: '' } }))}
                    errorMessage={props.addEditModalStates.user.error}
                  />
                </div>
              )}
              {allProvidersData && (
                <div>
                  <SearchSelect
                    placeholder='Select Provider'
                    value={props.addEditModalStates.doctor.value} // set selected value
                    options={allProvidersData} // set list of the data
                    onChange={provider => provider && props.setAddEditModalStates(prevVal => ({ ...prevVal, doctor: { value: provider, error: '' } }))}
                    errorMessage={props.addEditModalStates.doctor.error}
                  />
                </div>
              )}
            </InputsSection>
          )}

          <InputsSection style={{ justifyContent: 'center' }}>
            <CalendarBox
              onChange={date =>
                date &&
                props.setAddEditModalStates(prevVal => ({
                  ...prevVal,
                  appointmentDate: { value: date.toString(), error: '' },
                  appointmentTime: { value: '', error: '' },
                }))
              }
              value={props.addEditModalStates.appointmentDate.value.toString()}
              minDate={new Date()}
              errorMessage={props.addEditModalStates.appointmentDate.error}
            />
          </InputsSection>

          <InputsSectionColumn>
            {providerTimingLoading ? (
              <p>Loading timing</p>
            ) : (
              <AppointmentTime>
                {providerTimingData?.data.result &&
                  providerTimingData?.data.result.length > 0 &&
                  providerTimingData?.data.result.map((item, index) => (
                    <TimeButton
                      key={index}
                      disabled={timeCheck(props.addEditModalStates.appointmentDate.value, item.title) || item.isAlloted}
                      style={
                        props.addEditModalStates.appointmentTime.value === item.value
                          ? {
                              backgroundColor: '#006EDC',
                              color: '#FFFFFF',
                              borderColor: '#006EDC',
                            }
                          : {}
                      }
                      onClick={() => props.setAddEditModalStates(prevVal => ({ ...prevVal, appointmentTime: { value: item.value, error: '' } }))}
                    >
                      {item.title}
                    </TimeButton>
                  ))}
              </AppointmentTime>
            )}
            {props.addEditModalStates.appointmentTime.error && (
              <p className='dangerText' style={{ textAlign: 'center' }}>
                {props.addEditModalStates.appointmentTime.error}
              </p>
            )}
          </InputsSectionColumn>

          <ModalButtons
            submitButtonLabel={props.addEditModal.data && props.addEditModal.data._id ? 'Update Appointment' : 'Create Appointment'}
            cancelButtonLabel='Cancel'
            onCancel={props.onCloseModal}
          />
        </ModalForm>
      )}
    </Modal>
  );
};

export default AddEditModal;
