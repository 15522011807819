import toast from 'react-hot-toast';
import useApi from '../useApi';
import { IEditPasswordReq, IAddUserRes } from './index.type';

const useEditProfile = () => {
  const { loading: editProfileLoading, makeApiCall } = useApi({ url: '/users/updateMe', method: 'PATCH' });

  const editProfile = async (formData: IEditPasswordReq) => {
    const { response, error } = await makeApiCall<IEditPasswordReq, IAddUserRes>(formData);
    if (response?.status === 'success') {
      toast.success('Profile data saved successfully.');
      return response;
    }

    if (error?.status === 'fail' && error?.msg) {
      toast.error(error?.msg);
    } else if (error) {
      toast.error('Something went wrong. Please try again later');
    }
  };

  return {
    editProfileLoading,
    editProfile,
  };
};

export default useEditProfile;
