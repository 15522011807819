import React from 'react';

import { Modal } from '../../../components/Modal';
import ContactCareForm from './ContactCareForm';
import PatientDemographicForm from './PatientDemographicForm';
import HippaPoliciesForm from './HippaPoliciesForm';
import classes from './InsurancePolicies.module.css';
import { useGetUserInsuranceForm } from '../../../hooks/users';
import { EditContactCareForm, EditPatientDemographicForm } from './EditForm';

const InsurancePolicies = ({ insurancePoliciesModal, closeInsurancePoliciesModal }) => {
  const [editForm, setEditForm] = React.useState(false);
  const [currentForm, setCurrentForm] = React.useState(1);
  const [contactCareForm, setContactCareForm] = React.useState({
    data: {},
    loading: false,
    error: '',
  });
  const [demographicForm, setDemographicForm] = React.useState({
    data: {},
    loading: false,
    error: '',
  });
  const [hippaPiliciesForm, setHippaPiliciesForm] = React.useState({
    data: {},
    loading: false,
    error: '',
  });

  const { getUserInsuranceData, userInsuranceDataLoading } = useGetUserInsuranceForm({
    userId: insurancePoliciesModal.data?._id,
    documentType: 'all',
  });

  const getContactCareData = React.useCallback(async () => {
    setContactCareForm(prevData => ({ ...prevData, loading: userInsuranceDataLoading }));
    setDemographicForm(prevData => ({ ...prevData, loading: userInsuranceDataLoading }));
    setHippaPiliciesForm(prevData => ({ ...prevData, loading: userInsuranceDataLoading }));

    const data = await getUserInsuranceData();
    if (data?.status) {
      // Contact care form
      if (data?.resultCCC?.status) {
        setContactCareForm({
          data: data?.resultCCC?.data,
          loading: false,
          error: '',
        });
      } else {
        setContactCareForm({
          data: {},
          loading: false,
          error: data?.resultCCC?.message,
        });
      }

      // Patient Demographic Data
      if (data?.resultPDC?.status) {
        setDemographicForm({
          data: data?.resultPDC?.data,
          loading: false,
          error: '',
        });
      } else {
        setDemographicForm({
          data: {},
          loading: false,
          error: data?.resultPDC?.message,
        });
      }

      // HIPPA Policies Data
      if (data?.resultHPP?.status) {
        setHippaPiliciesForm({
          data: data?.resultHPP?.data,
          loading: false,
          error: '',
        });
      } else {
        setHippaPiliciesForm({
          data: {},
          loading: false,
          error: data?.resultHPP?.message,
        });
      }
    } else {
      setContactCareForm({ data: {}, loading: false, error: data?.message });
      setDemographicForm({ data: {}, loading: false, error: data?.message });
    }
    // eslint-disable-next-line
  }, [insurancePoliciesModal]);

  React.useEffect(() => {
    if (insurancePoliciesModal.status) {
      getContactCareData();
    }
  }, [insurancePoliciesModal, getContactCareData]);

  const handleChangeEditStatus = save => {
    if (save) getContactCareData();
    setEditForm(prevVal => !prevVal);
  };

  return (
    <Modal show={insurancePoliciesModal.status} style={{ width: '50vw' }}>
      <div className={classes.headingButtons}>
        <button
          disabled={currentForm === 1}
          onClick={() => {
            setCurrentForm(1);
            setEditForm(false);
          }}
          className={currentForm === 1 ? classes.headingButton + ' ' + classes.headingButtonActive : classes.headingButton}
        >
          Contact of Care
        </button>
        <button
          disabled={currentForm === 2}
          onClick={() => {
            setCurrentForm(2);
            setEditForm(false);
          }}
          className={currentForm === 2 ? classes.headingButton + ' ' + classes.headingButtonActive : classes.headingButton}
        >
          Patient Demographic Form
        </button>
        <button
          disabled={currentForm === 3}
          onClick={() => {
            setCurrentForm(3);
            setEditForm(false);
          }}
          className={currentForm === 3 ? classes.headingButton + ' ' + classes.headingButtonActive : classes.headingButton}
        >
          HIPAA Policies & Procedures
        </button>
      </div>

      {currentForm === 1 ? (
        editForm ? (
          <EditContactCareForm
            patient={insurancePoliciesModal.data}
            contactCareForm={contactCareForm}
            handleEdit={handleChangeEditStatus}
            closeInsurancePoliciesModal={handleChangeEditStatus}
          />
        ) : (
          <ContactCareForm
            loading={userInsuranceDataLoading}
            patient={insurancePoliciesModal.data}
            contactCareForm={contactCareForm}
            handleEdit={handleChangeEditStatus}
            closeInsurancePoliciesModal={closeInsurancePoliciesModal}
          />
        )
      ) : currentForm === 2 ? (
        editForm ? (
          <EditPatientDemographicForm
            patient={insurancePoliciesModal.data}
            demographicForm={demographicForm}
            handleEdit={handleChangeEditStatus}
            closeInsurancePoliciesModal={handleChangeEditStatus}
          />
        ) : (
          <PatientDemographicForm
            loading={userInsuranceDataLoading}
            demographicForm={demographicForm}
            handleEdit={handleChangeEditStatus}
            closeInsurancePoliciesModal={closeInsurancePoliciesModal}
          />
        )
      ) : (
        currentForm === 3 && (
          <HippaPoliciesForm
            loading={userInsuranceDataLoading}
            patient={insurancePoliciesModal.data}
            hippaPiliciesForm={hippaPiliciesForm}
            insurancePoliciesModal={insurancePoliciesModal}
            closeInsurancePoliciesModal={closeInsurancePoliciesModal}
            getContactCareData={getContactCareData}
          />
        )
      )}
    </Modal>
  );
};

export default InsurancePolicies;
