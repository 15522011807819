import React from 'react';
import toast from 'react-hot-toast';
import useApi from '../useApi';
import { ICheckinRes } from './index.type';

const useAllCheckinsHistory = ({ pageNumber }: { pageNumber: number }) => {
  const [checkinsHistoryData, setCheckinsHistoryData] = React.useState<ICheckinRes>();

  const utcTimeOffset = new Date().getTimezoneOffset();

  const { loading: checkinsHistoryDataLoading, makeApiCall } = useApi({
    url: `/appointment/check-in-history?offset=${utcTimeOffset}&page=${pageNumber}`,
    method: 'GET',
  });

  const getAllCheckinsHistory = async () => {
    const { response, error } = await makeApiCall<undefined, ICheckinRes>();
    if (response?.status) {
      setCheckinsHistoryData(response);
    }

    if (!response?.status) {
      setCheckinsHistoryData(undefined);
    }

    if (error) toast.error('Something went wrong while fetching checkins data. Please try again later');
  };

  React.useEffect(() => {
    getAllCheckinsHistory();
    // eslint-disable-next-line
  }, [pageNumber]);

  return {
    checkinsHistoryData,
    checkinsHistoryDataLoading,
  };
};

export default useAllCheckinsHistory;
