import toast from 'react-hot-toast';
import useApi from '../useApi';
import { IAddCampaignRes } from './index.type';

const useAddCampaign = () => {
  const { loading: addCampaignLoading, makeApiCall } = useApi({
    url: `/advertise/add`,
    method: 'POST',
    headerContentType: 'multipart/form-data',
  });

  const addCampaign = async (formData: FormData) => {
    const { response, error } = await makeApiCall<FormData, IAddCampaignRes>(formData);
    if (response?.status) {
      return response;
    }

    if (error || !response?.status) {
      toast.error('Something went wrong creating campaign. Please try again later');
    }
  };

  return {
    addCampaign,
    addCampaignLoading,
  };
};

export default useAddCampaign;
