import { FC, SetStateAction } from "react";
import SearchSelect from "../../components/SearchSelect";
import { IAdRule } from "../../hooks/adViews/index.type";
import AdViewStyles from "../../styles/AdViewScreen.module.css";
import {
	insuranceFields,
	keys,
	painDurationFields,
	redFlagsFields,
	riskRatingFields,
	sideEffectsFields,
	extendedOpioidFields,
} from "../Advertisements/staticData";
import {
	IActiveClinicRes,
	IPainLocationsRes,
} from "../../hooks/advertisements/index.type";
import MultiSelectBox from "../../components/Modal/SelectBox/MultiSelectBox";
import { DeleteRulesModalState } from "./DeleteRuleModal";

type RulesSelectorProps = {
	ruleData: IAdRule;
	activeClinics?: IActiveClinicRes;
	painLocations?: IPainLocationsRes;
	setDeleteRulesModal: React.Dispatch<SetStateAction<DeleteRulesModalState>>;
	setAdRule: (
		adRule: IAdRule,
		value: any,
		field: "field" | "operator" | "value"
	) => void;
};

const RulesSelector: FC<RulesSelectorProps> = (props) => {
	let innerRuleChildren: any[] = [];

	switch (props.ruleData.field) {
		case "riskRating":
			innerRuleChildren = riskRatingFields;
			break;
		case "redflag":
			innerRuleChildren = redFlagsFields;
			break;
		case "sideeffects":
			innerRuleChildren = sideEffectsFields;
			break;
		case "insurance":
			innerRuleChildren = insuranceFields;
			break;
		case "extendedReleaseOpioid":
			innerRuleChildren = extendedOpioidFields;
			break;
		case "clinic":
			innerRuleChildren = props.activeClinics?.data
				? props.activeClinics?.data
				: [];
			break;
		case "locationpain":
			innerRuleChildren = props.painLocations?.data
				? props.painLocations?.data.map((item) => ({
						label: item,
						value: item,
				  }))
				: [];
			break;
		case "duration":
			innerRuleChildren = painDurationFields;
			break;
		default:
			innerRuleChildren = [];
	}

	const innerRuleValue =
		innerRuleChildren &&
		innerRuleChildren.filter(function (option) {
			if (props.ruleData.value && Array.isArray(props.ruleData.value))
				return props.ruleData.value.find(
					(itm) => itm.value === option.value
				)
					? true
					: false;
			return option.value === props.ruleData.value;
		});

	let innerRuleValue1 = {
		label: "",
		value: "",
	};

	innerRuleChildren.forEach((option) => {
		if (option.value === props.ruleData.value) {
			innerRuleValue1 = option;
		}
	});

	let selectedKey = { label: "", value: "" };
	keys.forEach((option) => {
		if (option.value === props.ruleData.field) {
			selectedKey = option;
		}
	});

	const onValueChange = (
		value: any,
		field: "field" | "operator" | "value"
	) => {
		props.setAdRule(props.ruleData, value, field);
	};

	const onValueChange1 = (value: any, field: "value") => {
		props.setAdRule(props.ruleData, value, field);
	};

	return (
		<div className={AdViewStyles.rule_container}>
			{props.ruleData.field !== "" && (
				<div
					className={AdViewStyles.remove_button}
					onClick={() => {
						props.setDeleteRulesModal({
							status: true,
							mode: "Delete",
							data: props.ruleData,
						});
					}}
				>
					Delete
				</div>
			)}
			<div className={AdViewStyles.rule_row}>
				<SearchSelect
					placeholder="Select key"
					value={selectedKey}
					onChange={(item) => {
						onValueChange(item?.value, "field");
						onValueChange("", "value");
					}}
					options={keys}
				/>
				<select
					className={AdViewStyles.advertise_select}
					defaultValue={props.ruleData.operator}
					onChange={(e) => {
						onValueChange(e.target.value, "operator");
					}}
				>
					<option key="Contains" value="Contains">
						Equals
					</option>
				</select>
				{props.ruleData.field === "clinic" ||
				props.ruleData.field === "locationpain" ? (
					<MultiSelectBox
						placeholder="Select value"
						value={innerRuleValue}
						onChange={(item) => {
							onValueChange1(item, "value");
						}}
						options={innerRuleChildren}
					/>
				) : (
					<SearchSelect
						placeholder="Select value"
						value={innerRuleValue1}
						onChange={(item) => {
							onValueChange(item?.value, "value");
						}}
						options={innerRuleChildren}
					/>
				)}
			</div>
		</div>
	);
};

export default RulesSelector;
