import React from 'react';
import toast from 'react-hot-toast';
import useApi from '../useApi';
import { IAppointmentsRes } from './index.type';

const useAllAppointments = () => {
  const [appointmentsData, setAppointmentsData] = React.useState<IAppointmentsRes>();
  const utcTimeOffset = new Date().getTimezoneOffset();

  const { loading: appointmentsLoading, makeApiCall } = useApi({
    url: `/appointment/get-appointment-list?offset=${utcTimeOffset}`,
    method: 'GET',
  });

  const getAllAppointments = async () => {
    const { response, error } = await makeApiCall<undefined, IAppointmentsRes>();
    if (response?.status) {
      setAppointmentsData(response);
    }

    if (!response?.status) {
      setAppointmentsData(undefined);
    }

    if (error) toast.error('Something went wrong while fetching clinic data. Please try again later');
  };

  React.useEffect(() => {
    getAllAppointments();
    // eslint-disable-next-line
  }, []);

  return {
    appointmentsData,
    getAllAppointments,
    appointmentsLoading,
  };
};

export default useAllAppointments;
