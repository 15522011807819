import React from 'react';
import classes from './HomeScreen.module.css';

const Bubbles = ({ maxValue = 30, value = 10 }) => {
  const [maxBubbles, setMaxBubbles] = React.useState([]);
  const [bubbles, setBubbles] = React.useState([]);
  const [newBubbles, setNewBubbles] = React.useState([]);

  React.useEffect(() => {
    const temp = [];

    for (let i = 0; i < maxValue; i++) {
      temp.push(i);
    }
    setMaxBubbles(temp);
  }, [maxValue]);

  React.useEffect(() => {
    const temp = [];

    for (let i = 0; i < value; i++) {
      if (i < 9) {
        temp.push({ value: i, belowAverage: true });
      } else {
        temp.push({ value: i, belowAverage: false });
      }
    }
    setBubbles(temp);
  }, [value]);

  React.useEffect(() => {
    const temp = [];

    if (maxBubbles.length === maxValue && bubbles.length === value) {
      for (let i = 0; i < maxBubbles.length; i++) {
        if (i < bubbles.length) {
          // maxBubbles[i] === bubbles[i].value;
          temp.push({ ...bubbles[i], blank: false });
        } else {
          temp.push({ value: i, belowAverage: false, blank: true });
        }
        // for (let j = 0; j < bubbles.length; j++) {
        // }
      }
    }

    setNewBubbles(temp);
  }, [maxBubbles, bubbles, maxValue, value]);

  return (
    <div className={classes.chart7_bubbles}>
      {newBubbles.length > 0
        ? newBubbles.map((bubble) => {
            // let newBubble;
            // bubbles.forEach((fillBubble) => {
            //   newBubble =
            //     fillBubble.value === bubble
            //       ? { ...fillBubble, black: false }
            //       : { value: bubble, belowAverage: true, black: true };
            // });
            // const bubbleFull = bubbles.includes(bubble);
            return (
              <div
                key={bubble.value}
                className={
                  bubble.blank
                    ? classes.chart7_bubble
                    : bubble.belowAverage
                    ? classes.chart7_bubble_full
                    : classes.chart7_bubble_full_below
                }
              ></div>
            );
          })
        : null}
    </div>
  );
};

const Chart7 = () => {
  return (
    <div className={classes.chart7}>
      <p className={classes.chart7_heading}>
        Average Wait Time in Sept 2022 (in mins)
      </p>

      <div className={classes.chart7_data}>
        <div className={classes.chart7_charts}>
          <div className={classes.chart7_indication}></div>
          <div className={classes.chart7_chart}>
            <p className={classes.chart7_chart_title}>Cardio</p>
            <Bubbles value={Math.ceil(70 / 5)} />
            <p className={classes.chart7_chart_value}>70</p>
          </div>
          <div className={classes.chart7_chart}>
            <p className={classes.chart7_chart_title}>Surgery</p>
            <Bubbles value={Math.ceil(43 / 5)} />
            <p className={classes.chart7_chart_value}>43</p>
          </div>
          <div className={classes.chart7_chart}>
            <p className={classes.chart7_chart_title}>Neuro</p>
            <Bubbles value={Math.ceil(24 / 5)} />
            <p className={classes.chart7_chart_value}>24</p>
          </div>
          <div className={classes.chart7_chart}>
            <p className={classes.chart7_chart_title}>Paedics</p>
            <Bubbles value={Math.ceil(60 / 5)} />
            <p className={classes.chart7_chart_value}>60</p>
          </div>
        </div>

        {/* <div className={classes.chart7_stats}>
          <div className={classes.chart7_stat}>
            <p className={classes.chart7_stat_value}>4500</p>
            <p className={classes.chart7_stat_dept}>Cardio</p>
            <p className={classes.chart7_stat_text}>Patients</p>
          </div>
          <div className={classes.chart7_stat}>
            <p className={classes.chart7_stat_value}>3100</p>
            <p className={classes.chart7_stat_dept}>Neuro</p>
            <p className={classes.chart7_stat_text}>Patients</p>
          </div>
          <div className={classes.chart7_stat}>
            <p className={classes.chart7_stat_value}>2900</p>
            <p className={classes.chart7_stat_dept}>Paedics</p>
            <p className={classes.chart7_stat_text}>Patients</p>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Chart7;
