import React, { Fragment } from 'react';
import type { FC, PropsWithChildren } from 'react';

import { Modal, ModalForm, InputsSection, InputBox, ModalButtons } from '../../components/Modal';
import ModalHeading from '../../components/Modal/ModalHeading';
import DownloadFileSkeleton from '../../Skeletons/DownloadFileSkeleton';
import { IFile } from '../../hooks/folders/index.type';

export type DownloadModalState = PropsWithChildren<{
  status: boolean;
  data?: IFile;
}>;

export type IdownloadModalStates = PropsWithChildren<{
  password: { value: string; error: string };
}>;

type DownloadFileModalProps = PropsWithChildren<{
  loading: boolean;
  downloadFileModal: DownloadModalState;
  downloadFilePasswordInput: IdownloadModalStates;
  setDownloadFilePasswordInput: React.Dispatch<React.SetStateAction<IdownloadModalStates>>;
  onCloseModal?: () => void;
  handleDownloadFile?: (event: React.FormEvent<HTMLFormElement>) => void;
}>;

const DownloadFileModal: FC<DownloadFileModalProps> = props => {
  return (
    <Modal show={props.downloadFileModal.status}>
      <ModalHeading heading={`Download ${props.downloadFileModal.data?.name}?`} />
      {props.loading ? (
        <DownloadFileSkeleton fileProtected={props.downloadFileModal.data?.protected} />
      ) : (
        props.downloadFileModal.data && (
          <Fragment>
            <ModalForm style={{ marginBottom: '2.5rem' }} onSubmit={props.handleDownloadFile}>
              <InputsSection style={{ width: '34rem', flexWrap: 'wrap' }}>
                {props.downloadFileModal.data.protected ? (
                  <InputBox
                    label={`Enter password to download file`}
                    type='password'
                    placeholder='••••••••••••••••'
                    value={props.downloadFilePasswordInput.password.value}
                    onChange={event => {
                      props.setDownloadFilePasswordInput(prevVal => ({ ...prevVal, password: { value: event.target.value, error: '' } }));
                    }}
                    errorMessage={props.downloadFilePasswordInput.password.error}
                    style={{ width: '30rem' }}
                  />
                ) : (
                  <p className='modal__subtext' style={{ margin: '0', marginBottom: '0' }}>
                    Please click on download button to download this file.
                  </p>
                )}
              </InputsSection>
              <ModalButtons submitButtonLabel='Download File' cancelButtonLabel='Cancel' onCancel={props.onCloseModal} />
            </ModalForm>
          </Fragment>
        )
      )}
    </Modal>
  );
};

export default DownloadFileModal;
