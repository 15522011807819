import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { authApiCallBegan } from '../actions/beforeAuth';
import { RootState } from '../store';

export interface UserDetailsState {
  status: 'success' | 'fail';
  token: string;
  data?: {
    user: {
      active: boolean;
      age: number;
      clinicID: string;
      createdAt: string;
      distance: number;
      dob: string;
      dobString: string;
      email: string;
      gender: string;
      latitude: string;
      longitude: string;
      name: string;
      passwordChangedAt: string;
      phone: string;
      photo: string;
      role: string;
      stripeCustomerID: string;
      updatedAt: string;
      uploadFolder: [];
      userDeviceID: string;
      __v: number;
      _id: string;
      clinic: { name: string; uploadFolder: []; _id: string };
      modules?: { fileSharing?: boolean; checkIn?: boolean; questionnaire?: boolean };
    };
  };
}

export interface ErrorState {
  status: string;
  msg: string;
}
export interface LoginState {
  loading: boolean;
  error: ErrorState;
  userDetails: UserDetailsState;
}

const initialState: LoginState = {
  loading: false,
  error: {
    status: '',
    msg: '',
  },
  userDetails: {
    status: 'fail',
    token: '',
  },
};

const userInfoFromStorage = localStorage.getItem('clinIQ_userInfo');
if (userInfoFromStorage) {
  const datafromStorage = JSON.parse(userInfoFromStorage);
  initialState.userDetails = datafromStorage;
}

export const loginSlice = createSlice({
  name: 'userLogin',
  initialState,
  reducers: {
    request: state => {
      state.loading = true;
    },
    success: (state, action: PayloadAction<UserDetailsState>) => {
      state.loading = false;
      state.error = { status: '', msg: '' };
      state.userDetails = action.payload;
      localStorage.setItem('clinIQ_userInfo', JSON.stringify(action.payload));
    },
    fail: (state, action: PayloadAction<ErrorState>) => {
      state.loading = false;
      state.userDetails = {
        status: 'fail',
        token: '',
      };
      state.error = action.payload;
      localStorage.removeItem('clinIQ_userInfo');
    },
    logout: (state, action) => {
      state.loading = false;
      state.error = {
        status: '',
        msg: '',
      };
      state.userDetails = {
        status: 'fail',
        token: '',
      };

      localStorage.removeItem('clinIQ_userInfo');
    },
  },
});

// Action creators are generated for each case reducer function
export const { request, success, fail, logout } = loginSlice.actions;

export default loginSlice.reducer;

export const loginSliceValue = (state: RootState) => state.loginReducer;

export const loginUser = (email: string, password: string) =>
  authApiCallBegan({
    url: '/users/login',
    method: 'POST',
    data: { email, password },
    onAuthStart: request.type,
    onAuthSuccess: success.type,
    onAuthFailed: fail.type,
  });

export const patientEnrollment = (data: { email: string; password: string; clinicID: string }) =>
  authApiCallBegan({
    url: '/users/nextgen/user',
    method: 'POST',
    data,
    onAuthStart: request.type,
    onAuthSuccess: success.type,
    onAuthFailed: fail.type,
  });

export const logoutUser = () =>
  authApiCallBegan({
    onAuthReset: logout.type,
  });
