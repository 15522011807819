import type { FC, PropsWithChildren } from 'react';
import TableButton, { TableButtonProps } from './TableButton';
import classes from '../../styles/Table.module.css';
import { Button } from '../Modal';

type TableButtonsProps = PropsWithChildren<{
  buttons: TableButtonProps[];
  detailButton?: boolean;
  detailButtonLabel?: string;
  detailButtonOnClick?: React.MouseEventHandler<HTMLButtonElement>;
}>;

const TableButtons: FC<TableButtonsProps> = props => {
  return (
    <td>
      <div className={classes.table__buttons}>
        {props.detailButton && (
          <Button
            label={props.detailButtonLabel ? props.detailButtonLabel : ''}
            span={false}
            submitButtonStyle={{ fontSize: '1rem', padding: '.5rem 1rem' }}
            onClick={props.detailButtonOnClick}
          />
        )}

        {props.buttons.map((button, ind) => (
          <TableButton key={ind} icon={button.icon} tooltip={button.tooltip} onClick={button.onClick} variant={button.variant} />
        ))}
      </div>
    </td>
  );
};

export default TableButtons;
