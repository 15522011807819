import React from 'react';
import toast from 'react-hot-toast';
import useApi from '../useApi';
import { IFolderRes } from './index.type';

const useAllFolders = ({ clinicId }: { clinicId?: string }) => {
  const [foldersData, setFoldersData] = React.useState<IFolderRes>();

  const url = clinicId ? `/folders?clinic=${clinicId}` : '/folders';
  const { loading: foldersDataLoading, makeApiCall } = useApi({
    url,
    method: 'GET',
  });

  const getFoldersData = async () => {
    const { response, error } = await makeApiCall<undefined, IFolderRes>();
    if (response?.status === 'success') {
      setFoldersData(response);
    }

    if (response?.status === 'fail') {
      setFoldersData(undefined);
    }

    if (error) toast.error('Something went wrong while fetching folders. Please try again later');
  };

  return {
    foldersData,
    getFoldersData,
    foldersDataLoading,
  };
};

export default useAllFolders;
