import React from 'react';
import { useChangePassword, useEditProfile, useGetMyProfile } from '../hooks/users';
import { inputPhoneMasking, unMasking, validatePassword, validatePhone } from '../utils/validators';

import { Content, TextInput } from '../components';
import { Button } from '../components/Modal';
import EditMyProfileModal, { EditProfileModalState, IeditProfileModalStates } from '../pageComponents/Users/EditMyProfileModal';
import MyProfileSkeleton from '../Skeletons/MyProfileSkeleton';
import MyProfileStyles from '../styles/MyProfileScreen.module.css';

interface Ipassword {
  currentPassword: { value: string; error: string };
  newPassword: { value: string; error: string };
}
const MyProfile = () => {
  // ====================================== Edit Profile states start ======================================
  const [editProfileModal, setEditProfileModal] = React.useState<EditProfileModalState>({
    status: false,
  });
  const startEditProfileModalStatesValue = {
    name: { value: '', error: '' },
    phone: { value: '', error: '' },
    address: { value: '', error: '' },
  };
  const [editProfileModalStates, setEditProfileModalStates] = React.useState<IeditProfileModalStates>(startEditProfileModalStatesValue);
  // ====================================== Edit Profile states end ======================================

  // ====================================== Change Password states start ======================================
  const changePasswordStartState: Ipassword = {
    currentPassword: { value: '', error: '' },
    newPassword: { value: '', error: '' },
  };
  const [password, setPassword] = React.useState<Ipassword>(changePasswordStartState);
  // ====================================== Change Password states end ======================================

  // Hooks
  const { myProfileData, getMyProfileData, myProfileDataLoading } = useGetMyProfile();
  const { changePasswordLoading, changePassword } = useChangePassword();
  const { editProfileLoading, editProfile } = useEditProfile();

  // ====================================== Change Password function start ======================================
  const handleChangePassword = async () => {
    if (!password.currentPassword.value.trim()) {
      setPassword(prevVal => ({ ...prevVal, currentPassword: { ...prevVal.currentPassword, error: 'Please enter your current password' } }));
    } else if (!validatePassword(password.newPassword.value)) {
      setPassword(prevVal => ({ ...prevVal, newPassword: { ...prevVal.newPassword, error: 'Please enter strong password' } }));
    } else if (password.currentPassword.value === password.newPassword.value) {
      setPassword(prevVal => ({ ...prevVal, newPassword: { ...prevVal.newPassword, error: 'Please enter new password' } }));
    } else {
      const formData = {
        password: password.currentPassword.value,
        newPassword: password.newPassword.value,
      };
      await changePassword(formData);
    }
  };
  // ====================================== Change Password function end ======================================

  // ====================================== Edit Profile functions start ======================================
  React.useEffect(() => {
    if (editProfileModal.status && editProfileModal.data) {
      setEditProfileModalStates({
        name: { value: editProfileModal.data.name, error: '' },
        phone: { value: inputPhoneMasking(editProfileModal.data.phone), error: '' },
        address: { value: editProfileModal.data.address, error: '' },
      });
    }
  }, [editProfileModal]);

  const handleCloseModal = () => {
    setEditProfileModal({ status: false });
    setEditProfileModalStates(startEditProfileModalStatesValue);
  };

  const handleEditProfile = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (editProfileModal.data)
      if (!editProfileModalStates.name.value.trim()) {
        setEditProfileModalStates(prevVal => ({ ...prevVal, name: { ...prevVal.name, error: 'Please enter user name' } }));
      } else if (!editProfileModalStates.phone.value.trim() || !validatePhone(unMasking(editProfileModalStates.phone.value))) {
        setEditProfileModalStates(prevVal => ({ ...prevVal, phone: { ...prevVal.phone, error: 'Please enter valid user phone' } }));
      } else if (!editProfileModalStates.address.value.trim()) {
        setEditProfileModalStates(prevVal => ({ ...prevVal, address: { ...prevVal.address, error: 'Please enter user address' } }));
      } else {
        const formData = {
          name: editProfileModalStates.name.value,
          phone: editProfileModalStates.phone.value,
          address: editProfileModalStates.address.value,
        };
        const data = await editProfile(formData);
        if (data?.status === 'success') {
          handleCloseModal();
          getMyProfileData();
        }
      }
  };
  // ====================================== Edit Profile functions end ======================================

  return (
    <Content currentMenu='my-profile' headerTitle='My Profile' addBtn={false} searchBox={false}>
      {myProfileDataLoading || changePasswordLoading ? (
        <MyProfileSkeleton />
      ) : (
        myProfileData?.data &&
        myProfileData.data.data && (
          <div className={MyProfileStyles.content}>
            <div className={MyProfileStyles.content_left}>
              <div>
                <svg className={MyProfileStyles.content_left_profile_image}>
                  <use xlinkHref={`/assets/sprite.svg#icon-user-circle-o`} />
                </svg>
                <h2 className={MyProfileStyles.content_left_profile_name}>{myProfileData.data.data.name}</h2>
                <p className={MyProfileStyles.content_left_profile_role}>
                  Your are logged in with{' '}
                  {myProfileData.data.data.role && myProfileData.data.data.role.startsWith('file')
                    ? 'File ' + myProfileData.data.data.role.split('file')[1]
                    : myProfileData.data.data.role.slice(0, 1).toUpperCase() + myProfileData.data.data.role.slice(1)}{' '}
                  account.
                </p>

                <div className={MyProfileStyles.content_left_contacts}>
                  <div className={MyProfileStyles.content_left_contact}>
                    <div className={MyProfileStyles.content_left_contact_box + ' ' + MyProfileStyles.content_left_contact_box_red}>
                      <svg className={MyProfileStyles.content_left_contact_box_icon}>
                        <use xlinkHref={`/assets/sprite.svg#icon-mobile`} />
                      </svg>
                    </div>
                    <div className={MyProfileStyles.content_left_contact_data}>
                      <p className={MyProfileStyles.content_left_contact_data_title}>Phone</p>
                      <p className={MyProfileStyles.content_left_contact_data_field}>{myProfileData.data.data.phone}</p>
                    </div>
                  </div>

                  <div className={MyProfileStyles.content_left_contact}>
                    <div className={MyProfileStyles.content_left_contact_box + ' ' + MyProfileStyles.content_left_contact_box_blue}>
                      <svg className={MyProfileStyles.content_left_contact_box_icon}>
                        <use xlinkHref={`/assets/sprite.svg#icon-email`} />
                      </svg>
                    </div>
                    <div className={MyProfileStyles.content_left_contact_data}>
                      <p className={MyProfileStyles.content_left_contact_data_title}>Email</p>
                      <p className={MyProfileStyles.content_left_contact_data_field}>{myProfileData.data.data.email}</p>
                    </div>
                  </div>

                  <div className={MyProfileStyles.content_left_contact}>
                    <div className={MyProfileStyles.content_left_contact_box + ' ' + MyProfileStyles.content_left_contact_box_primary}>
                      <svg className={MyProfileStyles.content_left_contact_box_icon}>
                        <use xlinkHref={`/assets/sprite.svg#icon-map-pin`} />
                      </svg>
                    </div>
                    <div className={MyProfileStyles.content_left_contact_data}>
                      <p className={MyProfileStyles.content_left_contact_data_title}>Address</p>
                      <p className={MyProfileStyles.content_left_contact_data_field}>{myProfileData.data.data.address}</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className={MyProfileStyles.content_left_bottom}>
                <Button
                  label='Edit My Profile'
                  submitButtonStyle={{ justifyContent: 'center' }}
                  onClick={() => setEditProfileModal({ status: true, data: myProfileData.data.data })}
                />

                {/* {myProfileData.data.data.role !== 'admin' && (
                  <button
                    className={MyProfileStyles.userDetails__deatctivateButton}
                    // onClick={() => setDeactivateUserModal(true)}
                  >
                    Deactivate my account
                  </button>
                )} */}
              </div>
            </div>

            <div className={MyProfileStyles.content_right}>
              <div className={MyProfileStyles.changePasswordSection}>
                <div className={MyProfileStyles.changePasswordSection_left}>
                  <h2 className={MyProfileStyles.changePasswordSection_heading}>Change Password</h2>
                  <TextInput
                    type='password'
                    label='Current Password'
                    placeholder='Current password'
                    icon='key'
                    onChange={e => {
                      if (e.target.value.length > 7) {
                        setPassword(prevVal => ({ ...prevVal, currentPassword: { value: e.target.value, error: '' } }));
                      } else {
                        setPassword(prevVal => ({ ...prevVal, currentPassword: { value: e.target.value, error: 'Password should be at least 8 characters.' } }));
                      }
                    }}
                    check={password.currentPassword.value && password.currentPassword.error === '' ? true : false}
                    error={password.currentPassword.error}
                  />
                  <TextInput
                    type='password'
                    label='New Password'
                    placeholder='New password'
                    icon='key'
                    onChange={e => {
                      if (e.target.value.length > 7) {
                        setPassword(prevVal => ({ ...prevVal, newPassword: { value: e.target.value, error: '' } }));
                      } else {
                        setPassword(prevVal => ({ ...prevVal, newPassword: { value: e.target.value, error: 'Password should be at least 8 characters.' } }));
                      }
                    }}
                    check={password.newPassword.value && password.newPassword.error === '' ? true : false}
                    error={password.newPassword.error}
                  />
                  <Button label='Change Password' submitButtonStyle={{ alignSelf: 'flex-start' }} onClick={handleChangePassword} />
                </div>

                <div className={MyProfileStyles.changePasswordSection_right}>
                  <p className={MyProfileStyles.changePasswordSection_text}>We recommend to not share your password with anyone.</p>
                  <p className={MyProfileStyles.changePasswordSection_text}>
                    Also change your password frequently which adds an extra layer of security in your account.
                  </p>
                </div>
              </div>
            </div>
          </div>
        )
      )}

      {editProfileModal.status && (
        <EditMyProfileModal
          loading={editProfileLoading}
          editProfileModal={editProfileModal}
          editProfileModalStates={editProfileModalStates}
          setEditProfileModalStates={setEditProfileModalStates}
          handleEditProfile={handleEditProfile}
          onCloseModal={handleCloseModal}
        />
      )}
    </Content>
  );
};

export default MyProfile;
