import React from 'react';
import { TableButtons } from '../../components/Table';
import { IFeedback } from '../../hooks/feedbacks/index.type';
import { AddEditModalState } from './AddEditModal';
import { DeleteModalState } from './DeleteModal';
import classes from '../../styles/Feedback.module.css';

interface FeedbackItemProps {
  role?: string;
  item: IFeedback;
  setAddEditModal: React.Dispatch<React.SetStateAction<AddEditModalState>>;
  setDeleteModal: React.Dispatch<React.SetStateAction<DeleteModalState>>;
}

const FeedbackItem: React.FC<FeedbackItemProps> = ({ item, setAddEditModal, setDeleteModal, role }) => {
  return (
    <div className={classes.feedback_box}>
      <div className={classes.feedback}>
        <div className={classes.feedback_items}>
          <div className={classes.feedback_item}>
            <p>
              Feedback Question Title
              <span>Type: {item.type}</span>
            </p>
            <h3>{item.question}</h3>
          </div>
          <div className={classes.feedback_item}>
            <p>Feedback Question Placeholder</p>
            <h3>{item.placeholder}</h3>
          </div>
        </div>
        <p className={classes.feedback_order}>
          Order: <span>{item.order}</span>
        </p>
      </div>
      <div className={classes.feedback_actons}>
        {item.default && role !== 'admin' ? (
          <p className={classes.feedback_default}>Default feedback question</p>
        ) : (
          <>
            {item.default && <p className={classes.feedback_default}>Default feedback question</p>}
            <TableButtons
              buttons={[
                { icon: 'edit-3', onClick: () => setAddEditModal({ status: true, data: item }) },
                { icon: 'trash', variant: 'danger', onClick: () => setDeleteModal({ status: true, data: item }) },
              ]}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default FeedbackItem;
