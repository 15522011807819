import type { FC, PropsWithChildren } from 'react';
import { Modal, ModalForm, InputsSection, InputBox, ModalButtons } from '../../components/Modal';
import ModalHeading from '../../components/Modal/ModalHeading';
import DeleteModalSkeleton from '../../Skeletons/DeleteModalSkeleton';
import { IChildFolder, IFolder } from '../../hooks/folders/index.type';

export type DeleteModalState = PropsWithChildren<{
  status: boolean;
  isParent: boolean;
  data?: IFolder | IChildFolder;
}>;

export type IdeleteModalStates = PropsWithChildren<{
  name: { value: string; error: string };
}>;

type DeleteFolderModalProps = PropsWithChildren<{
  loading: boolean;
  deleteFolderModal: DeleteModalState;
  deleteFolderInput: IdeleteModalStates;
  setDeleteFolderInput: React.Dispatch<React.SetStateAction<IdeleteModalStates>>;
  onCloseModal?: () => void;
  handleDeleteFolder?: (event: React.FormEvent<HTMLFormElement>) => void;
}>;

const DeleteFolderModal: FC<DeleteFolderModalProps> = props => {
  return (
    <Modal show={props.deleteFolderModal.status}>
      <ModalHeading heading={`Delete ${props.deleteFolderModal.data?.name}?`} />
      {props.loading ? (
        <DeleteModalSkeleton />
      ) : (
        props.deleteFolderModal.data && (
          <ModalForm style={{ marginBottom: '2.5rem' }} onSubmit={props.handleDeleteFolder}>
            <InputsSection style={{ width: '34rem', flexWrap: 'wrap' }}>
              <InputBox
                label={`Enter ${props.deleteFolderModal.data.name} below to delete folder`}
                placeholder={props.deleteFolderModal.data.name}
                value={props.deleteFolderInput.name.value}
                onChange={event => {
                  props.setDeleteFolderInput(prevVal => ({ ...prevVal, name: { value: event.target.value, error: '' } }));
                }}
                errorMessage={props.deleteFolderInput.name.error}
                style={{ width: '30rem' }}
              />
              <p className='dangerText'>Please note that this will delete this folder entire data and will not be restored in future.</p>
            </InputsSection>

            <ModalButtons submitButtonLabel='Delete Folder' cancelButtonLabel='Cancel' onCancel={props.onCloseModal} />
          </ModalForm>
        )
      )}
    </Modal>
  );
};

export default DeleteFolderModal;
