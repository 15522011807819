import type { FC, PropsWithChildren } from 'react';
import FileExchangeStyles from '../../styles/FileExchangeScreen.module.css';

type FolderItemProps = PropsWithChildren<{
  selected?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  name: string;
  index: number;
}>;

const FolderItem: FC<FolderItemProps> = props => {
  return (
    <button className={props.selected ? FileExchangeStyles.folderSelected : FileExchangeStyles.folder} onClick={props.onClick}>
      <div className={FileExchangeStyles.folderLeft}>
        <svg className={FileExchangeStyles.folderIcon}>
          <use xlinkHref={`/assets/sprite.svg#icon-folder`} />
        </svg>
        <h4 className={FileExchangeStyles.folderName}>
          {props.index + 1}. {props.name.length < 18 ? props.name : props.name.slice(0, 18) + '...'}
        </h4>
      </div>
      {/* <h6 className={FileExchangeStyles.folderfiles}>10 Files</h6> */}
      {props.name.length >= 18 && <p className={FileExchangeStyles.folderNameDetails}>{props.index + 1 + '. ' + props.name}</p>}
    </button>
  );
};

export default FolderItem;
