import type { FC, PropsWithChildren } from 'react';
import classes from '../../styles/Table.module.css';

type TableProps = PropsWithChildren<{
  style?: React.CSSProperties;
}>;

const Table: FC<TableProps> = props => {
  return (
    <div className={classes.tableContainer} style={props.style}>
      <table className={classes.table}>{props.children}</table>
    </div>
  );
};

export default Table;
