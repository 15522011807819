import React from 'react';
import toast from 'react-hot-toast';
import useApi from '../useApi';
import { ITop10CampaignReq, ITop10CampaignRes } from './index.type';

const useGetTop10Ad = () => {
  const [top10CampaignData, setTop10CampaignData] = React.useState<ITop10CampaignRes>();

  const { loading: getTop10CampaignLoading, makeApiCall } = useApi({
    url: `/advertise/top-ten-campaign?offset=${new Date().getTimezoneOffset()}`,
    method: 'POST',
  });

  const getTop10Campaign = async (formData: ITop10CampaignReq) => {
    const { response, error } = await makeApiCall<ITop10CampaignReq, ITop10CampaignRes>(formData);
    if (response?.status) {
      setTop10CampaignData(response);
    }

    if (error || !response?.status) {
      setTop10CampaignData(undefined);
      toast.error('Something went wrong while fetching top 10 ads data. Please try again later');
    }
  };

  return {
    top10CampaignData,
    getTop10Campaign,
    getTop10CampaignLoading,
  };
};

export default useGetTop10Ad;
