import type { FC, PropsWithChildren } from 'react';
import ButtonStyles from './Button.module.css';

type ButtonProps = PropsWithChildren<{
  label: string;
  disabled?: boolean;
  type?: 'submit' | 'button' | 'reset';
  span?: boolean;
  submitButtonStyle?: React.CSSProperties;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}>;

const Button: FC<ButtonProps> = ({ label, onClick, type, disabled = false, submitButtonStyle = {}, span = true }) => {
  return (
    <button className={disabled ? ButtonStyles.button_disabled : ButtonStyles.button} disabled={disabled} type={type} style={submitButtonStyle} onClick={onClick}>
      {label} {span && <span />}
    </button>
  );
};

export default Button;
