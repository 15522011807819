import React from 'react';
import moment from 'moment';
import { formatTime } from '../../utils/date';
import { IPatientFeedback } from '../../hooks/feedbacks/index.type';

import { Rating } from '../../components';
import classes from '../../styles/Feedback.module.css';

interface FeedbackPatientProps {
  item: IPatientFeedback;
}

const FeedbackPatient: React.FC<FeedbackPatientProps> = ({ item }) => {
  return (
    // <div className={classes.feedback_box2}>
    //   <div className={classes.feedback}>
    //     <div className={classes.feedback_items}>
    //       <div className={classes.feedback_item}>
    //         <p>
    //           Question: {item.feedback.question}
    //           <span>Submitted By: {item.user.name}</span>
    //         </p>
    //         <h3>Feedback: {item.response ? item.response : 'N/A'}</h3>
    //       </div>
    //       <div className={classes.feedback_item}>
    //         <p>Rating</p>
    //         <h3>{item.rating}</h3>
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <div className={`${classes.patient_feedback} displayflex displayflexCol gap-1`}>
      <div className='flex gap-1 alignCenter justrifySpaceBetween'>
        <div className='flex gap-1'>
          <img src='/assets/profile.png' alt='Patient' />
          <div>
            <h4>{item.user.name}</h4>
            <h6>{moment(item.createdAt).format('DD MMM YYYY hh:mm A')}</h6>
          </div>
        </div>
        <Rating rating={item.rating} />
      </div>
      <div>
        <h5>{item.feedback.question}</h5>
        <p>{item.response ? item.response : '-'}</p>
      </div>
      <p>
        Appointment On {moment(item.appointment.appointmentDate).format('DD MMM YYYY')} {formatTime(item.appointment.appointmentTime)}
      </p>
    </div>
  );
};

export default FeedbackPatient;
