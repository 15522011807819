import type { FC, PropsWithChildren } from 'react';
import FileExchangeStyles from '../../styles/FileExchangeScreen.module.css';

type FileHeadingProps = PropsWithChildren<{
  title: string;
  uploadBtnLabel: string;
  uploadBtnClick?: React.MouseEventHandler<HTMLButtonElement>;
  backBtnClick?: React.MouseEventHandler<HTMLButtonElement>;
}>;

const FileHeading: FC<FileHeadingProps> = props => {
  return (
    <div className={FileExchangeStyles.folderFilesTop} style={{ marginBottom: '1rem' }}>
      {props.backBtnClick ? (
        <div className={FileExchangeStyles.folderFilesTopFlex}>
          <button className={FileExchangeStyles.uploadBtn} onClick={props.backBtnClick}>
            <svg className={FileExchangeStyles.uploadBtnIcon}>
              <use xlinkHref={`/assets/sprite.svg#icon-back`} />
            </svg>
            <h4 className={FileExchangeStyles.folderFilesSub}>{props.title}</h4>
          </button>
        </div>
      ) : (
        <h3 className={FileExchangeStyles.folderHeading}>{props.title}</h3>
      )}
      <button className={FileExchangeStyles.uploadBtn} onClick={props.uploadBtnClick}>
        {props.uploadBtnLabel}
        <svg className={FileExchangeStyles.uploadBtnIcon}>
          <use xlinkHref={`/assets/sprite.svg#icon-upload`} />
        </svg>
      </button>
    </div>
  );
};

export default FileHeading;
