import React from 'react';
import type { FC, PropsWithChildren } from 'react';
import { Modal, CancelButton } from '../../components/Modal';
import ModalHeading from '../../components/Modal/ModalHeading';
import Pagination from '../../components/Pagination';
import AnswerSkeleton from '../../Skeletons/AnswerSkeleton';
import { useGetUserAnswers } from '../../hooks/users';
import styles from '../../styles/users.module.css';
import { DeleteModalState } from './DeleteModal';

type QuestionnaireModelProps = PropsWithChildren<{
  questionnaireModal: DeleteModalState;
  onCancel: () => void;
}>;

const QuestionnaireModel: FC<QuestionnaireModelProps> = ({ questionnaireModal, onCancel }) => {
  const [pageNumber, setPageNumber] = React.useState<number>(1);
  const perpageData = 5;

  const { userAnswerData, userAnswersDataLoading } = useGetUserAnswers({ userId: questionnaireModal.data?._id, pageNumber, perpageData });

  const handlePageChange = async (currentPage: { selected: number }) => {
    setPageNumber(currentPage.selected + 1);
  };

  return (
    <Modal show={questionnaireModal.status}>
      <ModalHeading heading={'All Questionnaire Response'} />
      <div className={styles.modalcontainer}>
        <div className={styles.container}>
          {userAnswersDataLoading ? (
            <AnswerSkeleton />
          ) : userAnswerData?.data.data[0].records ? (
            userAnswerData?.data.data[0].records.map((item, index) => {
              return (
                <div className={styles.answerCard} key={index}>
                  <div className={styles.question}>
                    {pageNumber * perpageData + index + 1 - perpageData}.{item.question.question} ?
                  </div>
                  <div className={styles.answer}>
                    <span className={styles.response}>Response:</span>{' '}
                    {Array.isArray(item.option) ? (
                      <>{item.option.map(option => ` ${option.optionTitle} ,`)}</>
                    ) : typeof item.option === 'string' ? (
                      item.option
                    ) : Object.keys(item.option).length > 0 ? (
                      item.option.optionTitle
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              );
            })
          ) : (
            <div className={styles.message}>
              <div> No submitted questionnaire found for this user</div>
            </div>
          )}
        </div>
        <div className={styles.buttonsWrapper}>
          <div className={styles.buttons}>
            <Pagination
              style={{ marginTop: '0' }}
              itemsPerPage={perpageData}
              totalItems={userAnswerData?.data.data[0].count ? userAnswerData?.data.data[0].count : 0}
              onPageChange={handlePageChange}
            />
            <CancelButton
              cancelButtonStyle={{
                marginLeft: '2rem',
              }}
              label={'Cancel'}
              onClick={() => {
                setPageNumber(1);
                onCancel();
              }}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default QuestionnaireModel;
