import type { FC, PropsWithChildren } from 'react';
import { Modal, ModalForm, InputsSection, ModalButtons } from '../../components/Modal';
import ModalHeading from '../../components/Modal/ModalHeading';
import DeleteModalSkeleton from '../../Skeletons/DeleteModalSkeleton';
import { IFeedback } from '../../hooks/feedbacks/index.type';

export type DeleteModalState = PropsWithChildren<{
  status: boolean;
  data?: IFeedback;
}>;

type AddEditModalProps = PropsWithChildren<{
  loading: boolean;
  deleteModal: DeleteModalState;
  onCloseModal?: () => void;
  handleDelete?: (event: React.FormEvent<HTMLFormElement>) => void;
}>;

const DeleteModal: FC<AddEditModalProps> = props => {
  return (
    <Modal show={props.deleteModal.status}>
      <ModalHeading heading={`Delete ${props.deleteModal.data?.question}`} />
      {props.loading ? (
        <DeleteModalSkeleton />
      ) : (
        props.deleteModal.data && (
          <ModalForm style={{ marginBottom: '2.5rem' }} onSubmit={props.handleDelete}>
            <InputsSection style={{ width: '34rem', flexWrap: 'wrap' }}>
              <p className='dangerText'>Are you sure you want to delete this feedback?</p>
              <p className='primaryText'>
                If your intension is to restrict this feedback to show among patients, we recommend to change active status to false instead of deleting it.
              </p>
            </InputsSection>
            <ModalButtons submitButtonLabel='Delete Feedback' cancelButtonLabel='Cancel' onCancel={props.onCloseModal} />
          </ModalForm>
        )
      )}
    </Modal>
  );
};

export default DeleteModal;
