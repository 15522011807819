import React, { Fragment } from 'react';
import type { FC, PropsWithChildren } from 'react';
import { InputsSection, InputBox, ModalButtons, ModalForm, Modal, SelectBox, TextArea } from '../../../components/Modal';
import { ToggleSwitchWithLabel } from '../../../components';
import ModalHeading from '../../../components/Modal/ModalHeading';
import AddUserSkeleton from '../../../Skeletons/AddUserSkeleton';
import { inputPhoneMasking, returnOnlyNumber } from '../../../utils/validators';
import TextBox from '../../../components/Modal/InputBox/TextBox.module.css';
import { IClinicNew, INextgenPracticesRes } from '../../../hooks/clinic/index.type';
import { ICountryStatesRes } from '../../../redux/reducers/credentials/index.types';

export type AddEditModalState = PropsWithChildren<{
  status: boolean;
  data?: IClinicNew;
}>;
export type IaddEditModalStates = PropsWithChildren<{
  clinicType: { value: 'standalone' | 'ehr'; error: string };
  practiceId: { value: string; error: string };
  ehr: { value: string; error: string };
  name: { value: string; error: string };
  contactName: { value: string; error: string };
  email: { value: string; error: string };
  phone: { value: string; error: string };
  address: { value: string; error: string };
  latitude: { value: string; error: string };
  longitude: { value: string; error: string };
  distance: { value: number; error: string };
  // address: {
  //   addressLine1: { value: string; error: string };
  //   addressLine2: { value: string; error: string };
  //   city: { value: string; error: string };
  //   state: { value: string; error: string };
  //   country: { value: string; error: string };
  //   zip: { value: string; error: string };
  // };
  modules: { value: { fileSharing: boolean; checkIn: boolean; questionnaire: boolean }; error: string };
}>;

type AddEditModalProps = PropsWithChildren<{
  loading: boolean;
  addEditModal: AddEditModalState;
  onCloseModal?: () => void;
  handleAddEdit?: (event: React.FormEvent<HTMLFormElement>) => void;
  addEditModalStates: IaddEditModalStates;
  setAddEditModalStates: React.Dispatch<React.SetStateAction<IaddEditModalStates>>;
  nextgenPractices?: INextgenPracticesRes;
  nextgenPracticesLoading: boolean;
  countryInfoLoading: boolean;
  countryInfo: ICountryStatesRes;
}>;

const AddEditModal: FC<AddEditModalProps> = props => {
  const [addressLine1, setAddressLine1] = React.useState<string>('');
  const [addressLine2, setAddressLine2] = React.useState<string>('');
  const [city, setCity] = React.useState<string>('');
  const [state, setState] = React.useState<string>('');
  const [country, setCountry] = React.useState<string>('');
  const [zip, setZip] = React.useState<string>('');
  const [phone, setPhone] = React.useState<string>('');
  const { countryInfoLoading, countryInfo } = props;

  const getAddress = (practiceId: string) => {
    props.nextgenPractices?.data.items.forEach(item => {
      if (item.id === practiceId) {
        setAddressLine1(item.addressLine1);
        setAddressLine2(item.addressLine2);
        setCity(item.city);
        setState(item.state);
        setCountry(item.country);
        setZip(item.zip);
        setPhone(item.phone);
      }
    });
  };

  return (
    <Modal show={props.addEditModal.status}>
      <ModalHeading heading={props.addEditModal.data && props.addEditModal.data._id ? 'Update Clinic' : 'Clinic Enrollment'} />
      {props.loading ? (
        <AddUserSkeleton />
      ) : (
        <ModalForm style={{ marginBottom: '2.5rem' }} onSubmit={props.handleAddEdit}>
          <InputsSection>
            {!props.addEditModal.data?._id && (
              <div style={{ display: 'flex', gap: '1.5rem', flexDirection: 'column', width: '25rem' }}>
                <p className={TextBox.form_input__label}>Clinic Type</p>
                <div className='flex' style={{ gap: '2rem' }}>
                  <ToggleSwitchWithLabel
                    label='Stand Alone'
                    id='stand-alone'
                    checked={props.addEditModalStates.clinicType.value === 'standalone'}
                    onChange={() =>
                      props.setAddEditModalStates(prevVal => ({
                        // ...prevVal,
                        clinicType: { value: 'standalone', error: '' },
                        ehr: { value: '', error: '' },
                        practiceId: { value: '', error: '' },
                        name: { value: '', error: '' },
                        contactName: { value: '', error: '' },
                        email: { value: '', error: '' },
                        phone: { value: '', error: '' },
                        address: { value: '', error: '' },
                        latitude: { value: '', error: '' },
                        longitude: { value: '', error: '' },
                        distance: { value: 0, error: '' },
                        // address: {
                        //   addressLine1: { value: '', error: '' },
                        //   addressLine2: { value: '', error: '' },
                        //   city: { value: '', error: '' },
                        //   state: { value: '', error: '' },
                        //   country: { value: 'USA', error: '' },
                        //   zip: { value: '', error: '' },
                        // },
                        modules: {
                          value: {
                            fileSharing: false,
                            checkIn: false,
                            questionnaire: false,
                          },
                          error: '',
                        },
                      }))
                    }
                  />
                  <ToggleSwitchWithLabel
                    label='EHR Connected'
                    id='EHR'
                    checked={props.addEditModalStates.clinicType.value === 'ehr'}
                    onChange={() =>
                      props.setAddEditModalStates(prevVal => ({
                        // ...prevVal,
                        clinicType: { value: 'ehr', error: '' },
                        ehr: { value: '', error: '' },
                        practiceId: { value: '', error: '' },
                        name: { value: '', error: '' },
                        contactName: { value: '', error: '' },
                        email: { value: '', error: '' },
                        phone: { value: '', error: '' },
                        address: { value: '', error: '' },
                        latitude: { value: '', error: '' },
                        longitude: { value: '', error: '' },
                        distance: { value: 0, error: '' },
                        // address: {
                        //   addressLine1: { value: '', error: '' },
                        //   addressLine2: { value: '', error: '' },
                        //   city: { value: '', error: '' },
                        //   state: { value: '', error: '' },
                        //   country: { value: 'USA', error: '' },
                        //   zip: { value: '', error: '' },
                        // },
                        modules: {
                          value: {
                            fileSharing: false,
                            checkIn: true,
                            questionnaire: false,
                          },
                          error: '',
                        },
                      }))
                    }
                  />
                </div>
              </div>
            )}
            {props.addEditModalStates.clinicType.value === 'standalone' ? (
              <InputBox
                label='Clinic Name'
                placeholder='eg, John&#39;s Clinic'
                value={props.addEditModalStates.name.value}
                onChange={event => {
                  props.setAddEditModalStates(prevVal => ({ ...prevVal, name: { value: event.target.value, error: '' } }));
                }}
                errorMessage={props.addEditModalStates.name.error}
                locked={props.addEditModal.data?._id ? true : false}
              />
            ) : (
              <Fragment>
                <SelectBox
                  label='Select EHR'
                  value={props.addEditModalStates.ehr.value}
                  inputContainerStyle={{ width: '25rem' }}
                  onChange={event => {
                    props.setAddEditModalStates(prevVal => ({ ...prevVal, ehr: { value: event.target.value, error: '' } }));
                  }}
                  errorMessage={props.addEditModalStates.ehr.error}
                  locked={props.addEditModal.data?._id ? true : false}
                >
                  <option value=''>Select EHR</option>
                  <option value='nextgen'>NextGen</option>
                </SelectBox>

                {props.addEditModal.data?._id ? (
                  <InputBox
                    label='Select Clinic Name'
                    placeholder=''
                    value={props.addEditModalStates.name.value}
                    onChange={() => {}}
                    errorMessage={props.addEditModalStates.name.error}
                    locked={true}
                  />
                ) : (
                  <SelectBox
                    label='Select Clinic Name'
                    value={props.addEditModalStates.practiceId.value}
                    inputContainerStyle={{ width: '25rem' }}
                    onChange={event => {
                      props.setAddEditModalStates(prevVal => ({ ...prevVal, practiceId: { value: event.target.value, error: '' } }));
                      getAddress(event.target.value);
                    }}
                    errorMessage={props.addEditModalStates.practiceId.error}
                    locked={props.addEditModal.data?._id ? true : false}
                  >
                    <option value=''>Select Clinic</option>
                    {props.nextgenPracticesLoading ? (
                      <option value=''>Loading</option>
                    ) : (
                      props.nextgenPractices?.data.items.map(item => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))
                    )}
                  </SelectBox>
                )}
              </Fragment>
            )}
          </InputsSection>

          {props.addEditModalStates.clinicType.value === 'standalone' ? (
            <Fragment>
              <InputsSection>
                <InputBox
                  label='Contact Name'
                  placeholder='eg, John Smith'
                  value={props.addEditModalStates.contactName.value}
                  onChange={event => {
                    props.setAddEditModalStates(prevVal => ({ ...prevVal, contactName: { value: event.target.value, error: '' } }));
                  }}
                  errorMessage={props.addEditModalStates.contactName.error}
                  // locked={props.addEditModal.data?._id ? true : false}
                />
                <InputBox
                  label='Contact Email'
                  placeholder='eg, johnclinic@example.com'
                  value={props.addEditModalStates.email.value}
                  onChange={event => {
                    props.setAddEditModalStates(prevVal => ({ ...prevVal, email: { value: event.target.value, error: '' } }));
                  }}
                  errorMessage={props.addEditModalStates.email.error}
                  locked={props.addEditModal.data?._id ? true : false}
                />
                <InputBox
                  label='Contact Phone'
                  placeholder='eg, 123-456-789'
                  value={props.addEditModalStates.phone.value}
                  onChange={event => {
                    props.setAddEditModalStates(prevVal => ({ ...prevVal, phone: { value: inputPhoneMasking(event.target.value), error: '' } }));
                  }}
                  errorMessage={props.addEditModalStates.phone.error}
                  locked={props.addEditModal.data?._id ? true : false}
                />
              </InputsSection>
            </Fragment>
          ) : (
            <InputsSection>
              <InputBox
                label='Contact Name'
                placeholder='eg, John Smith'
                value={props.addEditModalStates.contactName.value}
                onChange={event => {
                  props.setAddEditModalStates(prevVal => ({ ...prevVal, contactName: { value: event.target.value, error: '' } }));
                }}
                errorMessage={props.addEditModalStates.contactName.error}
                locked={props.addEditModal.data?._id ? true : false}
              />
              <InputBox
                label='Contact Email'
                placeholder='eg, johnclinic@example.com'
                value={props.addEditModalStates.email.value}
                onChange={event => {
                  props.setAddEditModalStates(prevVal => ({ ...prevVal, email: { value: event.target.value, error: '' } }));
                }}
                errorMessage={props.addEditModalStates.email.error}
                locked={props.addEditModal.data?._id ? true : false}
              />
              <InputBox
                label='Contact Phone'
                placeholder='eg, 123-456-789'
                value={props.addEditModalStates.clinicType.value === 'ehr' && !props.addEditModal.data?._id ? phone : props.addEditModalStates.phone.value}
                onChange={() => {}}
                errorMessage=''
                locked={true}
              />
            </InputsSection>
          )}

          {props.addEditModalStates.clinicType.value === 'standalone' && (
            <InputsSection>
              <InputBox
                label='Latitude'
                placeholder='Latitude'
                value={props.addEditModalStates.latitude.value}
                onChange={event => {
                  props.setAddEditModalStates(prevVal => ({ ...prevVal, latitude: { value: event.target.value, error: '' } }));
                }}
                errorMessage={props.addEditModalStates.latitude.error}
              />
              <InputBox
                label='Longitude'
                placeholder='Longitude'
                value={props.addEditModalStates.longitude.value}
                onChange={event => {
                  props.setAddEditModalStates(prevVal => ({ ...prevVal, longitude: { value: event.target.value, error: '' } }));
                }}
              />
              <InputBox
                label='Distance (in meter)'
                placeholder='Distance'
                value={props.addEditModalStates.distance.value.toString()}
                onChange={event => {
                  props.setAddEditModalStates(prevVal => ({ ...prevVal, distance: { value: Number(returnOnlyNumber(event.target.value)), error: '' } }));
                }}
              />
            </InputsSection>
          )}

          <InputsSection>
            <TextArea
              label='Address'
              placeholder='Address'
              value={props.addEditModalStates.address.value}
              onChange={event => {
                props.setAddEditModalStates(prevVal => ({ ...prevVal, address: { value: event.target.value, error: '' } }));
              }}
              errorMessage={props.addEditModalStates.address.error}
              style={{ width: '79rem' }}
            />
          </InputsSection>

          {/* <InputsSection>
            <InputBox
              label='Address Line 1'
              placeholder='Address Line 1'
              value={
                props.addEditModalStates.clinicType.value === 'ehr' && !props.addEditModal.data?._id ? addressLine1 : props.addEditModalStates.address.addressLine1.value
              }
              onChange={event => {
                props.setAddEditModalStates(prevVal => ({ ...prevVal, address: { ...prevVal.address, addressLine1: { value: event.target.value, error: '' } } }));
              }}
              errorMessage={props.addEditModalStates.address.addressLine1.error}
              locked={props.addEditModalStates.clinicType.value === 'ehr'}
            />
            <InputBox
              label='Address Line 2'
              placeholder='Address Line 2'
              value={
                props.addEditModalStates.clinicType.value === 'ehr' && !props.addEditModal.data?._id ? addressLine2 : props.addEditModalStates.address.addressLine2.value
              }
              onChange={event => {
                props.setAddEditModalStates(prevVal => ({ ...prevVal, address: { ...prevVal.address, addressLine2: { value: event.target.value, error: '' } } }));
              }}
              errorMessage={props.addEditModalStates.address.addressLine2.error}
              locked={props.addEditModalStates.clinicType.value === 'ehr'}
            />
            <InputBox
              label='City'
              placeholder='City'
              value={props.addEditModalStates.clinicType.value === 'ehr' && !props.addEditModal.data?._id ? city : props.addEditModalStates.address.city.value}
              onChange={event => {
                props.setAddEditModalStates(prevVal => ({ ...prevVal, address: { ...prevVal.address, city: { value: event.target.value, error: '' } } }));
              }}
              errorMessage={props.addEditModalStates.address.city.error}
              locked={props.addEditModalStates.clinicType.value === 'ehr'}
            />
          </InputsSection>

          <InputsSection>
            {props.addEditModalStates.address.country.value &&
              countryInfo.data &&
              countryInfo.data.filter(country => country.iso3 === props.addEditModalStates.address.country.value)[0] &&
              countryInfo.data.filter(country => country.iso3 === props.addEditModalStates.address.country.value)[0].states.length > 0 && (
                <SelectBox
                  label='Select State'
                  inputContainerStyle={{ width: '25rem' }}
                  value={props.addEditModalStates.clinicType.value === 'ehr' && !props.addEditModal.data?._id ? state : props.addEditModalStates.address.state.value}
                  onChange={event => {
                    props.setAddEditModalStates(prevVal => ({ ...prevVal, address: { ...prevVal.address, state: { value: event.target.value, error: '' } } }));
                  }}
                  errorMessage={props.addEditModalStates.address.state.error}
                  locked={props.addEditModalStates.clinicType.value === 'ehr'}
                >
                  <option value='' disabled>
                    Select state
                  </option>
                  {countryInfoLoading ? (
                    <option value=''>Loading</option>
                  ) : (
                    countryInfo.data &&
                    countryInfo.data
                      .filter(country => country.iso3 === props.addEditModalStates.address.country.value)[0]
                      .states.map(state => (
                        <option key={state.state_code} value={state.state_code}>
                          {state.name}
                        </option>
                      ))
                  )}
                </SelectBox>
              )}

            <SelectBox
              label='Select Country'
              inputContainerStyle={{ width: '25rem' }}
              value={props.addEditModalStates.clinicType.value === 'ehr' && !props.addEditModal.data?._id ? country : props.addEditModalStates.address.country.value}
              onChange={event => {
                props.setAddEditModalStates(prevVal => ({ ...prevVal, address: { ...prevVal.address, country: { value: event.target.value, error: '' } } }));
              }}
              errorMessage={props.addEditModalStates.address.country.error}
              locked={props.addEditModalStates.clinicType.value === 'ehr'}
            >
              <option value='' disabled>
                Select Country
              </option>
              {countryInfoLoading ? (
                <option value=''>Loading</option>
              ) : (
                countryInfo.data &&
                countryInfo.data.map(coun => (
                  <option key={coun.iso2} value={coun.iso3}>
                    {coun.name}
                  </option>
                ))
              )}
            </SelectBox>
            <InputBox
              label='Zip'
              placeholder='Zip'
              value={props.addEditModalStates.clinicType.value === 'ehr' && !props.addEditModal.data?._id ? zip : props.addEditModalStates.address.zip.value}
              onChange={event => {
                props.setAddEditModalStates(prevVal => ({
                  ...prevVal,
                  address: { ...prevVal.address, zip: { value: returnOnlyNumber(event.target.value), error: '' } },
                }));
              }}
              errorMessage={props.addEditModalStates.address.zip.error}
              locked={props.addEditModalStates.clinicType.value === 'ehr'}
            />
          </InputsSection> */}

          <InputsSection>
            <div style={{ display: 'flex', gap: '1rem', flexDirection: 'column' }}>
              <p className={TextBox.form_input__label}>Allowed Modules</p>
              <div className='flex' style={{ gap: '2rem' }}>
                <ToggleSwitchWithLabel
                  label='Check-in module'
                  id='clinic-check-in'
                  checked={props.addEditModalStates.modules.value.checkIn}
                  onChange={() =>
                    props.setAddEditModalStates(prevVal => ({
                      ...prevVal,
                      modules: { value: { ...prevVal.modules.value, checkIn: !prevVal.modules.value.checkIn }, error: '' },
                    }))
                  }
                />
                <ToggleSwitchWithLabel
                  label='File Exchange'
                  id='clinic-file-sharing'
                  checked={props.addEditModalStates.modules.value.fileSharing}
                  onChange={() =>
                    props.setAddEditModalStates(prevVal => ({
                      ...prevVal,
                      modules: { value: { ...prevVal.modules.value, fileSharing: !prevVal.modules.value.fileSharing }, error: '' },
                    }))
                  }
                />
                <ToggleSwitchWithLabel
                  label='Questionnaire module'
                  id='questionnaire-module'
                  checked={props.addEditModalStates.modules.value.questionnaire}
                  onChange={() =>
                    props.setAddEditModalStates(prevVal => ({
                      ...prevVal,
                      modules: { value: { ...prevVal.modules.value, questionnaire: !prevVal.modules.value.questionnaire }, error: '' },
                    }))
                  }
                />
              </div>
              {props.addEditModalStates.modules.error && <p className={TextBox.form_input__error}>{props.addEditModalStates.modules.error}</p>}
            </div>
          </InputsSection>

          <ModalButtons
            submitButtonLabel={props.addEditModal.data && props.addEditModal.data._id ? 'Update Clinic' : 'Create Clinic'}
            cancelButtonLabel='Cancel'
            onCancel={props.onCloseModal}
          />
        </ModalForm>
      )}
    </Modal>
  );
};

export default AddEditModal;
