import toast from 'react-hot-toast';
import useApi from '../useApi';
import { ICancelAppointmentReq, ICancelAppointmentRes } from './index.type';

const useCancelAppointment = ({ appointmentId }: { appointmentId?: string }) => {
  const utcTimeOffset = new Date().getTimezoneOffset();

  const { loading: cancelAppointmentLoading, makeApiCall } = useApi({
    url: `/appointment/cancel-appointment?offset=${utcTimeOffset}&appointmentId=${appointmentId}`,
    method: 'PATCH',
  });

  const cancelAppointment = async (formData: ICancelAppointmentReq) => {
    const { response, error } = await makeApiCall<ICancelAppointmentReq, ICancelAppointmentRes>(formData);
    if (response?.status) {
      toast.success(response.message);
      return response;
    }

    if (error || !response?.status) {
      toast.error('Something went wrong. Please try again later');
      return { status: false };
    }
  };

  return {
    cancelAppointmentLoading,
    cancelAppointment,
  };
};

export default useCancelAppointment;
