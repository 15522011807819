import toast from 'react-hot-toast';
import useApi from '../useApi';

const useDeleteFeedback = ({ feedbackId }: { feedbackId?: string }) => {
  const { loading: deleteFeedbackLoading, makeApiCall } = useApi({
    url: `/feedback/${feedbackId}`,
    method: 'DELETE',
  });

  const deleteFeedback = async () => {
    const { error } = await makeApiCall<undefined, undefined>();

    if (error) {
      toast.error('Something went wrong whiling deleting feedback. Please try again later');
      return { status: false };
    } else {
      toast.error('Feedback deleted successfully.');
    }
  };

  return {
    deleteFeedbackLoading,
    deleteFeedback,
  };
};

export default useDeleteFeedback;
