import type { FC, PropsWithChildren } from 'react';

type InputsSectionProps = PropsWithChildren<{
  style?: React.CSSProperties;
}>;
const InputsSection: FC<InputsSectionProps> = props => {
  return (
    <div className='modalContainer__form--section-column' style={props.style}>
      {props.children}
    </div>
  );
};

export default InputsSection;
