import React from 'react';
import { Chart } from 'react-chartjs-2';
import { Modal } from '../../../components/Modal';
import ModalForm from '../../../components/Modal/ModalForm';
import InputsSection from '../../../components/Modal/InputsSection';
import ModalButtons from '../../../components/Modal/ModalButtons';
import SelectBox from '../../../components/Modal/SelectBox';
import classes from '../../../styles/HomeScreen.module.css';

function createGradient(ctx, area) {
  const colorStart = 'rgba(1, 196, 255, 0.8)';
  const colorMid = 'rgba(0, 113, 174, 0.8)';
  const colorEnd = 'rgba(0, 113, 174, .8';

  // const gradient = ctx.createLinearGradient(0, area.bottom, 0, area.top);
  const gradient = ctx.createLinearGradient(area.left, 0, area.right, 0);

  gradient.addColorStop(0, colorStart);
  gradient.addColorStop(0.5, colorMid);
  gradient.addColorStop(1, colorEnd);

  return gradient;
}

const PatientDetailModal = ({ data: modalData }) => {
  const { selectedDataModal, handleModalClose } = modalData;
  const [chartDataType, setChartDataType] = React.useState('monthly');
  const [chartMonth, setChartMonth] = React.useState('11');

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        beginAtZero: true,
        display: true,
        stacked: true,
        grid: {
          drawOnChartArea: false,
        },
        ticks: {
          font: {
            size: 10,
          },
        },
      },
      y: {
        display: true,
        stacked: true,
        grid: {
          drawOnChartArea: false,
        },
      },
    },
    plugins: {
      legend: {
        display: chartDataType === 'monthly' ? false : true,
        position: 'top',
      },
      title: {
        display: false,
        text: '',
      },
    },
  };

  const [data, setData] = React.useState({
    labels: [],
    datasets: [],
  });

  const chartRef = React.useRef(null);
  const [chartData, setChartData] = React.useState({
    datasets: [],
  });

  React.useEffect(() => {
    if (chartDataType === 'monthly' && selectedDataModal.data.monthly.data) {
      const newData = {
        labels: selectedDataModal.data.monthly.labels,
        datasets: [
          {
            type: 'bar',
            fill: true,
            label: selectedDataModal.data.monthly.label,
            data: selectedDataModal.data.monthly.data,
            borderColor: 'rgba(230, 111, 0, 1)',
            borderWidth: 0,
            borderRadius: 8,
          },
        ],
      };

      setData(newData);
    }

    if (chartDataType === 'yearly' && selectedDataModal.data.yearly.data) {
      const newData = {
        labels: selectedDataModal.data.yearly.labels,
        datasets: [
          {
            type: 'bar',
            fill: true,
            label: selectedDataModal.data.yearly.label,
            data: selectedDataModal.data.yearly.data,
            borderColor: 'rgba(230, 111, 0, 1)',
            borderWidth: 0,
            borderRadius: 8,
          },
        ],
      };

      setData(newData);
    }

    if (chartDataType === 'yearly' && selectedDataModal.data.yearly.data && selectedDataModal.data.yearly.data2) {
      const newData = {
        labels: selectedDataModal.data.yearly.labels,
        datasets: [
          {
            type: 'line',
            fill: false,
            label: selectedDataModal.data.yearly.data2Label,
            data: selectedDataModal.data.yearly.data2,
            borderColor: 'rgba(230, 111, 0, 1)',
            borderWidth: 1,
            tension: 0.5,
            pointBackgroundColor: 'rgba(230, 111, 0, 0.5)',
          },
          {
            type: 'bar',
            fill: true,
            label: selectedDataModal.data.yearly.label,
            data: selectedDataModal.data.yearly.data,
            borderColor: 'rgba(230, 111, 0, 1)',
            borderWidth: 0,
            borderRadius: 8,
            // backgroundColor: 'rgb(75, 192, 192)'
          },
        ],
      };

      setData(newData);
    }

    if (chartDataType === 'yearly' && selectedDataModal.data.yearly.dataSet) {
      const newData = {
        labels: selectedDataModal.data.yearly.labels,
        datasets: selectedDataModal.data.yearly.dataSet,
      };

      setData(newData);
    }
  }, [selectedDataModal, chartDataType]);

  React.useEffect(() => {
    const chart = chartRef.current;

    if (!chart) {
      return;
    }

    const chartData = {
      ...data,
      datasets: data.datasets.map((dataset, index) =>
        index === 0
          ? {
              ...dataset,
              backgroundColor: createGradient(chart.ctx, chart.chartArea),
            }
          : {
              ...dataset,
            }
      ),
    };

    setChartData(chartData);
  }, [data]);

  return (
    <Modal show={selectedDataModal.status}>
      <div className='modal__heading--container'>
        <h2 className='modal__heading2'>{selectedDataModal.title}</h2>
        <div className={classes.modal_heading_inputs}>
          <div className={classes.modal_heading_input_btns}>
            <button
              className={chartDataType === 'monthly' ? classes.modal_heading_input_btn + ' ' + classes.modal_heading_input_btn_active : classes.modal_heading_input_btn}
              onClick={() => setChartDataType('monthly')}
            >
              Monthly
            </button>
            <button
              className={chartDataType === 'yearly' ? classes.modal_heading_input_btn + ' ' + classes.modal_heading_input_btn_active : classes.modal_heading_input_btn}
              onClick={() => setChartDataType('yearly')}
            >
              Yearly
            </button>
          </div>

          <div className={classes.modal_heading_input_selects}>
            {chartDataType === 'monthly' ? (
              <SelectBox
                containerStyle={{ width: '8rem' }}
                inputContainerStyle={{ minWidth: '8rem', padding: '1rem' }}
                value={chartMonth}
                onChange={e => setChartMonth(e.target.value)}
              >
                <option value='1'>Jan</option>
                <option value='2'>Feb</option>
                <option value='3'>Mar</option>
                <option value='4'>Apr</option>
                <option value='5'>May</option>
                <option value='6'>Jun</option>
                <option value='7'>Jul</option>
                <option value='8'>Aug</option>
                <option value='9'>Sept</option>
                <option value='10'>Oct</option>
                <option value='11'>Nov</option>
                <option value='12'>Dec</option>
              </SelectBox>
            ) : (
              <SelectBox containerStyle={{ width: '8rem' }} inputContainerStyle={{ minWidth: '8rem', padding: '1rem' }}>
                <option>2022</option>
                <option>2021</option>
                <option>2020</option>
                <option>2019</option>
              </SelectBox>
            )}
          </div>
        </div>
      </div>

      <ModalForm style={{ marginBottom: '2.5rem' }}>
        {chartDataType === 'monthly' && (
          <InputsSection>
            <p className={classes.modal_total}>
              {selectedDataModal.data.monthly.totalAssessmentsTtile ? selectedDataModal.data.monthly.totalAssessmentsTtile : 'Total Assessments this month'}{' '}
              <span className={classes.grad_color}>{selectedDataModal.data.monthly.totalAssessmentsThisMonth}</span>
            </p>
          </InputsSection>
        )}
        <InputsSection
          style={{
            width: '50vw',
            height: chartDataType === 'monthly' ? '30vh' : 'calc(30vh + 87px + 2.5rem)',
          }}
        >
          <Chart ref={chartRef} type='bar' data={chartData} options={options} />
        </InputsSection>
        <ModalButtons cancelButtonLabel='Close' onCancel={handleModalClose} />
      </ModalForm>
    </Modal>
  );
};

export default PatientDetailModal;
