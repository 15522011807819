import React from 'react';
import SelectBox from '../../../components/Modal/SelectBox';
import classes from '../../../styles/HomeScreen.module.css';
import { MONTHS } from '../../../utils/common';

const Chart3 = ({ data, chartMonth, setChartMonth }) => {
  return (
    <div className={classes.chart3}>
      <div className={classes.modal_heading_inputs} style={{ width: '100%', justifyContent: 'space-between' }}>
        <p className={classes.chart4_heading}>
          Top Redflags in {MONTHS[parseInt(chartMonth) - 1]} <span>2022</span>
        </p>
        <div className={classes.modal_heading_input_selects}>
          <SelectBox
            containerStyle={{ width: '8rem' }}
            inputContainerStyle={{
              minWidth: '8rem',
              padding: '.5rem',
              boxShadow: 'none',
              backgroundColor: 'var(--light-grey)',
            }}
            value={chartMonth}
            onChange={e => setChartMonth(e.target.value)}
          >
            <option value='1'>Jan</option>
            <option value='2'>Feb</option>
            <option value='3'>Mar</option>
            <option value='4'>Apr</option>
            <option value='5'>May</option>
            <option value='6'>Jun</option>
            <option value='7'>Jul</option>
            <option value='8'>Aug</option>
            <option value='9'>Sept</option>
            <option value='10'>Oct</option>
            <option value='11'>Nov</option>
            <option value='12'>Dec</option>
          </SelectBox>
        </div>
      </div>
      {data &&
        data.data &&
        data.data.slice(0, 4).map((item, index) => (
          <div key={`chart3${index}`} className={classes.chart3_chart}>
            <p className={classes.chart3_chart_text}>{item.label}</p>
            <div className={classes.chart3_chart_bar}>
              <div style={{ width: `${(item.count * 100) / data.total}%` }} />
              <p>{item.count}</p>
            </div>
          </div>
        ))}
    </div>
  );
};

export default Chart3;
