import { Fragment, type FC, type PropsWithChildren } from 'react';
import AdCampaignsStyles from '../../styles/AdCampaigns.module.css';

import { Modal, ModalForm, ModalButtons } from '../../components/Modal';
import ModalHeading from '../../components/Modal/ModalHeading';
import { IAd } from '../../hooks/advertisements/index.type';

export type ViewRulesModalState = PropsWithChildren<{
  status: boolean;
  data?: IAd;
}>;

type ViewRulesModalProps = PropsWithChildren<{
  viewRilesModal: ViewRulesModalState;
  onCloseModal?: () => void;
}>;

const ViewRulesModal: FC<ViewRulesModalProps> = props => {
  return (
    <Modal show={props.viewRilesModal.status}>
      <ModalHeading heading={props.viewRilesModal.data?.advertise.title} />
      <ModalForm
        style={{
          marginBottom: '2.5rem',
          maxWidth: window.innerWidth <= 426 ? 'none' : '38vw',
          maxHeight: '75vh',
          overflow: 'auto',
        }}
      >
        <div className={AdCampaignsStyles.model_container}>
          {props.viewRilesModal.data?.advertise.ruleList.map((item, index) => (
            <div className={AdCampaignsStyles.model_card_container} key={index}>
              <div className={AdCampaignsStyles.model_card_upper_content}>
                {item.adsList.length > 0 &&
                  item.adsList.map((ad, adIndex) => (
                    <div className={AdCampaignsStyles.upper_content_name} key={adIndex}>
                      <p>{ad.label}</p>
                      {Number(ad.priority) < 100 && <h3>({ad.priority.toFixed(2)}%)</h3>}
                    </div>
                  ))}
              </div>
              <hr />
              <div className={AdCampaignsStyles.model_card_lower_content}>
                {item.rules.length > 0 &&
                  item.rules.map((rule, ind) => (
                    <Fragment>
                      <div className={AdCampaignsStyles.lower_content_chips} key={rule.field + ind}>
                        <div className={AdCampaignsStyles.lower_content_chip1}>{item.rules[ind].field}</div>
                        {rule.operator != null && <div className={AdCampaignsStyles.lower_content_chip2}>{rule.operator === 'Contains' ? 'equals' : rule.operator}</div>}
                      </div>

                      <div className={AdCampaignsStyles.wide_chips_container}>
                        {Array.isArray(rule.value) ? (
                          rule.value.map(ruleItem => (
                            <div className={AdCampaignsStyles.wide_chip} key={ruleItem.value}>
                              {ruleItem.label}
                            </div>
                          ))
                        ) : (
                          <div className={AdCampaignsStyles.wide_chip}>{rule.value}</div>
                        )}
                        {/* {Object.keys(rule.value).map((key, valueIndex) => (
                          <div className={AdCampaignsStyles.wide_chip} key={valueIndex}>
                            {item.rules[0].value[key].label}
                          </div>
                        ))} */}
                      </div>
                    </Fragment>
                  ))}
              </div>
            </div>
          ))}
        </div>
        <div style={{ marginRight: '2rem' }}>
          <ModalButtons cancelButtonLabel='Cancel' onCancel={props.onCloseModal} />
        </div>
      </ModalForm>
    </Modal>
  );
};

export default ViewRulesModal;
