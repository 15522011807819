import toast from 'react-hot-toast';
import useApi from '../useApi';
import { IAddProviderReq, IAddProviderRes } from './index.type';

const useAddProvider = ({ providerId }: { providerId?: string }) => {
  const url = providerId ? `/doctors/${providerId}` : '/doctors';
  const method = providerId ? 'PATCH' : 'POST';

  const { loading: addProviderLoading, makeApiCall } = useApi({ url, method });

  const addProvider = async (formData: IAddProviderReq) => {
    const { response, error } = await makeApiCall<IAddProviderReq, IAddProviderRes>(formData);
    if (response?.status) {
      return response;
    }

    if (error || !response?.status) {
      toast.error('Something went wrong. Please try again later');
      return { status: false };
    }
  };

  return {
    addProviderLoading,
    addProvider,
  };
};

export default useAddProvider;
