import toast from 'react-hot-toast';
import useApi from '../useApi';

const useDeleteFolder = ({ folderId }: { folderId?: string }) => {
  const { loading: deleteFolderLoading, makeApiCall } = useApi({
    url: `/folders/${folderId}`,
    method: 'DELETE',
  });

  const deleteFolder = async () => {
    const { error } = await makeApiCall<undefined, undefined>();

    if (error) {
      toast.error('Something went wrong whiling deleting folder. Please try again later');
      return { status: false };
    } else {
      toast.error('Folder deleted successfully.');
    }
  };

  return {
    deleteFolder,
    deleteFolderLoading,
  };
};

export default useDeleteFolder;
