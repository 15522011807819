import React, { useRef, useState } from 'react';
import { Chart } from 'react-chartjs-2';
import SelectBox from '../../../components/Modal/SelectBox';
import classes from '../../../styles/HomeScreen.module.css';
import { MONTHS } from '../../../utils/common';

const options = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      beginAtZero: true,
      grid: {
        drawOnChartArea: false,
      },
      ticks: {
        font: {
          size: 9,
        },
      },
    },
    y: {
      display: false,
    },
  },
  plugins: {
    legend: {
      display: false,
      position: 'top',
      // labels: false,
      // labels: {
      //   font: {
      //     size: 10,
      //     family: 'inherit',
      //   },
      // }
    },
    title: {
      display: false,
      text: 'Chart.js Line Chart',
    },
  },
};

function createGradient(ctx, area) {
  const colorStart = 'rgba(1, 196, 255, 0.5)';
  const colorMid = 'rgba(0, 113, 174, 0.5)';
  const colorEnd = 'rgba(0, 113, 174, .5';

  // const gradient = ctx.createLinearGradient(0, area.bottom, 0, area.top);
  const gradient = ctx.createLinearGradient(area.left, 0, area.right, 0);

  gradient.addColorStop(0, colorStart);
  gradient.addColorStop(0.5, colorMid);
  gradient.addColorStop(1, colorEnd);

  return gradient;
}
const Chart2 = ({ data, chartMonth, setChartMonth }) => {
  const chartRef = useRef(null);
  const [chartData, setChartData] = useState({
    datasets: [],
  });

  React.useEffect(() => {
    const chart = chartRef.current;

    if (!chart) {
      return;
    }
    if (data && data.data) {
      const chartData = {
        labels: data.data.map(item => item.label),
        datasets: [
          {
            fill: false,
            label: 'Side Effects',
            data: data.data.map(item => item.count),
            // borderColor: 'rgba(53, 162, 235,0)',
            borderWidth: 2,
            tension: 0.5,
            pointBackgroundColor: '#FF7B01',
            pointBorderColor: '#FF7B01',
            backgroundColor: createGradient(chart.ctx, chart.chartArea),
          },
        ],
      };

      setChartData(chartData);
    }
  }, [data]);

  return (
    <div className={classes.chart2}>
      <div className={classes.modal_heading_inputs}>
        <p className={classes.chart4_heading}>
          Top Side effects in {MONTHS[parseInt(chartMonth) - 1]} <span>2022</span>
        </p>
        <div className={classes.modal_heading_input_selects}>
          <SelectBox
            containerStyle={{ width: '8rem' }}
            inputContainerStyle={{
              minWidth: '8rem',
              padding: '.5rem',
              boxShadow: 'none',
              backgroundColor: 'var(--light-grey)',
            }}
            value={chartMonth}
            onChange={e => setChartMonth(e.target.value)}
          >
            <option value='1'>Jan</option>
            <option value='2'>Feb</option>
            <option value='3'>Mar</option>
            <option value='4'>Apr</option>
            <option value='5'>May</option>
            <option value='6'>Jun</option>
            <option value='7'>Jul</option>
            <option value='8'>Aug</option>
            <option value='9'>Sept</option>
            <option value='10'>Oct</option>
            <option value='11'>Nov</option>
            <option value='12'>Dec</option>
          </SelectBox>
        </div>
      </div>
      <div className={classes.chart4_data}>
        <p className={classes.chart4_data_number + ' ' + classes.grad_color}>
          {/* {apiData.count} */}
          {data && data.total ? data.total : 0} <span className={classes.chart4_active}>total</span>
        </p>
      </div>
      <div style={{ height: '80px' }}>
        <Chart ref={chartRef} type='line' data={chartData} options={options} />
      </div>
    </div>
  );
};

export default Chart2;
