import type { FC, PropsWithChildren } from 'react';
import { InputBox, InputsSection, InputsSectionColumn, ModalButtons, TextArea } from '../../../components/Modal';

export type Istep1States = PropsWithChildren<{
  title: { value: string; error: string };
  subTitle: { value: string; error: string };
  description: { value: string; error: string };
}>;

type Step1Props = PropsWithChildren<{
  loading: boolean;
  step1StatesStates: Istep1States;
  setStep1StatesStates: React.Dispatch<React.SetStateAction<Istep1States>>;
  onCancel?: React.MouseEventHandler<HTMLButtonElement>;
}>;

const Step1: FC<Step1Props> = props => {
  return (
    <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', overflow: 'auto', paddingRight: '1rem' }}>
      <div>
        <InputsSection>
          <InputBox
            icon='file-text'
            label='Campaign Title'
            placeholder='Your Campaign Title'
            value={props.step1StatesStates.title.value}
            onChange={e => props.setStep1StatesStates(prevVal => ({ ...prevVal, title: { value: e.target.value, error: '' } }))}
            errorMessage={props.step1StatesStates.title.error}
          />
          <InputBox
            icon='file-text'
            label='Campaign Subtitle*'
            placeholder='Your Campaign Subtitle'
            value={props.step1StatesStates.subTitle.value}
            onChange={e => props.setStep1StatesStates(prevVal => ({ ...prevVal, subTitle: { value: e.target.value, error: '' } }))}
            errorMessage={props.step1StatesStates.subTitle.error}
          />
        </InputsSection>
        <InputsSectionColumn>
          <TextArea
            label='Description*'
            placeholder='Write few lines about your ad'
            value={props.step1StatesStates.description.value}
            onChange={e => props.setStep1StatesStates(prevVal => ({ ...prevVal, description: { value: e.target.value, error: '' } }))}
            errorMessage={props.step1StatesStates.description.error}
          />
        </InputsSectionColumn>
      </div>
      <ModalButtons submitButtonLabel='Next' cancelButtonLabel='Cancel' loading={props.loading} onCancel={props.onCancel} style={{ paddingBottom: '1rem' }} />
    </div>
  );
};

export default Step1;
