import type { FC, PropsWithChildren } from 'react';
import FileExchangeStyles from '../../styles/FileExchangeScreen.module.css';

type AddFolderProps = PropsWithChildren<{
  autoFocus?: boolean;
  value: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClickYes?: React.MouseEventHandler<HTMLButtonElement>;
  onClickNo?: React.MouseEventHandler<HTMLButtonElement>;
}>;

const AddFolder: FC<AddFolderProps> = props => {
  return (
    <div className={FileExchangeStyles.addFolderInput}>
      <svg className={FileExchangeStyles.addFolderInputIcon}>
        <use xlinkHref={`/assets/sprite.svg#icon-folder`} />
      </svg>
      <input
        type='text'
        placeholder='Folder Name'
        autoFocus={props.autoFocus}
        value={props.value}
        onChange={props.onChange}
        className={FileExchangeStyles.addFolderInputBox}
      />
      <button className={FileExchangeStyles.addFolderInputSubmitBtn} onClick={props.onClickYes}>
        <svg className={FileExchangeStyles.addFolderInputSubmitBtnIcon}>
          <use xlinkHref={`/assets/sprite.svg#icon-check`} />
        </svg>
      </button>
      <button className={FileExchangeStyles.addFolderInputCloseBtn} onClick={props.onClickNo}>
        <svg className={FileExchangeStyles.addFolderInputCloseBtnIcon}>
          <use xlinkHref={`/assets/sprite.svg#icon-clear`} />
        </svg>
      </button>
    </div>
  );
};

export default AddFolder;
