import React from 'react';
import classes from './HomeScreen.module.css';

const Chart1 = ({ data, setSelectedDataModal }) => {
  const months = [
    { value: 'Jan', count: 1 },
    { value: 'Feb', count: 1 },
    { value: 'Mar', count: 1 },
    { value: 'Apr', count: 1 },
    { value: 'May', count: 1 },
    { value: 'June', count: 1 },
    { value: 'Jul', count: 1 },
    { value: 'Aug', count: 1 },
    { value: 'Sep', count: 1 },
    { value: 'Oct', count: 1 },
    { value: 'Nov', count: 1 },
    { value: 'Dec', count: 1 },
  ];

  let totalCount = data.count ? data.count : 100;
  data.data &&
    data.data.length > 0 &&
    data.data.forEach((d) => {
      months[new Date(d.date_created).getMonth()].count = d.count;
    });

  return (
    <div className={classes.chart1}>
      <div className={classes.chart1_left}>
        <p className={classes.chart4_heading}>
          Total Patients in <span>2022</span>
        </p>
        <div className={classes.chart4_data}>
          <p className={classes.chart4_data_number + ' ' + classes.grad_color}>
            {data.count ? data.count : '--'}
          </p>
          <p className={classes.chart4_active + ' ' + classes.grad_color}>
            {data.count ? data.count - 7540 + ' active' : '--'}
          </p>
        </div>

        <div className={classes.chart4_chart}>
          {/* {data.data &&
          data.data.length > 0 &&
          data.data.map((month, indx) => (
            <div key={indx} className={classes.chart4_chart_bar}>
              <div style={{ height: `${month.count / 50}%` }}></div>
              <p>{months[new Date(month.date_created).getMonth()]}</p>
            </div>
          ))} */}
          {months.map((month, indx) => {
            const height = (month.count * 250) / totalCount;
            return (
              <div key={indx} className={classes.chart4_chart_bar}>
                <div style={{ height: `${height}%` }}></div>
                {/* <p>{months[new Date(month.date_created).getMonth()]}</p> */}
                <p>{month.value}</p>
              </div>
            );
          })}
        </div>
      </div>

      <div className={classes.chart1_right}>
        <div className={classes.chart1_data}>
          <p className={classes.chart1_data_value}>
            {/* {data && data.conceptData && data.conceptData.data
              ? data.conceptData.data
              : '...'} */}
            6664
          </p>
          <p>
            {/* {data && data.conceptData && data.conceptData.title
              ? data.conceptData.title
              : '...'} */}
            Total Inpatients
          </p>
          <div className={classes.chart1_data_chart}>
            <div style={{ height: `${(807 * 200) / 6664}%` }}></div>
            <div style={{ height: `${(876 * 200) / 6664}%` }}></div>
            <div style={{ height: `${(1124 * 200) / 6664}%` }}></div>
            <div style={{ height: `${(695 * 200) / 6664}%` }}></div>
            <div style={{ height: `${(841 * 200) / 6664}%` }}></div>
            <div style={{ height: `${(407 * 200) / 6664}%` }}></div>
            <div style={{ height: `${(718 * 200) / 6664}%` }}></div>
            <div style={{ height: `${(842 * 200) / 6664}%` }}></div>
            <div style={{ height: `${(354 * 200) / 6664}%` }}></div>
            <div style={{ height: `${6664 / 6664}%` }}></div>
            <div style={{ height: `${6664 / 6664}%` }}></div>
            <div style={{ height: `${6664 / 6664}%` }}></div>
          </div>
          <button
            onClick={() => {
              setSelectedDataModal({
                status: true,
                title: 'Total Inpatients Details',
                data: {
                  label: 'Inpatients',
                  data: [807, 876, 1124, 695, 841, 407, 718, 842, 354],
                },
              });
            }}
          >
            Details
          </button>
        </div>

        <div className={classes.chart1_data}>
          <p className={classes.chart1_data_value}>
            {/* {data && data.drugData && data.drugData.data
              ? data.drugData.data
              : '...'} */}
            9709
          </p>
          <p>
            {/* {data && data.drugData && data.drugData.title
              ? data.drugData.title
              : '...'} */}
            Total Outpatients
          </p>
          <div className={classes.chart1_data_chart}>
            <div style={{ height: `${(1200 * 200) / 9709}%` }}></div>
            <div style={{ height: `${(1528 * 200) / 9709}%` }}></div>
            <div style={{ height: `${(1043 * 200) / 9709}%` }}></div>
            <div style={{ height: `${(1254 * 200) / 9709}%` }}></div>
            <div style={{ height: `${(468 * 200) / 9709}%` }}></div>
            <div style={{ height: `${(701 * 200) / 9709}%` }}></div>
            <div style={{ height: `${(1164 * 200) / 9709}%` }}></div>
            <div style={{ height: `${(1400 * 200) / 9709}%` }}></div>
            <div style={{ height: `${(951 * 200) / 9709}%` }}></div>
            <div style={{ height: `${9709 / 9709}%` }}></div>
            <div style={{ height: `${9709 / 9709}%` }}></div>
            <div style={{ height: `${9709 / 9709}%` }}></div>
          </div>
          <button
            onClick={() => {
              setSelectedDataModal({
                status: true,
                title: 'Total Outpatients Details',
                data: {
                  label: 'Outpatients',
                  data: [1200, 1528, 1043, 1254, 468, 701, 1164, 1400, 951],
                },
              });
            }}
          >
            Details
          </button>
        </div>
      </div>
    </div>
  );
};

export default Chart1;
