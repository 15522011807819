import type { FC, PropsWithChildren } from 'react';
import Calendar from 'react-calendar';
import styles from './SearchSelect/SearchSelect.module.css';
import { Value } from 'react-calendar/dist/cjs/shared/types';

type CalendarBoxProps = PropsWithChildren<{
  value: string;
  minDate: Date;
  errorMessage: string;
  onChange?: (value: Value, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}>;

const CalendarBox: FC<CalendarBoxProps> = ({ onChange, value, minDate, errorMessage }) => {
  return (
    <div className={styles.container} style={{ alignItems: 'center' }}>
      <Calendar onChange={onChange} value={value} minDate={minDate} className='react-calendar' />
      <p className='dangerText'>{errorMessage}</p>
    </div>
  );
};

export default CalendarBox;
