import type { FC, PropsWithChildren } from 'react';
import classes from '../../styles/Table.module.css';

export type TableButtonProps = PropsWithChildren<{
  icon: string;
  variant?: 'normal' | 'danger';
  tooltip?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}>;

const TableButton: FC<TableButtonProps> = props => {
  return (
    <button className={props.variant === 'danger' ? classes.table__button_delete : classes.table__button} onClick={props.onClick}>
      <svg className={props.variant === 'danger' ? classes.table__button_delete_icon : classes.table__button_icon}>
        <use xlinkHref={`/assets/sprite.svg#icon-${props.icon}`} />
      </svg>
      {props.tooltip && <p className={classes.table__button_tooltip}>{props.tooltip}</p>}
    </button>
  );
};

export default TableButton;
