import React from 'react';
import { useSelector } from 'react-redux';
import { loginSliceValue } from '../redux/reducers/loginSlice';

import { Content } from '../components';
import SuremedCharts from '../pageComponents/Home/Suremed';
import AllCharts from '../pageComponents/Home/All';

const HomeScreen = () => {
  const { userDetails } = useSelector(loginSliceValue);

  return (
    <Content currentMenu='dashboard' headerTitle='Dashboard' addBtn={false} searchBox={false}>
      {userDetails.data?.user.clinic && userDetails.data?.user.clinic.name?.toLowerCase().startsWith('suremed') ? <SuremedCharts /> : <AllCharts />}
    </Content>
  );
};

export default HomeScreen;
