import React from 'react';
import toast from 'react-hot-toast';
import useApi from '../../useApi';
import { INextgenPracticesRes } from '../index.type';

const useAllNextgenPractices = () => {
  const [nextgenPractices, setNextgenPractices] = React.useState<INextgenPracticesRes>();

  const { loading: nextgenPracticesLoading, makeApiCall } = useApi({
    url: `/nextgen/practices`,
    method: 'GET',
  });

  const getnextgenPractices = async () => {
    const { response, error } = await makeApiCall<undefined, INextgenPracticesRes>();
    if (response?.status === 'success') {
      setNextgenPractices(response);
    }

    if (response?.status === 'fail') {
      setNextgenPractices(undefined);
    }

    if (error) toast.error('Something went wrong while fetching nextgen practices data. Please try again later');
  };

  React.useEffect(() => {
    getnextgenPractices();
    // eslint-disable-next-line
  }, []);

  return {
    nextgenPractices,
    nextgenPracticesLoading,
  };
};

export default useAllNextgenPractices;
