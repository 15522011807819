import toast from 'react-hot-toast';
import useApi from '../useApi';
import { IToggleUserReq, IToggleUserRes } from './index.type';

const useToggleUser = ({ userId }: { userId?: string }) => {
  const { loading: toggleUserLoading, makeApiCall } = useApi({
    url: `/users/${userId}/toggleActive`,
    method: 'PATCH',
  });

  const toggleUser = async (FormData: IToggleUserReq) => {
    const { response, error } = await makeApiCall<IToggleUserReq, IToggleUserRes>(FormData);

    if (response?.status === 'success') {
      toast.success('User status changed successfully.');
      return response;
    }

    if (error) {
      toast.error('Something went wrong whiling changing user status. Please try again later');
      return { status: 'fail' };
    }
  };

  return {
    toggleUserLoading,
    toggleUser,
  };
};

export default useToggleUser;
