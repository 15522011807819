import React from 'react';
import SkeletonStyles from './Skeletons.module.css';
import TextBox from '../components/Modal/InputBox/TextBox.module.css';

const InputBoxSkeleton = ({ style }) => {
  return (
    <div className={TextBox.form_input}>
      <div className={[SkeletonStyles.form_input__label, SkeletonStyles.skeleton].join(' ')} />
      <div className={[SkeletonStyles.form_input__container, SkeletonStyles.skeleton].join(' ')} style={style} />
    </div>
  );
};

export default InputBoxSkeleton;
