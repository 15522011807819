import { Fragment } from 'react';
import type { FC, PropsWithChildren } from 'react';
import { MyContextMenu, MyContextMenuTrigger, MyMenuItem } from '../../components/ContextMenu';
import FolderItem from './FolderItem';

type FolderItemWithMenuProps = PropsWithChildren<{
  id: string;
  selected?: boolean;
  name: string;
  index: number;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  onRename?: () => void;
  onDelete?: () => void;
}>;

const FolderItemWithMenu: FC<FolderItemWithMenuProps> = props => {
  return (
    <Fragment>
      <MyContextMenuTrigger id={props.id}>
        <FolderItem selected={props.selected} onClick={props.onClick} index={props.index} name={props.name} />
      </MyContextMenuTrigger>

      <MyContextMenu id={props.id}>
        <MyMenuItem onClick={props.onRename}>
          <svg className='watchlist'>
            <use xlinkHref={`/assets/sprite.svg#icon-edit-2`} />
          </svg>
          <span>Rename Folder</span>
        </MyMenuItem>
        <MyMenuItem onClick={props.onDelete}>
          <svg className='delete'>
            <use xlinkHref={`/assets/sprite.svg#icon-folder-minus`} />
          </svg>
          <span>Delete Folder</span>
        </MyMenuItem>
      </MyContextMenu>
    </Fragment>
  );
};

export default FolderItemWithMenu;
