import type { FC, PropsWithChildren } from 'react';
import Select, { ActionMeta, SingleValue } from 'react-select';
import styles from './SearchSelect.module.css';

type SearchSelectLockedProps = PropsWithChildren<{
  value: { label: string; value: string };
  errorMessage: string;
}>;

export const SearchSelectLocked: FC<SearchSelectLockedProps> = ({ value, errorMessage }) => {
  return (
    <div className={styles.container}>
      <div className={styles.container_locked}>
        <div className={styles.container_value_box}>
          <p className={styles.container_value}>{value?.label}</p>
        </div>
        <div className={styles.lock_box}>
          <span className={styles.lock_separator}></span>
          <div className={styles.lock_icon_box}>
            <svg className={styles.lock_icon}>
              <use xlinkHref={`/assets/sprite.svg#icon-lock`} />
            </svg>
          </div>
        </div>
      </div>

      <p className='dangerText'>{errorMessage}</p>
    </div>
  );
};

type SearchSelectProps = PropsWithChildren<{
  value: { label: string; value: string };
  errorMessage?: string;
  placeholder: string;
  options: { label: string; value: string }[];
  onChange: (newValue: SingleValue<{ label: string; value: string }>, actionMeta: ActionMeta<{ label: string; value: string }>) => void;
}>;

const SearchSelect: FC<SearchSelectProps> = ({ placeholder = 'Select', value, options, onChange, errorMessage }) => {
  return (
    <div
    // className={styles.container}
    // style={{
    //   display: 'flex',
    //   alignItems: 'flex-start',
    //   justifyContent: 'center',
    // }}
    >
      {/* <div style={{ width: '100%' }}> */}
      <Select
        placeholder={placeholder}
        value={value} // set selected value
        options={options} // set list of the data
        onChange={onChange} // assign onChange function
      />
      {/* </div> */}
      {errorMessage && (
        <p className='dangerText' style={{ marginTop: '1rem' }}>
          {errorMessage}
        </p>
      )}
    </div>
  );
};

export default SearchSelect;
