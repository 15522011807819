import React from 'react';

import { Content } from '../components';
import TableSkeleton from '../Skeletons/TableSkeleton';
import AddEditModal, { AddEditModalState, IaddEditModalStates } from '../pageComponents/Clinics/New/AddEditModal';
import { CustomTableRow, Table, TableBody, TableButtons, TableHead, TableImageRow, TableNoContent, TableRowTitle, TableStatusWithToggle } from '../components/Table';
import Pagination from '../components/Pagination';
import DeleteModal, { DeleteModalState, IdeleteModalStates } from '../pageComponents/Clinics/New/DeleteModal';
import ToggleModal from '../pageComponents/Clinics/New/ToggleModal';

import { inputPhoneMasking, unMasking, validateEmail, validatePhone } from '../utils/validators';
import { clinicTableHeadData } from '../hooks/clinic/staticData';
import { useAddClinicNew, useGetAllClinicsNew, useAllNextgenPractices, useDeleteClinicNew, useToggleClinicNew } from '../hooks/clinic';
import { IAddClinicNewReq } from '../hooks/clinic/index.type';
import { useCountry } from '../hooks/generic';

const ClinicsNew = () => {
  const [pageNumber, setPageNumber] = React.useState<number>(1);
  const perpageData = 15;

  // ====================================== Add edit states start ======================================
  const [addEditModal, setAddEditModal] = React.useState<AddEditModalState>({
    status: false,
  });
  const startaddEditModalStatesValue: IaddEditModalStates = {
    clinicType: { value: 'standalone', error: '' },
    ehr: { value: '', error: '' },
    practiceId: { value: '', error: '' },
    name: { value: '', error: '' },
    contactName: { value: '', error: '' },
    email: { value: '', error: '' },
    phone: { value: '', error: '' },
    address: { value: '', error: '' },
    latitude: { value: '', error: '' },
    longitude: { value: '', error: '' },
    distance: { value: 0, error: '' },
    // address: {
    //   addressLine1: { value: '', error: '' },
    //   addressLine2: { value: '', error: '' },
    //   city: { value: '', error: '' },
    //   state: { value: '', error: '' },
    //   country: { value: 'USA', error: '' },
    //   zip: { value: '', error: '' },
    // },
    modules: {
      value: {
        fileSharing: false,
        checkIn: false,
        questionnaire: false,
      },
      error: '',
    },
  };
  const [addEditModalStates, setAddEditModalStates] = React.useState<IaddEditModalStates>(startaddEditModalStatesValue);
  // ====================================== Add edit states end ======================================

  // ====================================== Toggle states start ======================================
  const [toggleModal, setToggleModal] = React.useState<DeleteModalState>({
    status: false,
  });
  // ====================================== Toggle states end ======================================

  // ====================================== Delete states start ======================================
  const [deleteModal, setDeleteModal] = React.useState<DeleteModalState>({
    status: false,
  });

  const startDeleteModalStatesValue = {
    email: { value: '', error: '' },
  };
  const [deleteModalStates, setDeleteModalStates] = React.useState<IdeleteModalStates>(startDeleteModalStatesValue);
  // ====================================== Delete states end ======================================

  // ====================================== Hooks ======================================
  const { clinicsData, getClinicsData, clinicsDataLoading } = useGetAllClinicsNew({ pageNumber, perpageData });
  const { nextgenPractices, nextgenPracticesLoading } = useAllNextgenPractices();
  const { addClinicLoading, addClinic } = useAddClinicNew({ clinicId: addEditModal.data?._id });
  const { countryInfoLoading, countryInfo } = useCountry();
  const { deleteClinicLoading, deleteClinic } = useDeleteClinicNew({ clinicId: deleteModal.data?._id });
  const { toggleClinicLoading, toggleClinic } = useToggleClinicNew({ clinicId: toggleModal.data?._id });

  const onPageChange = (currentPage: { selected: number }) => {
    setPageNumber(currentPage.selected + 1);
  };

  // ====================================== Add edit functions start ======================================
  React.useEffect(() => {
    if (addEditModal.data?._id) {
      setAddEditModalStates({
        clinicType: { value: addEditModal.data.clinicType ? addEditModal.data.clinicType : 'standalone', error: '' },
        ehr: { value: addEditModal.data.ehr, error: '' },
        practiceId: { value: addEditModal.data.practiceId, error: '' },
        name: { value: addEditModal.data.name, error: '' },
        contactName: { value: addEditModal.data.contactName, error: '' },
        email: { value: addEditModal.data.email, error: '' },
        phone: { value: addEditModal.data.phone, error: '' },
        address: { value: addEditModal.data.address, error: '' },
        latitude: { value: addEditModal.data.latitude, error: '' },
        longitude: { value: addEditModal.data.longitude, error: '' },
        distance: { value: addEditModal.data.distance, error: '' },
        // address: {
        //   addressLine1: { value: addEditModal.data.userAddress?.addressLine1, error: '' },
        //   addressLine2: { value: addEditModal.data.userAddress?.addressLine2, error: '' },
        //   city: { value: addEditModal.data.userAddress?.city, error: '' },
        //   state: { value: addEditModal.data.userAddress?.state, error: '' },
        //   country: { value: addEditModal.data.userAddress?.country, error: '' },
        //   zip: { value: addEditModal.data.userAddress?.zip, error: '' },
        // },
        modules: {
          value: {
            fileSharing: addEditModal.data.modules && addEditModal.data.modules.fileSharing ? addEditModal.data.modules.fileSharing : false,
            checkIn: addEditModal.data.modules && addEditModal.data.modules.checkIn ? addEditModal.data.modules.checkIn : false,
            questionnaire: addEditModal.data.modules && addEditModal.data.modules.questionnaire ? addEditModal.data.modules.questionnaire : false,
          },
          error: '',
        },
      });
    }
  }, [addEditModal]);

  const handleCloseModal = () => {
    setAddEditModal({ status: false });
    setAddEditModalStates(startaddEditModalStatesValue);
  };

  const handleAddClinicAPI = async (formData: IAddClinicNewReq) => {
    const data = await addClinic(formData);
    if (data?.status === 'success') {
      handleCloseModal();
      getClinicsData();
    }
  };

  const handleAddClinic = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!addEditModalStates.clinicType.value) {
      setAddEditModalStates(prevVal => ({ ...prevVal, clinicType: { ...prevVal.clinicType, error: 'Please select clinic type' } }));
    } else if (addEditModalStates.clinicType.value === 'standalone' && !addEditModalStates.name.value) {
      setAddEditModalStates(prevVal => ({ ...prevVal, name: { ...prevVal.name, error: 'Please enter clinic name' } }));
    } else if (!addEditModalStates.contactName.value) {
      setAddEditModalStates(prevVal => ({ ...prevVal, contactName: { ...prevVal.contactName, error: 'Please enter contact name' } }));
    } else if (!validateEmail(addEditModalStates.email.value)) {
      setAddEditModalStates(prevVal => ({ ...prevVal, email: { ...prevVal.email, error: 'Please enter valid clinic email' } }));
    } else if (addEditModalStates.clinicType.value === 'standalone' && !validatePhone(unMasking(addEditModalStates.phone.value))) {
      setAddEditModalStates(prevVal => ({ ...prevVal, phone: { ...prevVal.phone, error: 'Please enter valid clinic phone' } }));
      //   else if (addEditModalStates.clinicType.value === 'standalone' && !addEditModalStates.address.addressLine1.value) {
      //   setAddEditModalStates(prevVal => ({
      //     ...prevVal,
      //     address: { ...prevVal.address, addressLine1: { ...prevVal.address.addressLine1, error: 'Please enter address line 1' } },
      //   }));
      // } else if (addEditModalStates.clinicType.value === 'standalone' && !addEditModalStates.address.city.value) {
      //   setAddEditModalStates(prevVal => ({
      //     ...prevVal,
      //     address: { ...prevVal.address, city: { ...prevVal.address.city, error: 'Please enter city' } },
      //   }));
      // } else if (addEditModalStates.clinicType.value === 'standalone' && !addEditModalStates.address.state.value) {
      //   setAddEditModalStates(prevVal => ({
      //     ...prevVal,
      //     address: { ...prevVal.address, state: { ...prevVal.address.state, error: 'Please sekect state' } },
      //   }));
      // } else if (addEditModalStates.clinicType.value === 'standalone' && !addEditModalStates.address.country.value) {
      //   setAddEditModalStates(prevVal => ({
      //     ...prevVal,
      //     address: { ...prevVal.address, country: { ...prevVal.address.country, error: 'Please select country' } },
      //   }));
    } else if (addEditModalStates.clinicType.value === 'standalone' && !addEditModalStates.latitude.value) {
      setAddEditModalStates(prevVal => ({
        ...prevVal,
        latitude: { ...prevVal.latitude, zip: { ...prevVal.latitude, error: 'Please enter latitude' } },
      }));
    } else if (addEditModalStates.clinicType.value === 'standalone' && !addEditModalStates.longitude.value) {
      setAddEditModalStates(prevVal => ({
        ...prevVal,
        longitude: { ...prevVal.longitude, zip: { ...prevVal.longitude, error: 'Please enter longitude' } },
      }));
    } else if (addEditModalStates.clinicType.value === 'standalone' && !addEditModalStates.distance.value) {
      setAddEditModalStates(prevVal => ({
        ...prevVal,
        distance: { ...prevVal.distance, zip: { ...prevVal.distance, error: 'Please enter distance' } },
      }));
    } else if (addEditModalStates.clinicType.value === 'ehr' && !addEditModalStates.ehr.value) {
      setAddEditModalStates(prevVal => ({ ...prevVal, ehr: { ...prevVal.ehr, error: 'Please select EHR' } }));
    } else if (addEditModalStates.clinicType.value === 'ehr' && !addEditModalStates.practiceId.value) {
      setAddEditModalStates(prevVal => ({ ...prevVal, practiceId: { ...prevVal.practiceId, error: 'Please select clinic name' } }));
    } else if (!addEditModalStates.modules.value.fileSharing && !addEditModalStates.modules.value.checkIn && !addEditModalStates.modules.value.questionnaire) {
      setAddEditModalStates(prevVal => ({ ...prevVal, modules: { ...prevVal.modules, error: 'Please select at least one module' } }));
    } else {
      if (addEditModalStates.clinicType.value === 'ehr') {
        let name = '',
          enterpriseId = '',
          phone = '',
          addressLine1 = '',
          addressLine2 = '',
          city = '',
          state = '',
          country = '',
          zip = '';
        nextgenPractices?.data.items.forEach(item => {
          if (item.id === addEditModalStates.practiceId.value) {
            name = item.name;
            enterpriseId = item.enterpriseId;
            phone = item.phone;
            addressLine1 = item.addressLine1;
            addressLine2 = item.addressLine2;
            city = item.city;
            state = item.state;
            country = item.country;
            zip = item.zip;
          }
        });

        const formData: IAddClinicNewReq = {
          name,
          enterpriseId,
          phone,
          userAddress: {
            addressLine1,
            addressLine2,
            city,
            state,
            country,
            zip,
          },
          contactName: addEditModalStates.contactName.value,
          clinicType: addEditModalStates.clinicType.value,
          ehr: addEditModalStates.ehr.value,
          email: addEditModalStates.email.value,
          practiceId: addEditModalStates.practiceId.value,
          modules: addEditModalStates.modules.value,
        };
        handleAddClinicAPI(formData);
      } else {
        const formData: IAddClinicNewReq = {
          clinicType: addEditModalStates.clinicType.value,
          name: addEditModalStates.name.value,
          contactName: addEditModalStates.contactName.value,
          email: addEditModalStates.email.value,
          phone: unMasking(addEditModalStates.phone.value),
          address: addEditModalStates.address.value,
          latitude: addEditModalStates.latitude.value,
          longitude: addEditModalStates.longitude.value,
          distance: addEditModalStates.distance.value,

          // userAddress: {
          //   addressLine1: addEditModalStates.address.addressLine1.value,
          //   addressLine2: addEditModalStates.address.addressLine2.value,
          //   city: addEditModalStates.address.city.value,
          //   state: addEditModalStates.address.state.value,
          //   country: addEditModalStates.address.country.value,
          //   zip: addEditModalStates.address.zip.value,
          // },
          menuSetting: {
            isDistance: addEditModalStates.modules.value.checkIn,
            isOTP: false,
          },
          modules: addEditModalStates.modules.value,
        };

        handleAddClinicAPI(formData);
      }
    }
  };
  // ====================================== Add edit functions end ======================================

  // ====================================== Toggle functions start ======================================
  const handleToggleClinic = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (toggleModal.data) {
      const data = await toggleClinic({ active: !toggleModal.data.active });
      if (data?.status === 'success') {
        setToggleModal({ status: false });
        getClinicsData();
      }
    }
  };
  // ====================================== Toggle functions end ======================================

  // ====================================== Delete functions start ======================================
  const handleCloseDeleteModal = () => {
    setDeleteModal({ status: false });
    setDeleteModalStates(startDeleteModalStatesValue);
  };

  const handleDeleteClinic = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (deleteModal.data) {
      if (!deleteModalStates.email.value.trim() || deleteModalStates.email.value !== deleteModal.data?.email) {
        setDeleteModalStates(prevVal => ({ ...prevVal, email: { ...prevVal.email, error: 'Please enter valid email' } }));
      } else {
        const data = await deleteClinic();
        if (data?.status === 'success') {
          handleCloseDeleteModal();
          getClinicsData();
        }
      }
    } else {
      setDeleteModalStates(prevVal => ({ ...prevVal, email: { ...prevVal.email, error: 'Please select clinic to delete' } }));
    }
  };
  // ====================================== Delete functions end ======================================

  // const returnFullAddress = (address: { addressLine1: string; addressLine2: string; city: string; state: string; country: string; zip: string }) => {
  //   const newaddress = address.addressLine2
  //     ? address.addressLine1 + ', ' + address.addressLine2 + ', ' + address.city + ', ' + address.state + ', ' + address.country + ' - ' + address.zip
  //     : address.addressLine1 + ', ' + address.city + ', ' + address.state + ', ' + address.country + ' - ' + address.zip;

  //   return newaddress.length <= 40 ? newaddress : newaddress.slice(0, 40) + '...';
  // };

  return (
    <Content
      currentMenu='clinics'
      headerTitle='Clinics'
      addBtn={true}
      addBtnClick={() => setAddEditModal({ status: true })}
      addBtnTitle='Add Clinic'
      searchBox={false}
      searchPlaceholder='Search Clinics'
      totalReult={clinicsData && clinicsData.totalResults > 0 ? clinicsData.totalResults + ' clinics' : ''}
    >
      <Table>
        <TableHead data={clinicTableHeadData} />
        <TableBody>
          <CustomTableRow>
            {clinicsDataLoading ? (
              <TableSkeleton rows={clinicTableHeadData.length} columns={10} />
            ) : clinicsData && clinicsData.totalResults > 0 ? (
              clinicsData.data.data.map(clinic => (
                <tr key={clinic._id}>
                  <TableImageRow alt={clinic.name} />
                  <TableRowTitle>
                    {clinic.name}
                    {clinic.clinicType === 'ehr' && clinic.ehr && <span>{clinic.ehr}</span>}
                  </TableRowTitle>
                  <td>{clinic.email ? clinic.email : 'N/A'}</td>
                  <td>{clinic.phone ? inputPhoneMasking(clinic.phone) : '-'}</td>
                  <td>{clinic.address ? clinic.address : ''}</td>
                  {/* <td>{clinic.userAddress ? returnFullAddress(clinic.userAddress) : ''}</td> */}
                  <td>{clinic.latitude ? clinic.latitude : ''}</td>
                  <td>{clinic.longitude ? clinic.longitude : ''}</td>
                  <td>{clinic.distance ? clinic.distance : ''}</td>
                  <td>{clinic.clinicID ? clinic.clinicID : ''}</td>
                  <td>
                    <TableStatusWithToggle id={clinic._id} checked={clinic.active} onChange={() => setToggleModal({ status: true, data: clinic })} />
                  </td>

                  <TableButtons
                    buttons={[
                      { icon: 'edit-3', tooltip: 'Edit Clinic', onClick: () => setAddEditModal({ status: true, data: clinic }) },
                      { icon: 'trash', tooltip: 'Delete Clinic', variant: 'danger', onClick: () => setDeleteModal({ status: true, data: clinic }) },
                    ]}
                  />
                </tr>
              ))
            ) : (
              <TableNoContent colSpan={clinicTableHeadData.length} message='No data found' />
            )}
          </CustomTableRow>
        </TableBody>
      </Table>

      <Pagination itemsPerPage={perpageData} totalItems={clinicsData ? clinicsData.totalResults : 0} onPageChange={onPageChange} />

      {addEditModal.status && (
        <AddEditModal
          loading={addClinicLoading}
          addEditModal={addEditModal}
          onCloseModal={handleCloseModal}
          addEditModalStates={addEditModalStates}
          setAddEditModalStates={setAddEditModalStates}
          handleAddEdit={handleAddClinic}
          nextgenPractices={nextgenPractices}
          nextgenPracticesLoading={nextgenPracticesLoading}
          countryInfoLoading={countryInfoLoading}
          countryInfo={countryInfo}
        />
      )}

      {toggleModal.status && (
        <ToggleModal loading={toggleClinicLoading} toggleModal={toggleModal} onCloseModal={() => setToggleModal({ status: false })} handleToggle={handleToggleClinic} />
      )}

      {deleteModal.status && (
        <DeleteModal
          loading={deleteClinicLoading}
          deleteModal={deleteModal}
          deleteModalStates={deleteModalStates}
          setDeleteModalStates={setDeleteModalStates}
          onCloseModal={handleCloseDeleteModal}
          handleDelete={handleDeleteClinic}
        />
      )}
    </Content>
  );
};

export default ClinicsNew;
