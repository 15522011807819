import type { FC, PropsWithChildren } from 'react';

type ModalFormProps = PropsWithChildren<{
  style?: React.CSSProperties;
  onSubmit?: (event: React.FormEvent<HTMLFormElement>) => void;
}>;

const ModalForm: FC<ModalFormProps> = props => {
  return (
    <form className='modalContainer__form' style={props.style} onSubmit={props.onSubmit}>
      {props.children}
    </form>
  );
};

export default ModalForm;
