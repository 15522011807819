import React from 'react';
import useAllClinics from './useAllProviders';

const useGetAllProviders = () => {
  const { providersData, getprovidersData, providersDataLoading } = useAllClinics();

  React.useEffect(() => {
    getprovidersData();
    // eslint-disable-next-line
  }, []);

  return {
    providersData,
    getprovidersData,
    providersDataLoading,
  };
};

export default useGetAllProviders;
