import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { SideProps } from '.';

const AdminSidebar: FC<SideProps> = ({ currentMenu }) => {
  return (
    <nav className='navigation'>
      <ul className='navigation__list'>
        <li className='navigation__list--link'>
          <img src='/assets/logoWhite.png' alt='logo' className='navigation__logo' />
        </li>
        <li className='navigation__list--item'>
          <Link to='/' className={currentMenu === 'dashboard' ? 'navigation__list--link-active' : 'navigation__list--link'}>
            <svg className={currentMenu === 'dashboard' ? 'navigation__icon-active' : 'navigation__icon'}>
              <use xlinkHref={`/assets/sprite.svg#icon-menu2`} />
            </svg>
            <span>Dashboard</span>
          </Link>
        </li>
        <li className='navigation__list--item'>
          <Link to='/clinics' className={currentMenu === 'clinics' ? 'navigation__list--link-active' : 'navigation__list--link'}>
            <svg className={currentMenu === 'clinics' ? 'navigation__icon-active' : 'navigation__icon'}>
              <use xlinkHref={`/assets/sprite.svg#icon-clinic2`} />
            </svg>
            <span>Clinics</span>
          </Link>
        </li>
        {/* <li className='navigation__list--item'>
          <Link
            to='/providers'
            className={
              currentMenu === 'providers'
                ? 'navigation__list--link-active'
                : 'navigation__list--link'
            }
          >
            <svg
              className={
                currentMenu === 'providers'
                  ? 'navigation__icon-active'
                  : 'navigation__icon'
              }
            >
              <use xlinkHref={`/assets/sprite.svg#icon-doctor2`} />
            </svg>
            <span>Providers</span>
          </Link>
        </li> */}
        <li className='navigation__list--item'>
          <Link to='/users' className={currentMenu === 'users' ? 'navigation__list--link-active' : 'navigation__list--link'}>
            <svg className={currentMenu === 'users' ? 'navigation__icon-active' : 'navigation__icon'}>
              <use xlinkHref={`/assets/sprite.svg#icon-users2`} />
            </svg>
            <span>Users</span>
          </Link>
        </li>
        <li className='navigation__list--item'>
          <Link to='/secure-files' className={currentMenu === 'secure-files' ? 'navigation__list--link-active' : 'navigation__list--link'}>
            <svg className={currentMenu === 'secure-files' ? 'navigation__icon-active' : 'navigation__icon'}>
              <use xlinkHref={`/assets/sprite.svg#icon-secure-file`} />
            </svg>
            <span>Secure Files</span>
          </Link>
        </li>
        <li className='navigation__list--item'>
          <Link to='/ad-campaigns' className={currentMenu === 'ad-campaigns' ? 'navigation__list--link-active' : 'navigation__list--link'}>
            <svg className={currentMenu === 'ad-campaigns' ? 'navigation__icon-active' : 'navigation__icon'}>
              <use xlinkHref={`/assets/sprite.svg#icon-ads`} />
            </svg>
            <span>Ad Campaigns</span>
          </Link>
        </li>
        <li className='navigation__list--item'>
          <Link to='/view-campaign' className={currentMenu === 'view-campaign' ? 'navigation__list--link-active' : 'navigation__list--link'}>
            <svg className={currentMenu === 'view-campaign' ? 'navigation__icon-active' : 'navigation__icon'}>
              <use xlinkHref={`/assets/sprite.svg#icon-ads`} />
            </svg>
            <span>View Ads</span>
          </Link>
        </li>
        <li className='navigation__list--item'>
          <Link to='/feedback' className={currentMenu === 'feedback' ? 'navigation__list--link-active' : 'navigation__list--link'}>
            <svg className={currentMenu === 'feedback' ? 'navigation__icon-active' : 'navigation__icon'}>
              <use xlinkHref={`/assets/sprite.svg#icon-bubbles`} />
            </svg>
            <span>Feedbacks</span>
          </Link>
        </li>
        <li className='navigation__list--item'>
          <Link to='/patient-feedback' className={currentMenu === 'patient-feedback' ? 'navigation__list--link-active' : 'navigation__list--link'}>
            <svg className={currentMenu === 'patient-feedback' ? 'navigation__icon-active' : 'navigation__icon'}>
              <use xlinkHref={`/assets/sprite.svg#icon-feedback`} />
            </svg>
            <span>Patient Feedback</span>
          </Link>
        </li>
        {/* <li className='navigation__list--item'>
          <Link
            to='/mapping'
            className={
              currentMenu === 'mapping'
                ? 'navigation__list--link-active'
                : 'navigation__list--link'
            }
          >
            <svg
              className={
                currentMenu === 'mapping'
                  ? 'navigation__icon-active'
                  : 'navigation__icon'
              }
            >
              <use xlinkHref={`/assets/sprite.svg#icon-ads`} />
            </svg>
            <span>Mapping</span>
          </Link>
        </li> */}
        {/* <li className='navigation__list--item'>
						<Link
							to='/campaign'
							className={currentMenu === 'campaign' ? 'navigation__list--link-active' : 'navigation__list--link'}>
							<svg className={currentMenu === 'campaign' ? 'navigation__icon-active' : 'navigation__icon'}>
								<use xlinkHref={`/assets/sprite.svg#icon-ads`} />
							</svg>
							<span>Campaign</span>
						</Link>
					</li>
					<li className='navigation__list--item'>
						<Link
							to='/view-campaign'
							className={currentMenu === 'view-campaign' ? 'navigation__list--link-active' : 'navigation__list--link'}>
							<svg className={currentMenu === 'view-campaign' ? 'navigation__icon-active' : 'navigation__icon'}>
								<use xlinkHref={`/assets/sprite.svg#icon-ads`} />
							</svg>
							<span>View Ads</span>
						</Link>
					</li>
					<li className='navigation__list--item'>
						<Link
							to='/campaign-analysis'
							className={
								currentMenu === 'campaign-analysis' ? 'navigation__list--link-active' : 'navigation__list--link'
							}>
							<svg className={currentMenu === 'campaign-analysis' ? 'navigation__icon-active' : 'navigation__icon'}>
								<use xlinkHref={`/assets/sprite.svg#icon-ads`} />
							</svg>
							<span>Analysis</span>
						</Link>
					</li> */}
      </ul>
      <div>
        <Link to='/my-profile' className={currentMenu === 'my-profile' ? 'navigation__list--link-active' : 'navigation__list--link'}>
          <svg className={currentMenu === 'my-profile' ? 'navigation__icon-active' : 'navigation__icon'}>
            <use xlinkHref={`/assets/sprite.svg#icon-users2`} />
          </svg>
          <span>My Profile</span>
        </Link>

        <p className='powered__text'>
          <span>
            Powered By
            <br />
            <a href='https://www.the-algo.com/' target='_blank' rel='noreferrer'>
              The Algorithm
            </a>
          </span>
          &copy; {new Date(Date.now()).getFullYear()}. clinIQ
        </p>
      </div>
      {/* <img src='./algo-logo.png' alt='The Algorithm' className='powered_logo' /> */}
    </nav>
  );
};

export default AdminSidebar;
