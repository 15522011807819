import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import VideoScreen from '../pageComponents/VideoCall/Video/Video';
import { Content } from '../components';
import { Socket } from 'socket.io-client';

interface VideoCallScreenProps {
  socket: Socket | null;
}

const VideoCallScreen: React.FC<VideoCallScreenProps> = ({ socket }) => {
  const params = useParams();
  useEffect(() => {
    var cookies = document.cookie.split(';');
    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i];
      var eqPos = cookie.indexOf('=');
      var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
    }
  }, []);
  useEffect(() => {
    console.log(params);
  }, [params]);
  return (
    <Content currentMenu='dashboard' headerTitle={'Video Call'} addBtn={false} addBtnTitle='New Call'>
      <VideoScreen data={params} socket={socket} />
    </Content>
  );
};

export default VideoCallScreen;
