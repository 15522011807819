import type { FC, PropsWithChildren } from 'react';
import * as XLSX from 'xlsx';

type ExportButtonProps = PropsWithChildren<{
  exportTitle: string;
  exportData: unknown[];
}>;

const ExportButton: FC<ExportButtonProps> = props => {
  const exportData = (data: unknown[], title: string) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Report');
    XLSX.writeFile(workbook, `${title}.xlsx`);
  };

  return <button onClick={() => exportData(props.exportData, props.exportTitle)}>{props.children}</button>;
};

export default ExportButton;
