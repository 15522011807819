// @ts-nocheck
import type { FC, PropsWithChildren } from 'react';
import { ContextMenuTrigger } from 'react-contextmenu';

type MenuTriggerProps = PropsWithChildren<{
  id: string;
}>;

const MyContextMenuTrigger: FC<MenuTriggerProps> = props => {
  return <ContextMenuTrigger id={props.id}>{props.children}</ContextMenuTrigger>;
};

export default MyContextMenuTrigger;
