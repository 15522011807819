import React from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';
import { IDownloadFileReq } from './index.type';
import { useSelector } from 'react-redux';
import { loginSliceValue } from '../../redux/reducers/loginSlice';

const useDownloadFile = () => {
  const [downloadFileLoading, setDownloadFileLoading] = React.useState<boolean>(false);
  const { userDetails } = useSelector(loginSliceValue);

  const config = {
    headers: {
      Authorization: `Bearer ${userDetails.token}`,
      'Content-Type': 'application/json',
    },
  };

  const downloadFile = async (formData: IDownloadFileReq) => {
    try {
      setDownloadFileLoading(true);
      // axios({
      //   url: `${process.env.REACT_APP_BASE_URL}/fileExchange/download`,
      //   method: 'POST',
      //   data: formData,
      //   headers: config.headers,
      //   responseType: 'blob', // important
      // })
      //   .then(response => {
      //     const url = window.URL.createObjectURL(new Blob([response.data]));
      //     const link = document.createElement('a');
      //     link.href = url;
      //     link.setAttribute('download', 'download.zip'); //or any other extension
      //     link.setAttribute('target', '_blank'); //or any other extension
      //     document.body.appendChild(link);
      //     link.click();
      //     setDownloadFileLoading(false);
      //     toast.success('File download successfull!');
      //     return { status: true };
      //   })
      //   .catch(err => {
      //     setDownloadFileLoading(false);
      //     toast.error('Incorrect password. Please try again');
      //     return { status: false };
      //   });

      const response = await axios({
        url: `${process.env.REACT_APP_BASE_URL}/fileExchange/download`,
        method: 'POST',
        data: formData,
        headers: config.headers,
        responseType: 'blob', // important
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'download.zip'); //or any other extension
      link.setAttribute('target', '_blank'); //or any other extension
      document.body.appendChild(link);
      link.click();
      setDownloadFileLoading(false);
      toast.success('File download successfull!');
      return { status: 'success' };
    } catch (err: any) {
      setDownloadFileLoading(false);
      const msg = formData.passwordProtected ? 'Incorrect password. Please try again' : 'Something went wrong while downloading file. Please try again.';
      toast.error(msg);
      return { status: 'fail' };
    }
  };

  return {
    downloadFile,
    downloadFileLoading,
  };
};

export default useDownloadFile;
