import axios from 'axios';
import * as actions from '../actions/afterAuth';

const api =
  ({ dispatch, getState }: any) =>
  (next: any) =>
  async (action: any) => {
    if (action.type !== actions.apiCallBegan.type) {
      return next(action);
    }

    const { url, method, data, onAuthStart, onAuthSuccess, onAuthFailed, onAuthReset } = action.payload;
    if (onAuthStart) {
      dispatch({ type: onAuthStart });
    }
    next(action);

    try {
      const {
        loginReducer: { token },
      } = getState();

      if (onAuthReset) {
        return dispatch({ type: onAuthReset, payload: null });
      }

      const config = {
        url: `${process.env.REACT_APP_BASE_URL + url}`,
        method,
        data,
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
      };

      const response = await axios(config);

      if (response?.data?.status) {
        // For General
        dispatch(actions.apiCallSuccess(response.data));

        // For Specific
        if (onAuthSuccess) {
          dispatch({ type: onAuthSuccess, payload: response.data });
        }
      } else {
        // For General
        dispatch(actions.apiCallFailed(response?.data));

        // For Specific
        if (onAuthFailed) {
          dispatch({
            type: onAuthFailed,
            payload: response?.data,
          });
        }
      }
    } catch (error: any) {
      // For General
      dispatch(actions.apiCallFailed(error.response && error.response.data ? error.response.data : error));

      // For Specific
      if (onAuthFailed) {
        dispatch({
          type: onAuthFailed,
          payload: error.response && error.response.data ? error.response.data : error,
        });
      }
    }
  };

export default api;
