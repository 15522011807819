import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loginSliceValue, loginUser } from '../redux/reducers/loginSlice';
import { toast } from 'react-hot-toast';
import { validateEmail, validatePassword } from '../utils/validators';

import { Carousel } from 'react-responsive-carousel';
import { Input } from '../components';
import CarouselItem from '../pageComponents/Login/CarouselItem';
import { Button } from '../components/Modal';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import classes from '../styles/PatientEnrollment.module.css';
import AppoitnemntSVG from '../pageComponents/Login/AppoitnemntSVG';
import FormsSVG from '../pageComponents/Login/FormsSVG';
import ReportingSVG from '../pageComponents/Login/ReportingSVG';
import BillingSVG from '../pageComponents/Login/BillingSVG';

interface IloginStates {
  email: { value: string; error: string };
  password: { value: string; error: string };
}

const LoginScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loginStates, setLoginStates] = React.useState<IloginStates>({
    email: { value: '', error: '' },
    password: { value: '', error: '' },
  });

  const { loading, userDetails, error } = useSelector(loginSliceValue);

  React.useEffect(() => {
    if (userDetails.status === 'success') {
      if (userDetails.data?.user.role === 'fileAdmin' || userDetails.data?.user.role === 'fileUser') {
        navigate('/secure-files');
      } else {
        navigate('/');
      }
    }
  }, [navigate, userDetails, error]);

  React.useEffect(() => {
    if (error.status === 'fail') {
      toast.error(error.msg);
      setLoginStates(prevVal => ({
        ...prevVal,
        password: { value: '', error: error.msg },
      }));
    }
  }, [error]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!validateEmail(loginStates.email.value)) {
      setLoginStates(prevVal => ({ ...prevVal, email: { ...prevVal.email, error: 'Please enter your valid email address' } }));
    } else if (!loginStates.password.value) {
      setLoginStates(prevVal => ({
        ...prevVal,
        password: { ...prevVal.password, error: 'Please enter your password' },
      }));
      //   else if (!validatePassword(loginStates.password.value)) {
      //   setLoginStates(prevVal => ({
      //     ...prevVal,
      //     password: { ...prevVal.password, error: 'Password must have atleast 8 characters with one capital letter, number & a special character.' },
      //   }));
      // }
    } else {
      dispatch(loginUser(loginStates.email.value, loginStates.password.value));
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.input_container}>
        <img src='assets/logo-new.png' alt='clinIQ Logo' className={classes.logo} />

        <div className={classes.container_content}>
          <h1 className={classes.container_heading}>
            Get Started Now! <span>Enter your credentials to access your account</span>
          </h1>

          <form className={classes.container_form} onSubmit={handleSubmit}>
            <Input
              label='Email address*'
              placeholder='Email address'
              type='email'
              name='email'
              value={loginStates.email.value}
              onChange={e => setLoginStates(prevVal => ({ ...prevVal, email: { value: e.target.value, error: '' } }))}
              errorMessage={loginStates.email.error}
              icon='email'
              valid={validateEmail(loginStates.email.value)}
            />

            <Input
              type='password'
              label='Password'
              placeholder='Enter password'
              icon='key'
              name='password'
              value={loginStates.password.value}
              onChange={e => setLoginStates(prevVal => ({ ...prevVal, password: { value: e.target.value, error: '' } }))}
              errorMessage={loginStates.password.error}
              valid={validatePassword(loginStates.password.value)}
            />

            <Button
              label={loading ? 'Please wait...' : 'Login'}
              span={false}
              type='submit'
              submitButtonStyle={{ justifyContent: 'center', fontSize: '1.5rem' }}
              disabled={loading}
            />
            {/* <SimpleButton type='button' label='Register your account' onClick={() => navigate('/enrollment')} /> */}
          </form>
        </div>

        <div className={classes.container_copyright}>
          <p>&copy; {new Date(Date.now()).getFullYear()} clinIQ</p>
          <p>
            Powered By{' '}
            <a href='https://www.the-algo.com/' target='__blank'>
              The Algorithm
            </a>
          </p>
        </div>
      </div>
      <div className={classes.carousel_container}>
        <Carousel selectedItem={1} autoPlay={true} infiniteLoop showStatus={false} showThumbs={false} showArrows={false}>
          <CarouselItem title='Reporting' subTitle='Provides real-time insights into clinic performance. Offers customizable reports and dashboards.'>
            <ReportingSVG />
          </CarouselItem>

          <CarouselItem title='Appointment scheduling' subTitle='Appointment scheduling feature helps reduce wait times and improve patient satisfaction'>
            <AppoitnemntSVG />
          </CarouselItem>

          <CarouselItem title='Billing' subTitle='Designed for the staff and your patients Intuitive interface. Straight forward and efficient. Secure'>
            <BillingSVG />
          </CarouselItem>

          <CarouselItem title='Forms' subTitle='Easily configure forms for your clinic. Search through submitted forms'>
            <FormsSVG />
          </CarouselItem>
        </Carousel>
      </div>
    </div>
  );
};

export default LoginScreen;
