import React from 'react';
import AdStatisticsStyles from '../../styles/AdStatistics.module.css';

interface DateInputProps {
  value: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const DateInput: React.FC<DateInputProps> = props => {
  return (
    <div className={AdStatisticsStyles.date_picker}>
      <input type='date' value={props.value} onChange={props.onChange} />
    </div>
  );
};

export default DateInput;
