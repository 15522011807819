import React from 'react';
import classes from '../../styles/NoData.module.css';

interface NoDataProps {
  title: string;
}
const NoData: React.FC<NoDataProps> = props => {
  return (
    <div className={classes.container}>
      <svg>
        <use xlinkHref={`/assets/sprite.svg#icon-bubbles`} />
      </svg>
      <p>{props.title}</p>
    </div>
  );
};

export default NoData;
