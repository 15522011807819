import React from 'react';
import classes from '../../../styles/HomeScreen.module.css';

const Chart4 = ({ data }) => {
  return (
    <div className={classes.chart4}>
      <p className={classes.chart4_heading}>
        Risk Rating Percentage (Last <span>3</span> months)
      </p>
      {/* <div className={classes.chart4_data}>
        <p className={classes.chart4_data_number + ' ' + classes.grad_color}>
          5284
        </p>
        <p className={classes.chart4_active + ' ' + classes.grad_color}>
          4710 active
        </p>
      </div> */}

      <div className={classes.chart1_bar} style={{ marginTop: '1rem' }}>
        <div className={classes.chart1_bar_graph}>
          {data && data.data && <div className={classes.chart1_bar_graph_value} style={{ width: `${(data.data.high / data.total) * 100}%` }} />}
          {data && data.data && (
            <div className={classes.chart1_bar_graph_arrow} style={{ left: `calc(${((data.data.high / data.total) * 100).toFixed(2)}% - 0.4rem)` }} />
          )}
        </div>

        <div className={classes.chart1_bar_values}>
          <p>High</p>
          <p className={classes.chart1_bar_values_num}>{data && data.data && ((data.data.high / data.total) * 100).toFixed(2)}%</p>
          {/* 3717 544 548 2625 */}
        </div>
      </div>

      <div className={classes.chart1_bar}>
        <div className={classes.chart1_bar_graph}>
          {data && data.data && <div className={classes.chart1_bar_graph_value} style={{ width: `${(data.data.medium / data.total) * 100}%` }} />}
          {data && data.data && (
            <div className={classes.chart1_bar_graph_arrow} style={{ left: `calc(${((data.data.medium / data.total) * 100).toFixed(2)}% - 0.4rem)` }} />
          )}
        </div>
        <div className={classes.chart1_bar_values}>
          <p>Medium</p>
          <p className={classes.chart1_bar_values_num}>{data && data.data && ((data.data.medium / data.total) * 100).toFixed(2)}%</p>
        </div>
      </div>

      <div className={classes.chart1_bar}>
        <div className={classes.chart1_bar_graph}>
          {data && data.data && <div className={classes.chart1_bar_graph_value} style={{ width: `${(data.data.low / data.total) * 100}%` }} />}
          {data && data.data && <div className={classes.chart1_bar_graph_arrow} style={{ left: `calc(${((data.data.low / data.total) * 100).toFixed(2)}% - 0.4rem)` }} />}
        </div>
        <div className={classes.chart1_bar_values}>
          <p>Low</p>
          <p className={classes.chart1_bar_values_num}>{data && data.data && ((data.data.low / data.total) * 100).toFixed(2)}%</p>
        </div>
      </div>
    </div>
  );
};

export default Chart4;
