import type { FC, PropsWithChildren } from 'react';
import { IAd } from '../../hooks/advertisements/index.type';
import ToggleSwitch from '../../components/ToggleSwitch';
import { TableButton } from '../../components/Table';
import AdCampaignsStyles from '../../styles/AdCampaigns.module.css';

type AdItemProps = PropsWithChildren<{
  ad: IAd;
  onToggleClick?: () => void;
  onEdit?: () => void;
  onDelete?: () => void;
  onViewRuleClick?: React.MouseEventHandler<HTMLButtonElement>;
}>;

const AdItem: FC<AdItemProps> = props => {
  return (
    <div className={AdCampaignsStyles.content_card}>
      {props.ad.advertise && (
        <>
          <div className={AdCampaignsStyles.card_header}>
            <div className={AdCampaignsStyles.content_card_texts}>
              <h3 className={AdCampaignsStyles.content_card_heading}>{props.ad.advertise.title}</h3>
              <h5 className={AdCampaignsStyles.content_card_sub_heading}>{props.ad.advertise.subTitle}</h5>
              <p className={AdCampaignsStyles.content_card_desc}>{props.ad.advertise.description}</p>
            </div>
            <div>
              {props.ad.advertise && (
                <div className={AdCampaignsStyles.content_card_actions_stats}>
                  <div className={AdCampaignsStyles.table__buttons}>
                    <button className={AdCampaignsStyles.view_rules_btn} onClick={props.onViewRuleClick}>
                      View Rules
                    </button>

                    <TableButton icon='edit-3' onClick={props.onEdit} />
                    <ToggleSwitch id={props.ad._id + 'check-box'} checked={props.ad.advertise.activeStatus} onChange={props.onToggleClick} />
                    <TableButton icon='trash' variant='danger' onClick={props.onDelete} />
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      )}

      <hr className={AdCampaignsStyles.line} />

      <div className={AdCampaignsStyles.cards}>
        {props.ad.campaignList &&
          props.ad.campaignList.length > 0 &&
          props.ad.campaignList.map(image => {
            return (
              <div className={AdCampaignsStyles.card} key={image._id}>
                <img key={image._id} src={process.env.REACT_APP_BASE_ASSET_URL + image.imageUrl} alt={image.imageName} className={AdCampaignsStyles.content_card_image} />
                <p className={AdCampaignsStyles.image_text}>{image.imageName}</p>
                <div className={AdCampaignsStyles.chips}>
                  <div className={AdCampaignsStyles.chip}>
                    {image.isPaid ? <div className={AdCampaignsStyles.dotGreen}></div> : <div className={AdCampaignsStyles.dotRed}></div>}
                    <p>{image.isPaid ? 'Paid' : 'Unpaid'}</p>
                  </div>

                  <div className={AdCampaignsStyles.chip}>
                    <p className={AdCampaignsStyles.volumeText}>
                      Min: <span className={AdCampaignsStyles.volumeValue}>{image.minVolume}</span> Max:{' '}
                      <span className={AdCampaignsStyles.volumeValue}>{image.maxVolume}</span>
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default AdItem;
