import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { SideProps } from '.';

const FileUserSidebar: FC<SideProps> = ({ currentMenu }) => {
  return (
    <nav className='navigation'>
      <ul className='navigation__list'>
        <li className='navigation__list--link'>
          <img src='/assets/logoWhite.png' alt='logo' className='navigation__logo' />
        </li>
        <li className='navigation__list--item'>
          <Link to='/secure-files' className={currentMenu === 'secure-files' ? 'navigation__list--link-active' : 'navigation__list--link'}>
            <svg className={currentMenu === 'secure-files' ? 'navigation__icon-active' : 'navigation__icon'}>
              <use xlinkHref={`/assets/sprite.svg#icon-secure-file2`} />
            </svg>
            <span>Secure Files</span>
          </Link>
        </li>
        <li className='navigation__list--item'>
          <Link to='/my-profile' className={currentMenu === 'my-profile' ? 'navigation__list--link-active' : 'navigation__list--link'}>
            <svg className={currentMenu === 'my-profile' ? 'navigation__icon-active' : 'navigation__icon'}>
              <use xlinkHref={`/assets/sprite.svg#icon-users2`} />
            </svg>
            <span>My Profile</span>
          </Link>
        </li>
      </ul>
      <div>
        <p className='powered__text'>
          <span>
            Powered By
            <br />
            <a href='https://www.the-algo.com/' target='_blank' rel='noreferrer'>
              The Algorithm
            </a>
          </span>
          &copy; {new Date(Date.now()).getFullYear()}. clinIQ
        </p>
      </div>
      {/* <img src='./algo-logo.png' alt='The Algorithm' className='powered_logo' /> */}
    </nav>
  );
};

export default FileUserSidebar;
