import React from 'react';
import type { FC, PropsWithChildren } from 'react';
import classes from '../styles/TextInput.module.css';

type TextInputProps = PropsWithChildren<{
  label?: string;
  type?: string;
  placeholder?: string;
  error?: string;
  value?: string;
  name?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => void;
  icon?: string;
  check?: boolean;
  customStyle?: React.CSSProperties;
  inputStyle?: React.CSSProperties;
  isTextArea?: boolean;
  required?: boolean;
}>;

const TextInput: FC<TextInputProps> = props => {
  return (
    <div className={classes.textInput} style={props.customStyle}>
      <label className={classes.textInput__label}>{props.label}</label>
      <div className={classes.inputBox}>
        {props.icon && (
          <svg className={classes.inputBox__icon}>
            <use xlinkHref={`/assets/sprite.svg#icon-${props.icon}`} />
          </svg>
        )}
        {props.isTextArea ? (
          <textarea
            className={classes.textInput__input}
            placeholder={props.placeholder}
            name={props.name}
            value={props.value}
            style={props.inputStyle}
            onChange={props.onChange}
            required={props.required}
            rows={2}
            cols={50}
          />
        ) : (
          <input
            className={classes.textInput__input}
            type={props.type ? props.type : 'text'}
            placeholder={props.placeholder}
            name={props.name}
            value={props.value}
            style={props.inputStyle}
            onChange={props.onChange}
            required={props.required}
          />
        )}
        {props.check ? (
          <svg id='icon-check' width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <circle cx='9' cy='9' r='9' fill='#4ED28B' />
            <path d='M5.66669 9L8.16669 11.5L12.3334 6.5' stroke='white' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
          </svg>
        ) : (
          ''
        )}
      </div>
      {props.error ? <p className={classes.errorText}>{props.error}</p> : ''}
    </div>
  );
};

export default TextInput;
