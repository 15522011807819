import type { FC, PropsWithChildren } from 'react';
import TextBoxStyles from '../InputBox/TextBox.module.css';

type UploadInputProps = PropsWithChildren<{
  label: string;
  errorMessage?: string;
  disabled?: boolean;
  multiple?: boolean;
  accept?: string;
  style?: React.CSSProperties;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}>;

const UploadInput: FC<UploadInputProps> = ({ onChange, errorMessage, label, style, disabled, accept }) => {
  return (
    <div className={TextBoxStyles.form_input}>
      {label && (
        <label htmlFor={label} className={TextBoxStyles.form_input__label}>
          {label}
        </label>
      )}
      <div className={TextBoxStyles.form_input__container} style={style}>
        <input type='file' multiple id={label} disabled={disabled} accept={accept} onChange={onChange} className={TextBoxStyles.form_input__box} />
      </div>
      {errorMessage && <p className={TextBoxStyles.form_input__error}>{errorMessage}</p>}
    </div>
  );
};

export default UploadInput;
