import React from 'react';
import type { FC, PropsWithChildren } from 'react';
import SkeletonStyles from './Skeletons.module.css';

type TableSkeletonProps = PropsWithChildren<{
  columns: number;
  rows: number;
}>;

const TableSkeleton: FC<TableSkeletonProps> = ({ columns = 10, rows = 8 }) => {
  const [tableColumns, setTableColumns] = React.useState<number[]>([]);
  const [tableRows, setTableRows] = React.useState<number[]>([]);

  React.useEffect(() => {
    const cols = [];
    for (let i = 0; i < columns; i++) cols.push(i + 1);
    setTableColumns(cols);
  }, [columns]);

  React.useEffect(() => {
    const rowss = [];
    for (let i = 0; i < rows; i++) rowss.push(i + 1);
    setTableRows(rowss);
  }, [rows]);

  return (
    <React.Fragment>
      {tableColumns &&
        tableColumns.length > 0 &&
        tableColumns.map(column => (
          <tr key={column}>
            {tableRows &&
              tableRows.length > 0 &&
              tableRows.map((row, index) =>
                index === 0 ? (
                  <td className='table__profile_container' key={index + row}>
                    <div className={[SkeletonStyles.table__profile_image, SkeletonStyles.skeleton].join(' ')} />
                  </td>
                ) : index === 1 ? (
                  <td key={index + row}>
                    <div className={[SkeletonStyles.table__row_title, SkeletonStyles.skeleton].join(' ')} />
                  </td>
                ) : (
                  <td key={index + row}>
                    <div className={[SkeletonStyles.table__row_text, SkeletonStyles.skeleton].join(' ')} />
                  </td>
                )
              )}
          </tr>
        ))}
    </React.Fragment>
  );
};

export default TableSkeleton;
