import React from 'react';
import type { FC, PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { loginSliceValue } from '../redux/reducers/loginSlice';

import Header from './Header';
import Sidebar, { SideProps } from './SideBar';
import '../styles/Content.css';

type ContentProps = PropsWithChildren<{
  currentMenu: SideProps['currentMenu'];
  headerTitle: string;
  addBtn?: boolean;
  addBtnTitle?: string;
  addBtnClick?: React.MouseEventHandler<HTMLButtonElement>;
  addBtnIcon?: string;
  searchBox?: boolean;
  searchPlaceholder?: string;
  handleSearchChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  totalReult?: string;
  dropdown?: boolean;
  dropdownValue?: string;
  dropdownChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  dropdownOptions?: { value: string; label: string }[];
}>;

const Content: FC<ContentProps> = props => {
  const navigate = useNavigate();

  const [userRole, setUserRole] = React.useState('');
  const { loading, userDetails } = useSelector(loginSliceValue);

  React.useEffect(() => {
    if (!loading && !userDetails.token) {
      navigate('/login');
    }
  }, [loading, userDetails, navigate]);

  React.useEffect(() => {
    if (userDetails.data?.user.role) {
      setUserRole(userDetails.data?.user.role);
    }
  }, [userDetails]);

  return (
    <main className='container'>
      <Sidebar currentMenu={props.currentMenu} userRole={userRole} />

      <div className='mainContent'>
        <Header
          headerTitle={props.headerTitle}
          addBtn={props.addBtn}
          addBtnTitle={props.addBtnTitle}
          addBtnClick={props.addBtnClick}
          addBtnIcon={props.addBtnIcon}
          searchBox={props.searchBox}
          searchPlaceholder={props.searchPlaceholder}
          handleSearchChange={props.handleSearchChange}
          totalReult={props.totalReult}
          userRole={userRole}
          dropdown={props.dropdown}
          dropdownValue={props.dropdownValue}
          dropdownChange={props.dropdownChange}
          dropdownOptions={props.dropdownOptions}
        />
        <div className='content'>{props.children}</div>
      </div>
    </main>
  );
};

export default Content;
