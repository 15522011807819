import loginReducer from './loginSlice';
import onlineUsers from './onlineUsers';
import videoCall from './videoCall';
// import userDetails from './userDetails';

const reducers = {
  loginReducer: loginReducer,
  onlineUsers: onlineUsers,
  videoCall: videoCall,
  // userDetails: userDetails,
};

export default reducers;
