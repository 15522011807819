import toast from 'react-hot-toast';
import useApi from '../useApi';
import { ItoggleStatusReq, ItoggleStatusRes } from './index.type';

const useToggleStatus = ({ id }: { id?: string }) => {
  const { loading: toggleStatusLoading, makeApiCall } = useApi({ url: `/status/${id}/toggleActive`, method: 'POST' });

  const toggleStatus = async (formData: ItoggleStatusReq) => {
    const { response, error } = await makeApiCall<ItoggleStatusReq, ItoggleStatusRes>(formData);
    if (response?.status === 'success') {
      toast.success('Active status changed successfully.');
      return response;
    }

    if (error) {
      toast.error('Something went wrong. Please try again later');
      return { status: false };
    }
  };

  return {
    toggleStatusLoading,
    toggleStatus,
  };
};

export default useToggleStatus;
