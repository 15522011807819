import React from 'react';
import { useDeleteCampaign, useGetAllAds, useToggleCampaign } from '../hooks/advertisements';

import { Content, NoCampaign } from '../components';
import { AdItem, ChipLink, CreateCampaignModal, ToggleButtons, ToggleModal } from '../pageComponents/Advertisements';
import NewAdCampaignsSkeleton from '../Skeletons/NewAdCampaignSkeleton';
import AdCampaignsStyles from '../styles/AdCampaigns.module.css';
import ViewRulesModal, { ViewRulesModalState } from '../pageComponents/Advertisements/ViewRulesModal';
import DeleteModal, { DeleteModalState, IdeleteModalStates } from '../pageComponents/Advertisements/DeleteModal';

const Campaigns = () => {
  const [currentFilter, setCurrentFilter] = React.useState<'all' | 'active'>('active');
  const [viewRilesModal, setViewRilesModal] = React.useState<ViewRulesModalState>({
    status: false,
  });
  const [toggleModal, setToggleModal] = React.useState<ViewRulesModalState>({
    status: false,
  });
  const [createCampaignModal, setCreateCampaignModal] = React.useState<ViewRulesModalState>({
    status: false,
  });

  const [deleteModal, setDeleteModal] = React.useState<DeleteModalState>({
    status: false,
  });

  const startDeleteModalStatesValue = {
    title: { value: '', error: '' },
  };
  const [deleteModalStates, setDeleteModalStates] = React.useState<IdeleteModalStates>(startDeleteModalStatesValue);

  const { adsData, getAllAds, adsDataLoading } = useGetAllAds({ filter: currentFilter });
  const { toggleCampaign, toggleCampaignLoading } = useToggleCampaign({ campaignId: toggleModal.data?._id });
  const { deleteCampaignLoading, deleteCampaign } = useDeleteCampaign({ campaignId: deleteModal.data?._id });

  const handleToggleCampaign = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (toggleModal.data) {
      const data = await toggleCampaign({ status: !toggleModal.data.advertise.activeStatus });
      if (data?.status) {
        getAllAds();
        setToggleModal({ status: false });
      }
    }
  };

  const handleCloseDeleteModal = () => {
    setDeleteModal({ status: false });
    setDeleteModalStates(startDeleteModalStatesValue);
  };

  const handleDeleteCampaign = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (deleteModal.data) {
      if (!deleteModalStates.title.value.trim() || deleteModalStates.title.value !== deleteModal.data?.advertise.title) {
        setDeleteModalStates(prevVal => ({ ...prevVal, title: { ...prevVal.title, error: 'Please enter valid campaign title' } }));
      } else {
        await deleteCampaign();
        handleCloseDeleteModal();
        getAllAds();
      }
    } else {
      setDeleteModalStates(prevVal => ({ ...prevVal, title: { ...prevVal.title, error: 'Please select campaign to delete' } }));
    }
  };

  return (
    <Content
      headerTitle='Ad Campaigns'
      currentMenu='ad-campaigns'
      addBtn={true}
      addBtnClick={() => setCreateCampaignModal({ status: true })}
      addBtnTitle='Create Campaign'
      searchBox={true}
      searchPlaceholder='Search ads'
    >
      <div className={AdCampaignsStyles.content}>
        <div className={AdCampaignsStyles.navigation_content}>
          <div className={AdCampaignsStyles.navigation}>
            <ChipLink label='All Campaigns' icon='ad-campaign-text' to='#!' active={true} />
            <ChipLink label='Ad statistics' icon='graph' to='/ad-statistics' active={false} />
          </div>

          <ToggleButtons
            buttons={[
              { label: 'Active', active: currentFilter === 'active', onClick: () => setCurrentFilter('active') },
              { label: 'All', active: currentFilter === 'all', onClick: () => setCurrentFilter('all') },
            ]}
          />
        </div>

        {adsDataLoading ? (
          <NewAdCampaignsSkeleton />
        ) : adsData && adsData.data.length > 0 ? (
          adsData.data.map(ad => (
            <AdItem
              key={ad._id}
              ad={ad}
              onEdit={() => setCreateCampaignModal({ status: true, data: ad })}
              onViewRuleClick={() => setViewRilesModal({ status: true, data: ad })}
              onToggleClick={() => setToggleModal({ status: true, data: ad })}
              onDelete={() => setDeleteModal({ status: true, data: ad })}
            />
          ))
        ) : (
          <NoCampaign />
        )}
      </div>

      {viewRilesModal.status && <ViewRulesModal viewRilesModal={viewRilesModal} onCloseModal={() => setViewRilesModal({ status: false })} />}
      {toggleModal.status && (
        <ToggleModal
          loading={toggleCampaignLoading}
          toggleModal={toggleModal}
          onCloseModal={() => setToggleModal({ status: false })}
          handleToggle={handleToggleCampaign}
        />
      )}
      {createCampaignModal.status && (
        <CreateCampaignModal
          createCampaignModal={createCampaignModal}
          setCreateCampaignModal={setCreateCampaignModal}
          getAllAds={getAllAds}
          onCloseModal={() => setCreateCampaignModal({ status: false })}
        />
      )}
      {deleteModal.status && (
        <DeleteModal
          loading={deleteCampaignLoading}
          deleteModal={deleteModal}
          deleteModalStates={deleteModalStates}
          setDeleteModalStates={setDeleteModalStates}
          onCloseModal={handleCloseDeleteModal}
          handleDelete={handleDeleteCampaign}
        />
      )}
    </Content>
  );
};

export default Campaigns;
