import React from 'react';
import useAllClinics from './useAllClinics';

const useGetAllClinics = ({ pageNumber, perpageData }: { pageNumber: number; perpageData: number }) => {
  const { clinicsData, getClinicsData, clinicsDataLoading } = useAllClinics({ pageNumber, perpageData });

  React.useEffect(() => {
    getClinicsData();
    // eslint-disable-next-line
  }, [pageNumber]);

  return {
    clinicsData,
    getClinicsData,
    clinicsDataLoading,
  };
};

export default useGetAllClinics;
