export const keys = [
	{
		label: "Risk Rating",
		value: "riskRating",
	},
	{
		label: "Redflag",
		value: "redflag",
	},
	{
		label: "Side-effect",
		value: "sideeffects",
	},
	{
		label: "Insurance",
		value: "insurance",
	},
	{
		label: "Clinic",
		value: "clinic",
	},
	{
		label: "Pain Location",
		value: "locationpain",
	},
	{
		label: "Pain Duration",
		value: "duration",
	},
	{
		label: "Extended-Release Opioid",
		value: "extendedReleaseOpioid",
	},
];

export const riskRatingFields = [
	{
		label: "High",
		value: "high",
	},
	{
		label: "Medium",
		value: "medium",
	},
	{
		label: "Low",
		value: "low",
	},
];

export const painDurationFields = [
	{
		label: "Acute",
		value: "acute",
	},
	{
		label: "Subacute",
		value: "subacute",
	},
	{
		label: "Chronic",
		value: "chronic",
	},
];

export const redFlagsFields = [
	{
		label: "Diversion",
		value: "diversion",
	},
	{
		label: "High BMI",
		value: "highBMI",
	},
	{
		label: "History of Overdose",
		value: "historyOfOverdose",
	},
	{
		label: "No Alt Treatment",
		value: "noAltTreatment",
	},
	{
		label: "Opioid Concomitant",
		value: "opioidConcomitant",
	},
	{
		label: "Possible Addiction",
		value: "possibleAddiction",
	},
	{
		label: "Tapering Opioid",
		value: "yaperingOpioid",
	},
	{
		label: "Weight Loss",
		value: "weightLoss",
	},
];

export const sideEffectsFields = [
	{
		label: "Appetite",
		value: "appetite",
	},
	{
		label: "Burn Tingle",
		value: "burnTingle",
	},
	{
		label: "Constipation",
		value: "constipation",
	},
	{
		label: "Depression",
		value: "depression",
	},
	{
		label: "Diarrhea",
		value: "diarrhea",
	},
	{
		label: "Dizzy",
		value: "dizzy",
	},
	{
		label: "Drowsiness",
		value: "drowsiness",
	},
	{
		label: "DryMouth",
		value: "dryMouth",
	},
	{
		label: "DSexDrive",
		value: "dSexDrive",
	},
	{
		label: "Headache",
		value: "headache",
	},
	{
		label: "Itchy Sweaty",
		value: "itchySweaty",
	},
	{
		label: "Memory",
		value: "memory",
	},
	{
		label: "Nausea",
		value: "nausea",
	},
	{
		label: "Pain Sensitivity",
		value: "painSensitivity",
	},
	{
		label: "Sleep Issues",
		value: "sleepIssues",
	},
	{
		label: "Stomach Issues",
		value: "stomachIssues",
	},
	{
		label: "Suicidal Thoughts",
		value: "suicidalThoughts",
	},
	{
		label: "Tolerance",
		value: "tolerance",
	},
	{
		label: "Unsteadiness",
		value: "unsteadiness",
	},
	{
		label: "Weakness",
		value: "weakness",
	},
	{
		label: "Withdrawal",
		value: "withdrawal",
	},
];

export const insuranceFields = [
	{
		label: "United Healthcare",
		value: "unitedHealthcare",
	},
	{
		label: "Medicaid",
		value: "medicaid",
	},
	{
		label: "Humana",
		value: "humana",
	},
	{
		label: "Medicare",
		value: "medicare",
	},
	{
		label: "Cigna",
		value: "cigna",
	},
	{
		label: "Blue Cross Blue Shield",
		value: "blueCrossBlueShield",
	},
	{
		label: "Aetna",
		value: "aetna",
	},
	{
		label: "Other",
		value: "other",
	},
	{
		label: "I am uninsured",
		value: "iAmUninsured",
	},
];

export const clinicFields = [
	{
		label: "Clinic",
		value: "unitedHealthcare",
	},
	{
		label: "Medicaid",
		value: "medicaid",
	},
	{
		label: "Humana",
		value: "humana",
	},
	{
		label: "Medicare",
		value: "medicare",
	},
	{
		label: "Cigna",
		value: "cigna",
	},
	{
		label: "Blue Cross Blue Shield",
		value: "blueCrossBlueShield",
	},
	{
		label: "Aetna",
		value: "aetna",
	},
	{
		label: "Other",
		value: "other",
	},
	{
		label: "I am uninsured",
		value: "iAmUninsured",
	},
];

export const extendedOpioidFields = [
	{
		label: "Yes",
		value: "yes",
	},
	{
		label: "No",
		value: "no",
	},
];
