import React from 'react';
import toast from 'react-hot-toast';
import useApi from '../useApi';
import { IProviderTimingRes } from './index.type';

const useProviderTiming = ({ doctorId, date }: { doctorId: string; date: string }) => {
  const [providerTimingData, setProviderTimingData] = React.useState<IProviderTimingRes>();

  const { loading: providerTimingLoading, makeApiCall } = useApi({
    url: `/appointment/get-time-data?doctor=${doctorId}&appointmentDate=${date}`,
    method: 'GET',
  });

  const getProviderTiming = async () => {
    const { response, error } = await makeApiCall<undefined, IProviderTimingRes>();
    if (response?.status) {
      setProviderTimingData(response);
    }

    if (!response?.status) {
      setProviderTimingData(undefined);
    }

    if (error) toast.error('Something went wrong while fetching provider timing. Please try again later');
  };

  return {
    providerTimingData,
    getProviderTiming,
    providerTimingLoading,
  };
};

export default useProviderTiming;
