import { TableHeadDataProps } from '../../components/Table/TableHead';

export const clinicTableHeadData: TableHeadDataProps[] = [
  { id: 0, title: '' },
  { id: 1, title: 'Name' },
  { id: 2, title: 'E-mail' },
  { id: 3, title: 'Phone' },
  { id: 4, title: 'Address' },
  { id: 5, title: 'Latitude' },
  { id: 6, title: 'Longitude' },
  { id: 7, title: 'Distance' },
  { id: 8, title: 'Clinic ID' },
  { id: 9, title: 'Status' },
  { id: 10, title: 'Actions' },
];
