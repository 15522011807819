import React from 'react';
import classes from './HomeScreen.module.css';

const Chart3 = () => {
  return (
    <div className={classes.chart3}>
      <p className={classes.chart4_heading}>Employees per department</p>
      <div className={classes.chart3_chart}>
        <p className={classes.chart3_chart_text}>Cardio</p>
        <div className={classes.chart3_chart_bar}>
          <div style={{ width: `${(34 * 100) / 123}%` }}></div>
          <p>34</p>
        </div>
      </div>
      <div className={classes.chart3_chart}>
        <p className={classes.chart3_chart_text}>Surgery</p>
        <div className={classes.chart3_chart_bar}>
          <div style={{ width: `${(20 * 100) / 123}%` }}></div>
          <p>20</p>
        </div>
      </div>
      <div className={classes.chart3_chart}>
        <p className={classes.chart3_chart_text}>Neuro</p>
        <div className={classes.chart3_chart_bar}>
          <div style={{ width: `${(54 * 100) / 123}%` }}></div>
          <p>54</p>
        </div>
      </div>
      <div className={classes.chart3_chart}>
        <p className={classes.chart3_chart_text}>Paedics</p>
        <div className={classes.chart3_chart_bar}>
          <div style={{ width: `${(15 * 100) / 123}%` }}></div>
          <p>15</p>
        </div>
      </div>
    </div>
  );
};

export default Chart3;
